import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TitleBackHandler from 'components/organisms/TitleBackHandler';
import OrderCompletedContainer from 'containers/OrderCompletedContainer';
import SLUG_PARAMS from 'utils/constants';

const UserOrderDetailContainer: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const backToUserOrder = () => {
    navigate(`${SLUG_PARAMS.CUSTOMER_INFO}?key=order-history`);
  };

  return (
    <div className='container mx-auto'>
      <button
        type='button'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className='m-backPopupButton items-center text-md border rounded-lg hidden md:flex bg-white mb-6 border-brilliant-azure h-9 mt-2 md:mt-0 px-4 py-2 w-fit text-gunmetal hover:text-brilliant-azure hover:bg-alice-blue transition-all group'
        onClick={backToUserOrder}
      >
        <svg className='mr-1' width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.14645 3.64645C6.34171 3.45118 6.65829 3.45118 6.85355 3.64645C7.04882 3.84171 7.04882 4.15829 6.85355 4.35355L3.70711 7.5L13.5 7.5C13.7761 7.5 14 7.72386 14 8C14 8.27614 13.7761 8.5 13.5 8.5L3.70713 8.5L6.85355 11.6464C7.04882 11.8417 7.04882 12.1583 6.85355 12.3535C6.65829 12.5488 6.34171 12.5488 6.14645 12.3535L2.14646 8.35354C1.95119 8.15828 1.95119 7.8417 2.14646 7.64644L6.14645 3.64645Z'
            fill={isHovered ? '#3D8EFF' : '#2E3538'}
            stroke={isHovered ? '#3D8EFF' : '#2E3538'}
            strokeWidth='0.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>

        Đơn hàng
      </button>
      <TitleBackHandler onBack={backToUserOrder}>
        Chi tiết đơn hàng
      </TitleBackHandler>
      <OrderCompletedContainer isUser isShowProfile />
    </div>
  );
};

export default UserOrderDetailContainer;
