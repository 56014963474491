/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IProduct } from 'types/search';

interface IInitialState {
  search: string;
  data: IProduct[];
}

const initialState: IInitialState = {
  search: '',
  data: [],
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery: ($state, action: PayloadAction<any>) => {
      $state.search = action.payload;
    },
    setSearchData: ($state, action: PayloadAction<any>) => {
      $state.data = action.payload;
    },
  },
});

export const { setSearchQuery, setSearchData } = searchSlice.actions;
export default searchSlice.reducer;
