/* eslint-disable implicit-arrow-linebreak */
import * as Yup from 'yup';

const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Cần phải nhập mật khẩu')
    .min(8, 'Mật khẩu phải dài ít nhất 8 ký tự')
    .matches(/\d/, 'Mật khẩu phải chứa ít nhất một số')
    // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      'Mật khẩu phải chứa ít nhất một ký tự đặc biệt'
    ),

  rePassword: Yup.string()
    .required('Cần phải xác nhận mật khẩu')
    .oneOf([Yup.ref('password')], 'Xác nhận mật khẩu không khớp'),
});

export const addressFormSchema = Yup.object().shape({
  lastName: Yup.string().required('Cần phải nhập họ'),
  firstName: Yup.string().required('Cần phải nhập tên riêng'),
  phoneNumber: Yup.string().required('Cần phải nhập số điện thoại'),
  email: Yup.string().email('Email không hợp lệ'),
  specificAddress: Yup.string().required('Cần phải nhập địa chỉ cụ thể'),
});

export const changePasswordLoginedSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .default('')
    .required('Cần phải nhập mật khẩu')
    .when('$hasPassword', {
      is: true,
      then: (schema) =>
        schema
          .required('Cần phải nhập mật khẩu cũ')
          .min(8, 'Mật khẩu phải dài ít nhất 8 ký tự')
          .matches(/\d/, 'Mật khẩu phải chứa ít nhất một số')
          // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
          .matches(
            /[!@#$%^&*(),.?":{}|<>]/,
            'Mật khẩu phải chứa ít nhất một ký tự đặc biệt'
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
  password: Yup.string()
    .required('Cần phải nhập mật khẩu')
    .min(8, 'Mật khẩu phải dài ít nhất 8 ký tự')
    .matches(/\d/, 'Mật khẩu phải chứa ít nhất một số')
    // .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      'Mật khẩu phải chứa ít nhất một ký tự đặc biệt'
    ),

  rePassword: Yup.string()
    .required('Cần phải xác nhận mật khẩu')
    .oneOf([Yup.ref('password')], 'Xác nhận mật khẩu không khớp'),
});

export default changePasswordSchema;
