/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef } from 'react';

import { CustomerAddressFormType } from '../CustomerAddressForm';
import CustomerAddressForm2 from '../CustomerAddressForm2';

import { AddressItemType } from 'components/organisms/AddressSelector';
import CustomModal from 'components/organisms/Modal';

interface UpdateAddressModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  handleSubmit: (data: CustomerAddressFormType) => void;
  title: string;
  userAddress?: AddressItemType
  isNewAddress?: boolean
}

const UpdateAddressModal: React.FC<UpdateAddressModalProps> = (
  {
    isOpen, onRequestClose, handleSubmit, title, userAddress, isNewAddress
  }
) => {
  const addressFormRef = useRef<any>(null);

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      className='max-w-3xl'
      isOverflow
      shouldCloseOnOverlayClick={false}
    >
      <div className='t-updateAddressModal py-4'>
        <CustomerAddressForm2
          ref={addressFormRef}
          onSubmit={handleSubmit}
          onRequestClose={onRequestClose}
          data={userAddress as any}
          isNewAddress={isNewAddress}
        />
      </div>
    </CustomModal>
  );
};

export default UpdateAddressModal;
