import classNames from 'classnames';
import React, {
  forwardRef, useCallback, useMemo, useState
} from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'number' | 'email' | 'password' | 'tel';
  sizeVariant?: 'base' | 'sm';
  error?: string;
  isSuccess?: boolean;
  loading?: boolean;
  isReadOnly?: boolean;
  className?: string;
  handleClear?: () => void;
}

const InputRef: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({
  type,
  placeholder,
  value,
  error,
  isSuccess,
  disabled,
  sizeVariant = 'base',
  className = '',
  handleClear,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  const inputType = useMemo(() => {
    if (type === 'password') {
      if (showPassword) {
        return 'text';
      }
      return 'password';
    }
    return type;
  }, [type, showPassword]);

  const inputClassNames = classNames(
    `border-1 border-gainsboro rounded-lg w-full focus:outline-none font-medium shadow-none text-gunmetal placeholder:text-roman-silver ${className}`,
    {
      'border-gainsboro-300 boreder-1': !error && !isSuccess && !disabled,
      'border-carmine-pink boreder-1': error,
      'border-medium-turquoise boreder-1': isSuccess,
      'bg-gainsboro boreder-1': disabled,
      'cursor-not-allowed': disabled,
      'bg-anti-flash-white2': disabled,
      'focus:border-brilliant-azure': true,
      'focus:shadow-input-focus': true,
      'px-4 py-2 h-[48px] text-base leading-6': sizeVariant === 'base',
      'p-2 h-[36px] text-md leading-4': sizeVariant === 'sm',
      'pr-8': !!handleClear
    }
  );

  return (
    <div className='a-input'>
      <div className='relative'>
        <input
          type={inputType === 'password' && showPassword ? 'text' : inputType}
          placeholder={placeholder}
          value={value}
          className={inputClassNames}
          disabled={disabled}
          {...props}
        />
        {handleClear && value && !disabled && (
          <button
            type='button'
            onClick={handleClear}
            className='absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none'
          >
            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M9.99935 1.66699C5.40768 1.66699 1.66602 5.40866 1.66602 10.0003C1.66602 14.592 5.40768 18.3337 9.99935 18.3337C14.591 18.3337 18.3327 14.592 18.3327 10.0003C18.3327 5.40866 14.591 1.66699 9.99935 1.66699ZM12.7993 11.917C13.041 12.1587 13.041 12.5587 12.7993 12.8003C12.6743 12.9253 12.516 12.9837 12.3577 12.9837C12.1993 12.9837 12.041 12.9253 11.916 12.8003L9.99935 10.8837L8.08268 12.8003C7.95768 12.9253 7.79935 12.9837 7.64102 12.9837C7.48268 12.9837 7.32435 12.9253 7.19935 12.8003C6.95768 12.5587 6.95768 12.1587 7.19935 11.917L9.11602 10.0003L7.19935 8.08366C6.95768 7.84199 6.95768 7.44199 7.19935 7.20033C7.44102 6.95866 7.84102 6.95866 8.08268 7.20033L9.99935 9.11699L11.916 7.20033C12.1577 6.95866 12.5577 6.95866 12.7993 7.20033C13.041 7.44199 13.041 7.84199 12.7993 8.08366L10.8827 10.0003L12.7993 11.917Z' fill='#84939A' />
            </svg>

          </button>
        )}
        {type === 'password' && !disabled && (
          <button
            type='button'
            onClick={handleClick}
            className='absolute inset-y-0 right-0 px-3 py-2 text-md font-medium text-brilliant-azure'
          >
            {!showPassword ? 'Hiện' : 'Ẩn'}
          </button>
        )}
      </div>
      {error && <p className='mt-1 text-carmine-pink text-sm'>{error}</p>}
    </div>
  );
};

const Input = forwardRef(InputRef);

export default Input;
