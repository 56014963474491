/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { toast } from 'react-toastify';

import InputPhoneNumber from './phone';

import AuthenticationWrapper from 'components/templates/AuthenticationWrapper';
import OTPLinkZalo, { OTPVerifyActionRef } from 'containers/Authenticate/password/otpForgotPassword';
import { profileApi, resendProfileOtpApi } from 'services/auth';
import { linkPhoneApi, verifyOtpLinkApi } from 'services/profile';
import { setIsSignInZalo, setUser } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const LINK_PHONE_STEP = {
  PHONE: 1,
  OTP: 2,
};

const LinkPhoneModal: React.FC = () => {
  const [step, setStep] = React.useState<number>(1);
  const [errorText, setErrorText] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingSendOtp, setLoadingSendOtp] = React.useState<boolean>(false);
  const [phone, setPhone] = React.useState<string>('');
  const otpForgotPasswordRef = React.useRef<OTPVerifyActionRef>(null);
  const { isSignInZalo } = useAppSelector((state) => state.auth);
  const [customerId, setCustomerId] = React.useState<any>(null);
  const [isResend, setIsReSend] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(setIsSignInZalo(false));
  };

  const handleSubmitPhoneNumber = async (data: any) => {
    try {
      setLoadingSendOtp(true);
      const response = await linkPhoneApi({ PhoneNumber: data?.phone });
      toast.success('Đã gửi mã OTP về số điện thoại của bạn');
      setCustomerId(response.customerId);
      setPhone(data.phone);
      setStep(2);
      setLoadingSendOtp(false);
    } catch (error: any) {
      setLoadingSendOtp(false);
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleSubmitOtp = async () => {
    try {
      setLoading(true);
      const data: any = await otpForgotPasswordRef.current?.handleForm();
      await verifyOtpLinkApi({
        Identifier: phone,
        VerificationCode: data.otp,
        CustomerId: customerId,
      });
      const profile = await profileApi();
      dispatch(setUser(profile.data));
      toast.success('Liên kết thành công');
      setLoading(false);
      handleClose();
    } catch (error: any) {
      setLoading(false);
      setErrorText('Mã xác thực không hợp lệ');
    }
  };

  const handleResendOtp = async () => {
    try {
      const response: any = await resendProfileOtpApi({ Identifier: phone });
      setCustomerId(response.customerId);
      toast.success('Đã gửi lại mã OTP đến số điện thoại của bạn');
    } catch (error: any) {
      setIsReSend(false);
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };

  const renderStep = () => {
    switch (step) {
      case LINK_PHONE_STEP.PHONE:
        return <InputPhoneNumber loading={loadingSendOtp} handleSubmit={handleSubmitPhoneNumber} handleClose={handleClose} />;
      case LINK_PHONE_STEP.OTP:
        return (
          <OTPLinkZalo
            isCustomerExist
            handleBack={() => { setStep(1); setErrorText(''); }}
            isResend={isResend}
            loading={loading}
            handleResend={(value) => {
              handleResendOtp();
              setIsReSend(value);
            }}
            phone={phone}
            handleSubmit={handleSubmitOtp}
            errorText={errorText}
            ref={otpForgotPasswordRef}
          />
        );
      default: return null;
    }
  };

  return (
    <AuthenticationWrapper isOpen={isSignInZalo} handleClose={handleClose}>
      {renderStep()}
    </AuthenticationWrapper>
  );
};

export default LinkPhoneModal;
