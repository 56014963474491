/* eslint-disable import/prefer-default-export */
import axiosInstance from 'services/common/instance';
import { IAddNewPasswordRequest, IUpdatePasswordRequest, IVerifyOtp } from 'types/auth';
import {
  BcoinsHistory,
  CustomerInfo,
  IDataAvatar,
  IDataUser,
  IParamsAddress,
  IProvinces,
  ISendLinkOtpResponse,
  LinkEmailRequest,
  LinkPhoneRequest,
} from 'types/users';

export function updateProfileApi(params: any): Promise<{ data: CustomerInfo }> {
  return axiosInstance.post('/customer/info', { data: params });
}

export function linkPhoneApi(
  params: LinkPhoneRequest
): Promise<ISendLinkOtpResponse> {
  return axiosInstance.post('/profile/link_phone', params);
}

export function linkEmailApi(
  params: LinkEmailRequest
): Promise<ISendLinkOtpResponse> {
  return axiosInstance.post('/profile/link_email', params);
}

export function verifyOtpLinkApi(
  params: IVerifyOtp
): Promise<{ data: CustomerInfo }> {
  return axiosInstance.post('/profile/verify_otp_link', params);
}

export function updatePasswordApi(
  params: IUpdatePasswordRequest
): Promise<{ data: CustomerInfo }> {
  return axiosInstance.post('/profile/set-password', params);
}

export function addNewPasswordApi(
  params: IAddNewPasswordRequest
): Promise<{ data: any }> {
  return axiosInstance.post('/profile/password', params);
}

export function getHistoryRewardPoints(
  page: number
): Promise<{ data: BcoinsHistory }> {
  return axiosInstance.get(`/order/customerrewardpoints/${page}`);
}
export function getDeliveryInfoApi(): Promise<{ data: IDataUser }> {
  return axiosInstance.get('/customer/addresses');
}

export function addDeliveryAddressApi(
  params: IParamsAddress,
  formValues?: any
): Promise<{ data: CustomerInfo }> {
  return axiosInstance.post('/customer/addressadd', {
    data: { Address: params },
    formValues,
  });
}

export function editDeliveryAddressApi(
  params: IParamsAddress,
  id: number,
  formValues?: any
): Promise<{ data: CustomerInfo }> {
  return axiosInstance.post(`/customer/addressedit/${id}`, {
    data: { Address: params },
    formValues,
  });
}

export function deleteDeliveryAddressApi(
  id: number
): Promise<{ data: CustomerInfo }> {
  return axiosInstance.post(`/customer/addressdelete/${id}`);
}

export function getProvincesInfoApi(): Promise<IProvinces[]> {
  return axiosInstance.get('/country/provinces');
}

export function updateAvatarApi(params: any): Promise<{}> {
  return axiosInstance.post('/customer/uploadavatar', params);
}

export function getAvatarApi(): Promise<{ data: IDataAvatar }> {
  return axiosInstance.get('/customer/avatar');
}
