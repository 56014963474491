import React from 'react';

import MatchHeight from 'components/organisms/MatchHeight';
import ProductCard, { ProductType } from 'components/organisms/ProductCard';

interface ProductGridProps {
  products?: ProductType[];
  isLoadingFetch?: boolean;
  refetchData?: () => void;
}

const ProductGrid: React.FC<ProductGridProps> = ({
  products = [],
  isLoadingFetch = false,
  refetchData = () => { },
}) => (
  <div className='mt-6'>
    <p className='max-w-lg text-xl lg:text-2xl font-semibold leading-normal'>
      Sản phẩm nổi bật
    </p>
    <MatchHeight matchClassName='o-productCard__title' containerClassname='t-productGrid grid grid-cols-2 xl:grid-cols-5 md:grid-cols-3 gap-3 lg:gap-4 mt-3'>
      {
        products.map((v, i) => (
          <ProductCard key={`item-${i.toString()}`} data={v} isLoadingFetch={isLoadingFetch} refetchData={refetchData} />
        ))
      }
    </MatchHeight>
  </div>
);

export default ProductGrid;
