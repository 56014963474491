import React, { useEffect, useRef } from 'react';

interface MatchHeightProps {
  children: React.ReactNode;
  matchClassName: string;
  containerClassname?: string;
}

const MatchHeight: React.FC<MatchHeightProps> = (
  { children, matchClassName, containerClassname }
) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const updateHeight = () => {
    const container = containerRef.current;
    if (container) {
      const elements = Array.from(container.querySelectorAll(`.${matchClassName}`));
      let maxHeight = 0;

      elements.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        (element as HTMLElement).style.height = 'auto';
      });

      elements.forEach((element) => {
        const elementHeight = element.getBoundingClientRect().height;
        if (elementHeight > maxHeight) {
          maxHeight = elementHeight;
        }
      });

      // Set all elements with the specified class name to the maximum height
      elements.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        (element as HTMLElement).style.height = `${maxHeight}px`;
      });
    }
  };

  useEffect(() => {
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, matchClassName]);

  return <div className={containerClassname} ref={containerRef}>{children}</div>;
};

export default MatchHeight;
