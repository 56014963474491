import React from 'react';

interface DividerProps {
}

const Divider: React.FC<DividerProps> = () => (
  <div className='a-divider h-[1px] w-full bg-gainsboro my-4 lg:my-6' />
);

export default Divider;
