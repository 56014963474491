/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
import { useGoogleLogin } from '@react-oauth/google';

import { linkSocial, profileApi } from 'services/auth';
import { setCodeVerifier, setIsLinkZalo, setUser } from 'store/auth';
import { useAppDispatch } from 'store/hooks';
import {
  generateCodeChallengeFromVerifier,
  generateCodeVerifier,
} from 'utils/functions';

const useLinkSocial = () => {
  const dispatch = useAppDispatch();
  const googleLinkSocial = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await linkSocial({
        Provider: 'Google',
        SocialAccessToken: tokenResponse.access_token,
      });
      const profile = await profileApi();
      dispatch(setUser(profile.data));
    },
  });

  const zaloLinkSocial = async () => {
    try {
      dispatch(setIsLinkZalo(true));
      const codeVerifier = generateCodeVerifier();
      dispatch(setCodeVerifier(codeVerifier));
      const codeChallenge = await generateCodeChallengeFromVerifier(
        codeVerifier
      );
      const url = `https://oauth.zaloapp.com/v4/permission?app_id=${process.env.REACT_APP_ZALO_APP_ID}&code_challenge=${codeChallenge}&redirect_uri=https://bidahub.com/callback/zalo&state=2000`;
      window.open(url, '_self');
    } catch (error: any) {
      //
      dispatch(setIsLinkZalo(false));
      dispatch(setCodeVerifier(''));
    }
  };

  // const handleUnlinkSocial = async (key: string) => {
  //   await unlinkSocial({
  //     Provider: key,
  //   });
  // };

  return {
    googleLinkSocial,
    // handleUnlinkSocial,
    zaloLinkSocial,
  };
};

export default useLinkSocial;
