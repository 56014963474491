/* eslint-disable @typescript-eslint/no-unused-vars */
import { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { setIsLogout, setLoggedIn } from 'store/auth';
import { useAppSelector } from 'store/hooks';

type ProtectedRouteProps = PropsWithChildren<any>;

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { user, isLogout } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user === null) {
      const returnUrl = encodeURIComponent(location.pathname + location.search);
      const isLoginFirst = false;

      if (isLogout) {
        dispatch(setLoggedIn(false));
        dispatch(setIsLogout(false));
      } else {
        navigate(`/?returnUrl=${returnUrl}&isLoginFirst=${isLoginFirst}`, {
          replace: true,
          state: { message: 'You must be logged in to view this page' },
        });
        dispatch(setLoggedIn(true));
        dispatch(setIsLogout(false));
      }
    }
  }, [navigate, user, location.pathname, location.search]);

  return children;
}
