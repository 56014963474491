import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import BackPopupButton from 'components/molecules/BackPopupButton';
import { detectEmailOrPhone } from 'utils/functions';

type PasswordScreenTypes = {
  password: string;
};

export interface PasswordScreenActionRef {
  handleGetValue: () => PasswordScreenTypes;
  handleForm: () => Promise<PasswordScreenTypes | undefined>;
  handleReset: (data: PasswordScreenTypes) => void,
}

interface PasswordScreenProps {
  phone: string;
  loading: boolean;
  handleBack: () => void;
  handleSwitchOtp: () => void;
  handleForgotPassword: () => void;
  handleSubmit: () => void;
  errorText?: string;
  loadingOtp?: boolean
}

const PasswordScreen = forwardRef<PasswordScreenActionRef, PasswordScreenProps>(({
  handleBack,
  phone,
  loading,
  handleSwitchOtp,
  handleForgotPassword,
  handleSubmit,
  errorText,
  loadingOtp
}, ref) => {
  const method = useForm<PasswordScreenTypes>({
    mode: 'onChange',
    defaultValues: {
      password: '',
    },
  });

  const passwordValidationRules = {
    required: 'Cần phải nhập mật khẩu',
    minLength: {
      value: 8,
      message: 'Mật khẩu phải dài ít nhất 8 ký tự',
    },
    validate: {
      hasNumber: (value: string) => /\d/.test(value) || 'Mật khẩu phải chứa ít nhất một số',
      hasSpecialChar: (value: string) => /[!@#$%^&*(),.?":{}|<>]/.test(value) || 'Mật khẩu phải chứa ít nhất một ký tự đặc biệt',
    },
  };

  useImperativeHandle(ref, () => ({
    handleGetValue: () => method.getValues(),
    handleForm: async () => {
      method.trigger();
      let result: PasswordScreenTypes | undefined;
      await method.handleSubmit((data: PasswordScreenTypes) => {
        result = data;
      })();
      return result;
    },
    handleReset: (data) => method.reset(data),
  }));
  return (
    <div className='h-full'>
      <BackPopupButton onClick={handleBack} />
      <h2 className='text-lg md:text-xl font-semibold text-[#1F2128] mb-2 mt-4'>Nhập mật khẩu</h2>
      <p className='text-feldgrau mb-4 lg:mb-8'>
        Vui lòng nhập mật khẩu BidaHub của {detectEmailOrPhone(phone) === 'phone' ? 'số điện thoại' : 'email'}
        <span className='ml-1 text-feldgrau font-semibold'>
          {phone}
        </span>
      </p>
      <label className='block text-[#1F2128] font-semibold mb-3' htmlFor='password'>Mật khẩu</label>
      <FormProvider {...method}>
        <form
          noValidate
          onSubmit={method.handleSubmit(() => {
            handleSubmit();
          })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              method.handleSubmit(() => {
                handleSubmit();
              })();
            }
          }}
        >
          <Controller
            name='password'
            rules={passwordValidationRules}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder='Nhập mật khẩu'
                error={fieldState?.error?.message || errorText}
                required
                type='password'
              />
            )}

          />
        </form>
      </FormProvider>
      <Button
        type='submit'
        size='large'
        loading={loading}
        variants='neon-fuchsia'
        className='mt-6'
        onClick={() => method.handleSubmit(() => {
          handleSubmit();
        })()}
      >
        Tiếp Tục
      </Button>
      <div className='flex justify-between py-4 bg-background mt-2'>
        <div className='text-sm text-[#3D8EFF] hover:opacity-70 cursor-pointer' onClick={handleForgotPassword}>Quên Mật Khẩu?</div>
        <div
          className='text-sm text-[#3D8EFF] hover:opacity-70 cursor-pointer'
          onClick={!loadingOtp ? handleSwitchOtp : () => { }}
        >Đăng Nhập Nhanh Bằng Mã OTP - Zalo hoặc Email
        </div>
      </div>
    </div>
  );
});
export default PasswordScreen;
