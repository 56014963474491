import React, { useState } from 'react';

import Button from 'components/atoms/Button';
import CustomModal from 'components/organisms/Modal';
import { calculatePoint } from 'utils/functions';

interface Props {
  bcoin: number;
  isFull?: boolean;
}

const ReceiveText: React.FC<Props> = ({ bcoin, isFull }) => {
  const [bCoinOpen, setBCoinOpen] = useState(false);
  return (
    <>
      <div className='receive text-md mt-2 md:mt-3'>
        {
          isFull ? <p className='inline'>Sẽ được nhận: </p> : <p className='hidden md:inline'>Sẽ được nhận: </p>
        }
        <p className='text-persian-green inline'>
          {!isFull && <span className='inline md:hidden'>+ </span>}
          {calculatePoint(bcoin)} <span className='underline cursor-pointer' onClick={() => setBCoinOpen(true)}>Bcoin</span>
        </p>
      </div>
      {/* BCoin modal  */}
      <CustomModal
        isOpen={bCoinOpen}
        onRequestClose={() => setBCoinOpen(false)}
        className='max-w-[720px]'
      >
        <div className='p-6'>
          <p className='text-gunmetal'>
            Bida coin (Bcoin) là điểm thưởng tích lũy khi mua sắm trên BidaHub,
            tạm tính ở mức 5% giá trị sản phẩm. Bcoin có thể dùng để thanh toán cho
            các giao dịch sau (1 Bcoin = 1 VND). Lưu ý: Số Bcoin thực nhận sẽ được
            điều chỉnh sau khi áp dụng các khuyến mãi tại bước thanh toán.
          </p>
        </div>
        <div className='footer py-4 px-6 border-t-1 border-gainsboro flex justify-center'>
          <Button
            onClick={() => setBCoinOpen(false)}
            size='large'
            variants='secondary'
            className='max-w-[100px]'
          >
            Đã Hiểu
          </Button>
        </div>
      </CustomModal>
    </>
  );
};

export default ReceiveText;
