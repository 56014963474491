import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  total: number;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage, totalPages, onPageChange, total
}) => {
  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i += 1) {
        pageNumbers.push(i);
      }
    } else if (currentPage <= 3) {
      pageNumbers.push(1, 2, 3, 4, '...', totalPages);
    } else if (currentPage > 3 && currentPage < totalPages - 2) {
      pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
    } else {
      pageNumbers.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
    }
    return pageNumbers;
  };

  const handlePageChange = (page: number | string) => {
    if (typeof page === 'number' && page !== currentPage) {
      onPageChange(page);
    }
  };

  return (
    <div className='m-pagination flex items-center space-x-2 justify-center md:justify-start'>
      <span className='mr-2 text-md hidden md:inline'>Trang {currentPage} của {totalPages} (tổng {total})</span>
      {
        currentPage > 1
        && (
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className='text-md px-3 py-1 border rounded bg-white text-gunmetal w-9 h-9'
            disabled={currentPage === 1}
          >
            &lt;
          </button>
        )
      }
      {getPageNumbers().map((page, index) => (
        <button
          key={`page-${index.toString()}`}
          onClick={() => handlePageChange(page)}
          className={`text-md w-9 h-9 p-1 border text-center rounded-md ${currentPage === page ? 'bg-blueRyb text-white border-blueRyb' : 'bg-white text-gunmetal border-gainsboro base-transition'}`}
          disabled={typeof page === 'string'}
        >
          {page}
        </button>
      ))}
      {
        currentPage !== totalPages
        && (
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className='text-md px-3 py-1 border rounded bg-white text-gunmetal w-9 h-9'
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        )
      }
    </div>
  );
};

export default Pagination;
