/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { ProductCardSkeleton } from 'components/atoms/Skeleton';
import Pagination from 'components/molecules/Pagination';
import MatchHeight from 'components/organisms/MatchHeight';
import ProductCard from 'components/organisms/ProductCard';
import TitleBackHandler from 'components/organisms/TitleBackHandler';
import { getDataSubcriptionListApi } from 'services/subcriptions';

interface NotifyProductProps {
  onBack?: () => void
}
const NotifyProduct: React.FC<NotifyProductProps> = ({
  onBack
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    pagenumber: 1,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchSubscriptions = async (refetch = false) => {
    try {
      if (!refetch) {
        setLoading(true);
      }
      const response = await getDataSubcriptionListApi(params);
      const dataParse = response.data.subscriptions.map((item: any) => ({
        id: item?.id || 0,
        title: item?.name || '',
        imageSrc: item?.defaultPictureModel?.imageUrl || 'https://picsum.photos/200',
        price: item?.productPrice?.priceValue,
        discount: item?.discountValue,
        count: item?.stockAvailability || 0,
        attributeInfo: item?.attributeInfo,
        productId: item?.id,
        productAttributes: item?.productAttributes,
        inWishlist: item?.inWishlist,
        wishlistId: item?.wishlistId,
        outStock: !item?.inStock || true,
        alreadySubscribed: item?.alreadySubscribed,
      }));
      setLoading(false);
      setData(dataParse as any);
      setTotalPages(response?.data?.pagerModel?.totalPages || 1);
      setTotalRecord(response?.data?.pagerModel?.totalRecords || 0);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const dataSke = [...new Array(8)];

  const handlePageChange = (pagenumber: number) => {
    setParams((prev) => ({ ...prev, pagenumber }));
    setCurrentPage(pagenumber);
  };

  return (
    <>
      <TitleBackHandler onBack={onBack}>
        Thông báo khi có hàng
      </TitleBackHandler>
      <div>
        <p className='text-md text-feldgrau font-medium mb-3'>Bạn sẽ được thông báo qua email khi sản phẩm có hàng.</p>
        <MatchHeight matchClassName='o-productCard__title' containerClassname='t-productGrid grid grid-cols-2 xl:grid-cols-4 md:grid-cols-3 gap-3 lg:gap-4 mt-4'>
          {loading && dataSke.map((_, i) => (
            <ProductCardSkeleton />
          ))}
          {
            data?.map((v, i) => (
              <ProductCard isLoadingFetch={loading} refetchData={() => fetchSubscriptions(true)} key={`item-${i.toString()}`} data={v as any} hideCategory />
            ))
          }
          {
            totalPages > 1
            && (
              <div className='background-panel bg-white rounded-lg pagination lg:p-4 px-4 py-3'>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  total={totalRecord}
                />
              </div>
            )
          }
        </MatchHeight>
      </div>
    </>
  );
};

export default NotifyProduct;
