import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { toast } from 'react-toastify';

import {
  orderFilteringOptions
} from 'assets/dummy';
import icCloseGray from 'assets/icons/ic_close_gray.svg';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import Pagination from 'components/molecules/Pagination';
import Pulldown from 'components/molecules/Pulldown';
import { Drawer } from 'components/organisms/Drawer';
import NestedCheckbox from 'components/organisms/NestedCheckbox';
import { ProductType } from 'components/organisms/ProductCard';
import ProductGrid from 'components/organisms/ProductGrid';
import ProductSlide from 'components/templates/ProductSlide';
import useCategories from 'hooks/useCategories';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { getProductbyCategoriesIds } from 'services/catalog';
import { getFeaturedProductsApi } from 'services/home';
import { useAppSelector } from 'store/hooks';
import { IParamsGetCategoriesIds } from 'types/catalog';
import { parseProductsToCart } from 'utils/functions';

export type ManufacturesType = {
  id: number;
  name: string;
  description: string;
  image: string;
};

const breadcrumbItems = [
  { label: 'Home', path: '/' },
  { label: 'Kết quả tìm kiếm' },
];

const SearchResultContainer: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<ProductType[]>([...new Array(10)]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const keyword = useAppSelector((state: any) => state.search.search);
  const [params, setParams] = useState<IParamsGetCategoriesIds>({
    categoryIds: [],
    manufacturerIds: [],
    pagenumber: 1,
    pagesize: 5,
    ms: null,
    orderby: 5,
    q: keyword
  });
  const {
    categories, manufacturers, calculateTotalPages
  } = useCategories();
  const scrollRef: any = useRef();
  const [featuredProducts, setFeaturedProducts] = useState<ProductType[]>([...new Array(5)]);
  const [featuredIsLoading, setFeaturedIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowDimensions();

  const fetchDataSearchResult = useCallback(async (refetch = false) => {
    try {
      if (!refetch) {
        setIsLoading(true);
      }
      const response: any = await getProductbyCategoriesIds(params);
      const productParse = response?.data.products?.map(
        (product: any) => ({
          imageSrc: product?.defaultPictureModel?.imageUrl,
          title: product?.name,
          category: '',
          brand: '',
          price: product?.productPrice?.priceValue,
          rating: product?.reviewOverviewModel?.ratingSum,
          reviews: product?.reviewOverviewModel?.totalReviews,
          id: product.id,
          count: 3,
          inWishlist: product?.inWishlist,
          description: product?.shortDescription,
          outStock: !product?.inStock,
          alreadySubscribed: product?.alreadySubscribed,
          wishlistId: product?.wishlistId
        })
      );
      setTotalPages(calculateTotalPages(response.data.totalItems, params.pagesize));
      setTotalItems(response.data.totalItems);
      setData(productParse);
      setIsLoading(false);
    } catch (error: any) {
      setData([]);
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  }, [params]);

  const fetchFeaturedProduct = async (refetch = false) => {
    try {
      if (!refetch) {
        setFeaturedIsLoading(true);
      }
      const response = await getFeaturedProductsApi();
      const products = parseProductsToCart(response.data as any);
      setFeaturedProducts(products);
      setFeaturedIsLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
      setFeaturedIsLoading(false);
    }
  };

  useEffect(() => {
    if (keyword) {
      fetchDataSearchResult();
    } else {
      setData([]);
    }
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        // behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [params]);

  useEffect(() => {
    setParams((prev: any) => ({
      ...prev,
      pagenumber: 1,
      q: keyword,
      categoryIds: [],
      manufacturerIds: []
    }));
  }, [keyword]);

  useEffect(() => {
    fetchFeaturedProduct();
  }, []);

  const handlePageChange = async (pagenumber: number) => {
    setParams((prev) => ({ ...prev, pagenumber }));
  };

  return (
    <>
      <div ref={scrollRef} className='container mx-auto py-4 lg:py-6'>
        <Breadcrumbs items={breadcrumbItems} />
      </div>
      <div className='container mx-auto'>
        <h1 className='text-xl md:text-2xl lg:text-3xl font-semibold mb-4 lg:mb-4'>
          Tìm kiếm
        </h1>
        <div className='flex gap-4'>
          <div className='w-3/12 hidden lg:block'>
            <div className='flex flex-col gap-4'>
              {categories.length > 0 && (
                <NestedCheckbox
                  title='Lọc theo danh mục'
                  data={categories as any}
                  onCheckedChange={(checkedItems) => {
                    setCurrentPage(1);
                    setParams((prev) => ({
                      ...prev,
                      pagenumber: 1,
                      categoryIds: checkedItems.map((item: any) => item.id),
                      ms: null
                    }));
                  }}
                />
              )}
              {
                manufacturers?.length > 0 && (
                  <NestedCheckbox
                    title='Lọc theo nhà sản xuất'
                    data={manufacturers}
                    onCheckedChange={(checkedItems) => {
                      setCurrentPage(1);
                      setParams((prev) => ({
                        ...prev,
                        pagenumber: 1,
                        manufacturerIds: checkedItems.map((item: any) => item.id),
                        ms: null
                      }));
                    }}
                  />
                )
              }
            </div>
          </div>
          <div className='w-full lg:w-9/12'>
            {
              width < 1024 && (
                <Drawer
                  isOpen={isOpen}
                  handleClose={() => setIsOpen(!isOpen)}
                  containerClassName='px-0 overflow-y-scroll'
                  btnCloseNaked
                  iconClose={icCloseGray}
                >
                  <div className='flex flex-col gap-4 mt-[-52px]'>
                    {
                      manufacturers?.length > 0 && (
                        <NestedCheckbox
                          title='Lọc theo nhà sản xuất'
                          data={manufacturers}
                          onCheckedChange={(checkedItems) => {
                            setCurrentPage(1);
                            setParams((prev) => ({
                              ...prev,
                              pagenumber: 1,
                              manufacturerIds: checkedItems.map((item: any) => item.id),
                              ms: null
                            }));
                          }}
                        />
                      )
                    }
                    {categories.length > 0
                      && (
                        <NestedCheckbox
                          title='Lọc theo danh mục'
                          data={categories as []}
                          onCheckedChange={(checkedItems) => {
                            setCurrentPage(1);
                            setParams((prev) => ({
                              ...prev,
                              pagenumber: 1,
                              categoryIds: checkedItems.map((item: any) => item.id),
                              ms: null
                            }));
                          }}
                        />
                      )}
                  </div>
                </Drawer>
              )
            }
            {
              data.length > 0 || isLoading
                ? (
                  <div className='flex flex-col gap-4'>
                    {/* Panel  */}
                    {/* Filtering  */}
                    <div className='filtering background-panel bg-white p-3 rounded-lg'>
                      <div className='flex justify-between gap-2 lg:gap-0'>
                        <div className='flex-none block lg:hidden w-[89px]'>
                          <div onClick={() => setIsOpen(true)} className='checkbox-filtering flex gap-2 p-2 items-center border border-brilliant-azure rounded-lg'>
                            <svg className='w-5 h-5 text-gunmetal' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
                              <path stroke='currentColor' strokeLinecap='round' strokeWidth='2' d='M18.796 4H5.204a1 1 0 0 0-.753 1.659l5.302 6.058a1 1 0 0 1 .247.659v4.874a.5.5 0 0 0 .2.4l3 2.25a.5.5 0 0 0 .8-.4v-7.124a1 1 0 0 1 .247-.659l5.302-6.059c.566-.646.106-1.658-.753-1.658Z' />
                            </svg>
                            <p className='text-sm'>
                              Bộ lọc
                            </p>
                          </div>
                        </div>

                        <div className='left w-full'>
                          <Pulldown
                            placeholder={`${orderFilteringOptions?.[0].label}`}
                            isHorizontal
                            label='Sắp xếp theo'
                            classNameContainer='lg:w-[360px]'
                            className='h-9 text-sm'
                            value={String(params.orderby)}
                            onChange={(e: any) => {
                              setCurrentPage(1);
                              setParams((prev: any) => ({
                                ...prev,
                                pagenumber: 1,
                                orderby: Number(e.target.value)
                              }));
                            }}
                            options={orderFilteringOptions}
                          />
                        </div>
                        <div className='right'>
                          <Pulldown
                            isHorizontal
                            label='Hiển thị'
                            classNameContainer='lg:w-[153px]'
                            className='h-9 text-sm'
                            value={String(params.pagesize)}
                            onChange={(e: any) => {
                              setCurrentPage(1);
                              setParams((prev: any) => ({
                                ...prev,
                                pagenumber: 1,
                                pagesize: e.target.value as number
                              }));
                            }}
                            options={[
                              { label: '5', value: '5' },
                              { label: '10', value: '10' },
                              { label: '15', value: '15' },
                              { label: '20', value: '20' },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Search Text  */}
                    <p className='text-feldgrau'>
                      {totalItems} kết quả tìm kiếm cho từ khoá &quot;{keyword}&quot;
                    </p>
                    {/* Panel */}
                    <div className='background-panel'>
                      <ProductGrid
                        data={data}
                        isLoadingFetch={isLoading}
                        className='grid grid-cols-2 xl:grid-cols-4 md:grid-cols-3 gap-3 lg:gap-4'
                        refetchData={() => {
                          fetchDataSearchResult(true);
                          fetchFeaturedProduct(true);
                        }}
                      />
                    </div>
                    {/* Panel */}
                    {
                      totalPages > 1
                      && (
                        <div className='background-panel bg-white rounded-lg pagination lg:p-4 px-4 py-3'>
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            total={totalItems}
                          />
                        </div>
                      )
                    }
                  </div>
                )
                : (
                  <div className='background-panel bg-white p-3 rounded-lg min-h-[420px] text-center'>
                    <p className='text-feldgrau mt-1'>Không tìm thấy sản phẩm nào với từ khoá “{keyword}”</p>
                  </div>
                )
            }

          </div>
        </div>
        <div className='mt-6'>
          <ProductSlide refetchData={() => { fetchFeaturedProduct(true); fetchDataSearchResult(true); }} isLoadingFetch={featuredIsLoading} productData={featuredProducts} title='Gợi ý cho bạn' />
        </div>
      </div>
    </>
  );
};

export default SearchResultContainer;
