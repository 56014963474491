import React from 'react';

import { introDumCard } from 'assets/dummy';
import IntroCard from 'components/molecules/IntroCard';

const IntroCardContainer: React.FC = () => (
  <div className='flex lg:flex-wrap gap-8 lg:gap-1 py-4 lg:py-6 overflow-x-auto custom-scrollbar lg:overflow-visible'>
    {
      introDumCard.map((v, i) => (
        <div className='relative flex-none lg:flex-auto' key={`item-${i.toString()}`}>
          <IntroCard {...v} />
          {
            i !== introDumCard.length - 1 && <div className='lg:block stroke h-12 w-[1px] bg-gainsboro absolute top-0 right-[-20px] lg:right-10' />
          }
        </div>
      ))
    }
  </div>
);

export default IntroCardContainer;
