import classNames from 'classnames';
import React, { useState } from 'react';

import expressLogo from 'assets/images/express.png';

interface DeliveryPartnerProps {
  onChange?: (value: number) => void;
  isStatic?: boolean;
}

const paymentOptions = [
  {
    id: 1,
    label: 'Vận chuyển 247 Express',
    options: [
      'Nội thành HCM: 1 ngày',
      'Ngoại thành: 1-2 ngày',
      'Các tỉnh miền Trung: 3-4 ngày',
      'Các tỉnh miền Bắc: 5-6 ngày',
    ],
    logo: expressLogo
  },
];

const DeliveryPartner: React.FC<DeliveryPartnerProps> = ({ onChange, isStatic }) => {
  const [selectedMethod, setSelectedMethod] = useState<number>(paymentOptions[0].id);

  const handleSelect = (id: number) => {
    setSelectedMethod(id);
    onChange?.(id);
  };

  const generalClass = classNames(
    'flex items-center ',
    {
      'border rounded-lg cursor-pointer py-[10px] px-4': !isStatic,
    }
  );

  return (
    <div className='o-deliveryPartner space-y-4'>
      {paymentOptions.map((option) => (
        <div
          key={`item-${option.id}`}
          onClick={() => !isStatic && handleSelect(option.id)}
          className={`${generalClass} ${selectedMethod === option.id && !isStatic ? 'border-blueRyb border-[2px] shadow-input-focus' : 'border-gainsboro'}`}
        >
          <img src={option.logo} alt={option.label} className='w-8 h-8 mr-3' />
          <div className='flex-grow'>
            <div className='font-semibold text-gunmetal-700'>{option.label}</div>
            <ul className='text-roman-silver text-md list-disc pl-4'>
              {
                option.options.map((item) => (
                  <li key={item}>{item}</li>
                ))
              }
            </ul>
          </div>
          {
            !isStatic && (
              <div className='ml-4'>
                <div
                  className={`relative w-5 h-5 rounded-full border-2 
              ${selectedMethod === option.id ? 'border-blueRyb bg-blueRyb' : 'border-roman-silver'}
            `}
                >
                  {selectedMethod === option.id && (
                    <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center'>
                      <div className='w-2.5 h-2.5 bg-white rounded-full' />
                    </div>
                  )}
                </div>
              </div>
            )
          }
        </div>
      ))}
    </div>
  );
};

export default DeliveryPartner;
