/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';

interface PointRangeProps {
  percent: number;
  setPercent: (value: number) => void;
  isRepayment?: boolean;
}

const PointRange: React.FC<PointRangeProps> = ({ percent, setPercent, isRepayment }) => {
  const marks = [0, 25, 50, 75, 100];
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const handleMouseMove = (
    clientX: number, // Use clientX for both mouse and touch
    sliderWidth: number,
    sliderLeft: number
  ) => {
    if (isRepayment) return;
    const newX = clientX - sliderLeft;
    let newValue = Math.max(0, Math.min((newX / sliderWidth) * 100, 100));

    // Round to nearest integer (step = 1)
    newValue = Math.round(newValue);
    setPercent(newValue);
  };

  const stopDragging = () => {
    document.onmousemove = null;
    document.onmouseup = null;
    document.ontouchmove = null;
    document.ontouchend = null;
  };

  const handleMouseDown = () => {
    if (isRepayment) return;
    const slider = sliderRef.current?.getBoundingClientRect();
    if (slider) {
      document.onmousemove = (event) => handleMouseMove(event.clientX, slider.width, slider.left);
      document.onmouseup = stopDragging;
    }
  };
  const handleTouchStart = () => {
    if (isRepayment) return;
    const slider = sliderRef.current?.getBoundingClientRect();
    if (slider) {
      document.ontouchmove = (
        event
      ) => handleMouseMove(event.touches[0].clientX, slider.width, slider.left);
      document.ontouchend = stopDragging;
    }
  };

  return (
    <div className='o-pointRange'>
      <div className='mt-4 rounded-lg bg-anti-flash-white2 px-3 pt-4 pb-10'>
        <label className='flex items-center'>
          <span>Phần trăm điểm (%)</span>
        </label>
        <div className='relative'>
          <div className='slider-container'>
            <div
              className='slider-track'
              ref={sliderRef}
              onMouseDown={handleMouseDown} // For mouse
              onTouchStart={handleTouchStart} // For touch
              onClick={handleTouchStart} // For touch
            >
              <div className='slider-fill' style={{ width: `${percent}%` }} />
              <div className='slider-thumb' style={{ left: `${percent}%` }}>
                <div
                  className='percent-tag absolute flex justify-between text-sm text-gunmetal font-medium whitespace-nowrap bg-white rounded px-2 py-1 shadow-md'
                  style={{
                    left: `${percent}%`,
                    top: '14px',
                    transform: 'translateX(-50%)',
                  }}
                >
                  <span>{`${percent.toFixed(0)} %`}</span>
                </div>
              </div>
              {/* Marks */}
              <div className='slider-marks'>
                {marks.map((mark) => (
                  <span
                    key={mark}
                    className={`slider-mark ${mark <= percent ? 'active' : ''}`} // Activate mark when value is greater than or equal to the mark
                    style={{ left: `${mark}%` }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointRange;
