/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import React from 'react';

import icArrow from 'assets/icons/ic_arrow.svg';
import chervonRight from 'assets/icons/ic_chevron_right.svg';

const TRANSITION_CLASSES = 'transition-transform duration-300 ease-in-out';
const DRAWER_BASE_CLASSES = 'fixed xl:static inset-0 transform xl:translate-x-0 z-50';
const OVERLAY_BASE_CLASSES = 'fixed inset-0 bg-black transition-opacity z-50 duration-300 ease-in-out block lg:hidden';

export type MenuItemData = {
  iconUrl: string;
  text: string;
  count?: number;
  routeKey: string;
};

type MenuItemProps = MenuItemData & {
  handleClick: (key: string) => void,
  activeKey: string
};
interface DrawerProps {
  isOpen: boolean;
  handleClose: () => void
  children: React.ReactNode,
  containerClassName?: string;
  iconClose?: string
  btnCloseNaked?: boolean
}

const MenuItem: React.FC<MenuItemProps> = ({
  iconUrl,
  text,
  count,
  handleClick,
  routeKey,
  activeKey
}) => {
  const isActive = activeKey === routeKey;
  return (
    <li
      className={classNames(
        'flex items-center justify-between p-4 rounded-lg text-base font-medium cursor-pointer',
        {
          'text-[#0D47F8] bg-[#EBF3FF] font-semibold': isActive,
          'text-[#2E3538] hover:bg-[#EBF3FF] hover:text-[#2E3538] hover:font-semibold': !isActive,
        }
      )}
      onClick={() => handleClick(routeKey)}
    >
      <span className='flex items-center'>
        <img aria-hidden='true' alt={routeKey} src={iconUrl} className='mr-2' />
        {text}
      </span>
      <div className='flex'>
        {/* eslint-disable-next-line max-len */}
        {/* {Number(count) > 0 && <p className='bg-neon-fuchsia text-white rounded-full w-[20px] h-[20px] text-sm text-center'>{count}</p>} */}
        <img aria-hidden='true' alt={`${routeKey}-chervon`} src={chervonRight} className='ml-1' />
      </div>
    </li>
  );
};

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  handleClose,
  children,
  containerClassName = '',
  iconClose = icArrow,
  btnCloseNaked
}) => (
  <div>
    <div
      className={`${OVERLAY_BASE_CLASSES} ${isOpen ? 'opacity-50' : 'opacity-0 pointer-events-none'}`}
      onClick={handleClose}
    />
    <div
      id='drawer'
      onClick={handleClose}
      className={`${DRAWER_BASE_CLASSES} ${TRANSITION_CLASSES} ${isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
    >
      <div className={`w-[324px] bg-card p-4 bg-white h-[100vh] xl:h-auto lg:rounded-xl ${containerClassName}`}>
        <div className='flex justify-end items-start mb-2 xl:mb-0'>
          <button
            className={`xl:hidden p-1 bg-white  rounded-lg ${btnCloseNaked ? '' : 'border border-[#D8DCDE]'}`}
            onClick={handleClose}
          >
            <img src={iconClose} alt='arrow' className='rotate-180' />
          </button>
        </div>
        {children}
      </div>
    </div>
  </div>
);

const DrawerButton: React.FC<{ toggleDrawer: () => void, title?: string }> = ({
  toggleDrawer,
  title,
}) => (
  <div className='xl:hidden flex items-center'>
    <button
      id='toggleDrawer'
      className='p-1 bg-white border border-[#D8DCDE] rounded-lg'
      onClick={toggleDrawer}
    >
      <img src={icArrow} alt='arrow' />
    </button>
    {title && <p className='text-[#1F2128] text-lg font-semibold ml-3'>{title}</p>}
  </div>
);

export {
  Drawer,
  DrawerButton,
  MenuItem,
};
