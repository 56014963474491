import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  getAccessTokenFromZaloApi,
  linkSocial,
  profileApi,
  signinOrSignupSocialApi,
} from 'services/auth';
import { setAccessToken } from 'services/common/storage';
import { setIsLinkZalo, setIsSignInZalo, setUser } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const CallbackZalo: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const { codeVerifier, isLinkZalo } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getAccessTokenFromZalo = async (code: any) => {
    try {
      const url = `https://oauth.zaloapp.com/v4/access_token?code=${code}&app_id=${process.env.REACT_APP_ZALO_APP_ID}&code_verifier=${codeVerifier}&grant_type=authorization_code`;
      const zaloResponse = await getAccessTokenFromZaloApi(url);
      const response = await signinOrSignupSocialApi({
        Type: 'Zalo',
        SocialAccessToken: zaloResponse.data.access_token,
      });
      setAccessToken(response.data.token);
      const profile = await profileApi();
      if (profile.data.phone === null) {
        dispatch(setIsSignInZalo(true));
      }
      dispatch(setUser(profile.data));
      navigate('/');
    } catch (error: any) {
      dispatch(setIsSignInZalo(false));
      navigate('/');
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const getAccessTokenLinkSocialFromZalo = async (code: any) => {
    try {
      const url = `https://oauth.zaloapp.com/v4/access_token?code=${code}&app_id=${process.env.REACT_APP_ZALO_APP_ID}&code_verifier=${codeVerifier}&grant_type=authorization_code`;
      const zaloResponse = await getAccessTokenFromZaloApi(url);
      await linkSocial({
        Provider: 'Zalo',
        SocialAccessToken: zaloResponse?.data?.access_token,
      });
      const profile = await profileApi();
      dispatch(setUser(profile.data));
      navigate('/customer-info?key=customer-info');
      dispatch(setIsLinkZalo(false));
    } catch (error: any) {
      navigate('/customer-info?key=customer-info');
      dispatch(setIsLinkZalo(false));
    }
  };

  useEffect(() => {
    const code = searchParams.get('code');
    if (!code) return;
    if (!isLinkZalo) {
      getAccessTokenFromZalo(code);
    } else {
      getAccessTokenLinkSocialFromZalo(code);
    }
  }, [searchParams]);

  return (
    <div className='p-4'>
      <p>Loading</p>
    </div>
  );
};
export default CallbackZalo;
