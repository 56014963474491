/* eslint-disable import/prefer-default-export */
import axiosInstance from 'services/common/instance';
import { IDataProductReviews, IProductReviewByIdResponse } from 'types/reviews';

export function getProductReviews(): Promise<{ data: IDataProductReviews }> {
  return axiosInstance.get('/product/productreviews');
}

export function getProductReviewsById(id: number): Promise<{ data: IProductReviewByIdResponse }> {
  return axiosInstance.get(`/product/productreviews/${id}`);
}
