import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  forwardRef, useEffect, useImperativeHandle, useMemo
} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import Input from 'components/atoms/Input';
import Pulldown from 'components/molecules/Pulldown';
import { useAppSelector } from 'store/hooks';
import { addressFormSchema } from 'utils/schemas';

export interface CustomerAddressFormType {
  lastName: string;
  firstName: string;
  phoneNumber: string;
  email: string;
  city: string;
  district: string;
  ward: string;
  specificAddress: string;
  saveAddress?: boolean;
  saveAddress2?: boolean;
  address1?: string
}
interface CustomerAddressFormProps {
  onSubmit: SubmitHandler<CustomerAddressFormType>;
}

const CustomerAddressForm = forwardRef((props: CustomerAddressFormProps, ref) => {
  const { onSubmit } = props;
  const {
    control, formState: { errors }, watch, setValue, handleSubmit
  } = useForm<CustomerAddressFormType>({
    resolver: yupResolver(addressFormSchema as any),
    mode: 'onChange'
  });
  const { provinces } = useAppSelector((state) => state.general);
  const selectedCity = watch('city');
  const selectedDistrict = watch('district');
  const formValues = watch();

  // const filteredDistricts = useMemo(() => (selectedCity
  //   ? districtsOptions[selectedCity] || []
  //   : []), [selectedCity]);

  const filteredDistricts = useMemo(() => {
    if (selectedCity) {
      const province = provinces?.find((p) => p.fullName === selectedCity);
      return province?.district?.map((district) => ({
        label: district.fullName,
        value: district.fullName,
      }));
    }
    return [];
  }, [selectedCity]);

  const filteredWards = useMemo(() => {
    if (selectedDistrict) {
      const province = provinces?.find((p) => p.fullName === selectedCity);
      const district = province?.district?.find((d) => d.fullName === selectedDistrict);
      return district?.ward?.map((ward) => ({
        label: ward.fullName,
        value: ward.fullName,
      }));
    }
    return [];
  }, [selectedDistrict]);

  useEffect(() => {
    // Reset district and ward when city changes
    setValue('district', '');
    setValue('ward', '');
  }, [selectedCity, setValue]);

  useEffect(() => {
    // Reset district and ward when city changes
    setValue('ward', '');
  }, [selectedDistrict, setValue]);

  useImperativeHandle(ref, () => ({
    formValues,
    // TODO: Trigger submit to catch validate
    submit: () => handleSubmit(onSubmit)(),
  }), [formValues, handleSubmit, onSubmit]);

  return (
    <form className='t-customerAddressForm' onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-col gap-y-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
          <div>
            <Controller
              name='lastName'
              control={control}
              render={({ field }) => (
                <Input {...field} id='lastName' placeholder='Họ *' error={errors?.lastName?.message} />
              )}
            />
          </div>

          <div>
            <Controller
              name='firstName'
              control={control}
              render={({ field }) => (
                <Input {...field} id='firstName' placeholder='Tên *' error={errors?.firstName?.message} />
              )}
            />
          </div>
        </div>

        <div>
          <Controller
            name='phoneNumber'
            control={control}
            render={({ field }) => (
              <Input {...field} id='phoneNumber' type='tel' placeholder='Số điện thoại *' error={errors?.phoneNumber?.message} />
            )}
          />
        </div>

        <div>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <Input {...field} id='email' type='email' placeholder='Email' error={errors?.email?.message} />

            )}
          />
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-3 gap-4'>
          <div>
            <Controller
              name='city'
              control={control}
              render={({ field }) => (
                <Pulldown
                  {...field}
                  placeholder='Chọn thành phố'
                  id='city'
                  options={
                    provinces?.map((province) => ({
                      label: province.fullName,
                      value: province.fullName,
                    })) || []
                  }
                  error={errors?.city?.message || ''}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name='district'
              control={control}
              render={({ field }) => (
                <Pulldown
                  {...field}
                  placeholder='Chọn Quận'
                  id='district'
                  options={filteredDistricts || []}
                  error={errors?.district?.message || ''}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name='ward'
              control={control}
              render={({ field }) => (
                <Pulldown
                  {...field}
                  placeholder='Chọn phường'
                  id='ward'
                  options={filteredWards || []}
                  error={errors?.ward?.message || ''}
                />
              )}
            />
          </div>
        </div>

        <div>
          <Controller
            name='specificAddress'
            control={control}
            render={({ field }) => (
              <Input {...field} id='specificAddress' placeholder='Địa chỉ cụ thể (số nhà, ngõ, hẻm...) *' error={errors?.specificAddress?.message} />

            )}
          />
        </div>

        <div className='flex items-center'>
          <Controller
            control={control}
            name='saveAddress2'
            render={({
              field: { onChange, onBlur, value },
            }) => (

              <label htmlFor='saveAddress' className='inline-flex items-center cursor-pointer'>
                <input
                  onChange={onChange}
                  onBlur={onBlur}
                  checked={value}
                  id='saveAddress'
                  type='checkbox'
                  className='sr-only peer'
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600" />
                <span className='ml-2 text-sm font-medium text-gunmetal'>
                  Lưu vào địa chỉ để thanh toán cho những đơn hàng sau!
                </span>
              </label>

            )}
          />
        </div>
      </div>
    </form>
  );
});

export default CustomerAddressForm;
