import React from 'react';

interface IntroCardProps {
  title: string;
  icon: string;
  sub: string;
}

const IntroCard: React.FC<IntroCardProps> = ({
  title, icon, sub
}) => (
  <div className='m-introCard flex relative'>
    <div className='m-introCard__icon'>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: icon }} />
    </div>
    <div className='ml-3'>
      <div className='m-introCard__title font-semibold text-md lg:text-base'>{title}</div>
      <div className='m-introCard__sub text-sm text-feldgrau'>{sub}</div>
    </div>
  </div>
);

export default IntroCard;
