import classNames from 'classnames';
import { debounce } from 'lodash';
import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/atoms/Icon';
import useClickOutside from 'hooks/useClickOutside';
import useHeaderHeight from 'hooks/useHeaderHeight';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { getSearchAutocomplete } from 'services/home';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setSearchQuery } from 'store/search';
import { SuggestionSearchType } from 'types/home';
import SLUG_PARAMS from 'utils/constants';
import { formatVndCurrency } from 'utils/functions';

interface CommonSearchProps {
  handleSearch?: (val: string) => void;
  // suggestionData?: SuggestionSearchType[];
}

const CommonSearch: React.FC<CommonSearchProps> = ({ handleSearch }) => {
  const { width } = useWindowDimensions();
  const headerHeight = useHeaderHeight();
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef: any = useRef(null);
  const [focused, setFocused] = useState(false);
  const [openSuggest, setOpenSuggest] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const [filteredSuggestions, setFilteredSuggestions] = useState<
    SuggestionSearchType[]
  >([]);

  const keyword = useAppSelector((state: any) => state.search.search);

  const dispatch = useAppDispatch();

  useClickOutside(searchRef, () => {
    setFocused(false);
    setOpenSuggest(false);
  });

  const inputClass = 'px-4 py-1 flex-1 border-0 focus:ring-0 rounded-lg';
  const wrapInputClass = classNames(
    'relative border-[2px] p-0.5 flex rounded-lg',
    {
      'border-blueRyb shadow-input-focus': focused,
      'border-gainsboro': !focused,
    }
  );

  useEffect(() => {
    if (width < 1024 && focused) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }

    return () => {
      document.documentElement.style.overflow = '';
    };
  }, [width, focused, searchTerm]);

  const clearSearch = () => {
    setFocused(false);
    setOpenSuggest(false);
  };

  const handleInputChange = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
      if (e.target.value) {
        try {
          const response: any = await getSearchAutocomplete({
            term: e.target.value,
          });
          setFilteredSuggestions(response?.data);
        } catch (error) {
          //
        }
      } else {
        setFilteredSuggestions([]);
      }
    },
    500
  );

  const handleFocusInput = () => {
    setFocused(true);
    setOpenSuggest(true);
  };

  const topSuggestMobile = useMemo(
    () => () => ({
      top: '100%',
      left: '0px',
      height: `calc(100vh - ${headerHeight}px)`,
    }),
    [headerHeight]
  );

  const onSelectSuggest = (val: string, id?: string) => {
    setSearchTerm(val);
    if (inputRef.current) {
      inputRef.current.value = val;
    }
    if (id) {
      navigate(`${SLUG_PARAMS.PRODUCTS_DETAIL}/${id}`);
    }
    setFocused(false);
    setOpenSuggest(false);
  };

  return width >= 1024 ? (
    <div className='o-commonSearch hidden lg:block ml-3 lg:ml-6 2xl:ml-10 flex-1 min-w-[475px] 2xl:min-w-[548px]'>
      <div className={wrapInputClass} ref={searchRef}>
        <input
          type='text'
          placeholder='Tìm kiếm trong cửa hàng'
          className={inputClass}
          onFocus={handleFocusInput}
          ref={inputRef}
          onChange={handleInputChange}
        />
        <button
          onClick={() => {
            dispatch(setSearchQuery(searchTerm || keyword));
            handleSearch?.(searchTerm); setOpenSuggest(false);
          }}
          type='button'
          className='text-white bg-blueRyb hover:bg-blue-400  button-base-style flex-none w-[140px]'
        >
          <div className='mr-2'>
            <Icon iconName='whiteSearch' size='24x24' />
          </div>
          Tìm kiếm
        </button>
        {openSuggest && (
          <div className='suggest-desktop absolute top-full mt-2 w-full bg-white shadow-lg rounded-lg z-10'>
            <ul className='overflow-y-auto max-h-[480px] custom-scrollbar'>
              {filteredSuggestions.map((item) => (
                <li
                  key={item.productId}
                  className='flex items-center px-4 py-3 hover:bg-anti-flash-white2 cursor-pointer'
                  onClick={() => onSelectSuggest(item.label, item.productId)}
                >
                  <img
                    src={item.productPictureUrl}
                    alt={item.label}
                    className='w-[72px] h-[72px] rounded-md mr-3'
                  />
                  <div>
                    <p className='text-md'>{item.label}</p>
                    <p className='font-semibold'>
                      {formatVndCurrency(item.productPrice)}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  ) : (
    // MOBILE ONLY
    <div
      className='o-commonSearch relative container mx-auto block lg:hidden w-full py-2'
      ref={searchRef}
    >
      <div className='flex gap-3'>
        {focused && (
          <button
            className='w-[44px] h-[44px] flex-none rounded-md flex justify-center items-center bg-anti-flash-white2'
            style={{ transform: 'rotate(180deg)' }}
            onClick={clearSearch}
          >
            <Icon iconName='chevronRight' size='24x24' />
          </button>
        )}
        <div className='relative flex-1 w-full border-gainsboro border-[2px] p-0.5 flex rounded-lg'>
          <input
            type='text'
            placeholder='Tìm kiếm trong cửa hàng'
            className={inputClass}
            onChange={handleInputChange}
            ref={inputRef}
            onFocus={handleFocusInput}
          />
          <button
            type='button'
            onClick={() => {
              dispatch(setSearchQuery(searchTerm || keyword));
              handleSearch?.(searchTerm); setOpenSuggest(false);
            }}
            className='text-white bg-blueRyb hover:bg-blue-400 button-base-style flex-none w-11 h-9 lg:w-4 lg:h-4 p-0 flex items-center justify-center'
          >
            <Icon iconName='whiteSearch' size='24x24' />
          </button>
        </div>
      </div>
      {openSuggest && (
        <div
          className='suggest-mobile absolute w-full bg-white z-10'
          style={topSuggestMobile()}
        >
          <ul
            className='overflow-y-auto'
            style={{ maxHeight: `calc(100vh - ${headerHeight}px)` }}
          >
            {filteredSuggestions.map((item) => (
              <li
                key={item.productId}
                className='flex items-center px-4 py-3 hover:bg-anti-flash-white2 cursor-pointer'
                onClick={() => onSelectSuggest?.(item.label, item?.productId)}
              >
                <img
                  src={item.productPictureUrl}
                  alt={item.label}
                  className='w-[72px] h-[72px] rounded-md mr-3'
                />
                <div>
                  <p className='text-md'>{item.label}</p>
                  <p className='font-semibold'>
                    {formatVndCurrency(item.productPrice)}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CommonSearch;
