import React from 'react';

import ProductDetailContainer from 'containers/ProductContainer.tsx/Detail';

const ProductDetail: React.FC = () => (
  <div className='p-productDetail bg-page'>
    <ProductDetailContainer />
  </div>
);

export default ProductDetail;
