/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  paymentOptions, productDummyData
} from 'assets/dummy';
import bankLineTexture from 'assets/images/bank-line-white.png';
// import qrImage from 'assets/images/qr-image.png';
import qrMBBank from 'assets/images/banks/qr_banking.jpg';
import qrCodeImg from 'assets/images/qrCode.png';
import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import Icon from 'components/atoms/Icon';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import { AddressItemType } from 'components/organisms/AddressSelector';
import { OrderSummaryDataTypes } from 'components/organisms/BankingMethodModal';
import ContentPanel from 'components/organisms/ContentPanel';
import { PaymentOptionsType } from 'components/organisms/PaymentMethodSelection';
import TitleBackHandler from 'components/organisms/TitleBackHandler';
import CouponPicker, { CouponType } from 'components/templates/CouponPicker';
import FailedPaymentModal from 'components/templates/FailedPaymentModal';
import PaymentInfo from 'components/templates/PaymentInfo';
import PointReward from 'components/templates/PointReward';
import ProcessPaymentModal from 'components/templates/ProcessPaymentModal';
import ProductSummaryMobile from 'components/templates/ProductSummaryMobile';
import ProductTable from 'components/templates/ProductTable';
import ProductVariantSelectionMobile from 'components/templates/ProductVariantSelectionMobile';
import { ConfirmModal } from 'containers/ModalContainer';
import { compareAddressData } from 'containers/OrderCompletedContainer';
import useCart from 'hooks/useCart';
import useCheckout from 'hooks/useCheckout';
import { useDeleteTableProduct } from 'hooks/useDelelteTableProduct';
import { getOrderDetailData } from 'services/order';
import { setIsRepayment, setRepaymentData } from 'store/cart';
import { setCartStepReducer } from 'store/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IShoppingCartOrderTotals } from 'types/cart';
import {
  calculatePoint,
  formatDecimalNumber, formatVndCurrency,
  handleCopyToClipboard,
  parseCurrency
} from 'utils/functions';

const breadcrumbItems = [
  { label: 'Home', path: '/' },
  { label: 'Cart' },
];

interface OrderSummaryBottomProps {
  points?: number;
  total: string;
  savingAmount?: any;
  currentStep: number;
  bcoinReward?: number;
  bcoinUsed?: number;
  voucherUsed?: CouponType;
  onUseBcoin?: () => void;
  onPayment?: () => void;
  onUseVoucher?: () => void;
}

interface OrderWithBankingProps {
  onBack: () => void;
  onProcess: () => void;
}

interface BankingMethodProcessProps {
  order: OrderSummaryDataTypes;
}

const BankingMethodProcess: React.FC<BankingMethodProcessProps> = ({ order }) => {
  const accountNumber: string = '0973458511';
  return (
    <div className='mb-8'>
      <div className='info bg-white p-4 relative'>
        {order?.orderNumber && (
          <div className='item flex items-center justify-between py-2 border-b border-dashed border-gainsboro'>
            <p className='text-feldgrau text-md'> Mã đơn hàng</p>
            <p className='text-gunmetal text-md font-semibold'>#{order.orderNumber}</p>
          </div>
        )}
        <div className='item flex items-center justify-between py-2 border-b border-dashed border-gainsboro'>
          <p className='text-feldgrau text-md'> Phương thức thanh toán</p>
          <div className='text-gunmetal text-md flex items-center gap-x-1'>
            <Icon iconName='bank' size='32x32' />
            Chuyển khoản
          </div>
        </div>
        <div className='item flex items-center justify-between py-2 border-b border-dashed border-gainsboro'>
          <p className='text-feldgrau text-md'>Số tiền cần thanh toán</p>
          <p className='text-neon-fuchsia text-lg font-semibold'>{formatVndCurrency(order.totalAmount)}</p>
        </div>
        <div className='line absolute top-[100%] left-0'>
          <img src={bankLineTexture} alt='texture' className='w-full' />
        </div>
      </div>
      {/* instruction  */}
      <div className='instruction flex flex-col gap-x-6 mt-6 bg-white p-4 relative'>
        <div className='qrCode  bg-white flex justify-center'>
          <div className='rounded-lg shadow-sm w-[200px] aspect-3/4'>
            <img src={order.qrCode || qrMBBank} alt='qr code' className='w-full object-contain rounded-lg' />
          </div>
        </div>
        <div className='content mt-4'>
          <h4 className='font-semibold text-gunmetal text-lg'>Hướng dẫn thanh toán</h4>
          <p className='mt-2 text-feldgrau text-sm italic'>Quét mã QR hoặc chuyển khoản theo thông tin sau</p>
          <ul className='text-gunmetal text-md mt-2 flex flex-col gap-y-1 font-medium'>
            <li>Ngân hàng: MBBank</li>
            <li>Số tài khoản: {accountNumber} <button onClick={() => handleCopyToClipboard(String(accountNumber))} className='text-brilliant-azure'>[Sao chép]</button></li>
            <li>Chủ tài khoản: TRUONG THI MY HANH</li>
            {order?.orderNumber ? (
              <li>
                <p className='italic'>Nội dung thanh toán: Mã đơn hàng + Số điện thoại mua hàng
                  <span className='block'>(Ví dụ: 596302 0949696696)</span>
                </p>
              </li>
            )
              : (
                <li>
                  <p className='italic'>Nội dung thanh toán: Số điện thoại mua hàng
                    <span className='block'>(Ví dụ: 0949696696)</span>
                  </p>
                </li>
              )}
          </ul>
        </div>
        <p className='text-sm italic mt-3 text-gunmetal'>Sau khi chuyển khoản, vui lòng nhấn vào nút “Đã Thanh Toán” để hoàn tất giao dịch. Bộ CSKH của BidaHub sẽ sớm liên hệ với bạn sau khi đơn hàng đã thanh toán để xác nhận và giao hàng.</p>
      </div>
    </div>
  );
};

const OrderSummaryBottom: React.FC<OrderSummaryBottomProps> = ({
  points, total, savingAmount, currentStep, bcoinReward, voucherUsed,
  bcoinUsed, onUseBcoin, onPayment, onUseVoucher
}) => (
  <div className='o-orderSummary__mobile block lg:hidden fixed bottom-0 left-0 right-0 bg-white z-50'>
    {/* Use Bcoin  */}
    {
      !bcoinUsed && currentStep !== 3 && currentStep !== 4
      && (
        <button type='button' className='flex py-3 px-4 justify-between items-center w-full bg-blueRyb text-white text-md' onClick={onUseBcoin}>
          <p>
            Bạn đang có{' '}
            <span className='font-semibold'>{formatDecimalNumber(points as number)} Bcoin</span>
          </p>
          <div className='flex gap-x-1 items-center'>
            <p className='text-medium-turquoise2 text-md'>Sử dụng</p>
            <Icon iconName='chevronRightWhite' size='16x16' />
          </div>
        </button>
      )
    }
    {
      bcoinUsed && currentStep !== 3 && currentStep !== 4
      && (
        <button type='button' className='flex py-3 px-4 justify-between items-center w-full text-md border-b-1 border-gainsboro' onClick={onUseBcoin}>
          <p className='text-blueRyb font-semibold'>
            Bạn đã sử dụng{' '}
            <span className='font-semibold text-raisin-black'>{formatDecimalNumber(bcoinUsed.toString())} Bcoin ({formatVndCurrency(bcoinUsed)})</span>
          </p>
        </button>
      )
    }
    {/* Use voucher  */}
    {
      currentStep !== 4 && currentStep !== 3 && (
        <button type='button' className='flex py-3 px-4 justify-between items-center w-full' onClick={onUseVoucher}>
          {
            voucherUsed
              ? (
                <div className='flex items-center'>
                  <Icon iconName='ticketDiscount' size='18x18' />
                  <p className='text-md text-gunmetal font-medium ml-1'>{voucherUsed.content}
                  </p>
                </div>
              )
              : (
                <>
                  <p className='text-md text-gunmetal'>Sử dụng voucher</p>
                  <p className='text-roman-silver flex items-center text-md'>Nhập hoặc chọn mã
                    <Icon iconName='chevronRight' size='16x16' />
                  </p>
                </>
              )
          }

        </button>
      )
    }
    {/* Submit  */}
    <div className={`submit py-3 px-4${currentStep === 1 || currentStep === 2 ? ' border-t-1 border-gainsboro' : ''}`}>
      {
        currentStep === 4 || currentStep === 3
          ? (
            <div className='flex flex-col gap-2 text-center'>
              {
                voucherUsed
                && <p className='text-md'>Bạn đã được giảm <span className='text-persian-green'>{formatVndCurrency(Number(voucherUsed.discount.replace('k', '000')))}</span> từ mã giảm giá</p>
              }
              <Button
                onClick={onPayment}
                rtl
                icon='arrowRight'
                variants='primary'
                size='large'
              >
                Tiếp tục thanh toán
              </Button>
            </div>
          )
          : (
            <>
              <div className='flex flex-row gap-4 items-center'>
                <div className='w-6/12'>
                  <p className='text-gunmetal text-md'>{currentStep === 2 ? 'Thành tiền' : 'Tạm tính'}</p>
                  <p className='text-xl text-neon-fuchsia font-semibold'>{total}</p>
                  {
                    savingAmount
                    && (
                      <p className='text-sm text-gunmetal'>Tiết kiệm: {' '}
                        <span className='text-persian-green'>{savingAmount}</span>
                      </p>
                    )
                  }
                </div>
                <div className='w-6/12'>
                  <Button
                    type='button'
                    onClick={onPayment}
                    variants='neon-fuchsia'
                    size='large'
                    className='capitalize'
                  >
                    {currentStep === 1 ? 'Đặt hàng' : 'Thanh toán'}
                  </Button>
                </div>
              </div>
              {
                bcoinReward && (
                  <p className='text-md text-gunmetal mt-1'>
                    Điểm thưởng sẽ được nhận {' '}
                    <span className='text-persian-green'>{formatDecimalNumber(bcoinReward?.toString())} Bcoin</span>
                  </p>
                )
              }
            </>
          )
      }

    </div>
  </div>
);

const OrderWithBanking: React.FC<OrderWithBankingProps> = ({ onBack, onProcess }) => (
  <div className='o-orderSummary__mobile block lg:hidden fixed bottom-0 left-0 right-0 bg-white z-50'>
    {/* Submit  */}
    <div className='submit py-3 px-4 flex flex-col gap-y-2'>
      <Button
        variants='neon-fuchsia'
        size='large'
        onClick={onProcess}
      >
        Đã thanh toán
      </Button>
      <Button
        variants='secondary'
        size='large'
        onClick={onBack}
      >
        Đổi phương thức khác
      </Button>

    </div>
  </div>
);

const CartMobileContainer: React.FC = () => {
  const [openFailedModal, setOpenFailedModal] = useState(false);
  const [openProcessPayment, setOpenProcessPayment] = useState(false);
  const [step, setStep] = useState(1);
  const [openVariants, setOpenVariants] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    data, orderTotals, discount, repaymentData, isRepayment
  } = useAppSelector((state) => state.cart);
  const [paymentSelected, setPaymentSelected] = useState<PaymentOptionsType>(paymentOptions[0]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedVoucher, setSelectedVoucher] = useState<CouponType>();
  const { openConfirmModal, handleConfirm } = useDeleteTableProduct();
  const { handleRepayment, onBankingSuccess } = useCart(paymentSelected);
  const { provinces } = useAppSelector((state) => state.general);
  const [isChecked, setIsChecked] = useState(false);
  const [errorText, setErrorText] = useState('');

  const repaymentSummary = {
    orderTotal: repaymentData?.orderTotal || '',
    orderTotalDiscount: formatVndCurrency(
      Number(repaymentData?.orderSubtotalValue || 0)
      - Number(repaymentData?.orderTotalValue || 0)
    ),
    subTotal: repaymentData?.orderSubtotal || repaymentData?.totalPrice || '',
    willEarnRewardPoints: calculatePoint(Number(repaymentData?.orderSubtotalValue * repaymentData?.items?.length)) || calculatePoint(Number(parseCurrency(repaymentData?.orderTotal) * repaymentData?.items.length)) || 0,
    subTotalDiscount: -(repaymentData?.orderTotalDiscountValue || 0),
    redeemedRewardPoints: repaymentData?.redeemedRewardPoints || 0,
    redeemedRewardPointsAmount: repaymentData?.redeemedRewardPointsAmount || '',
    isRepayment: true
  };

  const {
    coupons, handleRemoveCoupon, handleApplyCoupon, applyBCoin, addressFormRef,
    addresses, selectedAddress, handleChangeShippingAddress, rewardPointsBalance,
    fetchDataDeliveryAddress, handleNotesChange, onPayment: handlePayment,
    bankingResponse, handleCancelBankingProcess, onBankingProcessSuccess, processBankingOpen, setProcessBankingOpen, setForm
  } = useCheckout(paymentSelected);

  const handleVoucherSelect = (voucher: CouponType) => {
    setSelectedVoucher(voucher);
    // setStep(1);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (errorText) setErrorText('');
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    handleScrollToTop();
    dispatch(setCartStepReducer(step));
  }, [dispatch, step]);

  const backToFirstStep = () => {
    if (step === 1) return;
    setStep(1);
  };

  const onPayment = () => {
    if (!isChecked && step === 2) {
      setErrorText('Vui lòng đánh dấu vào ô Tôi đồng ý với Điều khoản dịch vụ và Chính sách bảo mật');
      return;
    }

    if (paymentSelected.id === 'banking') {
      setStep(5);
      return;
    }
    setStep(2);
    handleScrollToTop();
    if (step === 2) {
      handlePayment();
    }
  };

  const onUseVoucher = () => {
    setStep(4);
  };

  const onSelectVariantsMobile = () => {
    setOpenVariants(true);
  };

  const handleChangePaymentMethod = (value: PaymentOptionsType) => {
    setPaymentSelected(value);
  };

  const resetPaymentMethodDefault = () => {
    setPaymentSelected(paymentOptions[0]);
  };

  const onBankingBackToPrev = () => {
    setStep(2);
    resetPaymentMethodDefault();
  };

  const [orderAddress, setOrderAddress] = useState<AddressItemType | null>(null);

  const fetchDataOrderDetail = async () => {
    try {
      if (repaymentData?.customOrderNumber) {
        const response = await getOrderDetailData(repaymentData?.customOrderNumber as string);
        const address = {
          address: response?.data?.billingAddress?.address1,
          address2: response?.data?.billingAddress?.address2,
          email: response?.data?.billingAddress?.email,
          id: response?.data?.billingAddress?.id,
          name: `${response?.data?.billingAddress?.firstName} ${response?.data?.billingAddress?.lastName}`,
          phone: response?.data?.billingAddress?.phoneNumber
        };
        const resultAddress = compareAddressData(address, provinces);
        setOrderAddress(resultAddress);
        dispatch(setRepaymentData(response?.data));
        dispatch(setIsRepayment(true));
      }
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };

  useEffect(() => {
    fetchDataOrderDetail();
  }, []);

  /**
   * Step 1: Cart
   * Step 2: Process payment (final)
   * Step 3: Use Bcoin
   * Step 4: Use Voucher
   * Step 5: Banking payment process
   */

  const onRepayment = (customOrderNumber: any, orderTotalValue: any) => {
    if (!isChecked && step === 2) {
      setErrorText('Vui lòng đánh dấu vào ô Tôi đồng ý với Điều khoản dịch vụ và Chính sách bảo mật');
      return;
    }

    handleRepayment(customOrderNumber, orderTotalValue);
    // onPayment();
    if (paymentSelected.id === 'banking') {
      setStep(5);
    }
  };

  const loadedData = isRepayment ? repaymentData?.items : data;
  const summaryOrder = isRepayment ? repaymentSummary : orderTotals;
  useEffect(() => {
    if (isRepayment) {
      setStep(2);
    }
  }, [isRepayment]);

  useEffect(() => {
    if (searchParams.has('step')) {
      setStep(Number(searchParams.get('step')));
      searchParams.delete('step');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className='container mx-auto mb-3'>
              <h1 className='text-2xl font-semibold mb-3 lg:mb-6'>
                Giỏ hàng
              </h1>
              <div className='flex flex-col xl:flex-row gap-6'>
                <div className='xl:w-8/12'>
                  <div className='flex flex-col gap-6'>
                    <ProductTable
                      products={data?.map((item) => ({
                        id: item?.id || 0,
                        name: item?.productName || '',
                        image: item?.picture?.imageUrl || '',
                        price: item.unitPriceValue + (item.discountValue / item.quantity),
                        discount: item.discountValue,
                        count: item?.quantity || 0,
                        attributeInfo: item?.attributeInfo,
                        productId: item.productId,
                        productAttributes: item.productAttributes,
                        inWishlist: item?.inWishlist,
                        wishlistId: item?.wishlistId,
                      }))}
                      noCheck
                      onDelete={(id) => {
                        openConfirmModal(
                          'Xoá sản phẩm',
                          'Bạn có chắc muốn xóa sản phẩm đã chọn không ?',
                          id
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='mt-6'>
                {/* <ProductSlide productData={productDummyData} title='Có thể bạn sẽ thích' /> */}
              </div>
              <ConfirmModal onConfirm={handleConfirm} />

            </div>
            <OrderSummaryBottom
              onUseBcoin={() => setStep(3)}
              points={orderTotals?.redeemedRewardPoints || 0}
              onPayment={onPayment}
              currentStep={step}
              total={orderTotals?.orderTotal as string}
              bcoinReward={orderTotals?.willEarnRewardPoints}
              voucherUsed={selectedVoucher}
              // bcoinUsed={5000}
              onUseVoucher={onUseVoucher}
            />
          </>
        );
      case 2:
        return (
          <>
            <div className='container mx-auto mb-3'>
              {
                isRepayment ? (
                  <h2 className='text-xl lg:text-2xl font-semibold mb-3'>Thanh toán lại</h2>
                ) : (
                  <TitleBackHandler onBack={backToFirstStep}>
                    Xác nhận thanh toán
                  </TitleBackHandler>
                )
              }
              <div className='flex flex-col xl:flex-row gap-6'>
                <div className='xl:w-8/12'>
                  <div className='flex flex-col gap-6'>
                    {/* <PaymentInfo /> */}
                    <PaymentInfo
                      addresses={addresses}
                      isRepayment={isRepayment}
                      selectedAddress={isRepayment ? orderAddress : selectedAddress}
                      handleChangeShippingAddress={handleChangeShippingAddress}
                      fetchDataDeliveryAddress={fetchDataDeliveryAddress}
                      ref={addressFormRef}
                      handleNotesChange={handleNotesChange}
                      onPaymentMethodSelected={handleChangePaymentMethod}
                    />

                    {/* Product  */}
                    <ContentPanel contentClassName='p-0' title='Sản phẩm'>
                      <ProductSummaryMobile products={loadedData?.map((item: any) => ({
                        id: item?.productId || 0,
                        name: item?.productName || '',
                        image: item?.picture?.imageUrl || item?.defaultPictureModel?.imageUrl || 'https://picsum.photos/200',
                        price: item.unitPriceValue + ((item.discountValue / item.quantity) || 0),
                        discount: item.discountValue,
                        count: item?.quantity || 0,
                        attributeInfo: item?.attributeInfo,
                        wishlistId: item?.id || 0,
                      }))}
                      />
                    </ContentPanel>
                    <div className='o-orderSummary__desktop p-4 lg:p-6 bg-white rounded-xl overflow-hidden'>
                      <h2 className='text-lg font-semibold mb-4'>Tóm tắt đơn hàng</h2>

                      <div className='flex justify-between mb-2 text-gunmetal'>
                        <span>Tạm tính</span>
                        <span>{summaryOrder?.subTotal}</span>
                      </div>

                      <div className='flex justify-between mb-2'>
                        <span className='text-gunmetal'>Giảm giá</span>
                        <span className='text-persian-green'>{formatVndCurrency(summaryOrder?.subTotalDiscount || 0)}</span>
                      </div>
                      <div className='flex justify-between mb-2 text-gunmetal'>
                        <div className='text-gunmetal'>
                          <p>Điểm thưởng đã áp dụng</p>
                          {/* <p className='text-roman-silver text-sm mt-1'>{summaryOrder?.redeemedRewardPoints} BCoins</p> */}
                        </div>
                        <span className='text-persian-green'>{summaryOrder?.redeemedRewardPointsAmount}</span>
                      </div>
                      <div className='flex justify-between mb-2 text-gunmetal'>
                        <span>Vận chuyển</span>
                        <span>Miễn phí</span>
                      </div>

                      <div className='border-t border-gainsboro my-4' />

                      <div className='flex justify-between items-center mb-2'>
                        <span className='text-xl font-semibold text-gunmetal'>Thành tiền:</span>
                        <span className='text-xl font-semibold text-neon-fuchsia'>{summaryOrder?.orderTotal}</span>
                      </div>

                      <div className='text-sm text-right mb-4'>
                        <span className='text-md text-gunmetal'>Tiết kiệm:</span>
                        <span className='text-md text-persian-green ml-1'>{summaryOrder?.orderTotalDiscount}</span>
                        <p className='text-sm text-roman-silver'>
                          (Giá này đã bao gồm thuế GTGT và các chi phí khác)
                        </p>
                      </div>

                      <div className='flex items-center mb-2'>
                        <Checkbox
                          id='privacy'
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          classNameLabel='ml-2 text-gunmetal'
                          label={`Tôi đồng ý với
                    <a href='/' target='_blank' class='text-brilliant-azure'>
                      Điều khoản dịch vụ
                    </a> 
                    và 
                    <a href='/' target='_blank' class='text-brilliant-azure'>
                      Chính sách bảo mật
                    </a>`}
                        />

                      </div>
                      {errorText && <p className='text-neon-fuchsia text-sm mb-2'>{errorText}</p>}
                      <div className='flex justify-between'>
                        <span className='text-gunmetal'>Điểm thưởng sẽ được nhận</span>
                        <span className='text-persian-green font-semibold'>{formatDecimalNumber(summaryOrder?.willEarnRewardPoints || 0)} Bcoin</span>
                      </div>
                      {/* <div className='bg-alice-blue px-3 py-2 rounded-lg text-sm mt-1 mb-4'>
                        <p className='text-sm text-gunmetal mt-2'>
                          Điểm thưởng sẽ được kích hoạt sau khi đơn hàng được giao cho bạn. Để
                          biết thêm thông tin, vui lòng xem{' '}
                          <a href='/' target='_blank' className='text-brilliant-azure'>
                            TẠI ĐÂY
                          </a>
                        </p>
                      </div> */}

                      <div className='text-sm text-gray-700 bg-anti-flash-white2 px-3 py-2 rounded-lg'>
                        <span>Để xuất hoá đơn VAT xin vui lòng liên hệ Zalo hoặc số điện thoại hotline</span>{' '}
                        <span className='text-brilliant-azure'>
                          0949.696.696
                        </span>
                        <span>{' '}để được hỗ trợ</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              isRepayment ? (
                <div className='o-orderSummary__mobile block lg:hidden fixed bottom-0 left-0 right-0 bg-white z-50'>
                  <div className='flex flex-row gap-4 items-center py-3 px-4 border-t-1'>
                    <div className='w-6/12'>
                      <p className='text-gunmetal text-md'>Thành tiền</p>
                      <p className='text-xl text-neon-fuchsia font-semibold'>{summaryOrder?.orderTotal}</p>
                      {
                        summaryOrder?.orderTotalDiscount
                        && (
                          <p className='text-sm text-gunmetal'>Tiết kiệm: {' '}
                            <span className='text-persian-green'>{summaryOrder?.orderTotalDiscount}</span>
                          </p>
                        )
                      }
                    </div>
                    <div className='w-6/12'>
                      <Button
                        type='button'
                        onClick={() => { onRepayment(repaymentData?.customOrderNumber, repaymentData?.orderTotalValue); }}
                        variants='neon-fuchsia'
                        size='large'
                        className='capitalize'
                      >
                        Thanh toán lại
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <OrderSummaryBottom
                  onUseBcoin={() => setStep(3)}
                  points={orderTotals?.redeemedRewardPoints || 0}
                  onPayment={onPayment}
                  currentStep={step}
                  total={orderTotals?.orderTotal as string}
                  bcoinReward={orderTotals?.willEarnRewardPoints}
                  onUseVoucher={onUseVoucher}
                />
              )
            }

          </>
        );
      case 3:
        return (
          <>
            <div className='container mx-auto mb-3'>
              <TitleBackHandler onBack={backToFirstStep}>
                Sử dụng Bcoin
              </TitleBackHandler>
              <div className='flex flex-col xl:flex-row gap-6'>
                <div className='xl:w-8/12'>
                  <div className='flex flex-col gap-6'>
                    <PointReward
                      points={rewardPointsBalance}
                      appliedBcoin={orderTotals?.redeemedRewardPoints}
                      applyBCoin={applyBCoin}
                      orderTotal={orderTotals as IShoppingCartOrderTotals}
                    />
                  </div>
                </div>
              </div>
            </div>
            <OrderSummaryBottom
              onUseBcoin={() => setStep(3)}
              points={rewardPointsBalance || 0}
              onPayment={onPayment}
              currentStep={step}
              total={orderTotals?.orderTotal as string}
              bcoinReward={orderTotals?.willEarnRewardPoints}
              onUseVoucher={onUseVoucher}
            />
          </>
        );
      case 4:
        return (
          <>
            <div className='container mx-auto mb-3'>
              <TitleBackHandler onBack={backToFirstStep}>
                Sử dụng Voucher
              </TitleBackHandler>
              <div className='flex flex-col xl:flex-row gap-6'>
                <CouponPicker
                  data={coupons}
                  selectedVoucher={discount?.couponCode as string}
                  onSelect={handleApplyCoupon}
                  handleRemoveCoupon={handleRemoveCoupon}

                />
              </div>
            </div>
            <OrderSummaryBottom
              onUseBcoin={() => setStep(3)}
              points={orderTotals?.redeemedRewardPoints || 0}
              onPayment={onPayment}
              currentStep={step}
              total={orderTotals?.orderTotal as string}
              bcoinReward={orderTotals?.willEarnRewardPoints}
              voucherUsed={selectedVoucher}
              onUseVoucher={onUseVoucher}
            />
          </>
        );
      case 5:
        return (
          <div className='container mx-auto mb-3'>
            <TitleBackHandler onBack={onBankingBackToPrev}>
              Thanh toán đơn hàng
            </TitleBackHandler>
            <div className='flex flex-col xl:flex-row gap-6'>
              {/* TODO: Handle Banking payment process data  */}
              <BankingMethodProcess order={{
                totalAmount: parseCurrency(repaymentData?.orderTotal || '0') || parseCurrency(orderTotals?.orderTotal || '0') || 0,
                qrCode: '',
                orderNumber: bankingResponse?.completedModel?.customOrderNumber || repaymentData?.customOrderNumber || ''
              }}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='cart-mobile'>
      <div className='container mx-auto py-4 lg:py-6'>
        <Breadcrumbs items={breadcrumbItems} />
      </div>
      {renderContent()}
      {/* TODO: re-active when develop with logic api */}
      {
        // eslint-disable-next-line no-nested-ternary
        step === 5
          ? (
            <OrderWithBanking onBack={onBankingBackToPrev} onProcess={!isRepayment ? onBankingProcessSuccess : onBankingSuccess} />
          )
          : !isRepayment ? (
            <OrderSummaryBottom
              onUseBcoin={() => setStep(3)}
              points={rewardPointsBalance || 0}
              onPayment={() => { onPayment(); setForm(addressFormRef?.current); }}
              currentStep={step}
              total={orderTotals?.orderTotal as string}
              bcoinReward={orderTotals?.willEarnRewardPoints}
              // bcoinUsed={5000}
              savingAmount={orderTotals?.orderTotalDiscount || ''}
              onUseVoucher={onUseVoucher}
            />
          ) : <div />
      }
      <ProductVariantSelectionMobile
        onClose={() => setOpenVariants(false)}
        isOpen={openVariants}
        data={productDummyData[0]}
        onSubmit={() => setOpenVariants(false)}
      />
      <FailedPaymentModal
        isOpen={openFailedModal}
        onRequestClose={() => setOpenFailedModal(false)}
      />
      <ProcessPaymentModal
        isOpen={openProcessPayment}
        onRequestClose={() => setOpenProcessPayment(false)}
        qrCode={qrCodeImg}
        minutes={10}
        onCountdownEnd={() => setOpenProcessPayment(false)}
        onChangeMethod={() => setOpenProcessPayment(false)}
      />
    </div>
  );
};

export default CartMobileContainer;
