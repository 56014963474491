/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import CartQuickView from '../CartQuickView';
import CommonSearch from '../CommonSearch';

import heartIcon from 'assets/icons/ic_heart.svg';
import manIcon from 'assets/icons/ic_person.svg';
import logo from 'assets/images/logo.png';
import flagVn from 'assets/images/vn-flag.png';
import Icon, { IconName } from 'components/atoms/Icon';
import Authenticate from 'containers/Authenticate';
import LinkPhoneModal from 'containers/LinkPhoneZalo';
import useCart from 'hooks/useCart';
import useClickOutside from 'hooks/useClickOutside';
import useDeviceQueries from 'hooks/useDeviceQueries';
import { logoutApi } from 'services/auth';
import { clearLocalStorage } from 'services/common/storage';
import { setIsLogout, setLoggedIn, setUser } from 'store/auth';
import {
  setCartData, setIsRepayment, setOrderTotals, setRepaymentData
} from 'store/cart';
import { fixedMenuReducer, switchLanguageReducer } from 'store/general';
import { LanguageType } from 'store/general/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ICategories } from 'types/home';
import SLUG_PARAMS from 'utils/constants';
import { formatVndCurrency } from 'utils/functions';

export type MenuType = {
  title: string;
  path: string;
  children?: MenuType[];
};

interface HeaderProps {
  menus: ICategories[];
  isLogged?: boolean;
}
interface LanuageSwitcherProps {
  value: LanguageType;
  onChangeLange: (value: LanguageType) => void;
}

const langList = [
  { label: 'VN', value: 'vi', flag: flagVn },
  {
    label: 'EN',
    value: 'en',
    flag: 'https://preview.redd.it/flag-of-the-english-language-v0-1l33v80qn7ab1.png?auto=webp&s=aa01e6e3b62f933a6e6b35a7a4266513c9b9ffab',
  },
];

const LanuageSwitcher: React.FC<LanuageSwitcherProps> = ({
  value,
  onChangeLange,
}) => {
  const [open, setOpen] = useState(false);
  const langRef = useRef<HTMLDivElement>(null);
  const handleChange = (v: LanguageType) => {
    onChangeLange(v);
    setOpen(false);
  };
  useClickOutside(langRef, () => setOpen(false));
  return (
    <div className='o-header__lang flex items-center'>
      <div className='o-header__lang__label font-medium text-feldgrau text-sm'>
        Ngôn ngữ:
      </div>
      <div className='o-header__lang__group relative ml-2 z-10' ref={langRef}>
        <button
          className='o-header__lang__value font-semibold text-sm flex items-center cursor-pointer'
          onClick={() => setOpen(!open)}
        >
          <img src={value.flag} width={27} height={20} alt='flag' />
          <span className='mx-2'>{value.label}</span>
          <svg
            className='w-3 h-3 text-gunmetal'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 14 8'
          >
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1'
            />
          </svg>
        </button>
        {open && (
          <div className='o-header__lang__pulldown absolute left-0 min-w-[40px] bg-white shadow-md rounded-md text-center'>
            {langList.map((v, i) => (
              <div
                key={`item-${i.toString()}`}
                className='o-header__lang__pulldown__item font-semibold text-sm cursor-pointer px-2 py-1 hover:bg-anti-flash-white2 base-transition'
                onClick={() => handleChange(v)}
              >
                {v.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

interface InfoCardProps {
  to?: string;
  iconSrc: IconName;
  title: string;
  subtitle: string;
  isLink?: boolean;
  dropdown?: boolean;
  isCart?: boolean;
  cartCount?: number;
  isLogged?: boolean;
  handleOpenModal?: () => void;
  onClick?: () => void;
  className?: string;
}

const InfoCard: React.FC<InfoCardProps> = ({
  to,
  iconSrc,
  title,
  subtitle,
  isLink = false,
  dropdown,
  cartCount = 0,
  isCart,
  isLogged,
  handleOpenModal,
  onClick,
  className
}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLButtonElement>(null);

  useClickOutside(dropdownRef, () => setOpen(false));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const wishlistData = useAppSelector((state) => state.wishlist.data);
  const { handleFetchShoppingCart, handleFetchWishList } = useCart();
  const handleLogout = async () => {
    await logoutApi();
    await clearLocalStorage();
    await handleFetchShoppingCart();
    await handleFetchWishList();

    dispatch(setIsLogout(true));
    dispatch(setUser(null));
    dispatch(setCartData([]));
    dispatch(setOrderTotals(null));
    navigate('/');
    setTimeout(() => {
      dispatch(setIsLogout(false));
    }, 0);
  };
  if (isLink && to) {
    return (
      <Link
        to={to}
        className='items-center p-1 bg-card rounded-full lg:rounded-lg hover:bg-alice-blue2 base-transition cursor-pointer hidden lg:flex'
      >
        <div className='flex-none icon w-12 h-12 2xl:w-10 2xl:h-10 flex items-center justify-center rounded-full bg-persian-green bg-opacity-10'>
          <Icon iconName={iconSrc} size='24x24' />
        </div>
        <div className='ml-3 hidden xl:block'>
          <span className='text-roman-silver text-ss 2xl:text-xs font-medium'>
            {title}
          </span>
          <div className='xl:text-sm 2xl:text-base font-semibold'>
            {subtitle}
          </div>
        </div>
      </Link>
    );
  }

  return (
    <button
      className={`flex items-center p-1 bg-card rounded-full gap-3 lg:rounded-lg hover:bg-alice-blue2 base-transition cursor-pointer${dropdown ? ' relative' : ''
        } ${className}`}
      ref={dropdownRef}
      onClick={() => {
        if (dropdown) setOpen(!open);
        else if (to) navigate(to);
        else onClick?.();
      }}
    >
      <div className='flex-none icon w-12 h-12 2xl:w-10 2xl:h-10 flex items-center justify-center rounded-full bg-persian-green bg-opacity-10 relative'>
        <Icon iconName={iconSrc} size='24x24' />
        {isCart && (
          <span className='absolute border-[2px] border-white w-5 h-5 flex items-center justify-center text-sm text-white font-medium bg-neon-fuchsia rounded-full top-[-4px] right-[-4px]'>
            {cartCount}
          </span>
        )}
      </div>
      <div className='hidden xl:block text-left overflow-hidden'>
        <span className='text-roman-silver text-ss 2xl:text-xs font-medium'>
          {title}
        </span>
        <div className='xl:text-sm 2xl:text-base font-semibold truncate'>
          {
            isCart
              ? subtitle || '0đ'
              : subtitle
          }
        </div>
      </div>
      {dropdown && (
        <div className='ml-1 hidden xl:block'>
          <svg
            className='w-3 h-3 text-gunmetal'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 14 8'
          >
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1'
            />
          </svg>
        </div>
      )}
      {dropdown && open && (
        <div className='o-header__info__dropdown absolute top-[100%] p-2 right-[-30px] sm:right-0 lg:right-auto lg:left-0 w-48 sm:w-56 2xl:w-[250px] bg-white shadow-md rounded-lg z-50'>
          {!isLogged ? (
            <div
              onClick={handleOpenModal}
              className='o-header__info__dropdown__item flex items-center font-semibold text-sm 2xl:text-base cursor-pointer p-2 sm:p-4 rounded-lg hover:bg-alice-blue base-transition group'
            >
              <img src={manIcon} alt='man' className='w-4 h-4 lg:w-6 lg:h-6' />
              <span className='text-gunmetal group-hover:text-blueRyb base-transition ml-3'>
                Đăng nhập/Đăng ký
              </span>
            </div>
          ) : (
            <>
              <div
                onClick={() => navigate('/customer-info')}
                className='o-header__info__dropdown__item flex items-center font-semibold text-sm 2xl:text-base cursor-pointer p-2 sm:p-4 rounded-lg hover:bg-alice-blue base-transition group'
              >
                <img src={manIcon} alt='man' className='w-4 h-4 lg:w-6 lg:h-6' />
                <span className='text-gunmetal group-hover:text-blueRyb base-transition ml-3'>
                  Tài khoản
                </span>
              </div>
              <div
                onClick={handleLogout}
                className='o-header__info__dropdown__item flex items-center font-semibold text-sm 2xl:text-base cursor-pointer p-2 sm:p-4 rounded-lg hover:bg-alice-blue base-transition group'
              >
                <img src={manIcon} alt='man' className='w-4 h-4 lg:w-6 lg:h-6' />
                <span className='text-gunmetal group-hover:text-blueRyb base-transition ml-3'>
                  Đăng xuất
                </span>
              </div>
            </>
          )}

          {/* <div
            onClick={() => { setOpen(false); navigate(`${SLUG_PARAMS.WISHLIST}`); }}
            className='o-header__info__dropdown__item flex items-center font-semibold text-sm 2xl:text-base cursor-pointer p-2 sm:p-4 rounded-lg hover:bg-alice-blue base-transition group'
          >
            <img src={heartIcon} alt='like' className='w-4 h-4 lg:w-6 lg:h-6' />
            <span className='text-gunmetal group-hover:text-blueRyb base-transition ml-3'>
              DS yêu thích
            </span>
            <div className='mx-4'>
              {wishlistData.length > 0 && <p className='bg-neon-fuchsia text-white rounded-full w-[20px] h-[20px] text-sm text-center'>{wishlistData.length}</p>}
            </div>
          </div> */}
        </div>
      )}
    </button>
  );
};

const Header: React.FC<HeaderProps> = ({ menus, isLogged }) => {
  const { language, fixedMenu } = useAppSelector((state) => state.general);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [scrollY, setScrollY] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [openViewCart, setOpenViewCart] = useState(false);
  const { isMobile } = useDeviceQueries();
  const {
    user, loggedIn, isSignInZalo
  } = useAppSelector((state) => state.auth);
  const { data, orderTotals } = useAppSelector((state) => state.cart);
  useEffect(() => {
    const handleScroll = () => {
      const headerTopEle = document.querySelector('.o-header__top');
      const topHeight = headerTopEle ? headerTopEle.clientHeight : 0;

      const currentScrollY = window.scrollY;
      if (currentScrollY > topHeight) {
        dispatch(fixedMenuReducer(true));
      }
      if (currentScrollY <= topHeight) {
        dispatch(fixedMenuReducer(false));
      }
      setScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dispatch, scrollY]);

  const changeLanguage = (lang: LanguageType) => {
    dispatch(switchLanguageReducer(lang));
  };

  const toggleMobileMenu = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  const handleSearch = () => {
    navigate(SLUG_PARAMS.SEARCH_RESULT);
  };

  const userName = `${user?.firstName || ''} ${user?.lastName || ''}`;
  const userPhone = `${user?.phone || ''}`;
  const userEmail = `${user?.email || ''}`;

  // eslint-disable-next-line no-nested-ternary
  const resultName = userName === ' ' ? (userPhone === '' ? userEmail : userPhone) : userName;

  return (
    <>
      <header className='o-header bg-white z-40 relative'>
        <div className='o-header__top py-[10px] bg-anti-flash-white hidden lg:block'>
          <div className='container mx-auto'>
            <LanuageSwitcher value={language} onChangeLange={changeLanguage} />
          </div>
        </div>
        <div
          className={`o-header__sticked z-40 bg-white ${fixedMenu ? 'fixed top-0 w-full' : ''
            }`}
        >
          <div className='o-header__middle py-2 sm:py-[14px] lg:py-3 border-b-1 border-gainsboro'>
            <div className='container mx-auto'>
              <div className='flex items-center justify-between'>
                {/* LOGO  */}
                <Link
                  to='/'
                  className='o-header__logo flex-none aspect-w-4 w-[192px] h-10'
                >
                  <img
                    src={logo}
                    alt='bidahub'
                  />
                </Link>
                {/* SEARCH  */}
                <div className='hidden lg:block'>
                  <CommonSearch
                    handleSearch={handleSearch}
                  // suggestionData={suggestionListDummy}
                  />
                </div>
                {/* ACTION  */}
                <div className='o-header__right flex items-center xl:grid xl:grid-cols-3 xl:gap-x-6 lg:w-full ml-3 lg:ml-6'>
                  {/* Hotline */}
                  <InfoCard
                    to='tel:094969696'
                    iconSrc='call'
                    title='Hotline'
                    subtitle='0949.696.696'
                    isLink
                  />
                  {/* Login */}
                  <InfoCard
                    to='#'
                    className='xl:min-w-[180px]'
                    iconSrc='profileCircle'
                    title={isLogged ? 'Tài khoản' : 'Đăng nhập hoặc'}
                    subtitle={
                      isLogged
                        ? resultName
                        : 'Đăng ký'
                    }
                    dropdown
                    isLogged={isLogged}
                    handleOpenModal={() => setOpenAuthModal(true)}
                  />
                  {/* Cart */}
                  <InfoCard
                    iconSrc='cart'
                    title='Giỏ hàng'
                    subtitle={orderTotals?.subTotal || ''}
                    isCart
                    cartCount={data?.length || 0}
                    onClick={() => {
                      if (isMobile) {
                        dispatch(setRepaymentData(null));
                        dispatch(setIsRepayment(false));
                        navigate(`${SLUG_PARAMS.CART}?step=1`);
                      }
                      if (!isMobile) {
                        setOpenViewCart(true);
                      }
                    }}
                  />
                  {/* BURGER MENU MOBILE */}
                  <div className='burger relative'>
                    <div
                      className='items-center p-1 bg-card rounded-full hover:bg-alice-blue2 base-transition cursor-pointer flex lg:hidden'
                      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    >
                      <div className='flex-none icon w-12 h-12 2xl:w-10 2xl:h-10 flex items-center justify-center rounded-full bg-persian-green bg-opacity-10'>
                        <svg
                          className='w-5 h-5 text-blueRyb'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <path
                            stroke='currentColor'
                            strokeLinecap='round'
                            strokeWidth='2'
                            d='M5 7h14M5 12h14M5 17h14'
                          />
                        </svg>
                      </div>
                    </div>
                    {/* Menu Mobile  */}
                    {/* layer */}
                    <div
                      className={`block lg:hidden toggle-menu fixed top-0 left-0 w-full h-screen bg-slate-600 bg-opacity-30 shadow-md py-5 px-3 z-[51] base-transition ${mobileMenuOpen ? 'translate-x-0' : 'translate-x-[-100%]'
                        }`}
                      onClick={() => setMobileMenuOpen(false)}
                    />
                    {/* Toggle Container */}
                    <div
                      className={`toggle-menu overflow-auto block lg:hidden fixed top-0 left-0 w-full sm:w-1/3 h-screen bg-white shadow-md py-5 px-3 z-[52] base-transition ${mobileMenuOpen ? 'translate-x-0' : 'translate-x-[-100%]'
                        }`}
                    >
                      <div className='min-h-[475px]'>
                        <div className='logo flex justify-center relative'>
                          <Link
                            to='/'
                            className='o-header__logo block aspect-w-4 w-[149px] h-10'
                          >
                            <img
                              src='https://picsum.photos/200'
                              alt='bida hub logo'
                              className='object-cover w-full h-full'
                            />
                          </Link>
                          <div
                            className='close bg-anti-flash-white2 rounded-lg p-1 flex items-center justify-center absolute right-0 top-0'
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            <svg
                              className='w-6 h-w-6 text-raisin-black'
                              aria-hidden='true'
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              fill='none'
                              viewBox='0 0 24 24'
                            >
                              <path
                                stroke='currentColor'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M6 18 17.94 6M18 18 6.06 6'
                              />
                            </svg>
                          </div>
                        </div>
                        {/* mobile Menu  */}
                        <nav className='mt-4 text-center'>
                          <ul>
                            {menus?.map((menu, index) => (
                              <li
                                key={`item-${index.toString()}`}
                                className='my-2'
                              >
                                {menu.subCategories
                                  && menu.subCategories.length > 0 ? (
                                  <div className='relative'>
                                    <div
                                      onClick={() => menu.subCategories
                                        && menu.subCategories.length > 0
                                        && toggleMobileMenu()}
                                      className={`flex justify-center items-center px-2 py-4 font-semibold ${openMobileMenu ? 'bg-alice-blue2' : ''
                                        }`}
                                    >
                                      {menu.name}
                                      {menu.subCategories
                                        && menu.subCategories.length > 0 && (
                                          <svg
                                            className={`w-6 h-6 text-gunmetal ${openMobileMenu ? 'rotate-180' : ''
                                              } base-transition`}
                                            aria-hidden='true'
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            fill='none'
                                            viewBox='0 0 24 24'
                                          >
                                            <path
                                              stroke='currentColor'
                                              strokeLinecap='round'
                                              strokeLinejoin='round'
                                              strokeWidth='2'
                                              d='m8 10 4 4 4-4'
                                            />
                                          </svg>
                                        )}
                                    </div>
                                    {menu.subCategories
                                      && menu.subCategories.length > 0 && (
                                        <div
                                          className={`transition-all duration-300 ease-in-out overflow-hidden ${openMobileMenu
                                            ? 'max-h-full opacity-100'
                                            : 'max-h-0 opacity-0'
                                            }`}
                                        >
                                          <ul className='bg-alice-blue2'>
                                            {menu.subCategories.map(
                                              (child, idx) => (
                                                <li
                                                  key={`child-${idx.toString()}`}
                                                  className='my-2'
                                                >
                                                  <a
                                                    href={`${SLUG_PARAMS.CATEGORY_DETAIL}/${child.categoryId}`}
                                                    className='block px-2 py-4 link-hovering hover:bg-alice-blue2 font-semibold'
                                                  >
                                                    {child.name}
                                                  </a>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                  </div>
                                ) : (
                                  <a
                                    href={`${SLUG_PARAMS.CATEGORY_DETAIL}/${menu.categoryId}`}
                                    className='block px-2 py-4 link-hovering hover:bg-alice-blue2 font-semibold'
                                  >
                                    {menu.name}
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                        </nav>
                        <div className='language w-full p-6'>
                          <p className='text-roman-silver text-md text-center'>
                            Ngôn ngữ
                          </p>
                          <div className='flex justify-center mt-2'>
                            <div className='inline-flex justify-center gap-1 bg-anti-flash-white2 p-2 rounded-[4px]'>
                              {langList.map((lang, index) => {
                                const isActive = lang.value === language.value;
                                const strokeColor = isActive
                                  ? 'white'
                                  : 'black';
                                return (
                                  <button
                                    key={`lang-${index.toString()}`}
                                    onClick={() => changeLanguage(lang)}
                                    className={`rounded-[4px] p-2 flex items-center justify-center base-transition text-md font-semibold gap-3 ${!isActive
                                      ? 'bg-white text-black'
                                      : 'bg-blueRyb text-white'
                                      }`}
                                  >
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
                                        stroke={strokeColor}
                                        strokeWidth='1.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                      <path
                                        d='M7.99961 3H8.99961C7.04961 8.84 7.04961 15.16 8.99961 21H7.99961'
                                        stroke={strokeColor}
                                        strokeWidth='1.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                      <path
                                        d='M15 3C16.95 8.84 16.95 15.16 15 21'
                                        stroke={strokeColor}
                                        strokeWidth='1.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                      <path
                                        d='M3 16V15C8.84 16.95 15.16 16.95 21 15V16'
                                        stroke={strokeColor}
                                        strokeWidth='1.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                      <path
                                        d='M3 9.00001C8.84 7.05001 15.16 7.05001 21 9.00001'
                                        stroke={strokeColor}
                                        strokeWidth='1.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                    </svg>

                                    {lang.label}
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* MOBILE SEARCH  */}
          <div className='block lg:hidden'>
            <CommonSearch
              handleSearch={handleSearch}
            // suggestionData={suggestionListDummy}
            />
          </div>
          {/* MENU  */}
          <div className='o-header__menu py-3 bg-white hidden lg:block'>
            <div className='container mx-auto'>
              <div className='flex'>
                {/* <div className='relative cursor-pointer hover:show-child'>
                  <div className='o-header__menu__item flex items-center space-x-2 rounded-md hover:bg-anti-flash-white2 base-transition py-3 px-4'>
                    <Icon iconName='menu' />
                    <p className=' xl:text-sm 2xl:text-base font-semibold'>
                      Danh mục
                    </p>
                  </div>
                  <div className='absolute min-w-[250px] bg-white shadow-md rounded-md p-2 child top-[100%] left-0 hidden'>
                    {menus?.map((menu, index) => (
                      <div
                        className='relative'
                        key={`menu-${index.toString()}`}
                      >
                        <Link
                          to={`${SLUG_PARAMS.CATEGORY_DETAIL}/${menu.categoryId}`}
                          className='o-header__menu__item block rounded-md xl:text-sm 2xl:text-base font-semibold hover:bg-anti-flash-white2 base-transition py-3 px-4'
                        >
                          {menu.name}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div> */}
                {/* MENU  */}
                {menus?.map((menu, index) => (
                  <div
                    className={`relative${menu.subCategories && menu.subCategories.length > 0
                      ? ' hover:show-child'
                      : ''
                      }`}
                    key={`menu-${index.toString()}`}
                  >
                    <Link
                      to={`${SLUG_PARAMS.CATEGORY_DETAIL}/${menu.categoryId}`}
                      className='o-header__menu__item flex rounded-md xl:text-sm 2xl:text-base font-semibold hover:bg-anti-flash-white2 base-transition py-3 px-4'
                    >
                      {menu.name}
                      {menu.subCategories && menu.subCategories.length > 0 && (
                        <svg
                          className='w-6 h-6 text-gunmetal mt-[-2px]'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <path
                            stroke='currentColor'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='m8 10 4 4 4-4'
                          />
                        </svg>
                      )}
                    </Link>
                    {/* CHILD MENU  */}
                    {menu.subCategories && menu.subCategories.length > 0 && (
                      <div className='absolute min-w-[140px] bg-white shadow-md rounded-md p-2 child top-[100%] left-0 hidden'>
                        {menu.subCategories.map((child, idx) => (
                          <Link
                            key={`child-${idx.toString()}`}
                            to={`${SLUG_PARAMS.CATEGORY_DETAIL}/${child.categoryId}`}
                            className='o-header__menu__item__child block rounded-md text-sm font-semibold hover:bg-anti-flash-white2 base-transition p-2'
                          >
                            {child.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Authenticate
            isOpen={openAuthModal || loggedIn}
            handleClose={() => { setOpenAuthModal(false); dispatch(setLoggedIn(false)); }}
          />
          {isSignInZalo && <LinkPhoneModal />}
        </div>
      </header>
      <CartQuickView
        isOpen={openViewCart}
        onClose={() => setOpenViewCart(false)}
        onOpenAuthModal={() => setOpenAuthModal(true)}
      />
    </>
  );
};

export default Header;
