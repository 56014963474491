import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import { resetPasswordApi } from 'services/auth';
import { removeAccessToken, setAccessToken } from 'services/common/storage';
import changePasswordSchema from 'utils/schemas';

interface ChangePasswordForm {
  password: string;
  rePassword: string;
}

const ChangePassword: React.FC = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');
  const otp = searchParams.get('otp');
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control, handleSubmit
  } = useForm<ChangePasswordForm>({
    mode: 'onChange',
    resolver: yupResolver(changePasswordSchema),
    defaultValues: {
      password: '',
      rePassword: '',
    },
  });

  const onSubmit = async (data: ChangePasswordForm) => {
    try {
      setLoading(true);

      if (token) {
        setAccessToken(token);
      }
      const params = {
        VerificationCode: otp,
        Password: data.password,
      };
      await resetPasswordApi(params as any);
      if (window.opener) {
        window.opener.location.reload();
      }
      toast.success('Cập nhật mật khẩu thành công');
      setTimeout(() => {
        window.close();
        setLoading(false);
      }, 2000);
    } catch (error: any) {
      removeAccessToken();
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
      setLoading(false);
    }
  };

  return (
    <div className='max-w-md mx-auto p-4'>
      <h2 className='text-2xl font-semibold text-[#1F2128] mb-2 mt-4'>Mật khẩu mới</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label className='block text-[#1F2128] text-sm font-semibold mb-1' htmlFor='password'>Mật khẩu mới</label>
          <Controller
            name='password'
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder='Nhập mật khẩu mới'
                error={fieldState?.error?.message}
                type='password'
              />
            )}
          />
        </div>
        <div className='mt-4'>
          <Controller
            name='rePassword'
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder='Nhập lại mật khẩu mới'
                error={fieldState?.error?.message}
                type='password'
              />
            )}
          />

        </div>
        <Button
          loading={loading}
          type='submit'
          className='text-white bg-[#0D47F8] mt-2 md:mt-4 p-2 hover:opacity-70 transition-all'
        >
          Cập nhật
        </Button>
      </form>
    </div>
  );
};

export default ChangePassword;
