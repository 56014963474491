/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { citiesOptions, paymentOptions } from 'assets/dummy';
import qrCodeImg from 'assets/images/qrCode.png';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import { AddressItemType } from 'components/organisms/AddressSelector';
import BankingMethodModal from 'components/organisms/BankingMethodModal';
import OrderSummary from 'components/organisms/OrderSummary';
import { PaymentOptionsType } from 'components/organisms/PaymentMethodSelection';
import CouponPicker from 'components/templates/CouponPicker';
import FailedPaymentModal from 'components/templates/FailedPaymentModal';
import PaymentInfo from 'components/templates/PaymentInfo';
import PointReward from 'components/templates/PointReward';
import ProcessPaymentModal from 'components/templates/ProcessPaymentModal';
import ProductTable from 'components/templates/ProductTable';
import { ConfirmModal } from 'containers/ModalContainer';
import { compareAddressData } from 'containers/OrderCompletedContainer';
import useCart from 'hooks/useCart';
import useCheckout from 'hooks/useCheckout';
import { useDeleteTableProduct } from 'hooks/useDelelteTableProduct';
import { getOrderDetailData } from 'services/order';
import { setIsRepayment, setRepaymentData } from 'store/cart';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IShoppingCartOrderTotals } from 'types/cart';
import { calculatePoint, formatVndCurrency, parseCurrency } from 'utils/functions';

const breadcrumbItems = [{ label: 'Home', path: '/' }, { label: 'Cart' }];

const CartDesktopContainer: React.FC = () => {
  const [openFailedModal, setOpenFailedModal] = useState(false);
  const [paymentSelected, setPaymentSelected] = useState<PaymentOptionsType>(paymentOptions[0]);
  const [openProcessPayment, setOpenProcessPayment] = useState(false);
  const {
    data, orderTotals, discount, bcoinToUse, isRepayment, repaymentData
  } = useAppSelector((state) => state.cart);
  const {
    coupons, handleRemoveCoupon, handleApplyCoupon, onPayment, applyBCoin, addressFormRef,
    addresses, selectedAddress, handleChangeShippingAddress, rewardPointsBalance,
    fetchDataDeliveryAddress, handleNotesChange, setProcessBankingOpen, processBankingOpen,
    onBankingProcessSuccess, bankingResponse, handleCancelBankingProcess
  } = useCheckout(paymentSelected);
  const { openConfirmModal, handleConfirm } = useDeleteTableProduct();
  const {
    handleRepayment, setIsBanking, isBanking, onBankingSuccess
  } = useCart(paymentSelected);
  const [orderAddress, setOrderAddress] = useState<AddressItemType | null>(null);
  const { provinces } = useAppSelector((state) => state.general);

  const handleChangePaymentMethod = (value: PaymentOptionsType) => {
    setPaymentSelected(value);
  };
  const dispatch = useAppDispatch();

  const fetchDataOrderDetail = async () => {
    try {
      if (repaymentData?.customOrderNumber) {
        const response = await getOrderDetailData(repaymentData?.customOrderNumber as string);
        const address = {
          address: response?.data?.billingAddress?.address1,
          address2: response?.data?.billingAddress?.address2,
          email: response?.data?.billingAddress?.email,
          id: response?.data?.billingAddress?.id,
          name: `${response?.data?.billingAddress?.firstName} ${response?.data?.billingAddress?.lastName}`,
          phone: response?.data?.billingAddress?.phoneNumber,
        };
        const resultAddress = compareAddressData(address, provinces);
        setOrderAddress(resultAddress);
        dispatch(setRepaymentData(response?.data));
        dispatch(setIsRepayment(true));
      }
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };

  useEffect(() => {
    fetchDataOrderDetail();
  }, []);

  const dataRepayment = repaymentData?.items?.map((item: any) => ({
    id: item?.id || 0,
    name: item?.productName || '',
    image: item?.defaultPictureModel?.imageUrl || 'https://picsum.photos/200',
    price: item.unitPriceValue + ((item.discountValue / item.quantity) || 0),
    discount: item.discountValue,
    count: item?.quantity || 0,
    attributeInfo: item?.attributeInfo,
    productId: item.productId,
    productAttributes: item.productAttributes
  }));

  const cartData = data?.map((item) => ({
    id: item?.id || 0,
    name: item?.productName || '',
    image: item?.picture?.imageUrl || 'https://picsum.photos/200',
    price: item.unitPriceValue + (item?.discountValue / item?.quantity),
    discount: item?.discountValue,
    count: item?.quantity || 0,
    attributeInfo: item?.attributeInfo,
    productId: item?.productId,
    productAttributes: item?.productAttributes,
    inWishlist: item?.inWishlist,
    wishlistId: item?.wishlistId
  }));

  const Order = {
    orderTotal: repaymentData?.orderTotal || '',
    orderTotalDiscount: formatVndCurrency(
      Number(repaymentData?.orderSubtotalValue || 0)
      - Number(repaymentData?.orderTotalValue || 0)
    ),
    subTotal: repaymentData?.orderSubtotal || repaymentData?.totalPrice || '',
    willEarnRewardPoints: calculatePoint(repaymentData?.orderSubtotalValue * repaymentData?.items?.length) || calculatePoint(parseCurrency(repaymentData?.orderTotal) * repaymentData?.items.length) || 0,
    subTotalDiscount: -(repaymentData?.orderTotalDiscountValue || 0),
    redeemedRewardPoints: repaymentData?.redeemedRewardPoints || 0,
    redeemedRewardPointsAmount: repaymentData?.redeemedRewardPointsAmount || '',
    isRepayment: true
  };

  return (
    <>
      <div className='cart-desktop'>
        <div className='container mx-auto py-4 lg:py-6'>
          <Breadcrumbs items={breadcrumbItems} />
        </div>
        <div className='container mx-auto'>
          <h1 className='text-xl md:text-2xl lg:text-3xl font-semibold mb-4 lg:mb-4'>
            Giỏ hàng
          </h1>
          <div className='flex flex-col xl:flex-row gap-6'>
            <div className='xl:w-8/12'>
              <div className='flex flex-col gap-6'>
                <ProductTable
                  products={isRepayment ? dataRepayment : cartData}
                  noCheck
                  onDelete={(id) => {
                    openConfirmModal(
                      'Xoá sản phẩm',
                      'Bạn có chắc muốn xóa sản phẩm đã chọn không ?',
                      id
                    );
                  }}
                  isRepayment={isRepayment}
                />
                <div className='hidden lg:block'>
                  <PaymentInfo
                    isRepayment={isRepayment}
                    addresses={addresses}
                    selectedAddress={isRepayment ? orderAddress : selectedAddress}
                    handleChangeShippingAddress={handleChangeShippingAddress}
                    fetchDataDeliveryAddress={fetchDataDeliveryAddress}
                    ref={addressFormRef}
                    handleNotesChange={handleNotesChange}
                    onPaymentMethodSelected={handleChangePaymentMethod}
                  />
                </div>
              </div>
            </div>
            <div className='xl:w-4/12'>
              <div className='flex flex-col gap-6'>
                {!isRepayment
                  && (
                    <>
                      <div className='hidden lg:block'>
                        <CouponPicker
                          selectedVoucher={discount?.couponCode as string}
                          data={coupons}
                          onSelect={handleApplyCoupon}
                          handleRemoveCoupon={handleRemoveCoupon}
                          isRepayment={isRepayment}
                        />
                      </div>
                      <div className='hidden lg:block'>
                        <PointReward
                          points={rewardPointsBalance}
                          appliedBcoin={orderTotals?.redeemedRewardPoints}
                          applyBCoin={applyBCoin}
                          orderTotal={orderTotals as IShoppingCartOrderTotals}
                          isRepayment={isRepayment}
                        />
                      </div>
                    </>
                  )}
                {/* TOTAL  */}
                <OrderSummary
                  orderSummary={isRepayment ? Order as any : orderTotals}
                  // bcoinToUse={bcoinToUse}
                  onPayment={isRepayment ? () => handleRepayment(repaymentData?.customOrderNumber, repaymentData?.orderTotalValue) : onPayment}
                />
              </div>
            </div>
          </div>
          <div className='mt-6'>
            {/* <ProductSlide productData={productDummyData} title='Có thể bạn sẽ thích' /> */}
          </div>
        </div>
        {/* Delete address popup */}
        <ConfirmModal onConfirm={handleConfirm} />
      </div>
      <FailedPaymentModal
        isOpen={openFailedModal}
        onRequestClose={() => setOpenFailedModal(false)}
      />
      <ProcessPaymentModal
        isOpen={openProcessPayment}
        onRequestClose={() => setOpenProcessPayment(false)}
        qrCode={qrCodeImg}
        minutes={10}
        onCountdownEnd={() => setOpenProcessPayment(false)}
        onChangeMethod={() => setOpenProcessPayment(false)}
      />
      <BankingMethodModal
        isOpen={processBankingOpen || isBanking}
        handleClose={() => { handleCancelBankingProcess(); setIsBanking(false); }}
        onSuccess={!isRepayment ? onBankingProcessSuccess : onBankingSuccess}
        order={{
          totalAmount: parseCurrency(repaymentData?.orderTotal || '0') || parseCurrency(orderTotals?.orderTotal || '0') || 0,
          qrCode: '',
          orderNumber: bankingResponse?.completedModel?.customOrderNumber || repaymentData?.customOrderNumber || ''
        }}
      />
    </>
  );
};

export default CartDesktopContainer;
