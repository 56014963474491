import React from 'react';

import icFb from 'assets/icons/ic_fb2.svg';
import icInsta from 'assets/icons/ic_instagram.svg';
// import icTiktok from 'assets/icons/ic_tiktok.svg';
import icYoutube from 'assets/icons/ic_youtube.svg';
// import express from 'assets/images/247.png';
// import industry from 'assets/images/industryNTrade.png';
import miniApp from 'assets/images/miniApp.png';
import QR from 'assets/images/qr.png';

interface FooterProps {
  logo?: string;
  address?: string;
  phone?: string;
  email?: string;
  customerSupport?: {
    title: string;
    link: string;
  }[];
}

const socialLists = [
  {
    icon: icFb,
    link: 'https://www.facebook.com/bidahub.official',
  },
  {
    icon: icInsta,
    link: 'https://www.instagram.com/bidahub.social/',
  },
  {
    icon: icYoutube,
    link: 'https://www.youtube.com/@BidaHubArena',
  },
  // {
  //   icon: icTiktok,
  //   link: 'https://www.tiktok.com/@bidahub.official',
  // },
];

const Footer: React.FC<FooterProps> = ({
  logo,
  address,
  phone,
  email,
  customerSupport,
}) => (
  <footer className='t-footer bg-white text-gunmetal font-medium pt-0 lg:pt-4 px-4 pb-4 lg:p-8 container mx-auto'>
    <div className='-mx-4 flex flex-wrap justify-between'>
      <div className='w-full lg:w-1/3 p-4'>
        <div className='mb-4'>
          <div className='max-w-[174px]'>
            <img src={logo} alt='BidaHub Logo' className='aspect-174/42 object-cover mb-4' />
          </div>
          <p className='text-2xl font-semibold'>Hộ kinh doanh BidaHub</p>
        </div>
        <p className='mb-2'>{address}</p>
        <p className=' mb-2'>Mã số thuế: 8856137401-001</p>
        {phone && <p className='mb-2'>Điện thoại: <a className='link-hovering' href={`tel:${phone}`}>0949.696.696</a></p>}
        {email && <p>Email: <a className='link-hovering' href={`mailto:${email}`}>{email}</a></p>}
      </div>

      <div className='w-full lg:w-1/3 p-4'>
        <h2 className='text-2xl font-semibold mb-4'>Khách hàng</h2>
        <ul>
          {customerSupport?.map((item, index) => (
            <li key={`customer-support-${String(index)}`} className='li-before mb-2'>
              <a href={item.link} className='link-hovering'>{item.title}</a>
            </li>
          ))}
        </ul>
        <div className='flex items-center mt-4 space-x-4'>
          {/* <div className='aspect-120/48'>
            <img src={industry} alt='Bộ Công Thương' className='object-cover' />
          </div> */}
          {/* <div className='aspect-150/48'>
            <img src={express} alt='247Express' className='object-cover' />
          </div> */}
        </div>
      </div>

      <div className='w-full lg:w-1/3 p-4'>
        <p>Tham gia BidaHub trên ứng dụng Zalo,
          mua sắm đơn giản và nhiều ưu đãi độc quyền chỉ có tại Zalo Mini App
        </p>
        <p className='text-feldgrau text-md mt-2 mb-2'>Quét màn QR bên dưới trên ứng dụng Zalo để bắt đầu</p>
        <div className='flex items-center mt-4 space-x-4'>
          <div className='aspect-234/68'>
            <img src={miniApp} alt='BidaHub App' className='object-cover' />
          </div>
          <div className='w-[1px] self-stretch bg-gainsboro' />
          <div className='aspect-1'>
            <img src={QR} alt='QR Code' className='object-cover' />
          </div>
        </div>
        <div className='mt-4'>
          <p className='text-gunmetal'>Hoặc theo dõi chúng tôi tại đây:</p>
          <ul className='mt-4 flex gap-6'>
            {socialLists.map((item, index) => (
              <li key={`social-${String(index)}`}>
                <a href={item.link} target='_blank' rel='noreferrer'>
                  <img src={item.icon} width={24} height={24} alt='icon' />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
