/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';

import closeImg from 'assets/icons/ic_circle_close.svg';
import useScrollLock from 'hooks/useScrollLock';

Modal.setAppElement('#root');

interface ModalProps {
  title?: string;
  children: React.ReactNode;
  isOpen: boolean;
  onRequestClose: () => void;
  className?: string;
  isHideCloseBtn?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  modalFooter?: React.ReactNode;
  modalTop?: React.ReactNode;
  isOverflow?: boolean;
}

const CustomModal: React.FC<ModalProps> = ({
  title,
  isOpen,
  onRequestClose,
  className = 'max-w-lg',
  isHideCloseBtn,
  children,
  shouldCloseOnOverlayClick,
  modalFooter,
  modalTop,
  isOverflow
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onRequestClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onRequestClose]);

  useScrollLock(!!isOpen);

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={onRequestClose}
      portalClassName='o-popup_portal'
      ariaHideApp={false}
      className='o-popup_content fixed inset-0 p-4 overflow-auto no-scrollbar'
      overlayClassName='fixed inset-0 bg-gray-800 bg-opacity-50'
    >
      <div ref={modalRef} className={`relative mx-auto rounded-2xl w-full ${className}`}>
        <div className='bg-white rounded-2xl shadow-lg relative overflow-hidden w-full'>
          {!isHideCloseBtn && !modalTop && (
            <button
              onClick={onRequestClose}
              className='absolute top-0 right-0 md:top-2 md:right-2 rounded-lg hover:bg-anti-flash-white2 transition ease-in-out duration-300 p-1'
            >
              <img src={closeImg} alt='close' className='w-6' />
            </button>
          )}
          {title && (
            <div className='title p-4 border-b-1 border-gainsboro md:flex justify-between'>
              <h2 className='text-lg text-raisin-black font-semibold'>{title}</h2>
              {modalTop}
            </div>
          )}
          <div className='o-modal__body px-4 md:py-2 text-feldgrau'>
            {children}
          </div>
          {
            modalFooter && (
              <>
                <div className='h-[1px] bg-gainsboro w-full mt-2 md:mt-6' />
                <div className='submit py-4 px-4 lg:px-6'>
                  {modalFooter}
                </div>
              </>
            )
          }
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
