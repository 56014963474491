import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import icBanned from '../../../assets/icons/ic_banned.svg';

type Option = {
  label: string;
  value: string;
  disabled?: boolean;
};

type BoxSelectionsProps = {
  options: Option[];
  onSelect: (value: string) => void;
  title: string;
  isNaked?: boolean;
  selectedValue?: string;
};

const BoxSelections: React.FC<BoxSelectionsProps> = ({
  options, title, selectedValue, isNaked, onSelect
}) => {
  const [selected, setSelected] = useState<string | null>(selectedValue || null);

  useEffect(() => {
    setSelected(selectedValue || null);
  }, [selectedValue]);

  const handleClick = (value: string) => {
    setSelected(value);
    onSelect(value);
  };

  const buttonClassNames = useCallback((value: string, disabled?: boolean) => classNames(
    'px-3 py-2 rounded-lg border text-md font-medium text-center relative',
    {
      'bg-white border-gainsboro text-slate-gray': !!disabled && selected !== value,
      'bg-blueRyb text-white border-blueRyb': selected === value || (selected === value && disabled),
      'bg-white text-gunmetal border-gainsboro': selected !== value && !disabled,
      'bg-blueRyb text-gunmetal border-gainsboro': selected !== value && disabled,
    }
  ), [selected]);

  const mainClassname = classNames(
    'm-boxSelections bg-white p-3 border border-gainsboro rounded-lg xl:p-0 xl:border-none',
    {
      'p-3 border border-gainsboro rounded-lg': !isNaked
    }
  );

  return (
    <div className={mainClassname}>
      <p className='text-gunmetal font-semibold'>{title}</p>
      <div className='flex gap-2 mt-2 flex-wrap'>
        {options.map(({ label, value, disabled }) => (
          <button
            key={value}
            className={buttonClassNames(value, disabled)}
            onClick={() => handleClick(value)}
          >
            {label}
            {
              disabled
              && <img src={icBanned} alt='banned' className='absolute top-0 h-full left-1/2 -translate-x-1/2 ' />
            }
          </button>
        ))}
      </div>
    </div>
  );
};

export default BoxSelections;
