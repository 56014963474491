import React from 'react';

import SearchResultContainer from 'containers/SearchResult';

const SearchResult: React.FC = () => (
  <div className='p-searchResult bg-page'>
    <SearchResultContainer />
  </div>
);

export default SearchResult;
