/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import CountdownTimer from 'components/atoms/CountDownTimer';
import Input from 'components/atoms/Input';
import VerificationInput from 'components/molecules/VerificationInput';
import Modal from 'components/organisms/Modal';
import { profileApi, resendProfileOtpApi } from 'services/auth';
import { linkEmailApi, linkPhoneApi, verifyOtpLinkApi } from 'services/profile';
import { setUser } from 'store/auth';
import { useAppDispatch } from 'store/hooks';

interface ModalUpdateProps {
  isOpen: boolean;
  handleClose: () => void;
  modalKey: string;
  value?: string;
}

interface Step1Props {
  title: string;
  type: string;
  value: string;
  onChange: any;
  errorText?: string;
}

type Step2Props = Omit<Step1Props, 'title'>;

const Step1: React.FC<Step1Props> = ({
  title, type, value, onChange, errorText
}) => {
  const { control } = useForm();

  return (
    <div className='mt-6 md:mt-4'>
      <div className='flex-1'>
        <label
          htmlFor='lastName'
          className='font-semibold text-[2E3538] text-base'
        >
          {title}
        </label>
        <Controller
          name='userName'
          control={control}
          rules={{ required: 'This field is required' }}
          defaultValue={value}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              type={type as any}
              placeholder={
                type === 'phone' ? 'Nhập số điện thoại' : 'Nhập địa chỉ email'
              }
              className='mt-2'
              error={error?.message}
              onChange={(e) => {
                field.onChange(e);
                onChange(e.target.value);
              }}
            />
          )}
        />
      </div>
      <p className='text-red-600 font-medium text-sm mt-2'>{errorText}</p>
      <p className='mt-4 font-medium text-sm text-gunmetal'>
        Mã xác thực (OTP) sẽ được gửi đến{' '}
        {type === 'phone' ? 'số điện thoại' : 'email'} này để xác minh{' '}
        {type === 'phone' ? 'số điện thoại' : 'email'} là của bạn
      </p>
    </div>
  );
};

const Step2: React.FC<Step2Props> = ({
  type, value, onChange, errorText
}) => {
  const { control } = useForm();

  return (
    <div className='mt-6 md:mt-4'>
      <p className='font-medium text-base text-gunmetal'>
        Để xác thực {type === 'phone' ? 'số điện thoại' : 'email'} là của bạn,
        vui lòng nhập mã xác minh gồm 6 số vừa được gửi đến{' '}
        {type === 'phone' ? 'số điện thoại' : 'email'}{' '}
        <span className='font-semibold text-gunmetal'>{`"${value}"`}</span>
      </p>
      <div className='mt-4'>
        <Controller
          name='otp'
          rules={{ required: 'This field is required' }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <VerificationInput
              {...field}
              error={error?.message || errorText}
              onChange={(el: string) => {
                field.onChange(el);
                onChange(el);
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

const ModalUpdate: React.FC<ModalUpdateProps> = ({
  isOpen,
  handleClose,
  modalKey,
  value,
}) => {
  const {
    handleSubmit, setValue, reset, watch
  } = useForm({
    defaultValues: {
      userName: value || '',
      otp: '',
    },
  });
  const userName = watch('userName');
  const [step, setStep] = useState(1);
  const [customerId, setCustomerId] = useState<string>('');
  const [isResend, setIsResend] = useState<boolean>(false);
  const [loadingSendOtp, setLoadingSendOtp] = useState<boolean>(false);
  const [loadingSubmitOtp, setLoadingSubmitOtp] = useState<boolean>(false);
  const [errorText, setErrorText] = useState('');

  const dispatch = useAppDispatch();
  const onSubmit = async (data: any) => {
    try {
      setLoadingSubmitOtp(true);
      await verifyOtpLinkApi({
        Identifier: data?.userName,
        VerificationCode: data.otp,
        CustomerId: customerId,
      });
      const profile = await profileApi();
      dispatch(setUser(profile.data));
      toast.success(
        `Cập nhật ${modalKey === 'phone' ? 'số điện thoại' : 'email'
        } thành công`
      );
      handleClose();
      setStep(1);
      setLoadingSubmitOtp(false);
      setErrorText('');
    } catch (error: any) {
      setLoadingSubmitOtp(false);
      setErrorText('Mã xác thực không hợp lệ');
    }
  };

  const renderTitle = useMemo(() => {
    if (step === 1) {
      return modalKey === 'phone' ? 'Cập nhật số điện thoại' : 'Cập nhật email';
    }
    return 'Nhập mã xác minh';
  }, [step, modalKey]);

  const handleSendOtp = async (data: any) => {
    try {
      setLoadingSendOtp(true);
      setErrorText('');

      if (modalKey === 'phone') {
        const response = await linkPhoneApi({ PhoneNumber: data?.userName });
        setCustomerId(response.customerId);
        setStep(2);
      }
      if (modalKey === 'email') {
        const response = await linkEmailApi({ Email: data?.userName });
        setCustomerId(response.customerId);
        setStep(2);
      }
      toast.success(
        'Mã xác minh đã được gửi. Vui lòng kiểm tra hộp thư của bạn'
      );
      setLoadingSendOtp(false);
    } catch (error: any) {
      if (modalKey === 'phone' && error?.response?.data?.ErrorList?.[0] === 'Phone number already in use') {
        setErrorText('Số điện thoại đã được sử dụng');
      } else if (modalKey === 'email' && error?.response?.data?.ErrorList?.[0] === 'Email already in use') {
        setErrorText('Email đã được sử dụng');
      } else {
        toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
      }
      setLoadingSendOtp(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      const response: any = await resendProfileOtpApi({ Identifier: userName });
      setCustomerId(response.customerId);
      toast.success('Mã xác minh đã được gửi. Vui lòng kiểm tra hộp thư của bạn');
      reset({ ...watch(), otp: '' });
      setIsResend(true);
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };

  useEffect(() => {
    reset({
      userName: value || '',
      otp: '',
    });
  }, [value, reset]);

  return (
    <Modal
      isOverflow
      isOpen={isOpen}
      onRequestClose={() => {
        setErrorText('');
        handleClose();
        setStep(1);
      }}
      className='max-w-[480px] !h-auto'
      title={renderTitle}
      modalFooter={
        step === 1 ? (
          <div className='flex flex-row gap-4 justify-end'>
            <Button
              onClick={() => {
                setErrorText('');
                handleClose();
              }}
              type='button'
              size='large'
              variants='secondary'
              className='max-w-[120px]'
            >
              Quay lại
            </Button>
            <Button
              size='large'
              loading={loadingSendOtp}
              type='button'
              onClick={handleSubmit(handleSendOtp)}
              className='max-w-[120px]'
            >
              Cập nhật
            </Button>
          </div>
        ) : (
          <div>
            <Button
              loading={loadingSubmitOtp}
              size='large'
              onClick={handleSubmit(onSubmit)}
            >
              Xác minh
            </Button>
            {isResend ? (
              <div className='text-[#2E3538] cursor-pointer mt-4 md:mt-6 flex'>
                Gửi lại mã sau
                <CountdownTimer
                  initialSeconds={2}
                  variant='medium'
                  className='ml-1 text-base'
                  onComplete={() => setIsResend(false)}
                />
              </div>
            ) : (
              <div className='text-[#2E3538] cursor-pointer mt-4 md:mt-6 flex items-center'>
                Không nhận được mã
                <span
                  className='text-[#3D8EFF] text-md cursor-pointer ml-1'
                  onClick={handleResendOtp}
                >
                  Gửi lại mã
                </span>
              </div>
            )}
            <p className='text-md font-medium text-[#2E3538] mt-2'>
              Mã xác thực sẽ có hiệu lực trong vòng 15 phút
            </p>
          </div>
        )
      }
    >
      {step === 1 ? (
        <Step1
          title={modalKey === 'phone' ? 'Số điện thoại' : 'Địa chỉ Email'}
          type={modalKey === 'phone' ? 'phone' : 'email'}
          value={value || ''}
          onChange={(val: string) => {
            if (errorText) {
              setErrorText('');
            }
            setValue('userName', val);
          }}
          errorText={errorText}
        />
      ) : (
        <Step2
          type={modalKey === 'phone' ? 'phone' : 'email'}
          value={userName || ''}
          onChange={(val: string) => setValue('otp', val)}
          errorText={errorText}
        />
      )}
    </Modal>
  );
};
export default ModalUpdate;
