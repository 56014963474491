/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import BackPopupButton from 'components/molecules/BackPopupButton';
import { sendOTPForgotPasswordApi } from 'services/auth';
import SLUG_PARAMS from 'utils/constants';

interface ForgotPasswordProps {
  handleBack: () => void;
  handleComplete: (val: string) => void;
  setParams?: (value?: any) => void
}

type ForgotPasswordTypes = {
  contact: string;
};

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  handleBack,
  handleComplete,
  setParams
}) => {
  const method = useForm<ForgotPasswordTypes>({
    mode: 'onChange',
    defaultValues: {
      contact: '',
    },
  });
  const validateUserName = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10,15}$/; // Adjust the phone number length according to your needs

    if (emailRegex.test(value)) {
      return true;
    }

    if (phoneRegex.test(value)) {
      return true;
    }

    return 'Vui lòng nhập số điện thoại hoặc email hợp lệ';
  };

  const handleConfirm = async (data: any) => {
    try {
      const response = await sendOTPForgotPasswordApi({
        Identifier: data.contact
      });
      setParams?.(response);
      toast.success('Mã OTP đã được gửi đến số điện thoại hoặc email của bạn');
    } catch (error: any) {
      toast.error('Có lỗi xảy ra, vui lòng thử lại sau');
    }
  };

  return (
    <div className='h-full'>
      <BackPopupButton onClick={handleBack} />
      <h2 className='text-lg md:text-xl font-semibold text-[#1F2128] mb-2 mt-4'>Quên mật khẩu?</h2>
      <p className='text-feldgrau mb-8'>
        Vui lòng nhập thông tin tài khoản để lấy lại mật khẩu
      </p>
      <label className='block text-[#1F2128] font-semibold mb-3' htmlFor='contact'>Số điện thoại hoặc Email</label>
      <FormProvider {...method}>
        <form
          noValidate
          onSubmit={() => method.handleSubmit((data) => {
            handleComplete(data.contact);
            handleConfirm(data);
          })()}
        >
          <Controller
            name='contact'
            rules={{
              required: 'Trường này là bắt buộc',
              validate: validateUserName,
            }}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder='Nhập số điện thoại hoặc Email'
                error={fieldState?.error?.message}
                required
              />
            )}
          />
        </form>
      </FormProvider>
      <Button
        type='submit'
        size='large'
        variants='neon-fuchsia'
        className='mt-4 md:mt-6'
        onClick={() => method.handleSubmit((data) => {
          handleComplete(data.contact);
          handleConfirm(data);
        })()}
      >
        Lấy lại mật khẩu
      </Button>
      <p className='mt-6 text-feldgrau font-medium text-sm'>Đổi số điện thoại? <a href='tel:0949696696' className='text-[#3D8EFF] text-sm font-semibold'>Liên hệ hotline: 0949.696.696</a></p>
    </div>
  );
};
export default ForgotPassword;
