/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react';

import coinImg from 'assets/images/coin.png';
import ContentPanel from 'components/organisms/ContentPanel';
import TitleBackHandler from 'components/organisms/TitleBackHandler';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { BcoinsHistory } from 'types/users';
import { formatDateDDMMYYYYHHMM, formatDecimalNumber } from 'utils/functions';

interface UserBcoinTableProps {
  onMobileBack?: () => void;
  bcoins: number;
  data: BcoinsHistory;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  loading?: boolean;
}

const UserBcoinTable: React.FC<UserBcoinTableProps> = ({
  onMobileBack,
  setCurrentPage,
  bcoins,
  currentPage,
  data,
  loading
}) => {
  const bcoinFormat = formatDecimalNumber(bcoins);
  // const [currentPage, setCurrentPage] = useState(1);
  const { width } = useWindowDimensions();

  // Calculate the data to be displayed on the current page
  const currentData = data?.rewardPoints;

  // Calculate the total number of pages
  const totalPages = data?.pagerModel?.totalPages;
  const handleClick = (page: number) => {
    setCurrentPage(page);
  };

  // Generate pagination with ellipses
  const renderPagination = () => {
    const pageNumbers: number[] = [];
    const maxPagesToShow = 5; // Number of pages to show in pagination

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i += 1) {
        pageNumbers.push(i);
      }
    } else if (currentPage <= 3) {
      pageNumbers.push(1, 2, 3, 4, totalPages);
    } else if (currentPage > 3 && currentPage < totalPages - 2) {
      pageNumbers.push(
        1,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        totalPages
      );
    } else {
      pageNumbers.push(
        1,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages
      );
    }

    const changePagingButtonClass = 'w-9 h-9 flex items-center justify-center bg-white text-gray-700 rounded-md border-gainsboro border disabled:bg-anti-flash-white2 disabled:text-slate-gray disabled:cursor-not-allowed';

    // if (!loading) {
    //   return <LoadingDelivery />;
    // }

    return (
      <div className='flex items-center lg:ml-6 gap-x-2'>
        <button
          onClick={() => handleClick(currentPage - 1)}
          disabled={currentPage === 1}
          className={changePagingButtonClass}
        >
          &lt;
        </button>
        {pageNumbers.map((page, index) => (
          <React.Fragment key={`page-${page}`}>
            {index > 0 && page - pageNumbers[index - 1] > 1 && <span>...</span>}
            <button
              onClick={() => handleClick(page)}
              className={`w-9 h-9 flex items-center justify-center border-gainsboro border rounded-md ${currentPage === page
                ? 'bg-blueRyb text-white'
                : 'bg-white text-gunmetal'
                }`}
            >
              {page}
            </button>
          </React.Fragment>
        ))}
        <button
          onClick={() => handleClick(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={changePagingButtonClass}
        >
          &gt;
        </button>
      </div>
    );
  };
  const renderContent = useCallback(() => {
    if (width < 1024) {
      return (
        <div className='item-mobile'>
          {currentData?.map((item, index) => (
            <div
              key={`card-${index.toString()}`}
              className='bg-white-md p-4  border border-t-1 border-t-gainsboro'
            >
              <div className='flex justify-between'>
                <p className='text-raisin-black text-md'>{item.message}</p>
                <p
                  className={`text-md ${item.points < 0
                    ? 'text-international-orange'
                    : 'text-medium-turquoise'
                    }`}
                >
                  {item.points > 0
                    ? `+${formatDecimalNumber(item.points)}`
                    : formatDecimalNumber(item.points)}
                </p>
              </div>
              <p className='text-feldgrau text-md mt-2'>
                {formatDateDDMMYYYYHHMM(item.createdOn)}
              </p>
              {/* <p className='text-feldgrau text-md mt-2'>{`End date: ${formatDateDDMMYYYYHHMM(
                item.endDate
              )}`}
              </p> */}
              <p className='text-gunmetal text-md font-semibold mt-2'>
                {`Số dư đang có: ${formatDecimalNumber(item.pointsBalance)}`}
              </p>
            </div>
          ))}
        </div>
      );
    }
    return (
      <div className='overflow-x-auto'>
        <table className='min-w-full bg-white'>
          <thead>
            <tr className='text-left'>
              <th className='py-4 px-6 border-b font-semibold'>Ngày giao dịch</th>
              <th className='py-4 px-6 border-b font-semibold'>Điểm</th>
              <th className='py-4 px-6 border-b font-semibold'>
                Số dư đang có
              </th>
              <th className='py-4 px-6 border-b font-semibold'>Thông báo</th>
              {/* <th className='py-4 px-6 border-b font-semibold'>End date</th> */}
            </tr>
          </thead>
          <tbody>
            {currentData?.map((item, index) => (
              <tr key={`row-${index.toString()}`} className='hover:bg-gray-100'>
                <td className='py-4 px-6 border-b border-gainsboro text-feldgrau text-md'>
                  {formatDateDDMMYYYYHHMM(item.createdOn)}
                </td>
                <td
                  className={`py-4 px-6 border-b border-gainsboro text-md ${item.points < 0
                    ? 'text-international-orange'
                    : 'text-medium-turquoise'
                    }`}
                >
                  {formatDecimalNumber(item?.points || 0)}
                </td>
                <td className='py-4 px-6 border-b border-gainsboro text-md font-semibold text-feldgrau'>
                  {formatDecimalNumber(item?.pointsBalance || 0)}
                </td>
                <td className='py-4 px-6 border-b border-gainsboro text-md text-feldgrau'>
                  {item.message}
                </td>
                {/* <td className='py-4 px-6 border-b border-gainsboro text-md text-feldgrau'>
                  {formatDateDDMMYYYYHHMM(item.endDate)}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, currentData]);

  return (
    <div className='t-userBcoinTable'>
      <TitleBackHandler onBack={onMobileBack}>
        Điểm thưởng Bcoin
      </TitleBackHandler>
      <ContentPanel contentClassName='p-0'>
        {/* TOP  */}
        <div className='px-6 py-4'>
          <div className='flex flex-col lg:flex-row gap-2 lg:items-center'>
            <div className='flex items-center gap-x-2'>
              <img
                src={coinImg}
                alt='coin'
                className='w-[24px] h-[24px] object-cover'
              />
              <p className='text-medium-turquoise text-2xl font-semibold flex items-center gap-x-2'>
                <span>{bcoinFormat} </span><span className='text-gunmetal text-base'>Bcoin</span>
              </p>
            </div>
            <p className='text-feldgrau text-md'>
              tương ứng với ({bcoinFormat})
            </p>
          </div>
          {
            bcoins > 0 && (
              <p className='mt-2 text-md lg:text-base'>
                {bcoinFormat} coins will expire on.
              </p>
            )
          }

        </div>
        {/* TABLE  */}
        {renderContent()}
      </ContentPanel>
      {/* Pagination Controls */}
      <div className='pagination flex items-center justify-center lg:justify-start mt-3'>
        {width >= 1024 && (
          <span className='text-gunmetal text-md'>
            Total {data?.pagerModel?.totalRecords}
          </span>
        )}
        {renderPagination()}
      </div>
    </div>
  );
};

export default UserBcoinTable;
