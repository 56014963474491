import React from 'react';

// import { paymentOptions } from 'assets/dummy';
import errorImg from 'assets/images/error-payment.png';
import Button from 'components/atoms/Button';
import CustomModal from 'components/organisms/Modal';
import { formatVndCurrency } from 'utils/functions';

interface FailedPaymentModalProps {
  isOpen: boolean;
  orderId?: number;
  totalPrice?: number;
  onRequestClose: () => void;
  handleRepayment?: () => void;
}

const FailedPaymentModal: React.FC<FailedPaymentModalProps> = ({
  isOpen,
  orderId = 0,
  totalPrice = 0,
  onRequestClose,
  handleRepayment,
}) => (
  <CustomModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    title='Thanh toán không thành công'
    className='max-w-[720px]'
    modalFooter={(
      <div className='flex flex-col md:flex-row gap-4 justify-end'>
        {/* <Button
          onClick={onRequestClose}
          size='large'
          variants='secondary'
          className='text-gunmetal bg-white border-blueRyb border capitalize md:max-w-[220px]'
        >
          Đổi phương thức khác
        </Button> */}
        <Button
          onClick={handleRepayment}
          size='large'
          variants='neon-fuchsia'
          className='text-white bg-neon-fuchsia border-neon-fuchsia border hover:bg-white hover:text-gunmetal capitalize md:max-w-[220px]'
        >
          Thanh toán lại
        </Button>
      </div>
    )}
  >
    <div className='t-failedPaymentModal py-2'>
      <div className='top flex items-center flex-col md:flex-row justify-center gap-4 md:gap-6'>
        <img
          src={errorImg}
          alt='error img'
          className='w-[120px] h-[120px] lg:w-[148px] lg:h-[148px]'
        />
        <div className='px-4 lg:py-6 lg:px-6 py-4 bg-carmine-pink bg-opacity-[0.16] max-w-[404px] rounded-lg'>
          <span className='text-neon-fuchsia'>Thanh toán thất bại.</span> Vui
          lòng thanh toán lại hoặc thay đổi phương thức khác
        </div>
      </div>
      <div className='info bg-anti-flash-white2 p-4 rounded-md mt-4 lg:mt-6'>
        <div className='mb-4'>
          <h2 className='text-lg font-semibold'>Thông tin đơn hàng</h2>
        </div>
        <div className='space-y-2'>
          <div className='flex justify-between items-center'>
            <span className='text-feldgrau'>Mã đơn hàng</span>
            <span className='font-semibold text-raisin-black'>{`#${orderId}`}</span>
          </div>
          <div className='flex justify-between items-center border-t border-dashed pt-2 mt-2'>
            <span className='text-feldgrau max-w-[120px] md:max-w-[100%]'>
              Phương thức thanh toán
            </span>
            <div className='flex items-center space-x-2'>
              {/* <img
                src={paymentOptions[2].logo}
                alt='VNPAY QR'
                className='h-6 rounded-md'
              /> */}
              {/* <span className='font-semibold text-gray-800'>
                {paymentOptions[2].method}
              </span> */}
            </div>
          </div>
          <div className='flex justify-between items-center border-t border-dashed pt-2 mt-2'>
            <span className='text-feldgrau'>Tổng tiền</span>
            <span className='font-semibold text-neon-fuchsia text-lg'>
              {formatVndCurrency(totalPrice)}
            </span>
          </div>
        </div>
      </div>
    </div>
  </CustomModal>
);

export default FailedPaymentModal;
