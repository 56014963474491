import classNames from 'classnames';
import React from 'react';

interface SwitchProps {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

const Switch: React.FC<SwitchProps> = ({ checked, onChange, disabled }) => {
  const switchClassNames = classNames(
    'relative inline-flex items-center  h-6 rounded-full w-11 transition-colors focus:outline-none',
    {
      'bg-gainsboro': !checked && !disabled,
      'bg-blueRyb': checked && !disabled,
      'bg-anti-flash-white2': disabled,
    }
  );

  const circleClassNames = classNames(
    'inline-block w-4 h-4 transform rounded-full transition-transform',
    {
      'bg-gunmetal translate-x-1': !checked,
      'bg-white translate-x-6': checked,
      'bg-slate-gray': disabled,
    }
  );

  return (
    <button
      type='button'
      className={switchClassNames}
      onClick={!disabled ? onChange : undefined}
      disabled={disabled}
    >
      <span className={circleClassNames} />
    </button>
  );
};

export default Switch;
