import React from 'react';

import SLUG_PARAMS from 'utils/constants';

export type ManufacturesType = {
  title: string;
  logo: string;
  id: number
};

interface PartnersSlideProps {
  data: ManufacturesType[]
}

const PartnersSlide: React.FC<PartnersSlideProps> = ({ data }) => (
  <div className='t-partnersSlide'>
    <p className='max-w-lg text-xl lg:text-2xl font-semibold leading-normal mb-3'>
      Đối tác
    </p>
    <div className='grid grid-cols-2 md:grid-cols-4 md:flex md:flex-wrap gap-2 lg:gap-4 md:justify-center bg-white p-6 rounded-xl'>
      {
        data.map((part, index) => (
          <div className='md:p-2 aspect-200/96 md:w-3/12 xl:w-2/12' key={`cate-${index.toString()}`}>
            <a href={`${SLUG_PARAMS.MANUFACTURES}/${part.id}`} className='px-1.5 aspect-200/96 focus:outline-none' title={part.title}>
              <img src={part.logo} alt={part.title} className='w-full h-full object-cover rounded-xl' />
            </a>
          </div>
        ))
      }
    </div>
  </div>
);

export default PartnersSlide;
