/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import PasswordScreen from './password';
import SignInScreen, { SignInScreenActionRef } from './signInScreen';

import AuthenticationWrapper from 'components/templates/AuthenticationWrapper';
import {
  checkUserExistApi,
  checkUserPasswordApi,
  profileApi,
  resendOtpApi,
  signinOrSignupApi,
  verifyOtpApi,
} from 'services/auth';
import { setAccessToken } from 'services/common/storage';
import { setUser } from 'store/auth';
import { useAppDispatch } from 'store/hooks';
import { AUTHENTICATION_STEP, PASSWORD_STEP } from 'utils/constants';

interface AuthenticateProps {
  isOpen: boolean;
  handleClose: () => void;
}

type PasswordType = {
  type: string;
  value: string;
};

const Authenticate: React.FC<AuthenticateProps> = ({
  isOpen = false,
  handleClose,
}) => {
  const [step, setStep] = useState(AUTHENTICATION_STEP.USERNAME);
  const [passwordStep, setPasswordStep] = useState<number>(
    PASSWORD_STEP.PASSWORD
  );
  const [verifyOtpUserId, setVerifyOtpUserId] = useState<string>('');
  const [userName, setUserName] = useState('');
  const signInRef = useRef<SignInScreenActionRef>(null);
  const dispatch = useAppDispatch();
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [checkUserExistLoading, setCheckUserExistLoading] = useState<boolean>(false);
  const [isCustomerExist, setIsCustomerExist] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [loadingOtp, setLoadingOtp] = useState<boolean>(false);

  const handleCloseModal = () => {
    handleClose();
    setStep(AUTHENTICATION_STEP.USERNAME);
    setPasswordStep(PASSWORD_STEP.PASSWORD);
    setErrorText('');
  };

  const handleResendOtp = async () => {
    try {
      const response: any = await resendOtpApi({ Identifier: userName });
      setVerifyOtpUserId(response.customerId);
      toast.success(response?.message);
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };

  const handleSubmit = async (data: PasswordType) => {
    if (data && userName) {
      switch (data.type) {
        case 'otp':
          try {
            setLoginLoading(true);
            const response = await verifyOtpApi({
              CustomerId: verifyOtpUserId as string,
              Identifier: userName,
              VerificationCode: data.value,
            });
            setAccessToken(response.data.token);
            const profile = await profileApi();
            dispatch(setUser(profile.data));
            const checkPassword = await checkUserPasswordApi();
            if (!checkPassword?.isPasswordSet) {
              setPasswordStep(PASSWORD_STEP.SET_PASSWORD);
            } else {
              handleCloseModal();
            }
            setLoginLoading(false);
          } catch (error: any) {
            setLoginLoading(false);
            setErrorText('Mã xác thực không hợp lệ');
          }
          break;
        case 'password':
          try {
            setLoginLoading(true);
            const response = await signinOrSignupApi({
              Identifier: userName,
              Password: data.value,
            });
            setAccessToken(response.data.token);
            const profile = await profileApi();
            dispatch(setUser(profile.data));
            const checkPassword = await checkUserPasswordApi();
            if (!checkPassword?.isPasswordSet) {
              setPasswordStep(PASSWORD_STEP.SET_PASSWORD);
            } else {
              handleCloseModal();
            }
            setLoginLoading(false);
          } catch (error: any) {
            setErrorText('Thông tin mật khẩu không đúng. Vui lòng nhập lại');
            setLoginLoading(false);
          }
          break;
        default:
          break;
      }
    }
  };

  const handleSubmitIdentifier = async () => {
    try {
      setCheckUserExistLoading(true);
      const signInInformation = await signInRef.current?.handleForm();
      const response = await checkUserExistApi({
        Identifier: signInInformation?.userName as string,
      });
      setIsCustomerExist(response.isCustomerExist);
      if (!response.isCustomerExist) {
        const signupRes: any = await signinOrSignupApi({
          Identifier: signInInformation?.userName as string,
        });
        setVerifyOtpUserId(signupRes?.customerId);
        setPasswordStep(PASSWORD_STEP.OTP);
      }
      setStep(AUTHENTICATION_STEP.PASSWORD);
      setUserName(signInInformation?.userName || '');
      setCheckUserExistLoading(false);
    } catch (error: any) {
      setCheckUserExistLoading(false);
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };

  const handleSwitchOtp = async () => {
    try {
      setErrorText('');
      setLoadingOtp(true);
      const response: any = await signinOrSignupApi({
        Identifier: userName,
      });
      setVerifyOtpUserId(response?.customerId);
      setPasswordStep(PASSWORD_STEP.OTP);
      setLoadingOtp(false);
    } catch (error: any) {
      setLoadingOtp(false);
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };
  const renderStep = () => {
    switch (step) {
      case AUTHENTICATION_STEP.USERNAME:
        return (
          <SignInScreen
            handleCloseModal={handleCloseModal}
            handleSetStep={handleSubmitIdentifier}
            ref={signInRef}
            loading={checkUserExistLoading}
          />
        );
      case AUTHENTICATION_STEP.PASSWORD:
        return (
          <PasswordScreen
            handleBack={() => { setStep(AUTHENTICATION_STEP.USERNAME); setErrorText(''); }}
            phone={userName}
            handleCloseModal={handleCloseModal}
            handleSetPassword={handleSubmit}
            loginLoading={loginLoading}
            step={passwordStep}
            setStep={setPasswordStep}
            handleSwitchOtp={handleSwitchOtp}
            handleResendOtp={handleResendOtp}
            isCustomerExist={isCustomerExist}
            errorText={errorText}
            setErrorText={setErrorText}
            loadingOtp={loadingOtp}
          // ref={passwordRef}
          />
        );
      default:
        return null;
    }
  };
  return (
    <AuthenticationWrapper isOpen={isOpen} handleClose={handleCloseModal}>
      {renderStep()}
    </AuthenticationWrapper>
  );
};

export default Authenticate;
