import React, { useEffect, useState } from 'react';

import Button from 'components/atoms/Button';
import { IDataOrder } from 'types/order';
import { formatDateDDMMYYYYHHMM, formatVndCurrency } from 'utils/functions';
import {
  COMPLETE, PartiallyShipped, PENDING, SHIPPED
} from 'utils/order';

interface UserOrderItemProps {
  data: IDataOrder;
  onDetailsClick?: () => void;
  onReorderClick?: () => void;
  onRePayment?: () => void;
}

const UserOrderItem: React.FC<UserOrderItemProps> = ({
  data,
  onDetailsClick,
  onReorderClick,
  onRePayment,
}) => {
  const {
    orderNumber,
    orderDate,
    productQuantity,
    totalPrice,
    status,
    paymentStatus,
    shippingStatus,
    items,
  } = data;
  const [badge, setBadge] = useState({
    title: ''
  });
  useEffect(() => {
    if (paymentStatus === PENDING) {
      setBadge({
        title: 'Chờ thanh toán'
      });
      return;
    }
    if (shippingStatus === SHIPPED || shippingStatus === PartiallyShipped) {
      setBadge({
        title: 'Đang giao hàng'
      });
      return;
    }
    switch (status) {
      case 'Pending':
        setBadge({
          title: 'Chờ xác nhận'
        });
        break;
      case 'Processing':
        setBadge({
          title: 'Đang xử lý'
        });
        break;
      case 'WaitingPayment':
        setBadge({
          title: 'Chờ thanh toán'
        });
        break;
      case 'Complete':
        setBadge({
          title: 'Thành công'
        });
        break;
      case 'Cancelled':
        setBadge({
          title: 'Đã hủy'
        });
        break;
      default:
        break;
    }
  }, [status]);

  function decodeHtmlEntities(str: any) {
    const tempElement = document.createElement('textarea');
    tempElement.innerHTML = str;
    return tempElement.value.replace(/<br\s*\/?>/gi, ',');
  }

  return (
    <div className='bg-white'>
      <div className='flex mb-2 flex-col'>
        <div className='flex items-center space-x-2'>
          <p className={`text-lg font-semibold ${status === 'Cancelled' ? 'text-cadmium-red' : 'text-gunmetal'}`}>{badge.title}</p>
          <span className='text-gunmetal font-semibold text-lg'>#{orderNumber}</span>
        </div>
        <span className='text-md mt-2 text-feldgrau'>Ngày đặt hàng: {formatDateDDMMYYYYHHMM(orderDate)}</span>
      </div>

      {/* TODO: Mapping product list in a order  */}
      <div className='ordered-products space-y-2'>
        {
          items?.map((product, idx) => (
            <div className='flex space-x-3 items-center product-item' key={`product-${idx.toString()}`}>
              <div className='aspect-1 w-full max-w-[72px] rounded-lg overflow-hidden'>
                <img
                  src={product?.defaultPictureModel?.imageUrl}
                  alt={product?.productSeName}
                  className='object-cover w-full h-full'
                />
              </div>
              <div className='flex-1'>
                <h3 className='font-semibold text-gunmetal'>{product?.productName || ''}</h3>
                {product.attributeInfo && <p className='text-md text-feldgrau'>Phân loại hàng: {decodeHtmlEntities(product.attributeInfo)}</p>}
                {/* TODO: Show quantity for each item in a order  */}
                <div className='flex justify-between'>
                  <p className='text-md text-gunmetal'>x{product?.quantity || 1}</p>
                  <p className='text-md text-gunmetal'>{formatVndCurrency(product?.subTotalValue || 0)}</p>
                </div>
              </div>
            </div>
          ))
        }
      </div>

      {/* Bottom  */}
      <div className='md:flex justify-between items-center mt-2 bg-white-smoke px-4 py-2'>
        {/* Total  */}
        <div className='total flex'>
          <div className='text-gunmetal pr-2 border-r border-gainsboro'>
            {productQuantity} sản phẩm
          </div>
          <div className='font-semibold pl-2'>
            Tổng: {totalPrice}
          </div>
        </div>
        {/* button  */}
        <div className='flex space-x-4 mt-2 md:mt-0'>
          <Button variants='secondary' onClick={onDetailsClick} className='md:min-w-[92px] max-w-[90px] md:max-w-full'>
            Chi Tiết
          </Button>
          {
            paymentStatus === PENDING
            && (
              <Button variants='neon-fuchsia' onClick={onRePayment} className='md:min-w-[150px] max-w-[150px] md:max-w-full'>
                Thanh toán lại
              </Button>
            )
          }
          {
            status === COMPLETE
            && (
              <Button variants='secondary' onClick={onReorderClick} className='md:min-w-[92px] max-w-[90px] md:max-w-full'>
                Mua Lại
              </Button>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default UserOrderItem;
