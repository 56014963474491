import React from 'react';
import { Link } from 'react-router-dom';

// import menuDumData from 'assets/dummy';
import notFoundBg from 'assets/images/not-found.png';
// import Header from 'components/organisms/Header';
import Footer from 'components/templates/Footer';
import useHeaderHeight from 'hooks/useHeaderHeight';
import { useAppSelector } from 'store/hooks';
import { policyUrls } from 'utils/constants';

const NotFound: React.FC = () => {
  const { fixedMenu } = useAppSelector((state) => state.general);
  const headerHeight = useHeaderHeight();

  return (
    <>
      {/* <Header menus={menuDumData} /> */}
      <div className='bg-alice-blue2 py-6' style={fixedMenu ? { marginTop: `${headerHeight}px` } : {}}>
        <div className='container mx-auto'>
          <div className='lg:flex gap-4 items-center bg-alice-blue2'>
            <div className='lg:w-5/12'>
              <div className='flex flex-col gap-4 lg:gap-6 max-w-[460px] text-center md:text-left'>
                <h2 className='text-[96px] xl:text-[128px] xl:leading-[0.8] font-semibold'>404</h2>
                <p className='text-feldgrau text-2xl xl:text-5xl'>Có gì đó không ổn!</p>
                <p className='text-feldgrau xl:text-lg'>Chúng tôi không tìm thấy trang mà bạn đang tìm kiếm, bạn thử lại nhé!</p>
                <div>
                  <Link
                    to='/'
                    className='mt-4 inline-block px-6 py-2  lg:py-3 bg-blue-600 text-white md:font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-300 capitalize'
                  >
                    Quay lại trang chủ
                  </Link>
                </div>
              </div>
            </div>
            <div className='lg:w-7/12'>
              <div className='max-w-[780px]'>
                <img src={notFoundBg} alt='notfound' className='w-full' />
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer
        logo='https://placehold.co/174x42.png?text=BidaHub'
        address='Địa chỉ trụ sở kinh doanh: RG.B-09.21 (Officeted) Tầng 9, Tháp B, Chung cư Rivergate Residence, 151 - 155 Bến Văn Đồn, Phường 6, Quận 4, TP.HCM'
        phone='0949.696.696'
        email='support@bidahub.com'
        customerSupport={policyUrls}
      />
    </>
  );
};

export default NotFound;
