import React from 'react';
import { Link } from 'react-router-dom';

import BackgroundBadge from 'components/atoms/BackgroundBadge';
import StarRating from 'components/molecules/StarRating';
import SLUG_PARAMS from 'utils/constants';

export interface UserReviewsProps {
  rating: number;
  date: string;
  title: string;
  content: string;
  isVerified?: boolean;
  product: {
    title: string;
    id: number
  }
}

const UserReviews: React.FC<UserReviewsProps> = ({
  rating, isVerified, date, title, content, product
}) => (
  <div className='o-userReviews'>
    {/* Rating and Status */}
    <div className='flex items-center mb-2 gap-x-1'>
      <StarRating value={rating} isStatic />
      <BackgroundBadge size='xl' theme={isVerified ? 'forestGreen' : 'chocolate'}>
        {isVerified ? 'Đã duyệt' : 'Chưa duyệt'}
      </BackgroundBadge>
    </div>

    {/* Date and Time */}
    <p className='text-feldgrau text-md mb-4'>{date}</p>

    {/* Title */}
    <h3 className='font-semibold text-lg mb-3 text-black'>{title}</h3>

    {/* Review Content */}
    <p className='text-feldgrau mb-4'>
      {content}
    </p>

    {/* Product Link */}
    <p className='text-feldgrau'>
      Đánh giá sản phẩm:{' '}
      <Link to={`${SLUG_PARAMS.PRODUCTS_DETAIL}/${product.id}`} className='font-semibold text-brilliant-azure hover:underline'>
        {product.title}
      </Link>
    </p>
  </div>
);

export default UserReviews;
