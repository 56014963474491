import React from 'react';

import AuthenticateImg from 'assets/images/authentication_bg.png';
import Modal from 'components/organisms/Modal';

interface AuthenticationWrapperProps {
  isOpen: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
}

const AuthenticationWrapper: React.FC<AuthenticationWrapperProps> = ({
  isOpen = false,
  handleClose,
  children,
}) => (
  <Modal isOverflow isOpen={isOpen} onRequestClose={handleClose} className='max-w-[480px] lg:max-w-[1126px] h-full md:h-auto rounded-2xl'>
    <div className='flex flex-col lg:flex-row'>
      <div className='flex-1 p-4 lg:p-6'>
        <img src={AuthenticateImg} alt='authenticate' className='w-full aspect-500/640' />
      </div>
      <div className='flex-1 bg-white rounded-lg p-4 lg:p-6'>
        {children}
      </div>
    </div>
  </Modal>
);

AuthenticationWrapper.defaultProps = {
  children: undefined,
};

export default AuthenticationWrapper;
