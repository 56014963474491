/* eslint-disable no-undef */
import React, { ReactElement, useEffect, useLayoutEffect } from 'react';

import { handleScrollCenter } from 'utils/functions';

interface TabsHeadProps {
  activeKey: string;
  active?: boolean;
  handleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: React.ReactNode;
  index: number;
}

const TabsHead: React.FC<TabsHeadProps> = ({
  children,
  activeKey,
  handleClick,
  active,
  index
}) => (
  <div
    className={`o-tabs_tabsHead_head px-3 py-2 md:px-4 md:py-3 cursor-pointer gap-[6px] flex h-[36px] md:h-[48px] items-center base-transition whitespace-nowrap ${index === 6 ? '' : 'border-r border-r-gainsboro'} ${active ? ' text-blueRyb font-semibold border-b-2 border-blueRyb active' : 'text-feldgrau'}`}
    data-tab={activeKey}
    onClick={handleClick}
  >
    {children}
  </div>
);

interface TabsProps {
  children: ReactElement<TabsPanelProps> | Array<ReactElement<TabsPanelProps>>;
  header: any[];
  handleClickTab?: (id: number) => void;
  searchEle?: React.ReactNode;
}

const Tabs: React.FC<TabsProps> = ({
  children,
  header,
  handleClickTab,
  searchEle,
}) => {
  const [activeTab, setActiveTab] = React.useState('');
  const handleActiveTab = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id?: number,
  ) => {
    if (id && handleClickTab) {
      handleClickTab(id);
    }
    setActiveTab(event.currentTarget.getAttribute('data-tab')!);
  };

  useEffect(() => {
    if (header?.[0]) {
      setActiveTab(header[0].activeKey);
    }
  }, [header]);

  useLayoutEffect(() => {
    handleScrollCenter('.o-tabs_tabsHead', '.o-tabs_tabsHead_head.active');
  });

  return (
    <div className='o-tabs bg-white rounded-xl overflow-hidden'>
      <div
        className='o-tabs_tabsHead flex items-center overflow-x-auto no-scrollbar border border-gainsboro rounded-t-xl'
      >
        {header.map((ele, idx) => (
          <TabsHead
            key={`${ele.activeKey}-${ele.title}`}
            activeKey={ele.activeKey}
            active={ele.activeKey === activeTab}
            index={idx}
            handleClick={(e) => handleActiveTab(e, ele.id)}
          >
            <p>{ele.title}</p>
            {/* eslint-disable-next-line max-len */}
            {/* {ele?.count > 0 && <p className='o-tabs_tabsHead_count bg-carmine-pink text-white text-sm flex items-center justify-center rounded-full w-5 h-5'>{ele.count}</p>} */}
          </TabsHead>
        ))}
      </div>
      {searchEle}

      <div key={activeTab} className='o-tabs_tabsPanel p-4 md:p-6'>
        {(children as Array<ReactElement<TabsPanelProps>>).length > 1 ? (
          (children as Array<ReactElement<TabsPanelProps>>).map((ele) => {
            if (ele.props.activeKey === activeTab) {
              return (
                <div
                  key={ele.props.activeKey}
                  className='o-tabs_tabsPanel_container'
                >
                  {ele}
                </div>
              );
            }
            return (
              <div
                key={ele.props.activeKey}
                className='o-tabs_tabsPanel_container hidden'
              >
                {ele}
              </div>
            );
          })
        ) : (
          <div className='o-tabs_tabsPanel_container'>{children}</div>
        )}
      </div>
    </div>
  );
};
export interface TabsPanelProps {
  activeKey: string;
  id?: number;
  handleClickTab?: (id: number) => void;
  children: React.ReactNode;
}

export const TabsPanel: React.FC<TabsPanelProps> = ({
  children,
  activeKey,
  id,
  handleClickTab,
}) => (
  <div
    className='o-tabs_tabsPanel_panel'
    data-tab={activeKey}
    onClick={() => handleClickTab && id && handleClickTab(id)}
  >
    {children}
  </div>
);

export default Tabs;
