/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import CartDesktopContainer from './Desktop';
import CartMobileContainer from './Mobile';

import useWindowDimensions from 'hooks/useWindowDemensions';

const breadcrumbItems = [
  { label: 'Home', path: '/' },
  { label: 'Cart' },
];

const CartContainer: React.FC = () => {
  const { width } = useWindowDimensions();
  const customer = {
    name: 'Nguyen Van A',
    phone: '0965123123',
    address: 'Địa chỉ: 262/15 Luỹ Bán Bích, Chung cư Idico, P. Hoà Thạnh, Q. Tân Phú, HCM',
    email: 'pLjZd@example.com'
  };
  const handleSelect = (code: string) => {
    console.log(code);
  };
  return width < 1024 ? <CartMobileContainer /> : <CartDesktopContainer />;
};

export default CartContainer;
