import React from 'react';

import UserOrderDetailContainer from 'containers/CustomerContainer/UserOrderDetail';

const UserOrderDetail: React.FC = () => (
  <div className='p-userOrderDetail bg-page pt-6'>
    <UserOrderDetailContainer />
  </div>
);

export default UserOrderDetail;
