import classNames from 'classnames';
import React, { useState } from 'react';

export interface OptionType {
  id?: string | number;
  label: string;
  value: string;
  disabled?: boolean;
}

interface PulldownProps extends React.HTMLAttributes<HTMLSelectElement> {
  id?: string;
  label?: string;
  noteLabel?: string;
  required?: boolean;
  placeholder?: string;
  value?: OptionType | string;
  options: OptionType[];
  error?: string;
  disabled?: boolean;
  handleSelect?: (option: OptionType) => void;
  className?: string;
  classNameContainer?: string;
  isHorizontal?: boolean;
  size?: 'base' | 'sm';
  onChange?: (value: any) => void;
}

const Pulldown: React.FC<PulldownProps> = ({
  id,
  label,
  required,
  noteLabel,
  value,
  options,
  error,
  disabled,
  handleSelect,
  className,
  isHorizontal,
  classNameContainer,
  placeholder,
  size = 'base',
  onChange,
  ...props
}) => {
  const [selected, setSelected] = useState<any>();
  return (
    <div className={classNames(`relative ${classNameContainer}`, { 'flex items-center': isHorizontal })}>
      {label && (
        <label htmlFor={id} className={classNames('text-md font-medium text-gray-700', { 'flex-none mr-3 hidden lg:block': isHorizontal })}>
          {label}
          {required && <span className='text-carmine-pink'>*</span>}
          {noteLabel && <span className='text-gray-500 ml-1'>{noteLabel}</span>}
        </label>
      )}
      <div className={classNames('relative', { 'w-full': isHorizontal, 'mt-1': !isHorizontal })}>
        <select
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          className={classNames(
            'border-1 border-gainsboro rounded-lg w-full bg-white text-gunmetal-500 placeholder:text-roman-silver text-raisin-black font-medium',
            className,
            {
              'border-gainsboro-300': !error && !disabled,
              'border-carmine-pink': error,
              'bg-gainsboro cursor-not-allowed': disabled,
              'focus:border-brilliant-azure': true,
              'focus:shadow-input-focus': true,
              'px-4 py-2 h-[48px] text-base leading-6': size === 'base',
              'p-2 h-[36px] text-md leading-4': size === 'sm',
              'text-roman-silver': !selected && !value,
              'text-raisin-black': selected,
            }
          )}
          value={value as string}
          onChange={(e) => {
            onChange?.(e);
            setSelected(e.target.value);
          }}
          {...props}
        >
          <option value='' disabled hidden selected={!value}>
            {placeholder}
          </option>
          {options.map((option) => (
            <option style={{ color: '#1F2128' }} key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          ))}
        </select>
        {error && <p className='mt-1 text-sm text-carmine-pink'>{error}</p>}
      </div>
    </div>
  );
};

export default Pulldown;
