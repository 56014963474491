/* eslint-disable max-len */
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';

interface InputPhoneNumberProps {
  handleClose: () => void;
  handleSubmit: (data: any) => void
  loading: boolean
}

const InputPhoneNumber: React.FC<InputPhoneNumberProps> = ({ handleClose, handleSubmit, loading }) => {
  const method = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      phone: '',
    },
  });

  return (
    <div className='h-full'>
      <h2 className='text-lg md:text-xl font-semibold text-[#1F2128] mb-2 mt-4'>Liên kết Zalo và Số điện thoại</h2>
      <p className='text-feldgrau mb-8'>
        Vui lòng nhập số điện thoại để liên kết với Zalo của bạn
      </p>
      <label className='block text-[#1F2128] font-semibold mb-3' htmlFor='phone'>Số điện thoại</label>
      <FormProvider {...method}>
        <form
          noValidate
          onSubmit={(e) => method.handleSubmit((data) => {
            e.preventDefault();
            handleSubmit(data);
          })()}
        >
          <Controller
            name='phone'
            rules={{
              required: 'Trường này là bắt buộc',
              // validate: validateUserName,
            }}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder='Nhập số điện thoại'
                error={fieldState?.error?.message}
                required
              />
            )}
          />
          <Button
            type='submit'
            size='large'
            variants='neon-fuchsia'
            className='mt-4 md:mt-6'
            onClick={(e) => method.handleSubmit((data) => {
              e.preventDefault();
              handleSubmit(data);
            })()}
            loading={loading}
          >
            Liên kết
          </Button>
        </form>
      </FormProvider>
      <Button
        type='submit'
        size='large'
        variants='secondary'
        className='mt-2 md:mt-3'
        onClick={handleClose}
      >
        Để sau
      </Button>
    </div>
  );
};

export default InputPhoneNumber;
