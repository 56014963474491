/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import ModalChangePassword from './modalChangePassword';
import ModalUpdate from './modalUpdate';

import callIcon from 'assets/icons/ic_call.svg';
import IcFacebook from 'assets/icons/ic_fb.svg';
import IcGoogle from 'assets/icons/ic_google.svg';
import IcZalo from 'assets/icons/ic_zalo.svg';
import mailIcon from 'assets/icons/mail.svg';
import passwordIcon from 'assets/icons/password.svg';
import InformationContainer from 'components/molecules/InformationContainer';
import useLinkSocial from 'hooks/useLinkSocial';
import { useAppSelector } from 'store/hooks';

interface InformationProps {
  hasPassword: boolean;
  setHasPassword: (value: boolean) => void;
}

const Information: React.FC<InformationProps> = ({
  hasPassword,
  setHasPassword
}) => {
  const [openModal, setOpenModal] = useState({
    isOpen: false,
    modalKey: '',
    value: '',
  });
  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);
  const { user } = useAppSelector((state) => state.auth);
  const [activeNoti, setActiveNoti] = useState(false);
  const { googleLinkSocial, zaloLinkSocial } = useLinkSocial();

  const contactInfo = [
    {
      key: 'phone',
      icon: callIcon,
      title: 'Số điện thoại',
      desc: user?.phone,
      isButton: true,
    }, {
      key: 'email',
      icon: mailIcon,
      title: 'Địa chỉ Email',
      desc: user?.email,
      isButton: true,
    }
  ];
  const notificationReceive = [
    {
      key: 'email',
      icon: mailIcon,
      title: '',
      desc: 'Email',
      isButton: true,
    }, {
      key: 'zalo',
      icon: IcZalo,
      title: '',
      desc: 'Zalo',
      isButton: true,
    }
  ];

const [socical, setSocical] = useState([
    {
      authMethodName: 'ExternalAuth.Zalo',
      key: 'zalo',
      icon: IcZalo,
      title: '',
      desc: 'Zalo',
      isButton: false,
      isLinked: false,
    },
    {
      authMethodName: 'ExternalAuth.Facebook',
      key: 'facebook',
      icon: IcFacebook,
      title: '',
      desc: 'Facebook',
      isButton: false,
      isLinked: false,
    },
    {
      authMethodName: 'ExternalAuth.Google',
      key: 'google',
      icon: IcGoogle,
      title: '',
      desc: 'Google',
      isButton: false,
      isLinked: false,
    },
  ]);

  const security = [
    {
      key: 'password',
      icon: passwordIcon,
      title: '',
      desc: 'Đổi mật khẩu',
      isButton: true,
    },
  ];

  const handleSocialClick = (key: string) => {
    if (key === 'google') {
      googleLinkSocial();
    }
    if (key === 'zalo') {
      zaloLinkSocial();
    }

    setSocical((prevSocical) => prevSocical.map(
      (item) => (item.key === key ? { ...item, isLinked: !item.isLinked } : item)
    ));
  };

  // TODO: For Active notification
  const handleRadioSelect = (selectedKey: string) => {
    console.log('Selected radio key:', selectedKey);
  };
  useEffect(() => {
    if (user?.associatedExternalAuthRecords) {
      setSocical((prev) => prev?.map((item) => ({
        ...item,
        isLinked: user?.associatedExternalAuthRecords.some(
          (record) => record?.authMethodName === item?.authMethodName
        ),
      })));
    }
  }, [user]);

  return (
    <div className='mt-4 xl:mt-0 space-y-4 w-full max-w-full xl:max-w-[428px]'>
      <InformationContainer
        title='Số điện thoại & Email'
        links={contactInfo}
        isUpdate
        onClick={(key) => setOpenModal({ isOpen: true, modalKey: key, value: contactInfo.find((item) => item.key === key)?.desc || '' })}
      />

      <InformationContainer
        title='Nhận thông báo'
        links={notificationReceive}
        isChecked={activeNoti}
        onRadioSelect={handleRadioSelect}
        onCheckChange={() => setActiveNoti(!activeNoti)}
      />
      <InformationContainer
        title='Bảo mật'
        links={security}
        onClick={(key) => setOpenModalChangePassword(true)}
        isUpdate
      />

      <InformationContainer
        title='Liên kết mạng xã hội'
        links={socical}
        onClick={handleSocialClick}
      />
      <ModalUpdate
        isOpen={openModal.isOpen}
        modalKey={openModal.modalKey}
        value={openModal.value}
        handleClose={() => setOpenModal({ isOpen: false, modalKey: '', value: '' })}
      />
      <ModalChangePassword
        isOpen={openModalChangePassword}
        handleClose={() => setOpenModalChangePassword(false)}
        hasPassword={hasPassword}
        setHasPassword={setHasPassword}
      />
    </div>
  );
};
export default Information;
