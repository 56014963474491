/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import ProductInfo from '../ProductInfo';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import BoxSelections from 'components/molecules/BoxSelections';
import QuantitySelector from 'components/molecules/QuantitySelector';
import ReceiveText from 'components/molecules/ReceiveText';
import CustomModal from 'components/organisms/Modal';
import { ProductType } from 'components/organisms/ProductCard';
import SlideshowViewer, { SlideshowViewerRef } from 'components/organisms/SlideshowViewer';
import { IAddToCartRequest } from 'types/cart';
import { IProduct } from 'types/product';
import SLUG_PARAMS, { CART_TYPE } from 'utils/constants';
import { formatVndCurrency } from 'utils/functions';

interface ProductPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: ProductType & IProduct;
  onReceiveNoti?: (id: number) => void;
  onRegisterNoti?: () => void;
  onAddCart: (params: IAddToCartRequest, type: number) => void;
  fetchChangeAtrribute?: (preAttribute: any) => void
  sliderRef2?: SlideshowViewerRef;
  attributes?: IAttributes[];
  setAttributes?: (attributes: IAttributes[]) => void;
  onRemoveProductFromWishList?: (id: number) => void;
}

interface IAttributes {
  attributeId: string;
  value: string;
}

const ProductPreviewModal: React.FC<ProductPreviewModalProps> = ({
  isOpen,
  data,
  onClose,
  onReceiveNoti = () => { },
  onRegisterNoti,
  onAddCart,
  fetchChangeAtrribute = () => { },
  sliderRef2,
  attributes = [],
  setAttributes = () => { },
  onRemoveProductFromWishList = () => { },
}) => {
  const inWishList = !data?.inWishlist;
  const [productQuantity, setProductQuantity] = useState<number>(1);

  const handleChangeAtributes = async (attributeId: string, value: string) => {
    const i = attributes.findIndex((e) => e.attributeId === attributeId);
    let preAttribute = [...attributes];
    if (i === -1) {
      preAttribute = [...attributes, { attributeId, value }];
      setAttributes(preAttribute);
    } else {
      preAttribute[i] = { attributeId, value };
      setAttributes(preAttribute);
    }

    await fetchChangeAtrribute(preAttribute);
  };

  const onSubmit = async (type?: string, cartType?: number) => {
    try {
      const formValues = [
        {
          Key: `addtocart_${data.id}.EnteredQuantity`,
          Value: productQuantity.toString(),
        },
      ];
      attributes?.forEach((atribute) => {
        formValues.push({
          Key: `product_attribute_${atribute.attributeId}`,
          Value: atribute.value,
        });
      });
      const params = {
        productId: data.id,
        type: cartType as number,
        data: {
          FormValues: formValues,
        },
      };
      await onAddCart(params, cartType as number);
      setAttributes([]);
      setProductQuantity(1);
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const renderSubmitButton = (() => {
    const soldOutButton = !data?.alreadySubscribed ? (
      <Button
        onClick={() => onReceiveNoti(data?.id)}
        className='text-white bg-blueRyb border-blueRyb border hover:bg-white hover:text-gunmetal capitalize'
      >
        Nhận thông báo khi có hàng
      </Button>
    ) : (
      <Button
        onClick={() => onReceiveNoti(data?.id)}
        className='bg-white border-blueRyb text-gunmetal border'
      >
        Đã đăng ký nhận thông báo
      </Button>
    );

    const availableButton = (
      <Button
        disabled={data?.stockAvailability === 'Out of stock'}
        icon='cart'
        onClick={() => onSubmit('1', CART_TYPE.CART)}
        size='oversize'
        variants='neon-fuchsia'
      >
        Thêm vào giỏ
      </Button>
    );

    return !data?.inStock ? soldOutButton : availableButton;
  })();

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title='Xem nhanh sản phẩm'
      className='max-w-[1002px] h-full'
    >
      <div className='t-productPreviewModal'>
        <div className='flex flex-col lg:flex-row bg-white page-top lg:rounded-xl'>
          {/* Col 1 */}
          <div className='lg:w-6/12'>
            <SlideshowViewer
              data={data?.pictureModels?.map((picture) => ({
                title: picture.title,
                src: picture.imageUrl,
              }))}
              ref={sliderRef2 as any}
            />
          </div>
          {/* Col 2 */}
          <div className='lg:w-6/12 py-4 px-4 lg:px-6'>
            <h1 className='product-title text-raisin-black text-2xl font-semibold'>
              {data?.name}
            </h1>
            {/* <BackgroundBadge icon={<Icon iconName='verify' size='16x16' />}>
              Chính hãng 100%
            </BackgroundBadge> */}
            <p className='product-sku text-feldgrau mt-2'>SKU: {data?.sku}</p>
            {/* {data?.shortDescription && (
              <p className='text-feldgrau mt-3'>{data.shortDescription}</p>
            )} */}
            <div className='product-rating flex items-center pt-3'>
              {/* <StarRating count={5} value={data?.rating || 0} isStatic /> */}
              <p className='text-md text-brilliant-azure'>
                {/* ({data?.reviews || 0} đánh giá) */}
                {data?.productManufacturers?.map((productManufacturer) => (
                  <a href={`${SLUG_PARAMS.MANUFACTURES}/${productManufacturer?.id}`} key={productManufacturer?.id}>
                    {productManufacturer?.name}
                  </a>
                ))}
              </p>
            </div>
            <p className='product-price text-3xl leading-10 font-semibold mt-3 text-neon-fuchsia'>
              {formatVndCurrency(Number(data?.productPrice?.priceValue) || data?.price)}
            </p>
            {/* Category Selection  */}
            {data?.inStock && data?.productAttributes.length > 0 && (
              <div className='category-selection flex flex-col gap-6 mt-4 lg:mt-6'>
                {data?.productAttributes?.map((productAttribute) => (
                  <BoxSelections
                    key={`attr-${productAttribute?.id}`}
                    title={productAttribute?.name}
                    isNaked
                    options={productAttribute?.values?.map((value) => ({
                      label: value.name,
                      value: value.id,
                      // TODO: check quantity
                      // disabled: value?.quantity ? value?.quantity > 0 : false
                    }))}
                    onSelect={(value) => handleChangeAtributes(productAttribute.id, value)}
                    selectedValue={
                      attributes.find((attr: any) => attr.attributeId === productAttribute.id)?.value
                    }
                  />
                ))}
              </div>
            )}
            {/* Quantity selection  */}
            <div className='bottom mt-4 lg:mt-6'>
              <p className='text-gunmetal font-semibold mb-3'> Số lượng</p>
              <QuantitySelector
                onChange={(value) => setProductQuantity(value)}
                modifier='lg'
                isRepayment={data?.stockAvailability === 'Out of stock'}
              />
              <div className='mt-3'>
                <p className='inline-block border border-teal-deer rounded bg-honeydew px-3 py-[6px] text-md text-forest-green text-center'>
                  {/* {!data?.inStock ? 'Hết hàng' : 'Còn hàng'} */}
                  {data?.stockAvailability === 'Out of stock' ? 'Hết hàng' : data?.stockAvailability === '' ? 'Còn hàng' : `Còn hàng (${data?.stockAvailability !== 'In stock' ? data?.stockAvailability : ''}) `}
                </p>
              </div>
              <div className='mt-3 isLiked'>
                <div
                  onClick={inWishList ? () => onSubmit('1', CART_TYPE.WISHLIST) : () => onRemoveProductFromWishList(data?.wishlistId)}
                  className='flex items-center hover:cursor-pointer'
                >
                  {/* TODO: add like action will be handle later */}
                  <Icon
                    iconName={inWishList ? 'outlinedHeart' : 'filledHeart'}
                    size='20x20'
                  />
                  {inWishList ? (
                    <span className='inline-block ml-1 text-gunmetal text-md'>
                      Thêm vào DS yêu thích
                    </span>
                  ) : (
                    <span className='inline-block ml-1 text-neon-fuchsia text-md'>
                      Đã thêm vào DS yêu thích
                    </span>
                  )}
                </div>
              </div>
              <div className='mt-4 md:mt-6'>
                <ReceiveText
                  bcoin={
                    data?.productPrice?.priceValue ? Number(data?.productPrice?.priceValue ?? 0)
                      : (data?.price ?? 0)
                  }
                  isFull
                />
              </div>
            </div>
            <div className='addToCart mt-2 lg:mt-3'>
              {renderSubmitButton}
              <div className='mt-6 '>
                <Link
                  to={`${SLUG_PARAMS.PRODUCTS_DETAIL}/${data.id}`}
                  className='text-brilliant-azure font-normal hover:underline'
                >
                  Đến trang sản phẩm
                </Link>
              </div>
              {data?.alreadySubscribed && (
                <p className='text-feldgrau text-md text-center mt-2'>
                  *Bạn sẽ được thông báo khi sản phẩm có hàng trở lại
                </p>
              )}
            </div>
          </div>
        </div>
        {/* PRODUCT INFO */}
        <div className='bg-white mx-4 lg:mx-0 p-3 md:p-6 rounded-xl'>
          <ProductInfo data={data} />
        </div>
      </div>
    </CustomModal>
  );
};

export default ProductPreviewModal;
