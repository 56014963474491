import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AddressItemType } from 'components/organisms/AddressSelector';
import {
  ICouponApplied,
  IShoppingCartItem,
  IShoppingCartOrderTotals,
} from 'types/cart';

interface IInitialState {
  data: IShoppingCartItem[];
  orderTotals: IShoppingCartOrderTotals | null;
  discount: ICouponApplied | null;
  addressId: string;
  bcoinToUse: number;
  address?: AddressItemType | null;
  repaymentData?: any;
  isRepayment?: boolean;
  orderId?: string;
}

const initialState: IInitialState = {
  data: [],
  orderTotals: null,
  discount: null,
  addressId: '',
  bcoinToUse: 0,
  address: null,
  repaymentData: null,
  isRepayment: false,
  orderId: '',
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartData: ($state, action: PayloadAction<IShoppingCartItem[]>) => {
      $state.data = action.payload;
    },
    setOrderTotals: (
      $state,
      action: PayloadAction<IShoppingCartOrderTotals | null>
    ) => {
      $state.orderTotals = action.payload;
    },
    setDiscount: ($state, action: PayloadAction<ICouponApplied | null>) => {
      $state.discount = action.payload;
    },
    setAddressId: ($state, action: PayloadAction<string>) => {
      $state.addressId = action.payload;
    },
    setBcoinToUse: ($state, action: PayloadAction<number>) => {
      $state.bcoinToUse = action.payload;
    },
    setAddress: ($state, action: PayloadAction<AddressItemType>) => {
      $state.address = action.payload;
    },
    setRepaymentData: ($state, action: PayloadAction<any>) => {
      $state.repaymentData = action.payload;
    },
    setIsRepayment: ($state, action: PayloadAction<boolean>) => {
      $state.isRepayment = action.payload;
    },
    setOrderId: ($state, action: PayloadAction<string>) => {
      $state.orderId = action.payload;
    },
  },
});

export const {
  setCartData,
  setOrderTotals,
  setDiscount,
  setAddressId,
  setBcoinToUse,
  setAddress,
  setIsRepayment,
  setRepaymentData,
  setOrderId,
} = cartSlice.actions;
export default cartSlice.reducer;
