import React from 'react';

import MatchHeight from 'components/organisms/MatchHeight';
import ProductCard, { ProductType } from 'components/organisms/ProductCard';

interface ProductGridProps {
  data: ProductType[];
  className?: string;
  containerClassName?: string;
  isLoadingFetch?: boolean
  refetchData?: () => void;
}

const ProductGrid: React.FC<ProductGridProps> = ({
  data, className, containerClassName, isLoadingFetch, refetchData = () => {}
}) => (
  <div className={`o-productGrid ${containerClassName}`}>
    <MatchHeight matchClassName='o-productCard__title' containerClassname={`productGrid ${className || 'grid grid-cols-2 xl:grid-cols-5 md:grid-cols-3 gap-3 lg:gap-4 mt-4'}`}>
      {
        data.map((v, i) => (
          <ProductCard key={`item-${i.toString()}`} data={v} isLoadingFetch={isLoadingFetch} refetchData={refetchData} />
        ))
      }
    </MatchHeight>
  </div>
);

export default ProductGrid;
