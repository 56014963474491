/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import { useParams } from 'react-router-dom';

import {
  categoryCardData, nestedCategoriesDummy, nestedManufaturesDummy, orderFilteringOptions
} from 'assets/dummy';
import icCloseGray from 'assets/icons/ic_close_gray.svg';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import Pagination from 'components/molecules/Pagination';
import Pulldown from 'components/molecules/Pulldown';
import { Drawer } from 'components/organisms/Drawer';
import NestedCheckbox, { InputData, InputItem } from 'components/organisms/NestedCheckbox';
import ProductGrid from 'components/organisms/ProductGrid';
import CategoryCardSlide from 'components/templates/CategoryCardSlide';
import useCategories from 'hooks/useCategories';
import useWindowDimensions from 'hooks/useWindowDemensions';
import SLUG_PARAMS from 'utils/constants';

export type ManufacturesType = {
  id: number;
  name: string;
  description: string;
  image: string;
};

const dummyData = [
  {
    imageSrc: 'https://picsum.photos/200',
    title: 'Bao tay Molinari (Hà Lan)',
    category: 'Cao Su Đuôi Cơ',
    brand: 'Molinari',
    price: 450000,
    rating: 4,
    reviews: 3,
    id: 1,
  },
  {
    imageSrc: 'https://picsum.photos/200',
    title: 'Găng tay Predator',
    category: 'Cao Su Đuôi Cơ',
    brand: 'Predator',
    price: 500000,
    rating: 5,
    reviews: 10,
    id: 2,
  },
  {
    imageSrc: 'https://picsum.photos/200',
    title: 'Găng tay Kamui',
    category: 'Chất lượng cao',
    brand: 'Kamui',
    price: 600000,
    rating: 4,
    reviews: 8,
    id: 3,
  },
  {
    imageSrc: 'https://picsum.photos/200',
    title: 'Găng tay Molinari (Đức)',
    category: 'Cao Su Đuôi Cơ',
    brand: 'Molinari',
    price: 550000,
    rating: 4,
    reviews: 5,
    id: 4,
  },
  {
    imageSrc: 'https://picsum.photos/200',
    title: 'Găng tay Predator Second Edition Găng tay Predator Second Edition',
    category: 'Cao Su Đuôi Cơ',
    brand: 'Predator',
    price: 650000,
    rating: 5,
    reviews: 12,
    id: 5,
  },
  {
    imageSrc: 'https://picsum.photos/200',
    title: 'Găng tay Kamui Black',
    category: 'Chất lượng cao',
    brand: 'Kamui',
    price: 700000,
    rating: 5,
    reviews: 15,
    id: 6,
  },
  {
    imageSrc: 'https://picsum.photos/200',
    title: 'Găng tay Molinari Limited',
    category: 'Cao Su Đuôi Cơ',
    brand: 'Molinari',
    price: 750000,
    rating: 3,
    reviews: 4,
    id: 7,
  },
  {
    imageSrc: 'https://picsum.photos/200',
    title: 'Găng tay Predator Yellow',
    category: 'Cao Su Đuôi Cơ',
    brand: 'Predator',
    price: 800000,
    rating: 5,
    reviews: 20,
    id: 8,
  },
  {
    imageSrc: 'https://picsum.photos/200',
    title: 'Găng tay Molinari (Đức)',
    category: 'Cao Su Đuôi Cơ',
    brand: 'Molinari',
    price: 550000,
    rating: 4,
    reviews: 5,
    id: 4,
  },
  {
    imageSrc: 'https://picsum.photos/200',
    title: 'Găng tay Predator Second Edition Găng tay Predator Second Edition',
    category: 'Cao Su Đuôi Cơ',
    brand: 'Predator',
    price: 650000,
    rating: 5,
    reviews: 12,
    id: 5,
  },
  {
    imageSrc: 'https://picsum.photos/200',
    title: 'Găng tay Kamui Black',
    category: 'Chất lượng cao',
    brand: 'Kamui',
    price: 700000,
    rating: 5,
    reviews: 15,
    id: 6,
  },
  {
    imageSrc: 'https://picsum.photos/200',
    title: 'Găng tay Molinari Limited',
    category: 'Cao Su Đuôi Cơ',
    brand: 'Molinari',
    price: 750000,
    rating: 3,
    reviews: 4,
    id: 7,
  },
  {
    imageSrc: 'https://picsum.photos/200',
    title: 'Găng tay Predator Yellow',
    category: 'Cao Su Đuôi Cơ',
    brand: 'Predator',
    price: 800000,
    rating: 5,
    reviews: 20,
    id: 8,
  },
];

interface CategoryContainerProps {
  isSub?: boolean;
}

const CategoryContainer: React.FC<CategoryContainerProps> = ({ isSub }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const scrollRef: any = useRef();
  const {
    categories, products, setParams, manufacturers, params, categoryName, totalPages, totalRecord, fetchProductByCategory, setManufacturers, loadingProduct
  } = useCategories();
  const param = useParams();
  const manuId = param.id;
  const { width } = useWindowDimensions();

  const handlePageChange = (pagenumber: number) => {
    setParams((prev) => ({ ...prev, pagenumber }));
    setCurrentPage(pagenumber);
  };

  const breadcrumbItems = [
    { label: 'Home', path: '/' },
    { label: 'Danh Mục' },
    { label: String((categoryName !== null || categoryName !== undefined) && categoryName), path: `${SLUG_PARAMS.CATEGORY_DETAIL}/${manuId}` },
  ];

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        // behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [params]);

  const categoryParse = categories?.map((item: any) => ({
    ...item,
    image: 'https://picsum.photos/50',
    sub: item?.count
  }));

  return (
    <>
      <div ref={scrollRef} className='container mx-auto py-4 lg:py-6'>
        <Breadcrumbs items={breadcrumbItems} />
      </div>
      <div className='container mx-auto'>
        <h1 className='text-xl md:text-2xl lg:text-3xl font-semibold mb-4 lg:mb-4'>
          {categoryName || ''}
        </h1>
        <div className='flex gap-4'>
          <div className='w-3/12 hidden lg:block'>
            <div className='flex flex-col gap-4'>
              {categories.length > 0
                && (
                  <NestedCheckbox
                    title='Lọc theo danh mục'
                    data={categories as []}
                    onCheckedChange={(checkedItems) => {
                      setCurrentPage(1);
                      setParams((prev) => ({
                        ...prev, pagenumber: 1, categoryIds: checkedItems.map((item: any) => item.id), ms: null
                      }));
                    }}
                  />
                )}
              {
                manufacturers?.length > 0 && (
                  <NestedCheckbox
                    title='Lọc theo nhà sản xuất'
                    data={manufacturers}
                    onCheckedChange={(checkedItems) => {
                      setCurrentPage(1);
                      setParams((prev) => ({
                        ...prev, pagenumber: 1, manufacturerIds: checkedItems.map((item: any) => item.id), ms: null
                      }));
                    }}
                  />
                )
              }
            </div>
          </div>
          <div className='w-full lg:w-9/12'>
            {
              width < 1024 && (
                <Drawer
                  isOpen={isOpen}
                  handleClose={() => setIsOpen(!isOpen)}
                  containerClassName='px-0 overflow-y-scroll'
                  btnCloseNaked
                  iconClose={icCloseGray}
                >
                  <div className='flex flex-col gap-4 mt-[-52px]'>
                    {
                      manufacturers?.length > 0 && (
                        <NestedCheckbox
                          title='Lọc theo nhà sản xuất'
                          data={manufacturers}
                          onCheckedChange={(checkedItems) => {
                            setCurrentPage(1);
                            setParams((prev) => ({
                              ...prev, pagenumber: 1, manufacturerIds: checkedItems.map((item: any) => item.id), ms: null
                            }));
                          }}
                        />
                      )
                    }
                    {categories.length > 0
                      && (
                        <NestedCheckbox
                          title='Lọc theo danh mục'
                          data={categories as []}
                          onCheckedChange={(checkedItems) => {
                            setCurrentPage(1);
                            setParams((prev) => ({
                              ...prev, pagenumber: 1, categoryIds: checkedItems.map((item: any) => item.id), ms: null
                            }));
                          }}
                        />
                      )}
                  </div>
                </Drawer>
              )
            }
            <div className='flex flex-col gap-3 md:gap-4'>
              {/* Panel  */}
              {
                isSub
                && (
                  <div className=''>
                    <CategoryCardSlide data={categoryParse} />
                  </div>
                )
              }
              {/* Panel  */}
              <div className='filtering background-panel bg-white p-3 rounded-lg'>
                <div className='flex justify-between gap-2 lg:gap-0'>
                  <div className='flex-none block lg:hidden w-[92px] h-[36px] cursor-pointer' onClick={() => setIsOpen(true)}>
                    <div className='checkbox-filtering flex gap-2 p-[6px] items-center border border-brilliant-azure rounded-lg'>
                      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M12.25 13.5C12.6642 13.5 13 13.8358 13 14.25C13 14.6642 12.6642 15 12.25 15H7.75C7.33579 15 7 14.6642 7 14.25C7 13.8358 7.33579 13.5 7.75 13.5H12.25ZM14.25 9.25C14.6642 9.25 15 9.58579 15 10C15 10.4142 14.6642 10.75 14.25 10.75H5.75C5.33579 10.75 5 10.4142 5 10C5 9.58579 5.33579 9.25 5.75 9.25H14.25ZM16.25 5C16.6642 5 17 5.33579 17 5.75C17 6.16421 16.6642 6.5 16.25 6.5H3.75C3.33579 6.5 3 6.16421 3 5.75C3 5.33579 3.33579 5 3.75 5H16.25Z' fill='#2E3538' />
                      </svg>
                      <p className='text-sm'>
                        Bộ lọc
                      </p>
                    </div>
                  </div>
                  <div className='left w-full'>
                    <Pulldown
                      placeholder={`${orderFilteringOptions?.[0]?.label}`}
                      size='sm'
                      isHorizontal
                      label='Sắp xếp theo'
                      classNameContainer='lg:w-[360px]'
                      value={String(params?.orderby)}
                      onChange={(e: any) => {
                        setCurrentPage(1);
                        setParams((prev) => ({
                          ...prev,
                          page: 1,
                          orderby: e.target.value
                        }));
                      }}
                      options={orderFilteringOptions}
                    />
                  </div>
                  <div className='right'>
                    <Pulldown
                      isHorizontal
                      label='Hiển thị'
                      size='sm'
                      classNameContainer='w-[58px] lg:w-[153px]'
                      value={String(params?.pagesize)}
                      onChange={(e: any) => {
                        setParams((prev) => ({
                          ...prev,
                          pagenumber: 1,
                          pagesize: e.target.value
                        }));
                        setCurrentPage(1);
                      }}
                      options={[
                        { label: '5', value: '5' },
                        { label: '10', value: '10' },
                        { label: '15', value: '15' },
                        { label: '20', value: '20' },
                      ]}
                    />
                  </div>
                </div>
              </div>
              {/* Panel */}
              <div className='background-panel'>
                {products.length > 0 && (
                  <ProductGrid
                    data={products}
                    refetchData={() => fetchProductByCategory(true)}
                    className='grid grid-cols-2 xl:grid-cols-4 md:grid-cols-3 gap-3 lg:gap-4'
                    isLoadingFetch={loadingProduct}
                  />
                )}
              </div>
              {/* Panel */}
              {
                totalPages > 1
                && (
                  <div className='background-panel bg-white rounded-lg pagination lg:p-4 px-4 py-3'>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      total={totalRecord}
                    />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryContainer;
