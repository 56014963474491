import React from 'react';

import CategoryContainer from 'containers/CategoryContainer';

const CategoryDetail: React.FC = () => (
  <div className='p-categoryDetail bg-page'>
    <CategoryContainer />
  </div>
);

export default CategoryDetail;
