import React from 'react';

interface ContainerProps { }

const Container: React.FC<ContainerProps> = () => (
  <div>
    <p className='max-w-lg text-3xl font-semibold leading-normal'>
      Using Container
    </p>
    <hr className='my-3' />
    <div className='container mx-auto'>
      The container class sets the max-width of an element to match the
      min-width of the current breakpoint. This is useful if you’d prefer to
      design for a fixed set of screen sizes instead of trying to accommodate a
      fully fluid viewport.
    </div>
  </div>
);

export default Container;
