/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import { setPasswordForNewUserApi } from 'services/auth';

interface SetPasswordProps {
  closeModal?: () => void
}

const SetPassword: React.FC<SetPasswordProps> = ({ closeModal = () => { } }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const method = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      contact: '',
    },
  });

  const passwordValidationRules = {
    required: 'Cần phải nhập mật khẩu',
    minLength: {
      value: 8,
      message: 'Mật khẩu phải dài ít nhất 8 ký tự',
    },
    validate: {
      hasNumber: (value: string) => /\d/.test(value) || 'Mật khẩu phải chứa ít nhất một số',
      hasSpecialChar: (value: string) => /[!@#$%^&*(),.?":{}|<>]/.test(value) || 'Mật khẩu phải chứa ít nhất một ký tự đặc biệt',
    },
  };

  const handleFormSubmit = async (data: any) => {
    try {
      setLoading(true);
      const params = {
        password: data.newPassword,
      };
      await setPasswordForNewUserApi(params);
      closeModal();
      setLoading(false);
      toast.success('Tạo mật khẩu thành công');
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  return (
    <div className='h-full'>
      {/* <BackPopupButton onClick={handleBack} /> */}
      <h2 className='text-lg md:text-xl font-semibold text-[#1F2128] mb-2 mt-4'>Tạo mật khẩu</h2>
      <p className='text-feldgrau italic mb-8 mr-6'>
        Bạn có thể tạo mật khẩu để sử dụng cho các lần đăng nhập sau hoặc chọn đăng nhập nhanh bằng mã OTP được gửi đến email hoặc số điện thoại của bạn.
      </p>
      <FormProvider {...method}>
        <form
          noValidate
          onSubmit={method.handleSubmit(handleFormSubmit)}
        >
          <label className='block text-[#1F2128] font-semibold mb-3' htmlFor='password'>Mật khẩu mới</label>
          <Controller
            name='newPassword'
            rules={passwordValidationRules}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder='Nhập mật khẩu mới'
                error={fieldState?.error?.message}
                required
                type='password'
              />
            )}
          />
          <label className='block text-[#1F2128] font-semibold my-3' htmlFor='password'>Xác nhận mật khẩu mới</label>
          <Controller
            name='confirmNewPassword'
            rules={passwordValidationRules}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder='Xác nhận mật khẩu mới'
                error={fieldState?.error?.message}
                required
                type='password'
              />
            )}
          />
          <Button
            type='submit'
            size='large'
            variants='neon-fuchsia'
            className='mt-4 md:mt-6'
            disabled={loading}
            onClick={method.handleSubmit(handleFormSubmit)}
          >
            Tạo Mật Khẩu
          </Button>
        </form>
      </FormProvider>
      <Button
        // type='submit'
        size='large'
        variants='secondary'
        className='mt-2 md:mt-4'
        onClick={closeModal}
      >
        Để Sau
      </Button>
    </div>
  );
};

export default SetPassword;
