/* eslint-disable import/no-cycle */
import { forEach, debounce } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import closeImg from 'assets/icons/ic_circle_close.svg';
import cartNullImg from 'assets/images/icon_cart_null.png';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Pulldown from 'components/molecules/Pulldown';
import QuantitySelector from 'components/molecules/QuantitySelector';
import useCart from 'hooks/useCart';
import useScrollLock from 'hooks/useScrollLock';
import { addToCartApi, updateCartApi } from 'services/cart';
import {
  setCartData, setIsRepayment, setOrderTotals, setRepaymentData
} from 'store/cart';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IShoppingCartItem } from 'types/cart';
import SLUG_PARAMS from 'utils/constants';
import { formatDecimalNumber, parseAtributeInfoValue, parseAttributes } from 'utils/functions';

interface CartQuickViewProps {
  isOpen?: boolean;
  onClose?: () => void;
  onOpenAuthModal?: () => void;
  // products: {
  //   id: number;
  //   name: string;
  //   image: string;
  //   price: number;
  //   discount?: number;
  //   count?: number;
  // }[];
}

const formatPrice = (price: number): string => price.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });

const CartQuickView: React.FC<CartQuickViewProps> = ({ onClose, isOpen, onOpenAuthModal }) => {
  const [listHeight, setListHeight] = useState<number>(0);
  const summaryRef = useRef<HTMLDivElement>(null);
  const headRef = useRef<HTMLDivElement>(null);
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleFetchShoppingCart } = useCart();
  const { data, orderTotals } = useAppSelector((state) => state.cart);

  const changeCartProductQuantity = async (value: any, producId: number) => {
    try {
      const params = {
        FormValues: [
          {
            Key: `itemquantity${producId}`,
            Value: value
          }
        ]
      };
      const response = await updateCartApi(params);
      dispatch(setCartData(response?.data?.cart?.items));
      dispatch(setOrderTotals(response?.data?.orderTotals));
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };
  const handleUpdateCartQuantity = debounce(
    changeCartProductQuantity,
    500
  );
  // Calculate the height dynamically
  useEffect(() => {
    const updateListHeight = () => {
      if (summaryRef.current && headRef.current) {
        const summaryHeight = summaryRef.current.clientHeight;
        const headHeight = headRef.current.clientHeight;
        const totalHeight = window.innerHeight;
        const newListHeight = totalHeight - summaryHeight - headHeight;
        setListHeight(newListHeight);
      }
    };

    updateListHeight();

    window.addEventListener('resize', updateListHeight);

    return () => {
      window.removeEventListener('resize', updateListHeight);
    };
  }, [data]);
  useScrollLock(!!isOpen);

  const handleConfirm = async (id: number) => {
    try {
      const params = {
        FormValues: [
          {
            Key: 'removefromcart',
            Value: `${id}`,
          },
        ],
      };
      const response = await updateCartApi(params);
      dispatch(setCartData(response?.data?.cart?.items));
      dispatch(setOrderTotals(response?.data?.orderTotals));
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const changeCartProductAtribute = async (
    currentAtributes: any,
    value: any,
    attributeId: string,
    product: IShoppingCartItem,
  ) => {
    try {
      const formValues = [
        {
          Key: `addtocart_${product.productId}.UpdatedShoppingCartItemId`,
          Value: product.id,
        },
        {
          Key: `addtocart_${product.productId}.EnteredQuantity`,
          Value: product.quantity
        },
      ];
      if (currentAtributes) {
        forEach(currentAtributes, (item: any) => {
          if (item.attributeId === attributeId) {
            formValues.push({
              Key: `product_attribute_${attributeId}`,
              Value: value,
            });
          } else {
            formValues.push({
              Key: `product_attribute_${item.attributeId}`,
              Value: item.value,
            });
          }
        });
      }
      const params = {
        data: {
          FormValues: formValues,
        },
        productId: Number(product?.productId),
        type: 1,
      };
      await addToCartApi(params);
      handleFetchShoppingCart();
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleCheckout = () => {
    if (user) {
      dispatch(setIsRepayment(false));
      dispatch(setRepaymentData(null));
      navigate(SLUG_PARAMS.CART);
      onClose?.();
    } else {
      onClose?.();
      onOpenAuthModal?.();
    }
  };

  return (
    <div
      className={`o-cartQuickView fixed top-0 right-0 w-full h-screen z-50 flex justify-end transform transition-transform duration-500 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
    >
      {/* Overlay */}
      <div
        className='bg-black bg-opacity-30 z-[1] absolute top-0 left-0 w-full h-full'
        onClick={onClose}
      />

      {/* Body */}
      <div className='relative o-cartQuickView__body bg-white w-full max-w-[460px] z-[2] h-full'>
        {/* Head */}
        <div ref={headRef} className='head p-4 pb-3 flex justify-between border-b border-gainsboro'>
          <h3 className='text-lg font-semibold'>Xem nhanh giỏ hàng</h3>
          <button
            onClick={onClose}
            className='rounded-lg hover:bg-anti-flash-white2 transition ease-in-out duration-300 p-1'
          >
            <img src={closeImg} alt='close' className='w-6' />
          </button>
        </div>

        {/* List */}
        <div className='o-cartQuickView__list overflow-auto custom-scrollbar' style={{ height: `${listHeight}px` }}>
          {data?.length ? data.map((product) => (
            <div key={product.id} className='flex flex-col border-b px-2 py-4'>
              <div className='flex items-center'>
                {/* Thumbnail */}
                <div className='px-2'>
                  <a href={`${SLUG_PARAMS.PRODUCTS_DETAIL}/${product.productId}`} className='aspect-1 max-w-[72px] block rounded-lg overflow-hidden'>
                    <img src={product?.picture?.imageUrl} alt={product?.productName} className='w-full h-full object-contain' />
                  </a>
                </div>

                {/* Detail */}
                <div className='flex-1 px-2'>
                  <div className='flex justify-between items-center'>
                    <div className='info'>
                      <p className='product-name'><a href={`${SLUG_PARAMS.PRODUCTS_DETAIL}/${product.productId}`}>{product?.productName}</a></p>
                      <div className='flex flex-col gap-2'>
                        <div>
                          {product.attributeInfo.length > 0 && (
                            <div className='flex gap-x-2 flex-wrap'>
                              {product?.productAttributes?.map((attribute, index) => {
                                const options = attribute?.values?.map(
                                  (item) => ({
                                    id: item.id,
                                    label: item.name,
                                    value: item.id,
                                  })
                                );
                                const idx = parseAtributeInfoValue(
                                  product?.attributeInfo,
                                  options,
                                  index
                                );
                                const currentAtributes = parseAttributes(
                                  product.attributeInfo,
                                  product.productAttributes
                                );
                                return (
                                  <Pulldown
                                    placeholder='Chọn options'
                                    size='sm'
                                    value={options?.[idx]?.value as any}
                                    onChange={(e) => changeCartProductAtribute(
                                      currentAtributes,
                                      e.target.value,
                                      attribute.id,
                                      product
                                    )}
                                    options={options}
                                    className='text-md rounded-lg'
                                  />
                                );
                              })}
                            </div>
                          )}
                        </div>
                        <p className='font-semibold'>Giá: {formatPrice(Number(product?.unitPriceValue || 0))}</p>
                        <div className='bottom flex justify-between items-center'>
                          <QuantitySelector
                            initialQuantity={product?.quantity || 0}
                            onChange={
                              (value) => handleUpdateCartQuantity(value, product.id)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className='trash'>
                      <button
                        className='w-9 h-9 rounded-lg flex items-center justify-center bg-anti-flash-white2 base-transition hover:bg-gray-300'
                        // onClick={() => {
                        // eslint-disable-next-line max-len
                        //     openConfirmModal('Xoá sản phẩm', 'Bạn có chắc muốn xóa sản phẩm đã chọn không ?', product.id);
                        //   }}
                        onClick={() => handleConfirm(product.id)}
                      >
                        <Icon iconName='trashOutlined' size='24x24' />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )) : (
            <div className='o-cartQuickView__empty w-full h-full flex justify-center items-center'>
              <div className='max-w-[277px] mx-auto text-center flex flex-col justify-center items-center'>
                <img src={cartNullImg} alt='empty' width={100} height={100} />
                <p className='font-semibold text-gunmetal mt-4 mb-2'>Không có sản phẩm</p>
                <p className='text-feldgrau text-md'>Vui lòng thêm sản phẩm vào giỏ hàng để tiến hành thanh toán</p>
              </div>
            </div>
          )}
        </div>
        {/* <ConfirmModal onConfirm={handleConfirm} /> */}

        {/* Summary */}
        {
          data.length > 0
          && (
            <div ref={summaryRef} className='o-cartQuickView__summary bg-alice-blue2 px-4 py-6 absolute bottom-0 left-0 w-full'>
              <div className='flex justify-between'>
                <h4 className='text-lg font-semibold'>Tạm tính</h4>
                <p className='font-semibold text-lg text-neon-fuchsia'>
                  {orderTotals?.subTotal}
                </p>
              </div>
              <p className='text-feldgrau text-md mt-2'>Bạn sẽ nhận được <span>{formatDecimalNumber(orderTotals?.willEarnRewardPoints || 0)} BCoin</span> cho hóa đơn này</p>
              <Button
                className='rounded-md mt-4'
                size='large'
                variants='neon-fuchsia'
                onClick={handleCheckout}
              >
                Đến Thanh Toán
              </Button>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default CartQuickView;
