import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Button from 'components/atoms/Button';
import CountdownTimer from 'components/atoms/CountDownTimer';
import BackPopupButton from 'components/molecules/BackPopupButton';
import VerificationInput from 'components/molecules/VerificationInput';
import { detectEmailOrPhone } from 'utils/functions';

type OTPTypes = {
  otp: string;
};

export interface OTPScreenActionRef {
  handleGetValue: () => OTPTypes;
  handleForm: () => Promise<OTPTypes | undefined>;
  handleReset: (data: OTPTypes) => void,
}

interface OTPProps {
  phone: string;
  loading: boolean;
  handleBack: () => void;
  isResend: boolean;
  handleResend: (val: boolean) => void;
  handleSubmit: () => void;
  isCustomerExist: boolean;
  errorText?: string;
}

const OTP = forwardRef<OTPScreenActionRef, OTPProps>(({
  handleBack,
  phone,
  loading,
  handleResend,
  isResend,
  isCustomerExist,
  handleSubmit,
  errorText
}, ref) => {
  const method = useForm<OTPTypes>({
    mode: 'onChange',
    defaultValues: {
      otp: '',
    },
  });

  useImperativeHandle(ref, () => ({
    handleGetValue: () => method.getValues(),
    handleForm: async () => {
      method.trigger();
      let result: OTPTypes | undefined;
      await method.handleSubmit((data: OTPTypes) => {
        result = data;
      })();
      return result;
    },
    handleReset: (data) => method.reset(data),
  }));
  return (
    <div className='h-full'>
      <BackPopupButton onClick={handleBack} />
      <h2 className='text-lg md:text-xl font-semibold text-[#1F2128] mb-2 mt-4'>Nhập mã xác minh</h2>
      <p className='text-feldgrau mb-4'>
        {detectEmailOrPhone(phone) === 'phone' ? 'Số điện thoại' : 'Email'}
        <span className='mx-1 text-feldgrau text-sm font-semibold'>
          {phone}
        </span> {isCustomerExist ? 'đã' : 'chưa'} có tài khoản BidaHub.
        Vui lòng nhập mã xác thực đã được gửi đến {detectEmailOrPhone(phone) === 'phone' ? 'số điện thoại' : 'email'} của bạn.
      </p>
      {/* // 15min */}
      <CountdownTimer initialSeconds={60 * 15} variant='large' className='text-center my-4 md: my-6' />
      <FormProvider {...method}>
        <form
          noValidate
          onSubmit={method.handleSubmit(() => {
            handleSubmit();
          })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              method.handleSubmit(() => {
                handleSubmit();
              })();
            }
          }}
        >
          <Controller
            name='otp'
            rules={{
              required: 'Vui lòng nhập mã xác nhận và thử lại.',
            }}
            render={({ field, fieldState }) => (
              <VerificationInput
                {...field}
                isResend={isResend}
                error={fieldState?.error?.message || errorText}
              />
            )}
          />
        </form>
      </FormProvider>
      <Button
        loading={loading}
        type='submit'
        className='text-white bg-[#F5416C] text-sm font-semibold mt-2 md:mt-4 p-2 hover:opacity-70 transition-all'
        onClick={() => method.handleSubmit(() => {
          handleSubmit();
        })()}
      >
        Xác Nhận
      </Button>

      {isResend ? (
        <div className='text-[#2E3538] cursor-pointer mt-4 md:mt-6 flex justify-center items-center'>Gửi lại mã sau
          <CountdownTimer initialSeconds={60} variant='small' className='ml-1' onComplete={() => handleResend(false)} />
        </div>
      ) : (
        <div className='text-[#2E3538] mt-4 md:mt-6 flex items-center justify-center'>
          <div className='inline-block'>Không nhận được mã</div>
          <div className='text-[#3D8EFF] text-base inline-block ml-1 cursor-pointer' onClick={() => handleResend(true)}>Gửi lại mã</div>
        </div>
      )}
    </div>
  );
});
export default OTP;
