import React from 'react';

import Icon from 'components/atoms/Icon';

interface TitleBackHandlerProps {
  onBack?: () => void;
  children: React.ReactNode;
}

const TitleBackHandler: React.FC<TitleBackHandlerProps> = ({ children, onBack }) => (
  <div className='o-titleBackHandler'>
    <div className='flex gap-3 items-center mb-3'>
      <button onClick={onBack} className='back w-10 h-10 lg:hidden rounded-lg bg-white border border-gainsboro flex items-center justify-center'>
        <Icon iconName='arrowLeft' size='24x24' />
      </button>
      <h2 className='text-xl lg:text-2xl font-semibold'>
        {children}
      </h2>
    </div>
  </div>
);

export default TitleBackHandler;
