import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { getOrderData } from 'services/order';
import { useAppSelector } from 'store/hooks';
import { IDataOrder } from 'types/order';

const useOrderHistory = () => {
  const [data, setData] = useState<IDataOrder[]>([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.auth);

  const fetchOrderData = async () => {
    try {
      setLoading(true);
      const response = await getOrderData();

      const parseProducts = response?.data?.orders?.map((item) => ({
        ...item,
        orderNumber: item?.customOrderNumber || '',
        orderDate: item?.createdOn || '',
        productQuantity:
          item?.items?.reduce(
            (total, currentItem) => total + currentItem.quantity,
            0
          ) || 0,
        totalPrice: item?.orderTotal || '',
        status: item.orderStatus || '',
        items: item?.items || [],
        paymentStatus: item.paymentStatus || '',
        shippingStatus: item.shippingStatus || '',
        id: item?.id,
      }));
      setData(parseProducts as any);
      setLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi');
    }
  };

  useEffect(() => {
    if (user) {
      fetchOrderData();
    }
  }, []);

  return {
    fetchOrderData,
    data,
    loading,
  };
};

export default useOrderHistory;
