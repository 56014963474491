import React, { useRef, useState } from 'react';
import ReactSlick, { CustomArrowProps, Settings } from 'react-slick';

import chervonDownImage from 'assets/icons/ic_chevron_down.svg';

export interface CarouselProps {
  title?: string | React.ReactNode;
  className?: string;
  settings?: Settings;
  asNavFor?: ReactSlick;
  children: React.ReactNode;
}

interface ArrowProps extends CustomArrowProps {
  type: 'prev' | 'next';
  disabled?: boolean;
}

export const CustomArrow: React.FC<ArrowProps> = ({
  className,
  type,
  onClick,
  disabled,
}) => {
  const chervonImgClass = type === 'prev' ? 'rotate-90' : '-rotate-90';
  const arrowClassName = disabled ? 'cursor-not-allowed bg-anti-flash-white-2' : 'bg-white';
  return (
    <button aria-label='arrow' type='button' className={`flex justify-center items-center w-8 h-8 rounded shadow hover:anti-flash-white-2 ${className || arrowClassName}`} onClick={onClick}>
      <img aria-hidden='true' alt={`${type}-arrow`} src={chervonDownImage} className={`w-4 h-4 ${chervonImgClass}`} />
    </button>
  );
};

export const CustomDot: React.FC = (props) => (
  <span
    {...props}
    className='transition-all duration-300 ease-in-out border-2 border-gainsboro rounded-full bg-transparent w-2 h-2 md:w-3 md:h-3 m-auto'
  />
);

const Carousel: React.FC<CarouselProps> = (
  {
    title,
    className,
    settings,
    children,
    asNavFor,
  }
) => {
  const sliderRef = useRef<ReactSlick>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const childCount = React.Children.count(children);
  const isPrevDisabled = !settings?.infinite && currentSlide === 0;
  const isNextDisabled = !settings?.infinite && currentSlide === childCount;
  return (
    <div
      className={`relative ${className}`}
    >
      <div className='flex justify-between items-center'>
        {title}
        {settings?.arrows && (
          <div className='flex justify-center items-center gap-2'>
            <CustomArrow type='prev' onClick={() => sliderRef.current?.slickPrev()} disabled={isPrevDisabled} />
            <CustomArrow type='next' onClick={() => sliderRef.current?.slickNext()} disabled={isNextDisabled} />
          </div>
        )}
      </div>
      <ReactSlick
        centerPadding='0'
        {...settings}
        {...(asNavFor && { asNavFor })}
        ref={sliderRef}
        afterChange={(index) => setCurrentSlide(index)}
      >
        {React.Children.map(children, (item) => (
          <div className='p-2 md:p-4'>
            {item}
          </div>
        ))}
      </ReactSlick>
    </div>
  );
};

Carousel.defaultProps = {
  settings: {
    dots: true,
    dotsClass: 'absolute bottom-[-2rem] flex justify-center left-1/2 transform -translate-x-1/2',
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    cssEase: 'ease-in-out',
    infinite: false,
  },
};

export default Carousel;
