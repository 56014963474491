import React from 'react';

import WishlistContainer from 'containers/WishlistContainer';

const Wishlist: React.FC = () => (
  <div className='p-wislist bg-page'>
    <WishlistContainer />
  </div>
);

export default Wishlist;
