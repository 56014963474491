import { createAsyncThunk } from '@reduxjs/toolkit';

import { AuthState } from './types';

export const loginAction = createAsyncThunk(
  'auth/loginAction',
  async () => Promise.resolve(),
);
export const loginActionCases = {
  fulfilled: ($state: AuthState) => {
    $state.user = null;
    $state.status = 'fulfilled';
  },
  rejected: ($state: AuthState) => {
    $state.status = 'idle';
  },
  pending: ($state: AuthState) => {
    $state.status = 'loading';
  },
};
