import React from 'react';

import { IProduct } from 'types/product';

export type ProductInfoType = {
  brand: string;
  material: string;
  size: string;
  color: string;
  design: string[];
  usage: string;
  features: string[];
};
interface ProductInfoProps {
  data: IProduct;
}

const ProductInfo: React.FC<ProductInfoProps> = ({ data }) => (
  <div className='t-productInfo text-raisin-black'>
    <div className='flex flex-col lg:flex-row gap-3 lg:gap-8'>
      <div className='lg:w-1/2'>
        <h3 className='text-xl md:text-2xl font-semibold mb-4'>Thông tin sản phẩm</h3>
        {/* <h2 className='text-3xl lg:text-4xl font-semibold gradient-text leading-tight'>
          {data?.name}
        </h2> */}
        <p className='text-feldgrau mt-4'>
          {data?.shortDescription}
        </p>
      </div>
      <div className='lg:w-1/2'>
        <h3 className='text-xl md:text-2xl font-semibold mb-4'>Thông số kỹ thuật:</h3>

        <div className=' bg-anti-flash-white rounded-lg overflow-hidden'>
          <div className='px-4 lg:p-6'>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: data?.fullDescription }} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ProductInfo;
