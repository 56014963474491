/* eslint-disable import/prefer-default-export */
import axiosInstance from 'services/common/instance';
import {
  IBannerApiResponse,
  ICategoriesTreeResponse,
  IManufacturersResponse,
  SuggestionSearchType,
} from 'types/home';
import { IFeaturedResponse } from 'types/product';
import { ISearchDataResponse } from 'types/search';

export function getBannersApi(): Promise<{ data: IBannerApiResponse }> {
  return axiosInstance.get('/slider/homepageslider');
}

export function getCategoryTreeApi(): Promise<{
  data: ICategoriesTreeResponse;
}> {
  return axiosInstance.get('/home/categorytree');
}

export function getManufacturersApi(): Promise<{
  data: IManufacturersResponse;
}> {
  return axiosInstance.get('/home/manufacturers');
}

export function getFeaturedProductsApi(): Promise<{ data: IFeaturedResponse }> {
  return axiosInstance.get('/home/featureproducts');
}

export function getHotSaleProducts(): Promise<{ data: IFeaturedResponse }> {
  return axiosInstance.get('/home/bestsellerproducts');
}

export function getSearchAutocomplete(
  params: any
): Promise<{ data: SuggestionSearchType }> {
  return axiosInstance.get('/catalog/catalog/searchtermautocomplete', {
    params,
  });
}

export function getCategoriesWithProductsCountApi(): Promise<{
  data: IFeaturedResponse;
}> {
  return axiosInstance.get('/catalog/categories');
}

export function getProductBySearch(
  params: ISearchDataResponse,
  size: number,
  page: number,
  orderby: number
): Promise<{ data: ISearchDataResponse }> {
  return axiosInstance.get(
    `/catalog/search?q=${params}&pageSize=${size}&pageNumber=${page}&orderby=${orderby}`
  );
}
