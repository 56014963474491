import React from 'react';

interface ContentPanelProps {
  children?: React.ReactNode;
  title?: string;
  topAction?: React.ReactNode
  contentClassName?: string;
}

const ContentPanel: React.FC<ContentPanelProps> = ({
  children, title, topAction, contentClassName
}) => (
  <div className='o-contentPanel bg-white rounded-xl overflow-hidden'>
    {
      title
      && (
        <div className='top py-3 px-4 lg:px-6 flex justify-between items-center  border-b-1 border-gainsboro'>
          <h2 className='text-lg font-semibold'>{title}</h2>
          {
            topAction && (
              <div className='topAction'>
                {topAction}
              </div>
            )
          }
        </div>
      )
    }
    <div className={`content ${contentClassName || 'p-4 lg:p-6 rounded-b-lg'}`}>
      {children}
    </div>
  </div>
);

ContentPanel.defaultProps = {
  children: undefined,
};

export default ContentPanel;
