/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import Icon from 'components/atoms/Icon';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { IShoppingCartOrderTotals } from 'types/cart';
import {
  calculatePoint, formatDecimalNumber, formatDecimalNumberWithDot, formatVndCurrency
} from 'utils/functions';

// interface IOrderSummary {
//   orderSubTotalDiscount: any;
//   orderSubTotalDiscountValue: number;
//   orderSubtotal: string;
//   orderSubtotalValue: number;
//   orderTotal: string;
//   orderTotalDiscount: any;
//   orderTotalDiscountValue: number;
//   orderTotalValue: number;
//   redeemedRewardPoints: number;
//   redeemedRewardPointsAmount: string
// }

interface OrderSummaryProps {
  onPayment?: () => void;
  isCompleted?: boolean;
  isDetail?: boolean;
  onReOrder?: () => void;
  orderSummary?: IShoppingCartOrderTotals | null;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  onPayment = () => { }, onReOrder, isCompleted, isDetail, orderSummary
}) => {
  const { width } = useWindowDimensions();
  const isDesktop = useMemo(() => width >= 1024, [width]);
  const navigate = useNavigate();

  const [checked, setChecked] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const handlePayment = () => {
    if (!checked) {
      setHasError(true);
    } else {
      setHasError(false);
      onPayment();
    }
  };

  const desktopContent = () => (
    <div className='o-orderSummary__desktop p-4 lg:p-6 bg-white rounded-xl overflow-hidden'>
      <h2 className='text-lg font-semibold mb-4'>Tóm tắt đơn hàng</h2>

      <div className='flex justify-between mb-2 text-gunmetal'>
        <span>Tạm tính</span>
        <span>{orderSummary?.subTotal}</span>
      </div>
      <div className='flex justify-between mb-2'>
        <span className='text-gunmetal'>Giảm giá</span>
        <span className='text-persian-green'>{formatVndCurrency(orderSummary?.subTotalDiscount || 0)}</span>
      </div>
      <div className='flex justify-between mb-2 text-gunmetal'>
        <div className='text-gunmetal'>
          <p>Điểm thưởng đã áp dụng</p>
          {/* <p className='text-roman-silver text-sm mt-1'>{orderSummary?.redeemedRewardPoints} BCoins</p> */}
        </div>
        <span className='text-persian-green'>{orderSummary?.redeemedRewardPointsAmount}</span>
      </div>
      <div className='flex justify-between mb-2 text-gunmetal'>
        <span>Vận chuyển</span>
        <span>Miễn phí</span>
      </div>

      <div className='border-t border-gainsboro my-4' />

      <div className='flex justify-between items-center mb-2'>
        <span className='text-xl font-semibold text-gunmetal'>Thành tiền:</span>
        <span className='text-xl font-semibold text-neon-fuchsia'>{orderSummary?.orderTotal}</span>
      </div>
      {/* <div className='flex justify-between items-center mb-2'>
        <span className='font-semibold text-gunmetal'>Bạn được nhận</span>
        <span className='font-semibold text-roman-silver'>+{formatDecimalNumber('1000')} Bcoins</span>
      </div> */}

      <div className='text-sm text-right mb-4'>
        <span className='text-md text-gunmetal'>Tiết kiệm:</span>
        <span className='text-md text-persian-green ml-1'>{orderSummary?.orderTotalDiscount || 0}</span>
        {/* <p className='text-sm text-roman-silver'>
          (Giá này đã bao gồm thuế GTGT và các chi phí khác)
        </p> */}
      </div>
      {/* {
        !isDetail
        && (
          <div className='flex justify-between'>
            <span className='text-gunmetal'>Điểm thưởng sẽ được nhận</span>
            <span className='text-persian-green font-semibold'>{formatDecimalNumber(orderSummary?.willEarnRewardPoints || 0)} Bcoin</span>
          </div>
        )
      } */}

      {
        !isDetail && (
          <div className='bg-alice-blue px-3 py-2 rounded-lg text-sm mt-1 mb-4'>
            {/* <p className='text-sm text-gunmetal mt-2'>
              Điểm thưởng sẽ được kích hoạt sau khi đơn hàng được giao cho bạn. Để
              biết thêm thông tin, vui lòng xem{' '}
              <a href='/' target='_blank' className='text-brilliant-azure'>
                TẠI ĐÂY
              </a>
            </p> */}
          </div>
        )
      }

      {!isCompleted && (
        <>
          <div className='flex items-center mb-3'>
            <Checkbox
              onChange={() => {
                setChecked(!checked);
                if (hasError) setHasError(false);
              }}
              id='privacy'
              classNameLabel='ml-2 text-gunmetal'
              label={`Tôi đồng ý với
                    <a href='/' target='_blank' class='text-brilliant-azure'>
                      Điều khoản dịch vụ
                    </a> 
                    và 
                    <a href='/' target='_blank' class='text-brilliant-azure'>
                      Chính sách bảo mật
                    </a>`}
            />
          </div>
          {hasError && (
            <p className='text-neon-fuchsia text-sm my-2'>
              Vui lòng đánh dấu vào ô Tôi đồng ý với Điều khoản dịch vụ và Chính sách bảo mật
            </p>
          )}
        </>
      )}

      <div className='submit mb-3'>
        {isCompleted && !isDetail ? (
          <div>
            {
              orderSummary?.isRepayment ? (
                <Button size='large' onClick={() => navigate('/cart')} type='button' variants='neon-fuchsia'>
                  Thanh toán lại
                </Button>
              ) : (
                <div>
                  <Button onClick={onReOrder} size='large' type='button' className='text-white !bg-medium-turquoise2 capitalize'>
                    Đặt lại
                  </Button>
                  <Button onClick={() => navigate('/')} size='large' type='button' className='mt-3 text-white bg-blueRyb hover:bg-blue-400 w-full'>
                    Tiếp tục mua sắm
                  </Button>
                </div>
              )
            }

          </div>
        ) : isDetail ? (
          <div>
            {
              orderSummary?.isRepayment ? (
                <Button size='large' onClick={() => navigate('/cart')} type='button' variants='neon-fuchsia'>
                  Thanh toán lại
                </Button>
              ) : (
                <Button size='large' onClick={onReOrder} type='button' className='text-white !bg-medium-turquoise2 capitalize'>
                  Mua lại
                </Button>
              )
            }
          </div>
        ) : (
          <Button size='large' onClick={handlePayment} variants='neon-fuchsia'>
            {orderSummary?.isRepayment ? 'Thanh toán lại' : 'Thanh Toán'}
          </Button>
        )}
      </div>

      {/* <div className='text-gunmetal mt-3 font-medium flex justify-between'>
        <p>Điểm thưởng sẽ được nhận</p>
        <p className='text-neon-fuchsia'>{formatDecimalNumberWithDot(90000)} Bcoin</p>
      </div> */}
      {
        // !isDetail
        // && (
        <div className='flex justify-between'>
          <span className='text-gunmetal'>Điểm thưởng sẽ được nhận</span>
          <span className={`font-semibold ${!isDetail ? 'text-persian-green' : 'text-neon-fuchsia'}`}>{formatDecimalNumber(orderSummary?.willEarnRewardPoints || 0)} Bcoin</span>
        </div>
        // )
      }
      {/* <div className='text-sm text-gray-700 bg-alice-blue px-3 py-2 rounded-lg mt-3'>
        Điểm thưởng sẽ được kích hoạt sau khi đơn hàng được giao cho bạn. Để biết thêm thông tin, vui lòng xem
        <a href='/' target='_blank' className='text-brilliant-azure'>
          TẠI ĐÂY
        </a>
      </div> */}
      {/* TODO: In case the order completed with waiting payment status  */}
      {
        isCompleted && orderSummary?.isRepayment
        && (
          <div className='text-sm text-gray-700 bg-carmine-pink bg-opacity-[0.16] px-3 py-2 rounded-lg mt-3'>
            Đơn hàng của bạn chưa được thanh toán, vui lòng thanh toán lại để hoàn tất. Trường hợp đã thanh toán chuyển khoản vui lòng không thanh toán lại và đợi xác nhận trong vòng 24 giờ.
          </div>
        )
      }
      {/* TODO: Note for orders when paying manually via bank transfer */}
      {/* <div className='text-sm text-gray-700 bg-carmine-pink bg-opacity-[0.16] px-3 py-2 rounded-lg mt-3'>
        Nếu quý khách đã thanh toán, vui lòng bỏ qua bước thanh toán lại.
      </div> */}
      <div className='text-sm text-gray-700 bg-anti-flash-white2 px-3 py-2 rounded-lg mt-3'>
        <span>Để xuất hoá đơn VAT xin vui lòng liên hệ Zalo hoặc số điện thoại hotline</span>{' '}
        <span className='text-brilliant-azure'>
          0949.696.696
        </span>
        <span>{' '}để được hỗ trợ</span>
      </div>
    </div>
  );

  const mobileContent = () => (
    <div className='o-orderSummary__mobile fixed bottom-0 left-0 right-0 bg-white z-50'>
      <button type='button' className='flex py-3 px-4 justify-between items-center w-full bg-blueRyb text-white text-md' onClick={() => { }}>
        <p>
          Bạn đang có{' '}
          <span className='font-semibold'>{formatDecimalNumber('1200000')} Bcoin</span>
        </p>
        <div className='flex gap-x-1 items-center'>
          <p className='text-medium-turquoise2 text-md'>Sử dụng</p>
          <Icon iconName='chevronRightWhite' size='16x16' />
        </div>
      </button>
      <button type='button' className='flex py-3 px-4 justify-between items-center w-full' onClick={() => { }}>
        <p className='text-md text-gunmetal'>Sử dụng voucher</p>
        <p className='text-roman-silver flex items-center text-md'>Nhập hoặc chọn mã
          <Icon iconName='chevronRight' size='16x16' />
        </p>
      </button>
      <div className='submit py-3 px-4 border-1 border-gainsboro'>
        <div className='flex flex-row gap-4 items-center'>
          <div className='w-6/12'>
            <p className='text-gunmetal text-md'>Tạm tính</p>
            <p className='text-xl text-neon-fuchsia font-semibold'>{orderSummary?.orderTotal}</p>
            <p className='text-sm text-gunmetal'>Tiết kiệm: {' '}
              <span className='text-persian-green'>{formatVndCurrency(1350000)}</span>
            </p>
          </div>
          <div className='w-6/12'>
            <Button onClick={handlePayment} type='button' variants='neon-fuchsia' size='large'>
              Đặt hàng
            </Button>
          </div>
        </div>
        {
          !isDetail && (
            <p className='text-md text-gunmetal mt-1'>
              Điểm thưởng sẽ được nhận {' '}
              <span className='text-persian-green'>{calculatePoint(orderSummary?.willEarnRewardPoints || 0)} Bcoin</span>
            </p>
          )
        }
      </div>
    </div>
  );

  return (
    <div className='o-orderSummary'>
      {isCompleted ? desktopContent() : isDesktop ? desktopContent() : mobileContent()}
    </div>
  );
};

export default OrderSummary;
