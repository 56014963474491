/* eslint-disable import/prefer-default-export */
import axiosInstance from 'services/common/instance';
import {
  IDataProductAttribute,
  IProduct,
  IProductAttributeParams,
} from 'types/product';
import { IParamsProductReview, IParamsReviewHelpful } from 'types/reviews';

export function getProductDetailApi(
  productId: number
): Promise<{ data: IProduct }> {
  return axiosInstance.get(`/product/productdetails/${productId}`);
}

export function postReviewProductApi(
  params: IParamsProductReview
): Promise<{ data: IProduct }> {
  return axiosInstance.post(`/product/productreviewsadd/${params?.id}`, {
    data: { addProductReview: params.formData },
  });
}

export function postProductReviewHelpfulApi(
  params: IParamsReviewHelpful
): Promise<IParamsProductReview> {
  return axiosInstance.post(
    `/product/setproductreviewhelpfulness/${params?.id}`,
    {
      formValues: params.formValues,
    }
  );
}

export function getRelatedProductApi(
  params: number
): Promise<{ data: IProduct[] }> {
  return axiosInstance.get(`/product/relatedproducts/${params}`);
}

export function postSubscriptionStockProductsApi(
  params: number
): Promise<{ data: IProduct[] }> {
  return axiosInstance.post(`/backinstocksubscription/subscribe/${params}`);
}

export function getAlsoPurchasedProductsApi(
  params: number
): Promise<{ data: IProduct[] }> {
  return axiosInstance.get(`/product/productsalsopurchased/${params}`);
}

export function UpdatePriceWithAtrribute(
  params: IProductAttributeParams
): Promise<{ data: IDataProductAttribute }> {
  return axiosInstance.post(
    `/shoppingcart/productattributechange/${params.id}`,
    {
      formValues: params.formValues,
    }
  );
}
