import classNames from 'classnames';
import React, {
  forwardRef
} from 'react';

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  type?: 'text' | 'number' | 'email' | 'password' | 'tel';
  error?: string;
  isSuccess?: boolean;
  loading?: boolean;
  isReadOnly?: boolean;
  className?: string;
}

const TextareaRef: React.ForwardRefRenderFunction<HTMLTextAreaElement, TextareaProps> = ({
  type,
  placeholder,
  value,
  error,
  isSuccess,
  disabled,
  className = '',
  ...props
}) => {
  const inputClassNames = classNames(
    `border-1 border-gainsboro font-medium rounded-md p-4 w-full focus:outline-none shadow-none  text-gunmetal-500 placeholder:text-roman-silver ${className}`,
    {
      'border-gainsboro-300 boreder-1': !error && !isSuccess && !disabled,
      'border-carmine-pink boreder-1': error,
      'border-medium-turquoise boreder-1': isSuccess,
      'bg-gainsboro boreder-1': disabled,
      'cursor-not-allowed': disabled,
      'bg-anti-flash-white2': disabled,
      'focus:border-brilliant-azure': true,
      'focus:shadow-input-focus': true,
    }
  );

  return (
    <div className='relative a-textArea flex flex-col'>
      <textarea
        placeholder={placeholder}
        value={value}
        className={inputClassNames}
        disabled={disabled}
        {...props}
      />
      {error && <p className='mt-1 text-sm text-carmine-pink'>{error}</p>}
    </div>
  );
};

const Textarea = forwardRef(TextareaRef);

export default Textarea;
