import React from 'react';
import { useNavigate } from 'react-router-dom';

import CategoryCard, { CategoryCardType } from 'components/molecules/CategoryCard';
import SLUG_PARAMS from 'utils/constants';

interface CategoryCardSlideProps {
  data: CategoryCardType[]
  isLoadingFetch?: boolean
}

const CategoryCardSlide: React.FC<CategoryCardSlideProps> = ({ data, isLoadingFetch }) => {
  const navigate = useNavigate();

  return (
    <div className='t-categoryCardSlide flex overflow-x-auto gap-4 custom-scrollbar'>
      {
        data.map((cate, index) => (
          <div key={`cate-${index.toString()}`} className='flex-none lg:flex-auto'>
            <CategoryCard data={cate} onClick={(id) => navigate(`${SLUG_PARAMS.SUB_CATEGORY}/${id}`)} isLoadingFetch={isLoadingFetch} />
          </div>
        ))
      }
    </div>
  );
};

export default CategoryCardSlide;
