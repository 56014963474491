/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import Button from 'components/atoms/Button';
import AddressSelector, { AddressItemType } from 'components/organisms/AddressSelector';
import CustomModal from 'components/organisms/Modal';
import { useAppSelector } from 'store/hooks';

interface EditAddressModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  handleSubmit: (value: AddressItemType | null) => void;
  addressData?: AddressItemType[];
  selectedAddress: AddressItemType | null;
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
  onAdd?: () => void;
  onPickedAddress?: (address: AddressItemType) => void;
  initPickedAddress?: AddressItemType | null;
}

const EditAddressModal: React.FC<EditAddressModalProps> = (
  {
    isOpen,
    addressData,
    onRequestClose,
    handleSubmit,
    onEdit,
    onDelete,
    onAdd,
    selectedAddress,
    initPickedAddress = null,
    onPickedAddress = () => { }
  }
) => {
  const [pickedAddress, setPickedAddress] = useState<AddressItemType | null>(initPickedAddress);
  const addressState = useAppSelector((state) => state.cart.address);
  const handleChangeAddress = (address: AddressItemType) => {
    setPickedAddress(!address ? addressState as AddressItemType : address);
    onPickedAddress?.(address);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title='Địa chỉ của tôi'
      className='max-w-3xl'
      isOverflow
      shouldCloseOnOverlayClick={false}
    >
      <div className='t-editAddressModal py-4'>
        {
          addressData && (
            <AddressSelector
              addresses={addressData}
              onEdit={onEdit}
              selectedAddress={pickedAddress || selectedAddress}
              onDelete={onDelete}
              handleChangeAddress={handleChangeAddress}
            />
          )
        }
        <div className='mt-4'>
          <Button size='large' variants='primary' className='w-auto' onClick={onAdd}>Thêm Địa Chỉ Mới</Button>
        </div>
        <div className='h-[1px] bg-gainsboro w-full mt-6' />
        <div className='submit pt-4 lg:pt-6'>
          <div className='flex flex-row gap-4 justify-end'>
            <Button
              onClick={onRequestClose}
              size='large'
              variants='secondary'
              className='max-w-[120px]'
            >
              Hủy bỏ
            </Button>
            <Button
              onClick={() => handleSubmit(pickedAddress)}
              size='large'
              className='max-w-[120px]'
            >
              Xác nhận
            </Button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default EditAddressModal;
