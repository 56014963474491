import React from 'react';

import { ProductType } from 'components/organisms/ProductCard';
import ProductSlide from 'components/templates/ProductSlide';

export interface IHotSaleContainer {
  products: ProductType[],
  loading: boolean,
  refetchData: () => void,
}

const HotSaleContainer = ({ products, loading, refetchData }: IHotSaleContainer) => {
  if (products?.length <= 0) return <div />;
  return (
    <ProductSlide
      productData={products}
      isHot
      title='Sản phẩm bán chạy'
      isLoadingFetch={loading}
      refetchData={refetchData}
    />
  );
};

export default HotSaleContainer;
