/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ProductType } from '../../components/organisms/ProductCard/index';

import {
  getAllManufacturer,
  getProductbyCategoriesIds,
} from 'services/catalog';
import { IParamsGetCategoriesIds } from 'types/catalog';

const useManufacturer = () => {
  const params = useParams();
  const [products, setProducts] = useState<ProductType[]>([...new Array(5)]);
  const [description, setDescription] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [manufacturers, setManufacturers] = useState<any[]>([]);
  const [manuId, setManuID] = useState<number>(Number(params.id));
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [rawManufacturers, setRawManufacturers] = useState<any[]>([]);
  const [payload, setPayload] = useState<IParamsGetCategoriesIds>({
    pagesize: 5,
    pagenumber: 1,
    ms: null,
    orderby: 5,
    manufacturerIds: [Number(params.id)],
  });
  const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
  const calculateTotalPages = (totalItems: number, pageSize: number): number =>
    Math.ceil(totalItems / pageSize);
  const fetchManufacturer = async (refetch = false) => {
    try {
      if (!refetch) {
        setLoadingProduct(true);
      }
      const response: any = await getProductbyCategoriesIds(payload);
      setProducts(response?.data?.products);
      const parseProducts = response?.data?.products?.map((product: any) => ({
        imageSrc: product?.defaultPictureModel?.imageUrl,
        title: product?.name,
        category: '',
        brand: '',
        price: product?.productPrice?.priceValue,
        rating: product?.reviewOverviewModel?.ratingSum,
        reviews: product?.reviewOverviewModel?.totalReviews,
        id: product.id,
        count: 3,
        description: product?.shortDescription,
        outStock: !product?.inStock,
        alreadySubscribed: product?.alreadySubscribed,
        inWishlist: product?.inWishlist,
        wishlistId: product?.wishlistId,
      }));
      setProducts(parseProducts);
      setTotalPages(
        calculateTotalPages(response.data.totalItems, payload.pagesize)
      );
      setTotalRecord(response.data.totalItems);
      setLoadingProduct(false);
    } catch (error) {
      //
    }
  };

  const fetchAllManufacturer = async () => {
    try {
      const response: any = await getAllManufacturer();
      setRawManufacturers(response?.data);
      setManufacturers(
        response?.data?.map((item: any) => ({
          id: item?.id,
          title: item?.name,
          checked: item.id === Number(manuId),
          count: 0,
        })) || []
      );
      const findManufacturer = response?.data?.find(
        (item: any) => item.id === Number(manuId)
      );
      setImage(findManufacturer?.pictureModel?.imageUrl);
      setDescription(findManufacturer?.description);
      setName(findManufacturer?.name);
    } catch (error) {
      //
    }
  };

  const handleChangePorfolio = (id: any) => {
    const findManufacturer = rawManufacturers?.find(
      (item: any) => item.id === Number(id)
    );
    setImage(findManufacturer?.pictureModel?.imageUrl);
    setDescription(findManufacturer?.description);
    setName(findManufacturer?.name);
  };

  const handleManuIdChange = (value: any) => {
    setPayload((prev) => ({
      ...prev,
      page: 1,
      ms: null,
    }));
    setManuID(value);
  };

  useEffect(() => {
    fetchAllManufacturer();
  }, []);

  useEffect(() => {
    fetchManufacturer();
  }, [payload]);

  return {
    manuId,
    products,
    manufacturers,
    image,
    name,
    totalRecord,
    totalPages,
    description,
    payload,
    setPayload,
    handleChangePorfolio,
    fetchManufacturer,
    handleManuIdChange,
    loadingProduct,
  };
};
export default useManufacturer;
