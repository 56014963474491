/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect, useRef, useState
} from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import {
  DetailProductSkeleton, ProductInfoSkeleton,
  SlideShowViewerSkeleton
} from 'components/atoms/Skeleton';
import BoxSelections from 'components/molecules/BoxSelections';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import QuantitySelector from 'components/molecules/QuantitySelector';
import ReceiveText from 'components/molecules/ReceiveText';
import { ProductType } from 'components/organisms/ProductCard';
import SlideshowViewer, { SlideshowViewerRef } from 'components/organisms/SlideshowViewer';
import ProductInfo from 'components/templates/ProductInfo';
import ProductSlide from 'components/templates/ProductSlide';
import { ReviewDataTypes } from 'components/types';
import IntroCardContainer from 'containers/IntroCardContainer';
import useCart from 'hooks/useCart';
import { addToCartApi } from 'services/cart';
import {
  getProductDetailApi, getRelatedProductApi,
  postSubscriptionStockProductsApi,
  UpdatePriceWithAtrribute
} from 'services/product';
import { getProductReviewsById } from 'services/reviews';
import { updateFromWishList } from 'services/wishlist';
import { setLoggedIn } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IAddToCartRequest } from 'types/cart';
import { IProduct, IProductAttributeParams } from 'types/product';
import SLUG_PARAMS, { CART_TYPE } from 'utils/constants';
import { formatVndCurrency, parseCurrency } from 'utils/functions';

const benefits = [
  'Nhập khẩu và phân phối',
  'Bảo hành 12 tháng từ nhà sản xuất',
  'Mới 100% Full box, hàng chính hãng',
];

interface IAttributes {
  attributeId: string;
  value: string;
}

const ProductDetailContainer: React.FC = () => {
  const [product, setProduct] = useState<IProduct>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
  const [loadingReview, setLoadingReview] = useState<boolean>(false);
  const [loadingRelated, setLoadingRelated] = useState<boolean>(false);
  const [reviews, setReviews] = useState<ReviewDataTypes[]>([]);
  const [relatedProduct, setRelatedProduct] = useState<ProductType[]>([...new Array(5)]);
  const [productQuantity, setProductQuantity] = useState<number>(1);
  const [attributes, setAttributes] = useState<IAttributes[]>([]);
  const { id } = useParams();
  const { handleFetchShoppingCart, handleFetchWishList } = useCart();
  const slideRef = useRef<SlideshowViewerRef>(null);
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const fetchChangeAtrribute = async (preAttribute: any) => {
    const params: IProductAttributeParams = {
      id: Number(id),
      formValues: [
        {
          key: 'loadPicture',
          value: 'True'
        }
      ]
    };

    preAttribute?.forEach((attribute: any) => {
      params.formValues.push({
        key: `product_attribute_${attribute.attributeId}`,
        value: attribute.value,
      });
    });

    const response = await UpdatePriceWithAtrribute(params);

    setProduct((prev: any) => ({
      ...prev,
      productPrice: {
        priceValue: response.data.price?.replace(/[.\s₫]/g, '') || 0
      },
      sku: response.data.sku || '',
      isFreeShipping: response.data.isFreeShipping,
      stockAvailability: response.data.stockAvailability
    }));

    const indexSlide: number = product?.pictureModels.findIndex(
      (item) => item.imageUrl === response.data.pictureDefaultSizeUrl
    ) || 0;

    if (response.data.pictureDefaultSizeUrl) {
      slideRef.current?.goToSlide(indexSlide);
    }
    return response;
  };

  const handleFetchProductDetail = async (refetch = false) => {
    try {
      if (!refetch) {
        setLoadingProduct(true);
      }

      const response = await getProductDetailApi(Number(id));
      const productData = response.data;
      const defaultAttributes = productData.productAttributes?.map((attr) => {
        const firstValue = attr?.values?.[0];
        return firstValue ? { attributeId: attr.id, value: firstValue.id } : null;
      })?.filter(Boolean) || [];

      let updatedPrice = productData.productPrice?.priceValue;
      let updateStockAvailability = productData?.stockAvailability;
      if (defaultAttributes.length) {
        const attributeResponse = await fetchChangeAtrribute(defaultAttributes);
        updatedPrice = parseCurrency(attributeResponse?.data?.price) || updatedPrice || 0;
        updateStockAvailability = attributeResponse?.data?.stockAvailability;
      }

      setProduct({
        ...productData,
        productPrice: {
          ...productData.productPrice,
          priceValue: updatedPrice,
        },
        stockAvailability: updateStockAvailability
      });

      setAttributes(defaultAttributes as []);
      setLoadingProduct(false);
    } catch (error: any) {
      setLoadingProduct(false);
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleFetchRelatedProducts = async () => {
    try {
      setLoadingRelated(true);
      const response = await getRelatedProductApi(Number(id));
      const parseProducts = response?.data?.map((item) => ({
        imageSrc: item?.defaultPictureModel?.imageUrl || '',
        title: item?.name || '',
        category: 'Bộ bi 3 băng',
        brand: 'Predator',
        price: item?.productPrice?.priceValue || 0,
        rating: item.reviewOverviewModel.ratingSum || 0,
        reviews: item.reviewOverviewModel.totalReviews || 0,
        id: item?.id || 0,
        count: 432,
        description: item?.shortDescription || '',
        outStock: !item?.inStock,
        inWishlist: item?.inWishlist,
        alreadySubscribed: item?.alreadySubscribed,
        wishlistId: item?.wishlistId
      }));
      setRelatedProduct(parseProducts);
      setLoadingRelated(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const refetchData = async () => {
    try {
      const response = await getRelatedProductApi(Number(id));
      const parseProducts = response?.data?.map((item) => ({
        imageSrc: item?.defaultPictureModel?.imageUrl || '',
        title: item?.name || '',
        category: 'Bộ bi 3 băng',
        brand: 'Predator',
        price: item?.productPrice?.priceValue || 0,
        rating: item.reviewOverviewModel.ratingSum || 0,
        reviews: item.reviewOverviewModel.totalReviews || 0,
        id: item?.id || 0,
        count: 432,
        description: item?.shortDescription || '',
        outStock: !item?.inStock,
        inWishlist: item?.inWishlist,
        alreadySubscribed: item?.alreadySubscribed,
        wishlistId: item?.wishlistId
      }));
      setRelatedProduct(parseProducts);
    } catch (error) {
      //
    }
  };

  const handleFetchProductReviews = async () => {
    try {
      setLoadingReview(true);
      const response = await getProductReviewsById(Number(id));
      setReviews(response?.data?.items?.map?.((review) => ({
        name: review.customerName,
        profileImage: review.customerAvatarUrl,
        rating: review.rating,
        title: review.title,
        content: review.reviewText,
        createdAt: review.writtenOnStr,
        likes: review.helpfulness.helpfulYesTotal,
        dislikes: review.helpfulness.helpfulNoTotal,
        id: review.id
      })));
      setLoadingReview(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleFetchSubcriptionStockProducts = async () => {
    try {
      await postSubscriptionStockProductsApi(Number(id));
      if (product?.alreadySubscribed) {
        toast.success('Huỷ đăng ký nhận thông báo thành công!');
      } else {
        toast.success('Đăng ký nhận thông báo thành công!');
      }
      handleFetchProductDetail(true);
    } catch (error: any) {
      if (!user) {
        dispatch(setLoggedIn(true));
        toast.error('Vui lòng đăng nhập để đăng ký nhận thông báo');
      } else {
        toast.error(user?.email ? error?.response?.data?.ErrorList?.[0] : 'Cập nhật email ở trang tài khoản để nhận thông báo có hàng từ hệ thống');
      }
    }
  };

  const handleRemoveProductFromWishList = async (id: number, productId: number) => {
    try {
      const params = {
        formValues: [
          {
            key: 'removefromcart',
            value: `${id}`
          }
        ],
        message: '',
      };

      await updateFromWishList(params);
      const response = await getProductDetailApi(Number(productId));
      const productData = response.data;
      const defaultAttributes = attributes;
      let updatedPrice = productData.productPrice?.priceValue;
      let updateStockAvailability = productData?.stockAvailability;
      if (defaultAttributes.length) {
        const attributeResponse = await fetchChangeAtrribute(defaultAttributes);
        updatedPrice = parseCurrency(attributeResponse?.data?.price) || updatedPrice || 0;
        updateStockAvailability = attributeResponse?.data?.stockAvailability;
      }

      setProduct({
        ...productData,
        productPrice: {
          ...productData.productPrice,
          priceValue: updatedPrice,
        },
        stockAvailability: updateStockAvailability
      });
      handleFetchWishList();
      handleFetchShoppingCart();
      toast.success('Đã xoá sản phẩm khỏi danh sách yêu thích');
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleAddToCart = async (params: IAddToCartRequest, type?: number) => {
    try {
      if (type === 1) { setLoading(true); }
      await addToCartApi(params);
      toast.success(type === 1 ? 'Sản phẩm đã được thêm vào giỏ hàng' : 'Sản phẩm đã được thêm vào danh sách yêu thích');
      setLoading(false);
      if (type === 1) {
        handleFetchShoppingCart();
      } else {
        handleFetchWishList();
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const handleChangeAtributes = async (attributeId: string, value: string) => {
    const i = attributes.findIndex((e) => e.attributeId === attributeId);
    let preAttribute = [...attributes];
    if (i === -1) {
      preAttribute = [...attributes, { attributeId, value }];
      setAttributes(preAttribute);
    } else {
      preAttribute[i] = { attributeId, value };
      setAttributes(preAttribute);
    }
    await fetchChangeAtrribute(preAttribute);
  };

  const handleCheckProduct = async (type?: string, cartType?: number) => {
    try {
      const response = await getProductDetailApi(Number(id));
      const productData = response.data;
      const defaultAttributes = attributes;
      let updatedPrice = productData.productPrice?.priceValue;
      let updateStockAvailability = productData?.stockAvailability;
      if (defaultAttributes.length) {
        const attributeResponse = await fetchChangeAtrribute(defaultAttributes);
        updatedPrice = parseCurrency(attributeResponse?.data?.price) || updatedPrice || 0;
        updateStockAvailability = attributeResponse?.data?.stockAvailability;
      }

      setProduct({
        ...productData,
        productPrice: {
          ...productData.productPrice,
          priceValue: updatedPrice,
        },
        stockAvailability: updateStockAvailability
      });
      if (response.data.productAttributes.length >= 0) {
        const formValues = [
          {
            Key: `addtocart_${id}.EnteredQuantity`,
            Value: productQuantity.toString(),
          },
        ];
        attributes?.forEach((atribute) => {
          formValues.push({
            Key: `product_attribute_${atribute.attributeId}`,
            Value: atribute.value,
          });
        });
        await handleAddToCart({
          productId: Number(id),
          type: cartType as number,
          data: {
            FormValues: formValues,
          }
        }, cartType);
      }
      setAttributes((prev) => [...prev]);
      setProductQuantity(1);
      const result = await getProductDetailApi(Number(id));
      const productDataNew = result.data;
      const defaultAttributesNew = attributes;
      let updatedPriceNew = productDataNew.productPrice?.priceValue;
      let updateStockAvailabilityNew = productDataNew?.stockAvailability;
      if (defaultAttributes.length) {
        const attributeResponse = await fetchChangeAtrribute(defaultAttributesNew);
        updatedPriceNew = parseCurrency(attributeResponse?.data?.price) || updatedPriceNew || 0;
        updateStockAvailabilityNew = attributeResponse?.data?.stockAvailability;
      }

      setProduct({
        ...productDataNew,
        productPrice: {
          ...productDataNew.productPrice,
          priceValue: updatedPriceNew,
        },
        stockAvailability: updateStockAvailabilityNew
      });
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const renderSubmitButton = (() => {
    const soldOutButton = !product?.alreadySubscribed ? (
      <Button onClick={handleFetchSubcriptionStockProducts} size='large' className='text-white bg-blueRyb border-blueRyb border hover:bg-white hover:text-gunmetal capitalize'>
        Nhận thông báo khi có hàng
      </Button>
    ) : (
      <Button onClick={handleFetchSubcriptionStockProducts} className='bg-white border-blueRyb text-gunmetal border'>
        Đã đăng ký nhận thông báo
      </Button>
    );

    const availableButton = (
      <Button
        size='large'
        variants='neon-fuchsia'
        onClick={() => handleCheckProduct('1', CART_TYPE.CART)}
        loading={loading}
        icon='cart'
        disabled={product?.stockAvailability === 'Out of stock'}
      >
        Thêm vào giỏ
      </Button>
    );

    return !product?.inStock ? soldOutButton : availableButton;
  })();

  useEffect(() => {
    handleFetchProductDetail(false);
    handleFetchProductReviews();
    handleFetchRelatedProducts();
  }, [id]);

  const breadcrumbItems = [
    { label: 'Home', path: '/' },
    { label: 'Sản Phẩm' },
    { label: product?.name || '' },
  ];

  return (
    <>
      <div className='container mx-auto py-4 lg:py-6'>
        <Breadcrumbs items={breadcrumbItems} />
      </div>
      <div className='container px-0 lg:px-4 mx-auto'>
        <div className='flex flex-col gap-6'>
          <div className='flex flex-col lg:flex-row bg-white page-top lg:rounded-xl'>
            {/* COL 1 */}
            <div className='lg:w-7/12'>
              {loadingProduct ? <SlideShowViewerSkeleton /> : (
                <SlideshowViewer
                  data={product?.pictureModels?.map((picture) => ({
                    title: picture.title,
                    src: picture.imageUrl,
                  })) || []}
                  ref={slideRef}
                />
              )}
            </div>
            {/* COL 2 */}
            {loadingProduct ? <DetailProductSkeleton /> : (
              <div className='lg:w-5/12 py-4 px-4 lg:px-6'>
                <h1 className='product-title text-2xl font-semibold'>
                  {product?.name}
                </h1>
                {/* <BackgroundBadge icon={<Icon iconName='verify' size='16x16' />}>
                  Chính hãng 100%
                </BackgroundBadge> */}
                <p className='product-sku text-feldgrau mt-2'>SKU: {product?.sku || ''}</p>
                <div className='product-rating flex items-center pt-3'>
                  {/* <StarRating
                    count={5}
                    value={product?.productReviewOverview?.ratingSum || 0}
                    isStatic
                  /> */}
                  <p className='text-md text-brilliant-azure'>
                    {/* ({product?.productReviewOverview?.totalReviews || 0} đánh giá) */}
                    {product?.productManufacturers?.map((productManufacturer, index) => (
                      <a href={`${SLUG_PARAMS.MANUFACTURES}/${productManufacturer?.id}`} key={`cate-${index.toString()}`}>
                        {productManufacturer?.name}
                      </a>
                    ))}
                  </p>
                </div>
                <p className='product-price text-3xl leading-10 font-semibold mt-3 text-neon-fuchsia'>
                  {formatVndCurrency(Number(product?.productPrice?.priceValue || 0))}
                </p>
                {/* Category Selection  */}
                {product?.inStock && product?.productAttributes && (
                  <div className='category-selection flex flex-col gap-6 mt-4 lg:mt-6'>
                    {product?.productAttributes?.map((productAttribute) => (
                      <BoxSelections
                        key={`attr-${productAttribute?.id}`}
                        title={productAttribute?.name}
                        isNaked
                        options={productAttribute?.values?.map((value) => ({
                          label: value.name,
                          value: value.id,
                          // TODO: check quantity
                          // disabled: value?.quantity ? value?.quantity > 0 : false
                        }))}
                        onSelect={(value) => handleChangeAtributes(productAttribute.id, value)}
                        selectedValue={
                          attributes.find((attr: any) => attr.attributeId === productAttribute.id)?.value
                        }
                      />
                    ))}
                  </div>
                )}
                {/* Quantity selection  */}
                <div className='bottom mt-4 lg:mt-6'>
                  <p className='text-gunmetal font-semibold mb-3'> Số lượng</p>
                  <QuantitySelector
                    onChange={(value) => setProductQuantity(value)}
                    modifier='lg'
                    initialQuantity={productQuantity}
                    isRepayment={product?.stockAvailability === 'Out of stock'}
                  />
                  <div className='mt-3'>
                    <p className='inline-block border border-teal-deer rounded bg-honeydew px-3 py-[6px] text-md text-forest-green text-center'>
                      {product?.stockAvailability === 'Out of stock' ? 'Hết hàng' : product?.stockAvailability === '' ? 'Còn hàng' : `Còn hàng (${product?.stockAvailability !== 'In stock' ? product?.stockAvailability : ''}) `}
                    </p>
                  </div>
                  <div className='mt-3 isLiked'>
                    <button
                      onClick={!product?.inWishlist ? () => handleCheckProduct('1', CART_TYPE.WISHLIST) : () => handleRemoveProductFromWishList(product?.wishlistId, product?.id)}
                      className='flex items-center hover:cursor-pointer'
                    >
                      {/* TODO: add like action will be handle later */}
                      <Icon
                        iconName={!product?.inWishlist ? 'outlinedHeart' : 'filledHeart'}
                        size='20x20'
                      />
                      {!product?.inWishlist ? (
                        <span className='inline-block ml-1 text-gunmetal text-md'>
                          Thêm vào DS yêu thích
                        </span>
                      ) : (
                        <span className='inline-block ml-1 text-neon-fuchsia text-md'>
                          Đã thêm vào DS yêu thích
                        </span>
                      )}
                    </button>
                  </div>
                  <div className='mt-4 md:mt-6'>
                    <ReceiveText
                      bcoin={
                        Number(product?.productPrice?.priceValue || 0)
                      }
                      isFull
                    />
                  </div>
                </div>
                <div className='addToCart mt-2 lg:mt-3'>
                  {renderSubmitButton}
                  {
                    product?.alreadySubscribed
                    && <p className='text-feldgrau text-md text-center mt-2'>*Bạn sẽ được thông báo khi sản phẩm có hàng trở lại</p>
                  }
                </div>
              </div>
            )}
            <div className='container mx-auto bg-white rounded-xl block md:hidden border border-gainsboro'>
              <IntroCardContainer />
            </div>
          </div>
          <div className='container mx-auto bg-white rounded-xl hidden md:block'>
            <IntroCardContainer />
          </div>
          {/* PRODUCT INFO */}
          <div className='bg-white mx-4 lg:mx-0 p-3 md:p-6 rounded-xl'>
            {loadingProduct ? <ProductInfoSkeleton /> : <ProductInfo data={product as IProduct} />}
          </div>
          {/* PRODUCT REVIEWS */}
          {/* <div className='bg-white mx-4 lg:mx-0 p-3 md:p-6 rounded-xl'>
            {loadingReview ? <ProductReviewSkeleton /> : (
              <ProductReviews
                data={reviews}
                product={product as IProduct}
                handleFetchProductReviews={handleFetchProductReviews}
              />
            )}
          </div> */}
          {/* Related Products  */}
          {
            relatedProduct.length > 0
            && (
              <div className='mx-4 lg:mx-0 product-related'>
                <ProductSlide refetchData={refetchData} productData={relatedProduct} isLoadingFetch={loadingRelated} title='Sản phẩm liên quan' />
              </div>
            )
          }
        </div>
      </div>
    </>
  );
};

export default ProductDetailContainer;
