/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import PolicyLayout from 'components/templates/PolicyLayout';
import { getPolicyByID } from 'services/policies';

const PolicyDelivery: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [policyRules, setPolicyRules] = React.useState<string>('');
  const fetchPocilyRules = async () => {
    try {
      const res = await getPolicyByID(15);
      setPolicyRules(res?.data?.body);
    } catch (error) {
      //
    }
  };
  useEffect(() => {
    if (location.pathname) {
      fetchPocilyRules();
    } else {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <PolicyLayout heading='Chính sách giao nhận và thanh toán'>
      <div dangerouslySetInnerHTML={{
        __html: policyRules
      }}
      />
    </PolicyLayout>
  );
};

export default PolicyDelivery;
