import { useEffect, useState } from 'react';

import { ProductType } from 'components/organisms/ProductCard';
import { getFeaturedProductsApi, getHotSaleProducts } from 'services/home';
import { useAppSelector } from 'store/hooks';
import { IProduct } from 'types/product';

const useLogout = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [hotSaleProducts, setHotsaleProducts] = useState<ProductType[]>([
    ...new Array(5),
  ]);
  const [hotSaleLoading, setHotSaleLoading] = useState<boolean>(false);
  const [featuredProducts, setFeaturedProducts] = useState<ProductType[]>([
    ...new Array(10),
  ]);
  const [featuredLoading, setFeaturedLoading] = useState<boolean>(false);

  const parseProductsToCart = (products: IProduct[]) => {
    const parseProducts = products?.map((product) => ({
      imageSrc: product?.defaultPictureModel?.imageUrl,
      title: product?.name,
      category: '',
      brand: '',
      price: product?.productPrice?.priceValue,
      rating: product?.reviewOverviewModel?.ratingSum,
      reviews: product?.reviewOverviewModel?.totalReviews,
      id: product.id,
      count: 3,
      description: product?.shortDescription,
      outStock: !product?.inStock,
      alreadySubscribed: product?.alreadySubscribed,
      inWishlist: product?.inWishlist,
      wishlistId: product?.wishlistId,
    }));
    return parseProducts;
  };
  const fetchHotSaleProducts = async () => {
    try {
      setHotSaleLoading(true);
      const response = await getHotSaleProducts();
      const parseProducts = parseProductsToCart(response?.data || []);
      setHotsaleProducts(parseProducts);
      setHotSaleLoading(false);
    } catch (error) {
      //
    }
  };

  const fetchFeaturedProducts = async () => {
    try {
      setFeaturedLoading(true);
      const response = await getFeaturedProductsApi();
      const parseProducts = parseProductsToCart(response?.data || []);
      setFeaturedProducts(parseProducts);
      setFeaturedLoading(false);
    } catch (error) {
      //
    }
  };

  const refetchData = async () => {
    try {
      const promiseArr = [getHotSaleProducts(), getFeaturedProductsApi()];
      const response: any = await Promise.allSettled(promiseArr);
      setHotsaleProducts(parseProductsToCart(response?.[0]?.value.data));
      setFeaturedProducts(parseProductsToCart(response?.[1]?.value.data));
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    fetchHotSaleProducts();
    fetchFeaturedProducts();
  }, [user]);

  return {
    hotSaleProducts,
    refetchData,
    hotSaleLoading,
    featuredProducts,
    featuredLoading,
  };
};

export default useLogout;
