/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IParamsGetCategoriesIds } from '../types/catalog';

import { InputData } from 'components/organisms/NestedCheckbox';
import { ProductType } from 'components/organisms/ProductCard';
import {
  getAllManufacturer,
  getCategoriesApi,
  getProductbyCategoriesIds,
  getProductbyCategory,
} from 'services/catalog';
import { getManufacturersApi } from 'services/home';

const useCategories = () => {
  const [categories, setCategories] = useState<InputData[]>([]);
  const { id } = useParams();
  const [params, setParams] = useState<IParamsGetCategoriesIds>({
    categoryIds: [Number(id)],
    manufacturerIds: [],
    pagenumber: 1,
    pagesize: 5,
    ms: null,
    orderby: 5,
  });

  const [products, setProducts] = useState<ProductType[]>([...new Array(5)]);
  const [categoryName, setCategoryName] = useState<any>('');
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [manufacturers, setManufacturers] = useState<any[]>([]);
  const [loadingProduct, setLoadingProduct] = useState<boolean>(false);

  const fetchCategories = async () => {
    try {
      setCategories([]);
      const response = await getCategoriesApi();
      const categoriesParse = response?.data?.categories.map((item) => ({
        id: item?.id,
        title: item.name,
        count: item?.numberOfProducts || 0,
        checked: item.id === Number(id),
        children: item?.subCategories.map((sub) => ({
          id: sub?.id,
          checked: sub.id === Number(id) || item.id === Number(id),
          title: sub?.name,
          count: sub?.numberOfProducts || 0,
        })),
      }));
      setCategories(categoriesParse as any);
    } catch (error) {
      //
    }
  };

  const fetchManufacturers = async () => {
    try {
      const response: any = await getAllManufacturer();
      const manufacturerParse = response?.data?.map((item: any) => ({
        id: item?.id,
        title: item.name,
        count: item?.numberOfProducts || 0,
        checked: item.id === Number(id),
      }));
      setManufacturers(manufacturerParse);
    } catch (error) {
      //
    }
  };
  const calculateTotalPages = (totalItems: number, pageSize: number): number =>
    Math.ceil(totalItems / pageSize);

  const fetchProductByCategory = useCallback(
    async (refetch = false) => {
      try {
        if (!refetch) {
          setLoadingProduct(true);
        }
        const response: any = await getProductbyCategoriesIds(params);
        const productParse = response?.data.products?.map((product: any) => ({
          imageSrc: product?.defaultPictureModel?.imageUrl,
          title: product?.name,
          category: '',
          brand: '',
          price: product?.productPrice?.priceValue,
          rating: product?.reviewOverviewModel?.ratingSum,
          reviews: product?.reviewOverviewModel?.totalReviews,
          id: product.id,
          count: 3,
          inWishlist: product?.inWishlist,
          description: product?.shortDescription,
          outStock: !product?.inStock,
          alreadySubscribed: product?.alreadySubscribed,
          wishlistId: product?.wishlistId,
        }));
        setTotalPages(
          calculateTotalPages(response.data.totalItems, params.pagesize)
        );
        setTotalRecord(response.data.totalItems);
        // setCategoryName(response.data.name);
        setProducts(productParse);
        setLoadingProduct(false);
      } catch (error) {
        //
      }
    },
    [params]
  );

  useMemo(() => {
    setParams((prev) => ({ ...prev, categoryIds: [Number(id)] }));
  }, [id]);

  useEffect(() => {
    fetchCategories();
    fetchManufacturers();
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchProductByCategory();
    }
  }, [params]);

  return {
    categories,
    products,
    setParams,
    totalRecord,
    params,
    categoryName,
    totalPages,
    manufacturers,
    fetchProductByCategory,
    calculateTotalPages,
    setManufacturers,
    loadingProduct,
  };
};

export default useCategories;
