/* eslint-disable object-curly-newline */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { loginAction, loginActionCases } from './thunks';
import { AuthState } from './types';

import { CustomerInfo } from 'types/users';

const initialState: AuthState = {
  user: null,
  status: 'idle',
  userId: null,
  // provinces: [],
  loggedIn: false,
  isLogout: false,
  codeVerifier: '',
  isLinkZalo: false,
  isSignInZalo: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => ({
      ...state,
      user: null,
    }),
    setUser: ($state, action: PayloadAction<CustomerInfo | null>) => {
      $state.user = action.payload;
    },
    setUserId: ($state, action: PayloadAction<number | null>) => {
      $state.userId = action.payload;
    },
    // setProvinces: ($state, action: PayloadAction<IProvinces[]>) => {
    //   $state.provinces = action.payload;
    // },
    setLoggedIn: ($state, action: PayloadAction<boolean>) => {
      $state.loggedIn = action.payload;
    },
    setIsLogout: ($state, action: PayloadAction<boolean>) => {
      $state.isLogout = action.payload;
    },
    setCodeVerifier: ($state, action: PayloadAction<string>) => {
      $state.codeVerifier = action.payload;
    },
    setIsLinkZalo: ($state, action: PayloadAction<boolean>) => {
      $state.isLinkZalo = action.payload;
    },
    setIsSignInZalo: ($state, action: PayloadAction<boolean>) => {
      $state.isSignInZalo = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(loginAction.fulfilled, loginActionCases.fulfilled);
    builder.addCase(loginAction.rejected, loginActionCases.rejected);
    builder.addCase(loginAction.pending, loginActionCases.pending);
  },
});

// eslint-disable-next-line operator-linebreak
export const {
  logout,
  setUser,
  setUserId,
  setLoggedIn,
  setIsLogout,
  setCodeVerifier,
  setIsLinkZalo,
  setIsSignInZalo,
} = authSlice.actions;

export default authSlice.reducer;
