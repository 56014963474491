import React from 'react';

import TitleBackHandler from 'components/organisms/TitleBackHandler';
import WishlistContainer from 'containers/WishlistContainer';

interface Props {
  onBack?: () => void;
}

const ProductLiked: React.FC<Props> = ({ onBack }) => (
  <div>
    <TitleBackHandler onBack={onBack}>Sản phẩm yêu thích</TitleBackHandler>
    <WishlistContainer />
  </div>
);

export default ProductLiked;
