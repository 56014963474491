/* eslint-disable import/prefer-default-export */
import axiosInstance from 'services/common/instance';
import { IAddToCartRequest, IShoppingCartResponse, IUpdateRequest } from 'types/cart';

export function addToCartApi(
  params: IAddToCartRequest
): Promise<{ data: IShoppingCartResponse }> {
  return axiosInstance.post(`/shoppingCart/AddProductToCart/details/${params.productId}/${params.type}`, params.data);
}

export function getShoppingCartApi(): Promise<{ data: IShoppingCartResponse }> {
  return axiosInstance.get('/shoppingcart/cart');
}

export function updateCartApi(
  params: IUpdateRequest
): Promise<{ data: IShoppingCartResponse }> {
  return axiosInstance.post('/shoppingCart/updatecart', params);
}

export function applyCouponApi(
  params: IUpdateRequest
): Promise<{ data: IShoppingCartResponse }> {
  return axiosInstance.post('/shoppingcart/applydiscountcoupon', params);
}

export function removeCouponApi(
  params: IUpdateRequest
): Promise<{ data: IShoppingCartResponse }> {
  return axiosInstance.post('/shoppingcart/removediscountcoupon', params);
}

export function removeRewardPoint(): Promise<{ data: IShoppingCartResponse }> {
  return axiosInstance.delete('/shoppingcart/removerewardpoints');
}

export function applyCheckoutAttributeApi(
  params: IUpdateRequest
): Promise<{ data: IShoppingCartResponse }> {
  return axiosInstance.post('/shoppingcart/checkoutattributechange', params);
}
