/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { convertAttribute, ProductSummaryType } from '../ProductTable';

import { productVariants } from 'assets/dummy';
import { decodeHtmlEntities } from 'utils/functions';

interface ProductSummaryMobileProps {
  products: ProductSummaryType[];
}

const ProductSummaryMobile: React.FC<ProductSummaryMobileProps> = ({ products }) => {
  const formatPrice = (price: number): string => price.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });
  // Mobile version
  return (
    <div className='t-ProductSummaryMobile bg-white rounded-lg overflow-hidden'>
      {products.map((product) => (
        <div key={product.id} className='flex flex-col border-b p-4'>
          <div className='flex items-center space-x-4'>
            <div className='px-2'>
              <div className='aspect-1 max-w-[72px] rounded-lg overflow-hidden'>
                <img src={product.image} alt={product.name} className='w-full h-full object-cover' />
              </div>
            </div>
            <div className='flex-1'>
              <p className='product-name'>{product.name}</p>
              <div className='flex flex-col gap-2 text-md text-feldgrau'>
                <p>{decodeHtmlEntities(product?.attributeInfo as string).split('<br />').map((pair) => <p>{pair}</p>)}</p>
                <p className='text-gunmetal text-base'>Giá: {formatPrice(product.price)}</p>
                {
                  !!product.discount && <p className='text-neon-fuchsia text-sm'>Tiết kiệm: {formatPrice(product.discount)}</p>
                }
                <p>Số lượng: {product?.count || 0}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>

  );
};

export default ProductSummaryMobile;
