/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-useless-fragment */
import { FC, ReactNode, useEffect } from 'react';

import { profileApi } from 'services/auth/index';
import { getAccessToken } from 'services/common/storage';
import { getProvincesInfoApi } from 'services/profile';
import { setUser } from 'store/auth';
import { setProvinces } from 'store/general';
import { useAppDispatch } from 'store/hooks';

const AuthProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const dispatch = useAppDispatch();
  const token = getAccessToken();

  const getProfile = async () => {
    try {
      const response = await profileApi();
      dispatch(setUser(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  const getProvinces = async () => {
    try {
      const data = await getProvincesInfoApi();
      dispatch(setProvinces(data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      getProfile();
    }
  }, [token]);

    useEffect(() => {
      getProvinces();
  }, []);
   return <>{children}</>;
};

export default AuthProvider;
