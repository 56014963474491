/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import MatchHeight from 'components/organisms/MatchHeight';
import ProductCard, { ProductType } from 'components/organisms/ProductCard';

interface ProductSlideProps {
  title: string;
  productData: ProductType[];
  isHot?: boolean;
  isLoadingFetch?: boolean;
  refetchData?: () => void;
}

const ProductSlide: React.FC<ProductSlideProps> = ({
  title, productData, isHot, isLoadingFetch, refetchData = () => { }
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [minArrayLength, setMinArrayLength] = useState<number>(5);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const sliderRef = useRef<Slider>(null);
  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
    initialSlide: 0,
    beforeChange: (current: number, next: number) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // centerMode: true,
          centerPadding: '20px',
          infinite: false
        },
      },
    ],
  };

  const numItems = productData.length;
  const numSlidesToShow = settings.slidesToShow || 0;

  const showPrevArrow = currentSlide > 0;
  const showNextArrow = currentSlide + numSlidesToShow < numItems;

  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  useEffect(() => {
    if (windowWidth < 1199) {
      setMinArrayLength(3);
    }
    if (windowWidth < 600) {
      setMinArrayLength(1);
    }
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      sliderRef.current?.slickGoTo(0);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [productData]);

  const containerClassName = classNames(
    't-productSlide',
    {
      'bg-neon-fuchsia lg:py-6 py-4': isHot,
    }
  );
  const titleClassname = classNames(
    'text-xl lg:text-2xl font-semibold',
    {
      'text-white font-semibold ml-2': isHot,
    }
  );

  const iconClass = classNames(
    'w-7 h-7',
    { 'group-hover:text-white': !isHot }
  );

  const prevButtonClassname = classNames(
    `w-9 h-9 rounded-lg overflow-hidden flex items-center justify-center cursor-pointer group ${showPrevArrow ? 'bg-white' : 'bg-anti-flash-white2 cursor-not-allowed text-slate-gray'}`,
    { 'hover:bg-blueRyb': !isHot && showPrevArrow }
  );
  const nextButtonClassname = classNames(
    `w-9 h-9 rounded-lg overflow-hidden flex items-center justify-center cursor-pointer group ${showNextArrow ? 'bg-white' : 'bg-anti-flash-white2 cursor-not-allowed text-slate-gray'}`,
    { 'hover:bg-blueRyb': !isHot && showNextArrow }
  );

  const newArray = Array(minArrayLength).fill(null);

  productData.forEach((item, index) => {
    newArray[index] = item;
  });

  return (
    <div className={containerClassName}>
      <div className={isHot ? 'container mx-auto' : ''}>
        <div className='t-productSlide__top flex justify-between'>
          {/* Title  */}
          <h2 className='flex items-center'>
            {
              isHot && (
                <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M8.29446 14L6.52743 21.0681C6.34097 21.814 7.26385 22.324 7.79618 21.7693L19.7916 9.2693C20.2491 8.79255 19.9112 8 19.2505 8H14.791L16.462 2.98717C16.6238 2.50152 16.2624 2 15.7505 2H8.75046C8.4156 2 8.12131 2.22198 8.02931 2.54396L5.02931 13.044C4.89242 13.5231 5.25217 14 5.75046 14H8.29446Z' fill='#F7C100' />
                </svg>
              )
            }
            <span className={titleClassname}>
              {title}
            </span>
          </h2>
          {/* Arrows */}
          <div className='hidden lg:flex gap-4'>
            <button
              className={prevButtonClassname}
              onClick={handlePrevClick}
            >
              <svg className={iconClass} aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m14 8-4 4 4 4' />
              </svg>

            </button>
            <button
              className={nextButtonClassname}
              onClick={handleNextClick}
            >
              <svg className={iconClass} aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m10 16 4-4-4-4' />
              </svg>
            </button>
          </div>
        </div>
        <div className='t-productSlide__slider mt-2 mx-[-8px]'>
          <MatchHeight matchClassName='o-productCard__title'>
            <div className='relative overflow-hidden'>
              <Slider {...settings} ref={sliderRef}>
                {newArray.map((product, index) => (!product ? <div key={`item-${index.toString()}`} /> : (
                  <div key={`item-${index.toString()}`} className='p-1 lg:p-2'>
                    <ProductCard refetchData={refetchData} data={product} isLoadingFetch={isLoadingFetch} />
                  </div>
                )))}
              </Slider>
            </div>
          </MatchHeight>
        </div>
      </div>
    </div>
  );
};

export default ProductSlide;
