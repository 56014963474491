/* eslint-disable import/prefer-default-export */
import axios, { AxiosRequestConfig } from 'axios';

import axiosInstance from 'services/common/instance';
import {
  ISigninOrSignupResponse,
  ISigninOrSignup,
  IVerifyOtp,
  IResendOtp,
  ICheckUserExist,
  ICheckUserExistResponse,
  ISigninOrSignupSocial,
  IParamsLinkSocial,
  // IParamsUnlinkSocial,
} from 'types/auth';
import { CheckPasswordData, CustomerInfo } from 'types/users';

export function signinOrSignupApi(
  params: ISigninOrSignup
): Promise<ISigninOrSignupResponse> {
  return axiosInstance.post('/auth/signin_signup', params);
}

export function checkUserExistApi(
  params: ICheckUserExist
): Promise<ICheckUserExistResponse> {
  return axiosInstance.post('/auth/check_user', params);
}
export function profileApi(): Promise<{ data: CustomerInfo }> {
  return axiosInstance.get('/profile');
}

export function verifyOtpApi(
  params: IVerifyOtp
): Promise<ISigninOrSignupResponse> {
  return axiosInstance.post('/auth/verify_otp', params);
}

export function resendOtpApi(
  params: IResendOtp
): Promise<ISigninOrSignupResponse> {
  return axiosInstance.post('/auth/resend_otp', params);
}

export function resendProfileOtpApi(
  params: IResendOtp
): Promise<ISigninOrSignupResponse> {
  return axiosInstance.post('/profile/resend_otp', params);
}

export function signinOrSignupSocialApi(
  params: ISigninOrSignupSocial
): Promise<ISigninOrSignupResponse> {
  return axiosInstance.post('/auth/signin_signup_social', params);
}

export function logoutApi(): Promise<{ data: CustomerInfo }> {
  return axiosInstance.get('/customer/logout');
}

export async function getAccessTokenFromZaloApi(url: any, params?: any) {
  let response: any = null;
  response = axios({
    method: 'post',
    url: url as string,
    data: params,
    headers: {
      secret_key: process.env.REACT_APP_SECRET_ZALO,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  } as AxiosRequestConfig<any>);
  return response;
}

export async function sendOTPForgotPasswordApi(params: any) {
  return axiosInstance.post('/auth/forgot-password', params);
}

export async function verifyOTPForgotPasswordApi(params: any) {
  return axiosInstance.post('/auth/verify_otp', params);
}

export async function resetPasswordApi(params: any) {
  return axiosInstance.patch('/auth/reset-password', params);
}

export function checkUserPasswordApi(): Promise<CheckPasswordData> {
  return axiosInstance.get('/profile/check_password');
}

export async function setPasswordForNewUserApi(params: any) {
  return axiosInstance.post('/profile/password', params);
}
export function linkSocial(params: IParamsLinkSocial): Promise<{}> {
  return axiosInstance.post('/profile/link_social', params);
}

// export function unlinkSocial(
//   params: IParamsUnlinkSocial
// ): Promise<{ data: any }> {
//   return axiosInstance.post('/profile/unlink_social', params);
// }
