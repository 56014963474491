/* eslint-disable import/prefer-default-export */
import axiosInstance from 'services/common/instance';
import { IDataOrderDetail, IOrder } from 'types/order';

export function getOrderData(): Promise<{ data: IOrder }> {
  return axiosInstance.get('/order/history');
}

export function getOrderDetailData(
  params: string
): Promise<{ data: IDataOrderDetail }> {
  return axiosInstance.get(`/order/orderdetails/${params}`);
}

export function reOrderApi(
  id: string
): Promise<{ data: IDataOrderDetail }> {
  return axiosInstance.get(`/order/reorder/${id}`);
}
