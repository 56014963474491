/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Suspense, useEffect, useState } from 'react';

import { PartnersSlideSkeleton } from 'components/atoms/Skeleton';
import PartnersSlide from 'components/templates/PartnersSlide';
import { getManufacturersApi } from 'services/home';
import { IManufacturer } from 'types/home';

const ManufacturesSlideContainer: React.FC = () => {
  const [manufacturers, setManufacturers] = useState<IManufacturer[]>([...new Array(5)]);
  const [loading, setLoading] = useState(true);
  const fetchManufacturers = async () => {
    try {
      setLoading(true);
      const response = await getManufacturersApi();
      setManufacturers(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchManufacturers();
  }, []);

  if (loading) {
    return (
      <div className='container mx-auto'>
        <PartnersSlideSkeleton />;
      </div>
    );
  }

  return (
    <div className='container mx-auto'>
      <PartnersSlide
        data={manufacturers?.map((manufacturer) => ({
          title: manufacturer?.name,
          logo: manufacturer?.pictureModel?.imageUrl,
          id: manufacturer?.id,
        }))}
      />
    </div>
  );
};

export default ManufacturesSlideContainer;
