/* eslint-disable no-return-assign */
import React, {
  forwardRef, useState, useRef, useEffect
} from 'react';

interface VerificationInputProps {
  error?: string;
  onChange?: (value: string) => void;
  isResend?: boolean;
}

const VerificationInputRef: React.ForwardRefRenderFunction<{}, VerificationInputProps> = ({
  error,
  onChange,
  isResend
}) => {
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (value: string, index: number) => {
    if (/^\d*$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      const joinedCode = newCode.join('');
      if (onChange) {
        onChange(joinedCode);
      }
      // Move to next input if a digit is entered
      if (value && index < code.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'ArrowRight' && index < code.length - 1) {
      inputRefs.current[index + 1]?.focus();
    } else if (e.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    } else if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData('text');

    if (/^[0-9]+$/.test(pasteData)) {
      const newCode = pasteData.split('').slice(0, 6);

      newCode.forEach((char, index) => {
        const input = inputRefs.current[index];
        if (input) {
          input.value = char;
        }
      });

      setCode(newCode);

      if (onChange) {
        onChange(newCode.join(''));
      }
    }
    e.preventDefault();
  };

  useEffect(() => {
    // Auto-focus on the first input on mount
    inputRefs.current[0]?.focus();
  }, []);

  useEffect(() => {
    setCode(['', '', '', '', '', '']);
  }, [isResend]);

  return (
    <div>
      <div className='flex space-x-4 justify-center px-0 md:px-4'>
        {code.map((digit, index) => (
          <input
            key={`verify-${String(index)}`}
            type='text'
            value={digit}
            maxLength={1}
            placeholder='0'
            onPaste={(e) => handlePaste(e)}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(el) => inputRefs.current[index] = el}
            className={`p-0 text-md w-12 h-12 sm:text-lg lg:w-14 lg:h-14 lg:text-3xl font-semibold text-center text-[#2E3538] placeholder:text-[#9A9EAC] rounded-md border ${error
              ? 'border-carmine-pink' // Error state
              : 'border-gainsboro' // Normal state
              } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
          />
        ))}
      </div>
      {error && (
        <p className='text-carmine-pink flex justify-center text-sm mt-2  px-0 md:px-4'>
          {error}
        </p>
      )}
    </div>
  );
};

const VerificationInput = forwardRef(VerificationInputRef);

export default VerificationInput;
