import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Modal from 'components/organisms/Modal';
import { addNewPasswordApi, updatePasswordApi } from 'services/profile';
import { changePasswordLoginedSchema } from 'utils/schemas';

interface ChangePasswordForm {
  oldPassword: string;
  password: string;
  rePassword: string;
}

interface ModalChangePasswordProps {
  isOpen: boolean;
  handleClose: () => void;
  hasPassword: boolean;
  setHasPassword: (value: boolean) => void;
}

const ModalChangePassword: React.FC<ModalChangePasswordProps> = ({
  isOpen,
  handleClose,
  hasPassword,
  setHasPassword
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    control, handleSubmit, reset
  } = useForm<ChangePasswordForm>({
    mode: 'onChange',
    resolver: yupResolver(changePasswordLoginedSchema),
    context: { hasPassword },
    defaultValues: {
      oldPassword: '',
      password: '',
      rePassword: '',
    },
  });

  const onClose = () => {
    reset();
    handleClose();
  };

  const onSubmit = async (data: ChangePasswordForm) => {
    try {
      setLoading(true);
      if (hasPassword) {
        await updatePasswordApi({ Password: data.password, OldPassword: data.oldPassword });
      } else {
        await addNewPasswordApi({ Password: data.password });
        setHasPassword(true);
      }
        toast.success('Cập nhật mật khẩu thành công');
      onClose();
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
      setLoading(false);
    }
  };

  return (
    <Modal
      isOverflow
      isOpen={isOpen}
      onRequestClose={() => {
        onClose();
      }}
      className='max-w-[480px] !h-auto'
      title='Thay đổi mật khẩu'
      modalFooter={(
        <div className='flex flex-row gap-4 justify-end'>
          <Button
            onClick={onClose}
            size='large'
            variants='secondary'
            className='max-w-[120px]'
          >
            Quay lại
          </Button>
          <Button
            loading={loading}
            type='button'
            size='large'
            onClick={() => handleSubmit(onSubmit)()}
            className='max-w-[140px]'
          >
            Cập nhật
          </Button>
        </div>
      )}
    >

      <form onSubmit={handleSubmit(onSubmit)} className='bp mt-3 md:mt-4'>
        {
          hasPassword && (
          <div>
            <label className='block text-[#1F2128] text-sm font-semibold mb-2' htmlFor='password'>Mật khẩu hiện tại</label>
            <Controller
              name='oldPassword'
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  placeholder='Nhập mật khẩu hiện tại'
                  error={fieldState?.error?.message}
                  type='password'
                />
              )}
            />
          </div>
          )
        }
        <div className='mt-4'>
          <label className='block text-[#1F2128] text-sm font-semibold mb-2' htmlFor='password'>Mật khẩu mới</label>
          <Controller
            name='password'
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder='Nhập mật khẩu mới'
                error={fieldState?.error?.message}
                type='password'
              />
            )}
          />
        </div>
        <div className='mt-4'>
          <label className='block text-[#1F2128] text-sm font-semibold mb-2' htmlFor='password'>Nhập lại mật khẩu mới</label>
          <Controller
            name='rePassword'
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder='Nhập lại mật khẩu mới'
                error={fieldState?.error?.message}
                type='password'
              />
            )}
          />

        </div>
      </form>
    </Modal>
  );
};

export default ModalChangePassword;
