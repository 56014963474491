// StarRating.tsx
import React, { useState } from 'react';

import Icon, { IconSize } from 'components/atoms/Icon';

type StarRatingProps = {
  count?: number;
  value: number;
  isResponsive?: boolean;
  isStatic?: boolean;
  size?: IconSize
  onChange?: (value: number) => void;
  onClick?: () => void;
};

type StarProps = {
  filled: boolean;
  onClick: () => void;
  onMouseOver: () => void;
  onMouseLeave: () => void;
  isResponsive?: boolean;
  index: number;
  value: number;
  isStatic?: boolean;
  size?: IconSize
};

const Star: React.FC<StarProps> = ({
  filled, onClick, onMouseOver, onMouseLeave, isResponsive, index, value, isStatic, size
}) => (
  <button
    className={`text-2xl ${isStatic ? ' pointer-events-none' : ' cursor-pointer'} ${isResponsive && index !== 0 ? 'hidden sm:block' : ''}`}
    onClick={onClick}
    onMouseOver={() => !isStatic && onMouseOver()}
    onFocus={() => !isStatic && onMouseOver()}
    onMouseLeave={() => !isStatic && onMouseLeave()}
    tabIndex={0}
    type='button'
  >
    <div className='flex items-center'>
      <Icon iconName={filled ? 'filledStar' : 'star'} size={size || '16x16'} />
      <span className={`${isResponsive ? 'block sm:hidden' : 'hidden'} text-sm lg:text-md text-feldgrau ml-1`}>{value}</span>
    </div>
  </button>
);

const StarRating: React.FC<StarRatingProps> = ({
  count, value, onChange, isResponsive, isStatic, size, onClick
}) => {
  const [hoverValue, setHoverValue] = useState<number | undefined>(undefined);

  const handleClick = (index: number) => {
    onChange?.(index + 1);
  };

  const handleMouseOver = (index: number) => {
    setHoverValue(index + 1);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  return (
    <div className={`flex flex-row gap-1 ${onClick ? 'cursor-pointer' : ''}`} onClick={onClick}>
      {Array.from({ length: count ?? 5 }, (_, index) => (
        <Star
          key={index}
          filled={hoverValue !== undefined ? index < hoverValue : index < value}
          onClick={() => handleClick(index)}
          onMouseOver={() => handleMouseOver(index)}
          onMouseLeave={handleMouseLeave}
          isResponsive={isResponsive}
          index={index}
          value={value}
          isStatic={isStatic}
          size={size}
        />
      ))}
    </div>
  );
};

export default StarRating;
