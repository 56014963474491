/* eslint-disable import/prefer-default-export */
import axiosInstance from 'services/common/instance';
import { IDataWishList, IWishListParams } from 'types/wishlist';

export function getDataWishListApi(): Promise<{ data: IDataWishList }> {
  return axiosInstance.get('/shoppingcart/wishlist');
}

export function moveWishListToCart(
  params: IWishListParams
): Promise<IDataWishList> {
  return axiosInstance.post('/shoppingcart/additemstocartfromwishlist', params);
}

export function updateFromWishList(
  params: IWishListParams
): Promise<{ data: IDataWishList }> {
  return axiosInstance.post('/shoppingcart/updatewishlist', params);
}
