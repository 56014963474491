/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import OrderCompletedContainer from 'containers/OrderCompletedContainer';
import { useAppSelector } from 'store/hooks';

const OrderCompleted: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { orderId } = useAppSelector((state) => state.cart);
  useEffect(() => {
    if (!searchParams?.get('vnp_TxnRef')?.split('_')?.[0] && !orderId && !searchParams?.get('orderId')) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
  return (
    <div className='p-orderCompleted bg-page pt-6'>
      <OrderCompletedContainer orderId={searchParams?.get('vnp_TxnRef')?.split('_')?.[0] || searchParams?.get('orderId') || orderId} />
    </div>
  );
};

export default OrderCompleted;
