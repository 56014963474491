import React, { useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import couponEdgeMobileImg from 'assets/images/coupon-edge-mobile.png';
import couponEdgeImg from 'assets/images/coupon-edge.png';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import ContentPanel from 'components/organisms/ContentPanel';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { ICounpon } from 'types/checkout';
import { formatDateDDMMYYYY } from 'utils/functions';

export type CouponType = {
  code: string;
  remaining: number;
  discount: string;
  minimumOrder: string;
  expirationDate: string;
  content: string;
};

interface CouponItemProps {
  data: ICounpon;
  onClick?: (code: string) => void;
  selected?: boolean;
  disabled?: boolean;
}
interface CouponPickerProps {
  data?: ICounpon[];
  selectedVoucher?: string;
  handleRemoveCoupon?: () => void;
  onSelect?: (data: ICounpon, noUpdate?: boolean) => void;
  isRepayment?: boolean
}

const CouponItem: React.FC<CouponItemProps> = ({
  data,
  onClick,
  selected,
  disabled,
}) => (
  <button
    className={`t-couponItem w-full${disabled ? ' cursor-not-allowed' : ''}`}
    onClick={() => !disabled && onClick?.(data.couponCode)}
  >
    <div className='flex border border-gainsboro rounded-md border-l-0 overflow-hidden lg:max-w-[272px]'>
      <div
        className={`aspect-34/100 max-w-[34px] max-h-[100px]${selected ? ' border-r-1 border-blueRyb border-dashed' : ''
          }${disabled ? ' mix-blend-luminosity' : ''}`}
      >
        <picture>
          <source media='(min-width:1024px)' srcSet={couponEdgeImg} />
          <img
            src={couponEdgeMobileImg}
            alt='edge'
            className='w-full h-full object-cover'
          />
        </picture>
      </div>
      <div
        className={`flex-1 flex flex-col text-left justify-between px-3 py-2 lg:h-[100px]${selected ? ' bg-blueRyb' : ''
          }`}
      >
        <div>
          <div className={`font-semibold ${selected ? ' text-white' : ''}`}>
            {data.couponCode}
            {/* <span
              className={`text-sm ${selected ? ' text-white' : 'text-feldgrau'
                }`}
            >
              {data?.discountLimitation > 0
                ? `(Còn ${data.discountLimitation})`
                : ''}
            </span> */}
          </div>
          <div
            className={`text-sm mt-1${selected ? ' text-white' : ' text-gunmetal'
              }`}
          >
            {data?.name}
          </div>
        </div>
        <div
          className={`text-sm ${selected ? ' text-white' : 'text-feldgrau'}`}
        >
          HSD: {formatDateDDMMYYYY(data?.endDateUtc)}
        </div>
      </div>
    </div>
  </button>
);

const CouponPicker: React.FC<CouponPickerProps> = ({
  data,
  selectedVoucher,
  onSelect,
  handleRemoveCoupon,
  isRepayment
}) => {
  const [codeInput, setCodeInput] = useState(selectedVoucher || '');
  const sliderRef = useRef<Slider>(null);
  const { width } = useWindowDimensions();
  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
  };

  const onHandleSelect = (c: string, index: number) => {
    if (c === codeInput) {
      setCodeInput('');
      return;
    }
    setCodeInput(c);
    sliderRef.current?.slickGoTo(index);
  };
  const onConfirm = () => {
    const voucher = data?.find((f) => f.couponCode === codeInput);
    if (voucher) onSelect?.(voucher);
  };
  const handleClear = () => {
    setCodeInput('');
    handleRemoveCoupon?.();
  };
  return (
    <div className='t-couponPicker'>
      <ContentPanel title='Mã giảm giá'>
        <div className='flex flex-col-reverse lg:flex-col'>
          {width >= 1024 ? (
            <Slider {...settings} className='mt-3 lg:mt-0'>
              {data?.map((item, index) => (
                <div key={`item-${index.toString()}`} className='p-1 md:p-2'>
                  <CouponItem
                    data={item}
                    disabled={new Date() > new Date(item?.endDateUtc) || isRepayment}
                    selected={item.couponCode === codeInput}
                    onClick={(d) => onHandleSelect(d, index)}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <div className='mt-3'>
              {data?.map((item, index) => (
                <div key={`item-${index.toString()}`} className='p-1 md:p-2'>
                  <CouponItem
                    data={item}
                    disabled={new Date() > new Date(item?.endDateUtc) || isRepayment}
                    selected={item.couponCode === codeInput}
                    onClick={(d) => onHandleSelect(d, index)}
                  />
                </div>
              ))}
            </div>
          )}
          <div className='input lg:mt-4'>
            <p className='text-sm text-gunmetal'>Nhập mã giảm giá tại đây.</p>
            <div className='flex mt-1 gap-x-2'>
              <div className='w-full'>
                <Input
                  value={codeInput}
                  onChange={(e) => {
                    setCodeInput(e.target.value);
                  }}
                  handleClear={handleClear}
                  placeholder='Nhập mã giảm giá'
                  className={`${codeInput ? ' font-semibold uppercase' : ''}`}
                  disabled={isRepayment}
                />
              </div>
              <Button
                type='button'
                size='large'
                disabled={!codeInput || codeInput === selectedVoucher || isRepayment}
                className={`button-base-style max-w-[113px] ${codeInput && codeInput !== selectedVoucher
                  ? 'text-white bg-blueRyb hover:bg-blue-400'
                  : 'bg-anti-flash-white2 text-slate-gray'
                  }`}
                onClick={onConfirm}
              >
                Áp dụng
              </Button>
            </div>
            {selectedVoucher && (
              <p className='text-md text-medium-turquoise mt-1'>
                Mã giảm giá đã được áp dụng
              </p>
            )}
          </div>
        </div>
      </ContentPanel>
    </div>
  );
};

export default CouponPicker;
