import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';

import Information from './information';

import EditImage from 'assets/icons/ic_edit.svg';
import userAvatar from 'assets/images/user_avatar.png';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Radio from 'components/atoms/Radio';
import Pulldown, { OptionType } from 'components/molecules/Pulldown';
import TitleBackHandler from 'components/organisms/TitleBackHandler';
import { checkUserPasswordApi, profileApi } from 'services/auth';
import { getAvatarApi, updateAvatarApi, updateProfileApi } from 'services/profile';
import { setUser } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { dayOptions, monthOptions, yearOptions } from 'utils/constants';
import { convertDayToDD } from 'utils/functions';

type FormValues = {
  avatar: FileList | undefined;
  lastName: string;
  firstName: string;
  companyName: string;
  day: OptionType | undefined;
  month: OptionType | undefined;
  year: OptionType | undefined;
  gender: 'M' | 'F';
};

interface UserInfoProps {
  onBack: () => void;
}

const UserInfo: React.FC<UserInfoProps> = ({ onBack }) => {
  const { user } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      avatar: undefined,
      lastName: undefined,
      firstName: undefined,
      companyName: undefined,
      day: undefined,
      month: undefined,
      year: undefined,
      gender: undefined,
    },
  });
  const [preview, setPreview] = useState<string>(userAvatar);
  const [hasPassword, setHasPassword] = useState<boolean>(false);

  const onSubmit = async (data: FormValues) => {
    try {
      setLoading(true);
      await updateProfileApi({
        Gender: data.gender,
        FirstName: data.firstName,
        LastName: data.lastName,
        Company: data.companyName,
        DateOfBirthDay: data.day,
        DateOfBirthMonth: data.month,
        DateOfBirthYear: data.year,
        Phone: user?.phone as string,
        Email: user?.email as string,
      });
      const profile = await profileApi();
      dispatch(setUser(profile.data));
      toast.success('Cập nhật thông tin tài khoản thành công');
      setLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
      setLoading(false);
    }
  };

  const fetchAvatarUser = async () => {
    try {
      const response = await getAvatarApi();
      const avatarUrl = response?.data?.avatarUrl;
      if (avatarUrl) {
        setPreview(`${avatarUrl}?t=${new Date().getTime()}`);
      }
    } catch (error: any) {
      //
    }
  };

  const checkUserPassword = async () => {
    try {
      const response = await checkUserPasswordApi();
      setHasPassword(response?.isPasswordSet || false);
    } catch (error: any) {
      //
    }
  };

  useEffect(() => {
    fetchAvatarUser();
    reset({
      avatar: undefined,
      lastName: user?.lastName,
      firstName: user?.firstName,
      companyName: user?.company,
      day: user?.dateOfBirthDay
        ? (convertDayToDD(user?.dateOfBirthDay) as any)
        : undefined,
      month: user?.dateOfBirthMonth
        ? (convertDayToDD(user?.dateOfBirthMonth) as any)
        : undefined,
      year: user?.dateOfBirthYear,
      gender: user?.gender,
    });
    checkUserPassword();
  }, [user]);

  const handleUploadAvatar = async (formData: FormData) => {
    try {
      await updateAvatarApi(formData);
      toast.success('Cập nhật ảnh đại diện thành công');
      fetchAvatarUser();
    } catch (error: any) {
      toast.error('Đã xảy ra lỗi!');
      fetchAvatarUser();
    }
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: (value: FileList) => void
  ) => {
    try {
      const formData = new FormData();
      const file = event.target.files?.[0];
      if (file) {
        formData.append('avatar', file, String(file?.name));
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result as string);
          onChange(event.target.files as FileList);
        };
        reader.readAsDataURL(file);
        await handleUploadAvatar(formData);
      }
    } catch (error) {
      //
    }
  };

  return (
    <>
      <TitleBackHandler onBack={onBack}>Thông tin khách hàng</TitleBackHandler>
      <div className='flex flex-col xl:flex-row space-y-4 xl:space-y-0 xl:space-x-4 bg-white p-4 xl:p-6 rounded-xl'>
        <form onSubmit={handleSubmit(onSubmit)} className='xl:flex-1'>
          <p className='text-[#2E3538] text-base font-semibold mb-2'>
            Ảnh đại diện
          </p>
          <div className='flex justify-center mb-6'>
            <div className='relative'>
              <img
                src={preview}
                alt='Profile'
                className='w-24 h-24 rounded-full border-[4px] border-[#00A6931A] object-cover'
              />
              <Controller
                name='avatar'
                control={control}
                render={({ field: { onChange } }) => (
                  <input
                    type='file'
                    id='avatar'
                    className='hidden'
                    onChange={(e) => handleImageChange(e, onChange)}
                  />
                )}
              />
              <label
                htmlFor='avatar'
                className='absolute bottom-0 right-0 text-white rounded-full cursor-pointer'
              >
                <img src={EditImage} alt='Edit' />
              </label>
            </div>
          </div>
          <div className='flex space-x-2 mb-4'>
            <div className='flex-1'>
              <label
                htmlFor='lastName'
                className='font-semibold text-[2E3538] text-base'
              >
                Họ
              </label>
              <Controller
                name='lastName'
                control={control}
                rules={{ required: 'Họ là bắt buộc' }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    placeholder='Nhập họ'
                    className='mt-2'
                  />
                )}
              />
              {errors.lastName && (
                <span className='error inline-block text-carmine-pink text-sm'>
                  {errors.lastName.message}
                </span>
              )}
            </div>

            <div className='flex-1'>
              <label
                htmlFor='firstName'
                className='font-semibold text-[2E3538] text-base'
              >
                Tên
              </label>
              <Controller
                name='firstName'
                control={control}
                rules={{ required: 'Tên là bắt buộc' }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    placeholder='Nhập tên'
                    className='mt-2'
                  />
                )}
              />
              {errors.firstName && (
                <span className='error inline-block text-carmine-pink text-sm'>
                  {errors.firstName.message}
                </span>
              )}
            </div>
          </div>

          <div className='mb-4'>
            <label
              htmlFor='companyName'
              className='font-semibold text-[2E3538] text-base'
            >
              Tên công ty
            </label>
            <Controller
              name='companyName'
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type='text'
                  placeholder='Nhập tên công ty'
                  className='mt-2'
                />
              )}
            />
          </div>

          <div className='mb-4'>
            <label className='font-semibold text-[2E3538] text-base'>
              Ngày sinh
            </label>
            <div className='flex space-x-2 mt-2'>
              <div className='flex-1'>
                <Controller
                  name='day'
                  rules={{ required: 'Ngày là bắt buộc' }}
                  control={control}
                  render={({ field }) => (
                    <Pulldown
                      {...field}
                      placeholder='Chọn ngày'
                      options={dayOptions}
                    />
                  )}
                />
                {errors.day && (
                  <span className='error inline-block text-carmine-pink text-sm'>
                    {errors.day.message}
                  </span>
                )}
              </div>
              <div className='flex-1'>
                <Controller
                  name='month'
                  rules={{ required: 'Tháng là bắt buộc' }}
                  control={control}
                  render={({ field }) => (
                    <Pulldown
                      {...field}
                      placeholder='Chọn tháng'
                      options={monthOptions}
                    />
                  )}
                />
                {errors.month && (
                  <span className='error inline-block text-carmine-pink text-sm'>
                    {errors.month.message}
                  </span>
                )}
              </div>
              <div className='flex-1'>
                <Controller
                  name='year'
                  rules={{ required: 'Năm là bắt buộc' }}
                  control={control}
                  render={({ field }) => (
                    <Pulldown
                      {...field}
                      placeholder='Chọn năm'
                      options={yearOptions}
                    />
                  )}
                />
                {errors.year && (
                  <span className='error inline-block text-carmine-pink text-sm'>
                    {errors.year.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className='mb-6 xl:mb-8'>
            <label className='font-semibold text-[2E3538] text-base'>
              Giới tính
            </label>
            <div className='flex space-x-4 mt-2'>
              <Controller
                name='gender'
                control={control}
                rules={{ required: 'Giới tính là bắt buộc' }}
                render={({ field }) => (
                  <>
                    <Radio
                      label='Nam'
                      value='M'
                      checked={field.value === 'M'}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                    <Radio
                      label='Nữ'
                      value='F'
                      checked={field.value === 'F'}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  </>
                )}
              />
            </div>
            {errors.gender && (
              <span className='error inline-block text-carmine-pink text-sm'>
                {errors.gender.message}
              </span>
            )}
          </div>
          <Button loading={loading} type='submit' size='large' className='md:max-w-[140px]'>
            Lưu
          </Button>
        </form>
        <Information hasPassword={hasPassword} setHasPassword={setHasPassword} />
      </div>
    </>
  );
};

export default UserInfo;
