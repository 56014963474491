/* eslint-disable max-len */
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';

// Define the structure of a node
interface Node {
  id: number;
  label: string;
  checked: boolean;
  childrenNodes: Node[];
  parent: Node | null;
  count?: number;
}

// Define the structure of the input data
export interface InputItem {
  id: number;
  title: string;
  count?: number;
  checked?: boolean;
  children?: InputItem[];
}

export type InputData = InputItem[];

// Transform input data into a tree of nodes
const transform = (data: InputData, parent: Node | null = null): Node[] => data.map((item: any) => {
  const node: Node = {
    id: item.id,
    label: item.title,
    checked: !!item.checked,
    childrenNodes: [],
    parent,
    count: item.count
  };

  if (item.children?.length > 0) {
    const children = transform(item.children, node);
    node.childrenNodes = children;
    if (children.every((child) => child.checked)) {
      node.checked = true;
    }
  }

  return node;
});

// Update ancestor nodes based on current node state
const updateAncestors = (node: Node) => {
  if (!node.parent) {
    return;
  }

  const { parent } = node;
  if (parent.checked && !node.checked) {
    parent.checked = false;
    updateAncestors(parent);
    return;
  }

  if (!parent.checked && node.checked) {
    if (parent.childrenNodes.every((child) => child.checked)) {
      parent.checked = true;
      updateAncestors(parent);
    }
  }
};

// Toggle descendant nodes based on current node state
const toggleDescendants = (node: Node) => {
  const { checked } = node;

  node.childrenNodes.forEach((child) => {
    // eslint-disable-next-line no-param-reassign
    child.checked = checked;
    toggleDescendants(child);
  });
};

// const setCheckedToFalse = (items: any, id?: number) => items.map((item: any) => {
//   if (id === item.id) {
//     return item;
//   }
//   const newItem = { ...item, checked: false };

//   // If the item has children, apply the function recursively
//   if (newItem?.childrenNodes?.length > 0) {
//     // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-shadow
//     newItem.childrenNodes = newItem.childrenNodes?.map((item: any) => ({ ...item, checked: id === item.id }));
//   }

//   return newItem;
// });

// Find a specific node by id
const findNodeById = (nodes: Node[], id: number): Node | undefined => {
  for (const node of nodes) {
    if (node.id === id) {
      return node;
    }

    const found = findNodeById(node.childrenNodes, id);
    if (found) {
      return found;
    }
  }

  return undefined;
};

interface NestedCheckboxProps {
  data: InputData;
  onCheckedChange: (checkedItems: { id: number; title: string }[], id: any) => void;
  title?: string;
}

interface NestedCheckboxHelperProps {
  nodes: Node[];
  onBoxChecked: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// Helper component to render nested checkboxes recursively
const NestedCheckboxHelper: React.FC<NestedCheckboxHelperProps> = ({
  nodes,
  onBoxChecked
}) => (

  <ul className='list-none pl-2 space-y-2 pt-2'>
    {nodes.map(({
      id, label, checked, childrenNodes, count
    }) => {
      let children = null;
      if (childrenNodes.length > 0) {
        children = (
          <NestedCheckboxHelper
            nodes={childrenNodes}
            onBoxChecked={onBoxChecked}
          />
        );
      }

      return (
        <li
          key={id}
        >
          <div className={classNames('hover:bg-alice-blue space-2 rounded px-2 py-1', {
            'bg-blue-100': checked,
          })}
          >
            <input
              type='checkbox'
              name={label}
              id={id.toString()}
              value={id.toString()}
              checked={checked}
              onChange={onBoxChecked}
              className='form-checkbox h-4 w-4 text-primary border-manatee  rounded cursor-pointer hover:roman-silver-200 focus:outline-none focus:ring-0 focus:ring-offset-0 undefined bg-white boreder-1'
            />
            <label htmlFor={id.toString()} className='ml-2 text-gunmetal text-md cursor-pointer'>
              {label} {count ? <span className='text-roman-silver'>({count})</span> : ''}
            </label>
          </div>
          {children}
        </li>
      );
    })}
  </ul>
);

// Main component to render nested checkboxes
const NestedCheckbox: React.FC<NestedCheckboxProps> = ({
  data,
  onCheckedChange,
  title
}) => {
  const initialNodes = transform(data);
  const [nodes, setNodes] = useState<Node[]>(initialNodes);
  const handleBoxChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    const id = parseInt(e.currentTarget.value, 10);
    const node = findNodeById(nodes, id);
    if (node) {
      node.checked = checked;
      toggleDescendants(node);
      updateAncestors(node);
      setNodes(cloneDeep(nodes));

      // Gather all currently checked nodes
      const checkedValues: { id: number; title: string }[] = [];
      const collectCheckedNodes = (nodesArr: Node[]) => {
        nodesArr.forEach((no) => {
          if (no.checked) {
            checkedValues.push({ id: no.id, title: no.label });
          }
          if (no.childrenNodes.length > 0) {
            collectCheckedNodes(no.childrenNodes);
          }
        });
      };

      collectCheckedNodes(nodes);

      // Call the callback function with the updated checked values
      onCheckedChange(checkedValues, checked ? id : checkedValues?.[0]?.id || null);
    }
  };
  return (
    <div className='o-nestedCheckbox bg-white rounded-lg'>
      {
        title
        && (
          <div className='px-4 lg:px-6 py-3 border-b-1 border-gainsboro'>
            <h3 className='font-semibold text-raisin-black'>{title}</h3>
          </div>
        )
      }
      <div className='px-2 py-2 lg:py-4'>

        <NestedCheckboxHelper nodes={nodes} onBoxChecked={handleBoxChecked} />
      </div>
    </div>
  );
};

export default NestedCheckbox;
