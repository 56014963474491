/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-useless-fragment */
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';

import { getShoppingCartApi } from 'services/cart';
import { getDataWishListApi } from 'services/wishlist';
import { setCartData, setDiscount, setOrderTotals } from 'store/cart';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLoading, setWishListData } from 'store/wishlist';
import { parseOrderTotal } from 'utils/functions';

export interface ICartContext {
  handleFetchShoppingCart: () => Promise<void>;
  handleFetchWishList: () => Promise<void>;
}

export const CartContext = createContext<ICartContext | undefined>(undefined);

const CartProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handleFetchShoppingCart = useCallback(async () => {
    try {
      const response = await getShoppingCartApi();
      dispatch(setCartData(response?.data?.cart?.items));
      // dispatch(setOrderTotals(response?.data?.orderTotals));
      dispatch(
        setOrderTotals(
          parseOrderTotal(
            response?.data?.cart?.items,
            response?.data?.orderTotals
          )
        )
      );
      dispatch(setDiscount(response?.data?.cart?.discountBox?.appliedDiscountsWithCodes?.[0]));
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  }, [user]);

  const handleFetchWishList = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const response = await getDataWishListApi();
      dispatch(setWishListData(response.data.items.map((item) => ({
        id: item.productId,
        name: item.productName,
        image: item.picture.imageUrl,
        price: item.unitPriceValue,
        discount: item.discountValue,
        count: item.quantity,
        attributeInfo: item.attributeInfo,
        wishlistId: item.id,
        productAttributes: item.productAttributes,
      }))));
      dispatch(setLoading(false));
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  }, [user]);

  useEffect(() => {
    handleFetchShoppingCart();
    handleFetchWishList();
  }, [user]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CartContext.Provider value={{ handleFetchShoppingCart, handleFetchWishList }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
