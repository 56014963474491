import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  verify: 'verify',
  verifyTurquoise: 'verifyTurquoise',
  whiteSearch: 'whiteSearch',
  cart: 'cart',
  profileCircle: 'profileCircle',
  call: 'call',
  star: 'star',
  filledStar: 'filledStar',
  filledHeart: 'filledHeart',
  outlinedHeart: 'outlinedHeart',
  outlinedHeart2: 'outlinedHeart2',
  trashOutlined: 'trashOutlined',
  tick: 'tick',
  circleTick: 'circleTick',
  chevronRight: 'chevronRight',
  chevronRightWhite: 'chevronRightWhite',
  arrowLeft: 'arrowLeft',
  arrowRightWhite: 'arrowRightWhite',
  ticketDiscount: 'ticketDiscount',
  menu: 'menuy',
  bank: 'bank',
};

export type IconName = keyof typeof iconList;

export type IconSize = '16x16' | '18x18' | '20x20' | '24x24' | '26x26' | '32x32' | '40x40' | '48x48' | '60x60';
interface IconProps {
  iconName?: IconName;
  size?: IconSize;
}
const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />
);

Icon.defaultProps = {
  size: '20x20',
  iconName: 'verify',
};

export default Icon;
