/* eslint-disable import/prefer-default-export */
import axiosInstance from 'services/common/instance';
import {
  IShoppingCartResponse,
  IUpdateRequest,
  // ISaveBillingExistAddressRequest,
  IVnpayCreatePaymentRequest,
  ISavePaymentMethodRequest,
} from 'types/cart';
import { ICounpon, IOrderCompleted } from 'types/checkout';

export function saveShippingExistAddress(
  params: IUpdateRequest
): Promise<{ data: IShoppingCartResponse }> {
  return axiosInstance.post('/checkout/saveshipping', params);
}

export function saveBillingExistAddress(
  params: any
): Promise<{ data: IShoppingCartResponse }> {
  return axiosInstance.post('/checkout/savebilling', params);
}

export function savePaymentMethod(
  params: ISavePaymentMethodRequest
): Promise<{ data: IShoppingCartResponse }> {
  return axiosInstance.post('/checkout/savepaymentmethod', params);
}

export function saveShippingMethod(
  params: IUpdateRequest
): Promise<{ data: IShoppingCartResponse }> {
  return axiosInstance.post('/checkout/saveshippingmethod', params);
}

export function getCouponsApi(): Promise<ICounpon[]> {
  return axiosInstance.get('/coupon');
}

export function confirmOrder(): Promise<{ data: IShoppingCartResponse }> {
  return axiosInstance.post('/checkout/confirmorder');
}

export function orderCompleted(): Promise<{ data: IOrderCompleted }> {
  return axiosInstance.get('/checkout/completed');
}

export function createVnpayPaymentUrl(
  params: IVnpayCreatePaymentRequest
): Promise<{ data: IShoppingCartResponse }> {
  return axiosInstance.post('/v1/vnpay/payment-url', params);
}

export async function createVietQRPaymentUrl(orderId: any, amount: any): Promise<any> {
    try {
        const res: any = await fetch(`${process.env.REACT_APP_VIETQR_BASE_URL}vqr/api/token_generate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Basic ${window.btoa(`${process.env.REACT_APP_VIETQR_USERNAME}:${process.env.REACT_APP_VIETQR_PASSWORD}`)}`,
            },
        });
        const tokenData = await res.json();
        const data = await fetch(`${process.env.REACT_APP_VIETQR_BASE_URL}vqr/api/qr/generate-customer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenData.access_token}`
          },
          body: JSON.stringify({
            bankCode: 'MB',
            bankAccount: process.env.REACT_APP_VIETQR_BANK_ACCOUNT,
            userBankName: process.env.REACT_APP_VIETQR_BANK_NAME,
            content: `${orderId}`,
            qrType: 0,
            amount,
            orderId,
            transType: 'C',
            sign: 'Bidahub',
            urlLink: `${process.env.REACT_APP_VIETQR_SUCCESS_URL}order-completed?orderId=${orderId}`,
          }),
        });
        if (!data.ok) {
          const errorData = await data.json();
          throw new Error(errorData.Message);
        }
        return await data.json();
    } catch (error) {
        console.error('Error during registerService:', error);
        throw error; // Re-throw the error if you want the caller to handle it
    }
}
