/* eslint-disable import/prefer-default-export */
import axiosInstance from 'services/common/instance';
import { ISubcrtionData } from 'types/subcription';
import { toQueryString } from 'utils/functions';

export function getDataSubcriptionListApi(
  params: any
): Promise<{ data: ISubcrtionData }> {
  return axiosInstance.get(
    `/backinstocksubscription/customersubscriptions?${toQueryString(params)}`
  );
}
