import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';

import {
  getAccessToken,
  removeAccessToken,
  removeRefreshAccessToken,
} from './storage';

import { camelizeKeys } from 'utils/functions';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  ($config: AxiosRequestConfig): AxiosRequestConfig => {
    if ($config.headers) {
      const token = getAccessToken();
      if (token) {
        $config.headers.Token = token;
      }
      $config.headers['Content-Type'] = 'application/json';
      $config.headers.DeviceId = '44b4d8cd-7a2d-4a5f-a0e2-798021f1e294';
      $config.headers.Accept = '*/*';
    }
    return $config;
  },
  async (error: AxiosError): Promise<AxiosError> => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => camelizeKeys(response.data),
  async (error: AxiosError): Promise<AxiosError> => {
    if (error.response?.status === 401) {
      removeAccessToken();
      removeRefreshAccessToken();
      window.location.href = '/';
    }
    return Promise.reject(
      error.response && error.response.status === 422
        ? (error.response.data as any).errors
        : error
    );
  }
);
export default axiosInstance;
