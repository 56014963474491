import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Divider from 'components/atoms/Divider';
import { LoadingDelivery } from 'components/molecules/Loading';
import ContentPanel from 'components/organisms/ContentPanel';
import TitleBackHandler from 'components/organisms/TitleBackHandler';
import UserReviews, { UserReviewsProps } from 'components/organisms/UserReviews';
import UserBcoinTable from 'components/templates/UserBcoinTable';
import { getHistoryRewardPoints } from 'services/profile';
import { getProductReviews } from 'services/reviews';
import { useAppSelector } from 'store/hooks';
import { BcoinsHistory } from 'types/users';

interface UserReviewsContainerProps {
  onBack?: () => void;
}

export const UserReviewsContainer: React.FC<UserReviewsContainerProps> = ({
  onBack,
}) => {
  const [data, setData] = useState<UserReviewsProps[]>([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.auth);

  const fetchDataProductReviews = async () => {
    try {
      setLoading(true);
      const response = await getProductReviews();
      const parseProductReviews = response?.data?.productReviews?.map((item) => ({
        rating: item?.rating || 0,
        isVerified: item?.approvalStatus || true,
        date: item?.writtenOnStr || '',
        title: item?.title || '',
        content: item?.reviewText || '',
        product: {
          title: item?.productName || '',
          id: item?.productId || 0,
        }
      }));

      setData(parseProductReviews);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };

  useEffect(() => {
    if (user) {
      fetchDataProductReviews();
    }
  }, []);

  return (
    <>
      <TitleBackHandler onBack={onBack}>Sản phẩm đã đánh giá</TitleBackHandler>
      {
        !loading ? (
          <ContentPanel>
            {data?.map((review, index) => (
              <Fragment key={`review-${index.toString()}`}>
                <UserReviews
                  rating={review?.rating}
                  isVerified={review?.isVerified}
                  date={review?.date}
                  title={review?.title}
                  content={review?.content}
                  product={review?.product}
                />
                {index < data.length - 1 && <Divider />}
              </Fragment>
            ))}
          </ContentPanel>
        )
          : <LoadingDelivery />
      }
    </>
  );
};

interface UserBcoinContainerProps {
  onBack?: () => void;
}

export const UserBcoinContainer: React.FC<UserBcoinContainerProps> = ({
  onBack = () => { },
}) => {
  const [bcoins, setBcoins] = useState<BcoinsHistory>();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.auth);

  const fetchUserBcoins = async () => {
    try {
      setLoading(true);
      const response = await getHistoryRewardPoints(currentPage);
      setBcoins(response?.data);
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };
  useEffect(() => {
    if (user) {
      fetchUserBcoins();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <UserBcoinTable
      bcoins={bcoins?.rewardPointsBalance || 0}
      data={bcoins as BcoinsHistory}
      onMobileBack={onBack}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      loading={loading}
    />
  );
};
