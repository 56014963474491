/* eslint-disable import/prefer-default-export */
/* eslint-disable react/react-in-jsx-scope */
import { createBrowserRouter } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoutes';

import App from 'App';
import CallbackZalo from 'pages/CallbackZalo';
import Cart from 'pages/Cart';
import CategoryDetail from 'pages/CategoryDetail';
import ChangePassword from 'pages/ChangePassword';
import Components from 'pages/Components';
import CustomerInfo from 'pages/CustomerInfo';
import Home from 'pages/Home';
import Manufacturers from 'pages/Manufacturers';
import NotFound from 'pages/NotFound';
import OrderCompleted from 'pages/OrderCompleted';
import PolicyBCoin from 'pages/PolicyBCoin';
import PolicyDelivery from 'pages/PolicyDelivery';
import PolicyGuarantee from 'pages/PolicyGuarantee';
import PolicyReturn from 'pages/PolicyReturn';
import PolicyRules from 'pages/PolicyRules';
import PolicySecure from 'pages/PolicySecure';
import ProductDetail from 'pages/ProductDetail';
import SearchResult from 'pages/SearchResult';
import SubCategory from 'pages/SubCategory';
import UserOrderDetail from 'pages/UserOrderDetail';
import Wishlist from 'pages/Wishlist';
import SLUG_PARAMS from 'utils/constants';

export const router = createBrowserRouter([
  {
    path: SLUG_PARAMS.HOME,
    element: <App />,
    children: [
      {
        path: SLUG_PARAMS.HOME,
        element: <Home />,
      },
      {
        path: SLUG_PARAMS.COMPONENTS,
        element: <Components />,
      },
      {
        path: SLUG_PARAMS.WISHLIST,
        element: (
          // <ProtectedRoute>
          <Wishlist />
          // </ProtectedRoute>
        ),
      },
      {
        path: SLUG_PARAMS.CART,
        element: (
          <ProtectedRoute>
            <Cart />
          </ProtectedRoute>
        ),
      },
      {
        path: `${SLUG_PARAMS.ORDER_COMPLETED}`,
        element: (
          <ProtectedRoute>
            <OrderCompleted />
          </ProtectedRoute>
        ),
      },
      {
        path: `${SLUG_PARAMS.MANUFACTURES}/:id`,
        element: (
          <Manufacturers />
        ),
      },
      {
        path: `${SLUG_PARAMS.PRODUCTS_DETAIL}/:id`,
        element: (
          <ProductDetail />
        ),
      },
      {
        path: SLUG_PARAMS.CHANGE_PASSWORD,
        element: (
          // <ProtectedRoute>
          <ChangePassword />
          // </ProtectedRoute>
        ),
      },
      {
        path: SLUG_PARAMS.SEARCH_RESULT,
        element: (
          <SearchResult />
        ),
      },
      {
        path: `${SLUG_PARAMS.CATEGORY_DETAIL}/:id`,
        element: (
          <CategoryDetail />
        ),
      },
      {
        path: `${SLUG_PARAMS.SUB_CATEGORY}/:id`,
        element: (
          <SubCategory />
        ),
      },
      {
        path: SLUG_PARAMS.CUSTOMER_INFO,
        element: (
          <ProtectedRoute>
            <CustomerInfo />
          </ProtectedRoute>
        ),
      },
      {
        path: `${SLUG_PARAMS.CUSTOMER_INFO}/order-detail/:id`,
        element: (
          <ProtectedRoute>
            <UserOrderDetail />
          </ProtectedRoute>
        ),
      },
      {
        path: `${SLUG_PARAMS.POLICY_RULES}`,
        element: <PolicyRules />,
      },
      {
        path: `${SLUG_PARAMS.POLICY_SECURE}`,
        element: <PolicySecure />,
      },
      {
        path: `${SLUG_PARAMS.POLICY_BCOIN}`,
        element: <PolicyBCoin />,
      },
      {
        path: `${SLUG_PARAMS.POLICY_DELIVERY}`,
        element: <PolicyDelivery />,
      },
      {
        path: `${SLUG_PARAMS.POLICY_GUARANTEE}`,
        element: <PolicyGuarantee />,
      },
      {
        path: `${SLUG_PARAMS.POLICY_RETURN}`,
        element: <PolicyReturn />,
      },
    ],
    errorElement: <NotFound />,
  },
  {
    path: SLUG_PARAMS.CALLBACK_ZALO,
    element: <CallbackZalo />,
  },
]);
