/* eslint-disable import/prefer-default-export */
import axiosInstance from 'services/common/instance';
import {
  IDataCategory,
  IParamsGetCategoriesIds,
  IParamsGetCategory,
  IProductByCategory,
} from 'types/catalog';
import { toQueryString } from 'utils/functions';

export function getCategoriesApi(): Promise<{ data: IDataCategory }> {
  return axiosInstance.get('/catalog/categories');
}

export function getProductbyCategory(
  params: IParamsGetCategory
): Promise<{ data: IProductByCategory }> {
  return axiosInstance.get(
    `/catalog/category/${params.id}?PageSize=${params.size}&pagenumber=${params.page}&ms=${params.ms}&orderby=${params.orderby}`
  );
}

export function getProductbyManufacturer(
  id: number,
  payload: any
): Promise<{ data: IProductByCategory }> {
  return axiosInstance.get(
    `/catalog/manufacturer/${id}?PageSize=${payload.size}&pagenumber=${payload.page}&ms=${payload.ms}&orderby=${payload.orderby}`
  );
}

export function getAllManufacturer(): Promise<{ data: IProductByCategory }> {
  return axiosInstance.get('/catalog/manufacturer/all');
}

export function getProductbyCategoriesIds(
  params: IParamsGetCategoriesIds
): Promise<{ data: IProductByCategory }> {
  return axiosInstance.get(
    `/catalog/categories/byids?${toQueryString(params)}`
  );
}
