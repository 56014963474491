/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import useCart from './useCart';

import { AddressItemType } from 'components/organisms/AddressSelector';
import { PaymentOptionsType } from 'components/organisms/PaymentMethodSelection';
import {
  applyCheckoutAttributeApi,
  applyCouponApi,
  removeCouponApi,
  removeRewardPoint,
} from 'services/cart';
import {
  confirmOrder,
  createVnpayPaymentUrl,
  getCouponsApi,
  createVietQRPaymentUrl,
  orderCompleted,
  saveBillingExistAddress,
  savePaymentMethod,
  saveShippingMethod,
} from 'services/checkout';
import { getDeliveryInfoApi, getHistoryRewardPoints } from 'services/profile';
import {
  setAddress,
  setAddressId,
  setBcoinToUse,
  setCartData,
  setDiscount,
  setOrderId,
  setOrderTotals,
} from 'store/cart';
import { setLoadingReducer } from 'store/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ISavePaymentMethodRequest } from 'types/cart';
import { ICounpon } from 'types/checkout';
import { IDataUser } from 'types/users';
import {
  convertCurrencyStringToNumber,
  parseOrderTotal,
} from 'utils/functions';

const useCheckout = (paymentMethod: PaymentOptionsType) => {
  const [coupons, setCoupons] = useState<ICounpon[]>([]);
  const [addresses, setAddresses] = useState<AddressItemType[]>([]);
  const [bankingResponse, setBankingResponse] = useState<any>(null);
  const [notes, setNotes] = useState<string>('');
  const [processBankingOpen, setProcessBankingOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] =
    useState<AddressItemType | null>(null);
  const { discount, addressId, bcoinToUse, orderTotals } = useAppSelector(
    (state) => state.cart
  );
  const { provinces } = useAppSelector((state) => state.general);
  const [rewardPointsBalance, setRewardPointsBalance] = useState<number>(0);
  const { user } = useAppSelector((state) => state.auth);
  const addressFormRef: any = useRef();
  const dispatch = useAppDispatch();
  const { handleFetchShoppingCart } = useCart();
  const navigate = useNavigate();
  const handleCouponApiSuccess = async (response: ICounpon[]) => {
    try {
      const filteredCoupons = response.filter(
        (coupon) =>
          new Date(coupon.startDateUtc) <= new Date() &&
          new Date() <= new Date(coupon.endDateUtc)
      );
      setCoupons(filteredCoupons);
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };
  const handleRemoveCoupon = async () => {
    if (discount) {
      try {
        const response = await removeCouponApi({
          FormValues: [
            {
              Key: `removediscount-${discount?.id}`,
              Value: discount?.couponCode as string,
            },
          ],
        });
        dispatch(setCartData(response?.data?.cart?.items));
        dispatch(
          setOrderTotals(
            parseOrderTotal(
              response?.data?.cart?.items,
              response?.data?.orderTotals
            )
          )
        );
        dispatch(setDiscount(null));
      } catch (error: any) {
        toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
      }
    }
  };

  const handleApplyCoupon = async (value: ICounpon) => {
    try {
      if (discount) {
        await handleRemoveCoupon();
      }
      const response = await applyCouponApi({
        FormValues: [
          {
            Key: 'discountcouponcode',
            Value: value.couponCode,
          },
        ],
      });
      dispatch(setCartData(response?.data?.cart?.items));
      dispatch(
        setOrderTotals(
          parseOrderTotal(
            response?.data?.cart?.items,
            response?.data?.orderTotals
          )
        )
      );
      // dispatch(setOrderTotals(response?.data?.orderTotals));
      dispatch(
        setDiscount(
          response?.data?.cart?.discountBox?.appliedDiscountsWithCodes?.[0]
        )
      );
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const searchCityName = (codeName: string) =>
    provinces && provinces?.find((p) => p.codeName === codeName)?.name;

  const searchDistrictName = (cityCodeName: string, districtCodeName: string) =>
    provinces &&
    provinces
      ?.find((p) => p.codeName === cityCodeName)
      ?.district?.find((d) => d.codeName === districtCodeName)?.name;

  const searchWardName = (
    cityCodeName: string,
    districtCodeName: string,
    wardCodeName: string
  ) =>
    provinces &&
    provinces
      ?.find((p) => p.codeName === cityCodeName)
      ?.district?.find((d) => d.codeName === districtCodeName)
      ?.ward?.find((w) => w.codeName === wardCodeName)?.name;

  const handleAddressApiSuccess = async (response: { data: IDataUser }) => {
    try {
      const addressesParsed = response?.data?.addresses?.map((item) => {
        const address2 = JSON?.parse(item?.address2);
        // const city = searchCityName(address2?.city);
        // const district = searchDistrictName(address2?.city, address2?.district);
        // const ward = searchWardName(
        //   address2?.city,
        //   address2?.district,
        //   address2?.ward
        // );
        return {
          id: item?.id,
          name: `${item?.firstName} ${item?.lastName}`,
          address: `${item?.address1 ? item?.address1 : item?.address2}, ${
            address2.ward || ''
          }, ${address2.district || ''}, ${address2.city || ''}`,
          phone: item?.phoneNumber,
          email: item?.email,
          address2,
          isDefault: item?.formattedCustomAddressAttributes === 'Default: Yes',
        };
      });
      setAddresses(addressesParsed);
      if (!selectedAddress) {
        const selectAddress = addressesParsed?.find((item) => item.isDefault);
        setSelectedAddress(
          selectAddress || addressesParsed?.[addressesParsed.length - 1] || null
        );
        dispatch(
          setAddress(
            selectAddress ||
              addressesParsed?.[addressesParsed.length - 1] ||
              null
          )
        );
        dispatch(
          setAddressId(
            selectAddress?.id ||
              addressesParsed?.[addressesParsed.length - 1]?.id
              ? String(addressesParsed?.[addressesParsed.length - 1]?.id)
              : ''
          )
        );
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };

  const fetchDataDeliveryAddress = async () => {
    try {
      const response = await getDeliveryInfoApi();
      handleAddressApiSuccess(response);
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };

  const fetchData = async () => {
    try {
      dispatch(setLoadingReducer(true));
      const promiseArrr = [
        getCouponsApi(),
        getDeliveryInfoApi(),
        getHistoryRewardPoints(1),
      ];
      const results: any = await Promise.allSettled(promiseArrr);
      handleCouponApiSuccess(results?.[0]?.value);
      handleAddressApiSuccess(results?.[1]?.value);
      setRewardPointsBalance(
        results?.[2]?.value?.data?.rewardPointsBalance || 0
      );
      dispatch(setLoadingReducer(false));
    } catch (error) {
      dispatch(setLoadingReducer(false));
    }
  };

  const handleNotesChange = (e: any) => {
    setNotes(e.target.value);
  };
  const [form, setForm] = useState<any>(null);

  const onPayment = async () => {
    if (paymentMethod.id === 'banking') {
      // setForm(addressFormRef.current.formValues);
      setProcessBankingOpen(true);
    } else {
      setProcessBankingOpen(false);
      try {
        dispatch(setLoadingReducer(true));
        await applyCheckoutAttributeApi({
          FormValues: [
            {
              Key: 'checkout_attribute_2',
              Value: notes,
            },
            {
              Key: 'checkout_attribute_3',
              Value: paymentMethod.id,
            },
          ],
        });
        let saveBillingAddressParams: any = {};
        if (!addressId) {
          const data = addressFormRef?.current?.formValues;
          saveBillingAddressParams = {
            data: {
              billingNewAddress: {
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.phoneNumber,
                email: data?.email || `${data?.phoneNumber}@gmail.com`,
                address1: data.specificAddress,
                address2: JSON.stringify({
                  district: data.district,
                  ward: data.ward,
                  city: data.city,
                }),
              },
              shipToSameAddress: true,
            },
            FormValues: [
              {
                Key: 'address_attribute_1',
                Value: data?.saveAddress2 ? 1 : 0,
              },
            ],
          };
        } else {
          saveBillingAddressParams = {
            Data: {
              PickupInStore: false,
              ShipToSameAddress: true,
            },
            FormValues: [
              {
                Key: 'billing_address_id',
                Value: addressId,
              },
            ],
          };
        }
        await saveBillingExistAddress(saveBillingAddressParams);
        await saveShippingMethod({
          FormValues: [
            {
              Key: 'shippingoption',
              Value: 'Giao hàng nhanh 24 giờ___Shipping.FixedByWeightByTotal',
            },
          ],
        });
        const savePaymentParams: ISavePaymentMethodRequest = {
          FormValues: [
            {
              Key: 'paymentmethod',
              Value: 'Payments.CheckMoneyOrder',
            },
          ],
        };

        if (bcoinToUse > 0) {
          savePaymentParams.Data = {
            RewardPointsAmountsUserInput: bcoinToUse,
            UseRewardPoints: true,
          };
        }
        await savePaymentMethod(savePaymentParams);
        await confirmOrder();
        const response = await orderCompleted();
        if (paymentMethod.id === 'vietqr') {
          const res = await createVietQRPaymentUrl(
            response.data.completedModel.orderId,
            convertCurrencyStringToNumber(orderTotals?.orderTotal || '0 ₫')
          );
          window.open(res.qrLink, '_self');
          dispatch(setBcoinToUse(0));
          handleFetchShoppingCart();
        }
        if (paymentMethod.id === 'vnpay') {
          const vnpayResponse: any = await createVnpayPaymentUrl({
            OrderId: response.data.completedModel.orderId,
          });
          dispatch(setLoadingReducer(false));
          window.open(vnpayResponse.url, '_self');
          dispatch(setBcoinToUse(0));
          handleFetchShoppingCart();
        }
      } catch (error: any) {
        dispatch(setLoadingReducer(false));
        toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
      }
      // switch (newNumber) {
      //   case 1:
      //     setOpenFailedModal(true);
      //     break;
      //   case 2:
      //     setOpenProcessPayment(true);
      //     break;
      //   default:
      //     navigate('/order-completed/abds');

      //     break;
      // }
    }
  };

  const applyBCoin = async (numericValue: number) => {
    if (numericValue === 0) {
      try {
        await removeRewardPoint();
      } catch (error: any) {
        toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
      }
    } else {
      try {
        await savePaymentMethod({
          FormValues: [
            {
              Key: 'paymentmethod',
              Value: 'Payments.CheckMoneyOrder',
            },
          ],
          Data: {
            RewardPointsAmountsUserInput: numericValue,
            UseRewardPoints: true,
          },
        });
      } catch (error: any) {
        toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
        console.log(error);
      }
    }
    handleFetchShoppingCart();
  };

  const handleChangeShippingAddress = async (
    address: AddressItemType | null
  ) => {
    setSelectedAddress(address);
    dispatch(setAddressId(String(address?.id) || ''));
  };

  // TODO: Process Banking payment
  const onBankingProcessSuccess = async () => {
    try {
      dispatch(setLoadingReducer(true));
      await applyCheckoutAttributeApi({
        FormValues: [
          {
            Key: 'checkout_attribute_2',
            Value: notes,
          },
          {
            Key: 'checkout_attribute_3',
            Value: paymentMethod.id,
          },
        ],
      });
      let saveBillingAddressParams: any = {};
      if (!addressId) {
        const data = addressFormRef?.current?.formValues || form?.formValues;

        saveBillingAddressParams = {
          data: {
            billingNewAddress: {
              firstName: data?.firstName,
              lastName: data?.lastName,
              phoneNumber: data?.phoneNumber,
              email: data?.email || `${data?.phoneNumber}@gmail.com`,
              address1: data?.specificAddress,
              address2: JSON.stringify({
                district: data?.district,
                ward: data?.ward,
                city: data?.city,
              }),
            },
            shipToSameAddress: true,
          },
          FormValues: [
            {
              Key: 'address_attribute_1',
              Value: data?.saveAddress2 ? 1 : 0,
            },
          ],
        };
      } else {
        saveBillingAddressParams = {
          Data: {
            PickupInStore: false,
            ShipToSameAddress: true,
          },
          FormValues: [
            {
              Key: 'billing_address_id',
              Value: addressId,
            },
          ],
        };
      }
      await saveBillingExistAddress(saveBillingAddressParams);
      await saveShippingMethod({
        FormValues: [
          {
            Key: 'shippingoption',
            Value: 'Giao hàng nhanh 24 giờ___Shipping.FixedByWeightByTotal',
          },
        ],
      });
      const savePaymentParams: ISavePaymentMethodRequest = {
        FormValues: [
          {
            Key: 'paymentmethod',
            Value: 'Payments.CheckMoneyOrder',
          },
        ],
      };

      if (bcoinToUse > 0) {
        savePaymentParams.Data = {
          RewardPointsAmountsUserInput: bcoinToUse,
          UseRewardPoints: true,
        };
      }
      await savePaymentMethod(savePaymentParams);
      await confirmOrder();
      const response = await orderCompleted();
      dispatch(setLoadingReducer(false));
      setBankingResponse(response?.data);
      dispatch(setOrderId(String(response?.data?.completedModel?.orderId)));
      // window.open(vnpayResponse.url, '_self');
      dispatch(setBcoinToUse(0));
      setProcessBankingOpen(false);
      handleFetchShoppingCart();
      navigate('/order-completed');
    } catch (error: any) {
      dispatch(setLoadingReducer(false));
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
      console.log(error);
    }
  };
  const handleCancelBankingProcess = () => {
    setProcessBankingOpen(false);
  };

  useEffect(() => {
    if (user) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleRemoveCoupon,
    handleApplyCoupon,
    handleChangeShippingAddress,
    fetchDataDeliveryAddress,
    onPayment,
    applyBCoin,
    handleNotesChange,
    coupons,
    addresses,
    rewardPointsBalance,
    selectedAddress,
    addressFormRef,
    setProcessBankingOpen,
    processBankingOpen,
    onBankingProcessSuccess,
    bankingResponse,
    handleCancelBankingProcess,
    setForm,
  };
};
export default useCheckout;
