export const PENDING = 'Pending';
export const PROCESSING = 'Processing';
export const COMPLETE = 'Complete';
export const CANCELLED = 'Cancelled';
export const AUTHORIZED = 'Authorized';
export const PAID = 'Paid';
export const PARTIALLY_REFUNDED = 'PartiallyRefunded';
export const REFUNDED = 'Refunded';
export const VOIDED = 'Voided';
export const WAITING_PAYMENT = 'WaitingPayment';
export const SHIPPING = 'Shipping';
export const SHIPPED = 'Shipped';
export const DELIVERED = 'Delivered';
export const PartiallyShipped = 'PartiallyShipped';
export const NotShippedYet = 'Not yet shipped';

export const ORDER_STATUS = [
  {
    label: PENDING,
    value: 10,
  },
  {
    label: PROCESSING,
    value: 20,
  },
  {
    label: COMPLETE,
    value: 30,
  },
  {
    label: CANCELLED,
    value: 40,
  },
];

export const PAYMENT_STATUS = [
  {
    label: PENDING,
    value: 10,
  },
  {
    label: AUTHORIZED,
    value: 20,
  },
  {
    label: PAID,
    value: 30,
  },
  {
    label: PARTIALLY_REFUNDED,
    value: 35,
  },
  {
    label: REFUNDED,
    value: 40,
  },
  {
    label: VOIDED,
    value: 50,
  },
];

export default function transOrderStatus(data: any) {
  if (data?.paymentMethodStatus === 'Pending') {
    return 'Chờ thanh toán';
  }
  switch (data?.orderStatus) {
    case PENDING:
      return 'Chờ xác nhận';
    case PROCESSING:
      return 'Đang xử lý';
    case COMPLETE:
      return 'Thành công';
    case CANCELLED:
      return 'Đã huỷ';
    default:
      return 0;
  }
}
