import React, { useState } from 'react';

import { nestedCategoriesDummy, productDummyData } from 'assets/dummy';
import BackgroundBadge from 'components/atoms/BackgroundBadge';
import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import Radio from 'components/atoms/Radio';
import BoxSelections from 'components/molecules/BoxSelections';
import Pulldown from 'components/molecules/Pulldown';
import StarRating from 'components/molecules/StarRating';
import Switch from 'components/molecules/Switch';
import Carousel, { CustomArrow } from 'components/organisms/Carousel';
import CustomModal from 'components/organisms/Modal';
import NestedCheckbox from 'components/organisms/NestedCheckbox';
import ProductCard from 'components/organisms/ProductCard';
import Container from 'components/templates/Container';
import ProductGrid from 'components/templates/ProductGrid';
// import ProductInfo from 'components/templates/ProductInfo';
import ProductSlide from 'components/templates/ProductSlide';
import { UserBcoinContainer, UserReviewsContainer } from 'containers/UserDashboardBackup';

const settings = {
  className: 'center',
  centerPadding: '60px',
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  infinite: true,
  arrows: true,
  cssEase: 'ease-in-out',
  prevArrow: <CustomArrow type='prev' />,
  nextArrow: <CustomArrow type='next' />,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
  ],
};

const boxSelectionOptions = [
  { label: 'Đỏ', value: 'red' },
  { label: 'Đen', value: 'black' },
  { label: 'Xanh nhạt', value: 'light-blue', disabled: true },
  { label: 'Xanh', value: 'blue' },
];

const Components: React.FC = () => {
  const [rating, setRating] = useState<number>(0);
  const [checked, setChecked] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  return (
    <div className=' bg-alice-blue2 mx-auto'>
      <h1 className='text-xl text-center font-bold text-cadmium-red'>
        Components
      </h1>
      <div className='mt-2 container mx-auto'>
        {/* Button  */}
        <Button size='medium'>
          Primary/medium button
        </Button>
        <br />
        <br />
        <Button size='large'>
          Primary/large button
        </Button>
        <br />
        <br />
        <Button size='large' disabled>
          Disabled Primary/large button
        </Button>
        <br />
        <br />
        <Button size='large' variants='secondary'>
          Secondary/large button
        </Button>
        <br />
        <br />
        <Button size='large' variants='secondary' loading>
          Secondary/large button
        </Button>
        <br />
        <br />
        <Button
          size='large'
          icon='eye'
        >
          Icon Secondary/large button
        </Button>
        <br />
        <br />
        <Button
          size='large'
          icon='eye'
          variants='secondary'
        >
          Icon Secondary/large button
        </Button>
        <br />
        <br />
        <Button
          size='large'
          icon='eye'
          variants='neon-fuchsia'
        >
          Icon Secondary/large button
        </Button>
        <br />
        <br />
        <Button
          size='large'
          rtl
          icon='arrowRight'
          disabled
        >
          Icon Secondary/large button
        </Button>
        <br />
        <br />
        <br />
        {/* input  */}
        <Input type='password' placeholder='Input' />
        <Input type='text' placeholder='Input' sizeVariant='sm' className='mt-2' />
        <br />
        {/* checkbox  */}
        <Checkbox />
        <Checkbox label='Checkbox' />
        <Checkbox disabled checked label='Checkbox' />
        {/* Switch */}
        <Switch
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        {/* Radio  */}
        <Radio label='Radio' />
        <br />
        <NestedCheckbox
          title='Filter Component'
          data={nestedCategoriesDummy}
          onCheckedChange={(checkedItems) => console.log(checkedItems)}
        />
        {/* Container  */}
        <Container />
        <br />
        {/* Rating Star  */}
        <p className='max-w-lg text-3xl font-semibold leading-normal'>
          Rating
        </p>
        <hr className='my-3' />
        <StarRating count={5} value={rating} onChange={setRating} />
        <br />
        {/* Modal */}
        <button type='button' className='mt-4 p-2 bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-300' onClick={() => setIsOpenModal(true)}>Open Modal</button>
        <CustomModal
          title='This is modal title'
          isOpen={isOpenModal}
          onRequestClose={() => setIsOpenModal(false)}
        >
          <div>Content</div>
        </CustomModal>
        {/* Product Card  */}
        <ProductGrid />
        {/* Pulldown  */}
        <Pulldown
          placeholder='Chọn options'
          options={[
            { label: 'Option 1', value: '1' },
            { label: 'Option 2', value: '2' }]}
        />
        <Pulldown
          placeholder='Chọn options'
          size='sm'
          options={[
            { label: 'Option 1', value: '1' },
            { label: 'Option 2', value: '2' }]}
        />
        {/* Carousel */}
        <Carousel
          settings={settings}
          className='mt-4'
          title={<h4 className='font-semibold'>Carousel</h4>}
        >
          {
            productDummyData.map((item, index) => (
              <ProductCard key={`item-${index.toString()}`} data={item} />
            ))
          }
        </Carousel>
        <br />
        <h1>BADGE</h1>
        <BackgroundBadge icon={<Icon iconName='verify' size='16x16' />}>
          Chính hãng 100%
        </BackgroundBadge>
        <br />
        <h1>Box Selection</h1>
        <BoxSelections title='Mau sac' options={boxSelectionOptions} onSelect={(value) => console.log(value)} />
        <br />
        <h1>Thông tin sản phẩm</h1>
        {/* <ProductInfo data={productInfoDummy} /> */}
        <br />
        <ProductSlide productData={productDummyData} title='Sản phẩm liên quan' />
        <div className='w-full h-28' />
      </div>

      <div className='py-3 container mx-auto'>
        <UserReviewsContainer />
      </div>
      <div className='py-3 container mx-auto'>
        <UserBcoinContainer />
      </div>
    </div>
  );
};

export default Components;
