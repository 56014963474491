/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BannerSliderSkeleton } from 'components/atoms/Skeleton';
import { getBannersApi } from 'services/home';
import { ISlider } from 'types/home';

const BannerContainer: React.FC = () => {
  const [banners, setBanners] = useState<ISlider[]>([]);
  const [loading, setLoading] = useState(true);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const fetchBanners = async () => {
    try {
      setLoading(true);
      const response = await getBannersApi();
      setBanners(response.data.sliders);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBanners();
  }, []);

  if (loading) {
    return <div className='mb-1.5'><BannerSliderSkeleton /></div>;
  }
  return (
    <div>
      <Slider {...settings}>
        {banners?.map((banner) => (
          <div className='aspect-375/216 lg:aspect-1440/446'>
            <picture>
              <source media='(min-width:992px)' srcSet={banner.imageUrl} />
              <img
                src={banner.imageUrl}
                loading='lazy'
                alt='banner'
                className='w-full h-full object-cover'
              />
            </picture>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default BannerContainer;
