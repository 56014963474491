import React from 'react';

import TickCircle from 'assets/icons/ic_tick_circle.svg';
import Button from 'components/atoms/Button';
import BackPopupButton from 'components/molecules/BackPopupButton';

interface CompleteProps {
  handleBack: () => void;
  handleCloseModal: () => void;
  contact: string;
}

const Complete: React.FC<CompleteProps> = ({
  handleBack,
  contact,
  handleCloseModal
}) => (
  <div className='h-full p-4'>
    <BackPopupButton onClick={handleBack} />
    <div className='flex flex-col items-center justify-center p-4'>
      <h2 className='text-2xl font-semibold text-[#1F2128] mb-2 mt-4'>Quên mật khẩu?</h2>
      <div className='flex items-center justify-center w-16 h-16 bg-primary rounded-full mt-4'>
        <img aria-hidden='true' alt='check-icon' src={TickCircle} />
      </div>
      <p className='text-feldgrau text-sm my-4 text-center font-medium'>
        Link lấy lại mật khẩu đã được gửi tới “{contact}”, vui lòng kiểm tra tin nhắn.
      </p>
      <Button
        type='submit'
        className='text-[#2E3538] bg-white text-sm font-semibold border border-[#3D8EFF] mt-2 md:mt-4 p-2 hover:opacity-70 transition-all'
        onClick={handleCloseModal}
      >
        Đóng
      </Button>
    </div>
  </div>
);

export default Complete;
