import classNames from 'classnames';
import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
  className?: string;
  variant?: 'small' | 'large' | 'medium';
  initialSeconds: number;
  onComplete?: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  variant = 'small',
  className = '',
  onComplete,
  initialSeconds
}) => {
  const [secondsRemaining, setSecondsRemaining] = useState(initialSeconds);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsRemaining((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(intervalId);
          if (onComplete) onComplete();
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const coundownTimer = classNames(
    `${className}`,
    {
      'text-sm text-[#3D8EFF]': variant === 'small',
      'text-md text-[#3D8EFF]': variant === 'medium',
      'text-[#2E3538] text-3xl': variant === 'large',
    }
  );

  return (
    <p className={coundownTimer}>{formatTime(secondsRemaining)}</p>
  );
};

export default CountdownTimer;
