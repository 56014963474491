/* eslint-disable @typescript-eslint/no-unused-vars */
import { useGoogleLogin } from '@react-oauth/google';
import React, { forwardRef, useImperativeHandle } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import IcFacebook from 'assets/icons/ic_fb.svg';
import IcGoogle from 'assets/icons/ic_google.svg';
import IcTick from 'assets/icons/ic_tick.svg';
import IcZalo from 'assets/icons/ic_zalo.svg';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import { profileApi, signinOrSignupSocialApi } from 'services/auth';
import { setAccessToken } from 'services/common/storage';
import { setCodeVerifier, setUser } from 'store/auth';
import { useAppDispatch } from 'store/hooks';
import { generateCodeChallengeFromVerifier, generateCodeVerifier } from 'utils/functions';

type SignInFormTypes = {
  userName: string;
};

const benefitList = [
  'Theo dõi đơn hàng, lịch sử mua hàng',
  'Tích điểm ưu đãi trên mọi đơn hàng',
  'Nhận ưu đãi độc quyền cho thành viên mới',
];

export interface SignInScreenActionRef {
  handleGetValue: () => SignInFormTypes;
  handleForm: () => Promise<SignInFormTypes | undefined>;
  handleReset: (data: SignInFormTypes) => void;
}

interface SignInScreenProps {
  handleSetStep: () => void;
  handleCloseModal: () => void;
  loading: boolean;
}

const SignInScreen = forwardRef<SignInScreenActionRef, SignInScreenProps>(
  ({ handleSetStep, loading, handleCloseModal }, ref) => {
    const method = useForm<SignInFormTypes>({
      mode: 'onChange',
      defaultValues: {
        userName: '',
      },
    });

    const dispatch = useAppDispatch();

    const googleLogin = useGoogleLogin({
      onSuccess: async (tokenResponse) => {
        const response = await signinOrSignupSocialApi({
          Type: 'Google',
          SocialAccessToken: tokenResponse.access_token,
        });
        setAccessToken(response.data.token);
        const profile = await profileApi();
        dispatch(setUser(profile.data));
        handleCloseModal();
      },
    });

    const responseFacebook = async (facebookResponse: any) => {
      const response = await signinOrSignupSocialApi({
        Type: 'Facebook',
        SocialAccessToken: facebookResponse.accessToken,
      });
      setAccessToken(response.data.token);
      const profile = await profileApi();
      dispatch(setUser(profile.data));
      handleCloseModal();
    };

    const validateUserName = (value: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^\d{10,15}$/; // Adjust the phone number length according to your needs

      if (emailRegex.test(value)) {
        return true;
      }

      if (phoneRegex.test(value)) {
        return true;
      }

      return 'Vui lòng nhập số điện thoại hoặc email hợp lệ';
    };

    useImperativeHandle(ref, () => ({
      handleGetValue: () => method.getValues(),
      handleForm: async () => {
        method.trigger();
        let result: SignInFormTypes | undefined;
        await method.handleSubmit((data: SignInFormTypes) => {
          result = data;
        })();
        return result;
      },
      handleReset: (data) => method.reset(data),
    }));

    const zaloLogin = async () => {
      try {
        const codeVerifier = generateCodeVerifier();
        dispatch(setCodeVerifier(codeVerifier));
        const codeChallenge = await generateCodeChallengeFromVerifier(codeVerifier);
        const url = `https://oauth.zaloapp.com/v4/permission?app_id=${process.env.REACT_APP_ZALO_APP_ID}&code_challenge=${codeChallenge}&redirect_uri=https://bidahub.com/callback/zalo&state=2000`;
        window.open(url, '_self');
      } catch (error) {
        dispatch(setCodeVerifier(''));
      }
    };
    return (
      <div className='h-full flex flex-col text-raisin-black'>
        <h2 className='text-5xl h-[60px] font-semibold'>Xin chào, 👋</h2>
        <h3 className='text-xl md:text-2xl font-semibold mt-4'>
          Đăng nhập hoặc Tạo tài khoản
        </h3>
        <FormProvider {...method}>
          <form
            noValidate
            className='mt-6 lg:mt-8'
            onSubmit={method.handleSubmit(() => {
              handleSetStep();
            })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                method.handleSubmit(() => {
                  handleSetStep();
                })();
              }
            }}
          >
            <label className='block font-semibold mb-3' htmlFor='contact'>
              Số điện thoại hoặc Email
            </label>
            <Controller
              name='userName'
              rules={{
                required: 'Trường này là bắt buộc',
                validate: validateUserName,
              }}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  placeholder='Nhập số điện thoại hoặc Email'
                  error={fieldState?.error?.message}
                  required
                />
              )}
            />
          </form>
        </FormProvider>
        <Button
          type='submit'
          size='large'
          loading={loading}
          variants='neon-fuchsia'
          className='mt-6'
          onClick={() => method.handleSubmit(() => {
            handleSetStep();
          })()}
        >
          Tiếp Tục
        </Button>
        <div className='p-3 md:p-4 bg-[#F3F7FC] rounded-lg my-6 md:my-8'>
          <p className='my-2 text-[#1F2128] text-md font-semibold'>
            Các phúc lợi khi trở thành hội viên của BidaHub:
          </p>
          {benefitList.map((item) => (
            <div key={item} className='flex mb-1 md:mb-2'>
              <img src={IcTick} alt={item} />
              <p className='text-[#2E3538] text-sm font-medium ml-2'>{item}</p>
            </div>
          ))}
        </div>
        <div className='flex items-center px-4 md:px-10 '>
          <hr className='flex-grow border-zinc-300' />
          <span className='mx-4 text-md font-semibold text-[#2E3538]'>
            Hoặc tiếp tục bằng
          </span>
          <hr className='flex-grow border-zinc-300' />
        </div>
        <div className='mt-6 flex space-y-2 md:space-y-0 md:space-x-6 flex-wrap'>
          <button
            onClick={zaloLogin}
            className='bg-white border border-[#D8DCDE] p-2 rounded-lg flex w-full md:flex-1 justify-center items-center'
          >
            <img src={IcZalo} alt='zalo' className='mr-2' />
            Zalo
          </button>
          <button
            onClick={() => googleLogin()}
            className='bg-white border border-[#D8DCDE] p-2 rounded-lg flex w-full md:flex-1 justify-center items-center'
          >
            <img src={IcGoogle} alt='google' className='mr-2' />
            Google
          </button>
          {/* <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
            fields='name,email,picture'
            onClick={(e: any) => console.log(e)}
            callback={responseFacebook}
            render={(renderProps) => (
              <button
                className='bg-white border border-[#D8DCDE] p-2 rounded-lg
                flex w-full md:flex-1 justify-center items-center'
                onClick={renderProps.onClick}
              >
                <img src={IcFacebook} alt='facebook' className='mr-2' />
                Facebook
              </button>
            )}
          /> */}
        </div>
      </div>
    );
  }
);
export default SignInScreen;
