/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Input from 'components/atoms/Input';
import { LoadingDelivery } from 'components/molecules/Loading';
import Tabs, { TabsPanel } from 'components/organisms/Tabs';
import TitleBackHandler from 'components/organisms/TitleBackHandler';
import UserOrderItem from 'components/organisms/UserOrderItem';
import useCart from 'hooks/useCart';
import useOrderHistory from 'hooks/useOrderHistory';
import { setIsRepayment, setOrderTotals, setRepaymentData } from 'store/cart';
import { useAppDispatch } from 'store/hooks';
import SLUG_PARAMS from 'utils/constants';
import { parseOrderTotal } from 'utils/functions';
import {
  CANCELLED,
  COMPLETE,
  NotShippedYet,
  PartiallyShipped,
  PENDING,
  PROCESSING,
  SHIPPED,
} from 'utils/order';

interface UserOrderContainerProps {
  onBack?: () => void;
}

const UserOrderContainer: React.FC<UserOrderContainerProps> = ({ onBack }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleReOrder } = useCart();
  const { data, loading } = useOrderHistory();

  const dataPending = data?.filter(
    (item) => item?.status === PENDING
  );
  const dataProcessing = data?.filter(
    (item) => item?.status === PROCESSING && item.shippingStatus === NotShippedYet
  );
  const dataComplete = data?.filter((item) => item?.status === COMPLETE);
  const dataCancelled = data?.filter((item) => item?.status === CANCELLED);
  const dataWaitingPayment = data?.filter(
    (item) => item.paymentStatus === PENDING
  );
  const dataShipping = data?.filter(
    (item) => item?.shippingStatus === SHIPPED || item?.shippingStatus === PartiallyShipped
  );

  const tabs = [
    {
      title: 'Tất cả',
      count: data.length || 0,
      activeKey: 'all',
      id: 1,
    },
    {
      title: 'Chờ thanh toán',
      count: dataWaitingPayment.length || 0,
      activeKey: 'waiting_payment',
      id: 2,
    },
    {
      title: 'Chờ xác nhận',
      count: dataPending.length || 0,
      activeKey: 'confirming',
      id: 3,
    },
    {
      title: 'Đang xử lý',
      count: dataProcessing.length || 0,
      activeKey: 'processing',
      id: 4,
    },
    {
      title: 'Đang giao hàng',
      count: dataShipping.length || 0,
      activeKey: 'shipping',
      id: 5,
    },
    {
      title: 'Thành công',
      count: dataComplete.length || 0,
      activeKey: 'success',
      id: 6,
    },
    {
      title: 'Đã hủy',
      count: dataCancelled.length || 0,
      activeKey: 'cancelled',
      id: 7,
    },
  ];

  const handleRePayment = (value: any) => {
    dispatch(setIsRepayment(true));
    dispatch(setRepaymentData(value));
    navigate('/cart');
  };

  return (
    <div>
      <TitleBackHandler onBack={onBack}>Đơn hàng</TitleBackHandler>
      <div className='t-userOrder__tabs'>
        {!loading ? (
          <Tabs
            header={tabs}
            searchEle={(
              <div className='p-3'>
                <Input placeholder='Tìm kiếm đơn hàng' />
              </div>
            )}
          >
            <TabsPanel activeKey='all'>
              <div className='t-userOrder__list'>
                {data.map((v, i) => (
                  <div
                    className={`${i === 0
                      ? 'pb-4 lg:pb-6'
                      : 'py-4 lg:py-6 border-t border-gainsboro'
                      }`}
                    key={`all-order-${i.toString()}`}
                  >
                    <UserOrderItem
                      data={v}
                      onRePayment={() => handleRePayment(v)}
                      onReorderClick={() => handleReOrder(v.orderNumber)}
                      onDetailsClick={() => navigate(
                        `${SLUG_PARAMS.CUSTOMER_INFO}/order-detail/${v.orderNumber}`
                      )}
                    />
                  </div>
                ))}
              </div>
            </TabsPanel>
            <TabsPanel activeKey='waiting_payment'>
              <div className='t-userOrder__list'>
                {dataWaitingPayment.map((v, i) => (
                  <div
                    className={`${i === 0
                      ? 'pb-4 lg:pb-6'
                      : 'py-4 lg:py-6 border-t border-gainsboro'
                      }`}
                    key={`waiting-payment-${i.toString()}`}
                  >
                    <UserOrderItem
                      data={v}
                      onRePayment={() => handleRePayment(v)}
                      onReorderClick={() => handleReOrder(v.orderNumber)}
                      onDetailsClick={() => navigate(
                        `${SLUG_PARAMS.CUSTOMER_INFO}/order-detail/${v.orderNumber}`
                      )}
                    />
                  </div>
                ))}
              </div>
            </TabsPanel>
            <TabsPanel activeKey='confirming'>
              <div className='t-userOrder__list'>
                {dataPending.map((v, i) => (
                  <div
                    className={`${i === 0
                      ? 'pb-4 lg:pb-6'
                      : 'py-4 lg:py-6 border-t border-gainsboro'
                      }`}
                    key={`confirming-${i.toString()}`}
                  >
                    <UserOrderItem
                      data={v}
                      onRePayment={() => handleRePayment(v)}
                      onReorderClick={() => handleReOrder(v.orderNumber)}
                      onDetailsClick={() => navigate(
                        `${SLUG_PARAMS.CUSTOMER_INFO}/order-detail/${v.orderNumber}`
                      )}
                    />
                  </div>
                ))}
              </div>
            </TabsPanel>
            <TabsPanel activeKey='processing'>
              <div className='t-userOrder__list'>
                {dataProcessing.map((v, i) => (
                  <div
                    className={`${i === 0
                      ? 'pb-4 lg:pb-6'
                      : 'py-4 lg:py-6 border-t border-gainsboro'
                      }`}
                    key={`processing-${i.toString()}`}
                  >
                    <UserOrderItem
                      data={v}
                      onReorderClick={() => handleReOrder(v.orderNumber)}
                      onDetailsClick={() => navigate(
                        `${SLUG_PARAMS.CUSTOMER_INFO}/order-detail/${v.orderNumber}`
                      )}
                    />
                  </div>
                ))}
              </div>
            </TabsPanel>
            <TabsPanel activeKey='shipping'>
              <div className='t-userOrder__list'>
                {dataShipping.map((v, i) => (
                  <div
                    className={`${i === 0
                      ? 'pb-4 lg:pb-6'
                      : 'py-4 lg:py-6 border-t border-gainsboro'
                      }`}
                    key={`shipping-${i.toString()}`}
                  >
                    <UserOrderItem
                      data={v as any}
                      onReorderClick={() => handleReOrder(v.orderNumber)}
                      onDetailsClick={() => navigate(
                        `${SLUG_PARAMS.CUSTOMER_INFO}/order-detail/${v.orderNumber}`
                      )}
                    />
                  </div>
                ))}
              </div>
            </TabsPanel>
            <TabsPanel activeKey='success'>
              <div className='t-userOrder__list'>
                {dataComplete.map((v, i) => (
                  <div
                    className={`${i === 0
                      ? 'pb-4 lg:pb-6'
                      : 'py-4 lg:py-6 border-t border-gainsboro'
                      }`}
                    key={`success-${i.toString()}`}
                  >
                    <UserOrderItem
                      data={v}
                      onReorderClick={() => handleReOrder(v.orderNumber)}
                      onDetailsClick={() => navigate(
                        `${SLUG_PARAMS.CUSTOMER_INFO}/order-detail/${v.orderNumber}`
                      )}
                    />
                  </div>
                ))}
              </div>
            </TabsPanel>
            <TabsPanel activeKey='cancelled'>
              <div className='t-userOrder__list'>
                {dataCancelled.map((v, i) => (
                  <div
                    className={`${i === 0
                      ? 'pb-4 lg:pb-6'
                      : 'py-4 lg:py-6 border-t border-gainsboro'
                      }`}
                    key={`cancelled-${i.toString()}`}
                  >
                    <UserOrderItem
                      data={v}
                      onRePayment={() => handleRePayment(v)}
                      onReorderClick={() => handleReOrder(v.orderNumber)}
                      onDetailsClick={() => navigate(
                        `${SLUG_PARAMS.CUSTOMER_INFO}/order-detail/${v.orderNumber}`
                      )}
                    />
                  </div>
                ))}
              </div>
            </TabsPanel>
          </Tabs>
        ) : (
          <LoadingDelivery />
        )}
      </div>
    </div>
  );
};

export default UserOrderContainer;
