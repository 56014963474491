import React, { useEffect, useMemo, useState } from 'react';

import { bankList } from 'assets/dummy';
import qrCodeIcon from 'assets/images/qrCode.svg';
import CustomModal from 'components/organisms/Modal';
import { formatVndCurrency } from 'utils/functions';

interface ProcessPaymentModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  qrCode?: string;
  minutes: number;
  onChangeMethod?: () => void;
  onCountdownEnd?: () => void; // New prop for countdown end
}

const instructions: React.ReactNode[] = [
  <>Mở ứng dụng ngân hàng hỗ trợ VNPAY-QR trên điện thoại</>,
  <>Trên ứng dụng chọn tính năng <span className='inline-flex items-center'> <img src={qrCodeIcon} alt='qr' width={24} height={24} /> Quét mã QR  </span>
  </>,
  <>Quét QR ở trang này và thanh toán</>
];

const ProcessPaymentModal: React.FC<ProcessPaymentModalProps> = ({
  isOpen, qrCode, onRequestClose, minutes, onChangeMethod, onCountdownEnd
}) => {
  const [time, setTime] = useState(minutes * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          if (onCountdownEnd) {
            onCountdownEnd(); // Trigger the countdown end callback
          }
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [onCountdownEnd]);

  const displayTime = useMemo(() => {
    const min = Math.floor(time / 60);
    const seconds = time % 60;
    return { min, seconds };
  }, [time]);

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title='Thanh toán bằng VNPAY-QR'
      className='max-w-[720px]'
      isHideCloseBtn
      modalTop={(
        <button onClick={onChangeMethod} className='text-brilliant-azure'>
          Đổi phương thức khác
        </button>
      )}
    >
      <div className='t-processPaymentModal py-2'>
        <div className='top flex items-center flex-col md:flex-row justify-center gap-4 md:gap-6'>
          <div className='bg-alice-blue2 p-4 md:w-5/12 rounded-lg'>
            <img src={qrCode} alt='qr' loading='lazy' className='w-[225px] h-[225px] rounded-lg' />
            <div className='flex justify-between mt-4'>
              <p>Tổng tiền</p>
              <p className='text-neon-fuchsia font-semibold'>{formatVndCurrency(780000)}</p>
            </div>
          </div>
          <div className='md:w-7/12'>
            <h4 className='text-xl font-semibold text-raisin-black'>
              Quét mã QR để thanh toán
            </h4>
            {/* Info  */}
            <div className='space-y-3 mt-3'>
              {instructions.map((instruction, index) => (
                <div key={`instruction-${index.toString()}`} className='flex items-center'>
                  <div className='mr-2'>
                    <div className='bg-blueRyb text-white w-5 h-5 text-sm rounded-full inline-flex items-center justify-center'>
                      {index + 1}
                    </div>
                  </div>
                  <div className='inline-block'>
                    <span className='text-feldgrau'>{instruction}</span>
                  </div>
                </div>
              ))}
            </div>
            {/* Countdown  */}
            <div className='countdown flex flex-col items-center justify-center bg-alice-blue p-6 rounded-xl mt-4 lg:mt-6'>
              <p className='text-raisin-black mb-3'>Giao dịch sẽ kết thúc sau:</p>
              <div className='flex items-center space-x-1'>
                <div className='bg-neon-fuchsia text-white font-semibold w-7 h-7 flex items-center justify-center rounded-md'>
                  {String(displayTime.min).padStart(2, '0')}
                </div>
                <span className='text-raisin-black text-md'>:</span>
                <div className='bg-neon-fuchsia text-white font-semibold w-7 h-7 flex items-center justify-center rounded-md'>
                  {String(displayTime.seconds).padStart(2, '0')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='h-[1px] w-full bg-gainsboro my-4' />
        {/* Banks  */}
        <div className='banks'>
          <div className='panel'>
            <h5 className='text-raisin-black font-semibold'>
              Danh sách Ngân hàng/ Ví điện tử có áp dụng khuyến mãi
            </h5>
            <div className='grid grid-cols-3 gap-2 mt-4 md:grid-cols-5'>
              {
                bankList.map((b, i) => (
                  <button className='item bg-anti-flash-white2 flex items-center justify-center px-3 py-2 rounded-lg' key={`bank-${i.toString()}`}>
                    <img src={b.icon} alt={b.name} />
                  </button>
                ))
              }
            </div>
          </div>
          <div className='panel mt-6'>
            <h5 className='text-raisin-black font-semibold'>
              Danh sách Ngân hàng/ Ví điện tử khác ỗ trợ thanh toán
            </h5>
            <div className='grid grid-cols-3 gap-2 mt-4 md:grid-cols-5'>
              {
                bankList.map((b, i) => (
                  <button className='item bg-anti-flash-white2 flex items-center justify-center px-3 py-2 rounded-lg' key={`bank-${i.toString()}`}>
                    <img src={b.icon} alt={b.name} />
                  </button>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ProcessPaymentModal;
