import React from 'react';

import ManufacturesContainer from 'containers/ManufacturesContainer';

const Manufacturers: React.FC = () => (
  <div className='p-manufacturers bg-page'>
    <ManufacturesContainer />
  </div>
);

export default Manufacturers;
