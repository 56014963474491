/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';

import Switch from '../Switch';

import Button from 'components/atoms/Button';
import Radio from 'components/atoms/Radio';

interface SocialLink {
  key: string;
  icon: string;
  title?: string;
  desc?: string;
  isButton: boolean;
  isLinked?: boolean;
}

interface InformationContainerProps {
  title: string;
  links: SocialLink[];
  onClick?: (key: string) => void;
  isChecked?: boolean;
  onCheckChange?: () => void;
  onRadioSelect?: (selectedKey: string) => void;
  isUpdate?: boolean;
}

// Separate the SocialLinkItem component for individual link rendering
const SocialLinkItem: React.FC<{
  link: SocialLink;
  onClick?: (key: string) => void;
  isCheckMode?: boolean;
  onRadioChange?: (key: string) => void;
  notiEnabled?: boolean;
  isSelected?: boolean;
  isUpdate?: boolean
}> = ({
  link, onClick, isCheckMode, onRadioChange, notiEnabled, isSelected, isUpdate
}) => (
    <div className='flex items-center justify-between mb-3'>
      <div className='flex items-center space-x-2 xl:space-x-4'>
        <div className='w-10 h-10 p-2 rounded-full bg-[#52D2CA29] flex items-center justify-center'>
          <img src={link.icon} alt={link.title || ''} className='w-5 h-5' />
        </div>
        <div className='max-w-[119px] md:max-w-[211px]'>
          {link.title && <p className='text-sm md:text-md font-medium text-[#84939A] truncate'>{link.title}</p>}
          {link.desc && <p className='text-md md:text-base font-medium text-[#1F2128] truncate'>{link.desc}</p>}
        </div>
      </div>
      {isCheckMode ? (
        <div className={`${notiEnabled ? 'opacity-1' : 'opacity-50 pointer-events-none'}`}>
          <Radio
            label=''
            value={link.key}
            name='notification'
            checked={isSelected}
            onChange={() => onRadioChange?.(link.key)}
          />
        </div>
      ) : (
        <Button
          variants='secondary'
          onClick={() => onClick?.(link.key)}
          disabled={link.isLinked}
          className='w-[110px]'
        >
          {link.isLinked ? 'Đã Liên Kết' : (isUpdate ? 'Cập nhật' : 'Liên Kết')}
        </Button>
      )}
    </div>
  );

// Main component
const InformationContainer: React.FC<InformationContainerProps> = ({
  title,
  links,
  onClick,
  isChecked,
  onCheckChange,
  onRadioSelect,
  isUpdate
}) => {
  const [selectedRadio, setSelectedRadio] = useState<string>('email'); // Set 'email' as the default value

  // Trigger onRadioSelect when the switch is enabled
  useEffect(() => {
    if (isChecked && selectedRadio) {
      onRadioSelect?.(selectedRadio);
    }
  }, [isChecked, selectedRadio, onRadioSelect]);

  const handleRadioChange = (key: string) => {
    setSelectedRadio(key);
    onRadioSelect?.(key); // Notify parent of change
  };

  return (
    <div className='bg-[#F3F7FC] p-4 rounded-lg'>
      <div className='flex justify-between'>
        <h2 className='font-semibold text-lg text-[#2E3538] mb-3'>{title}</h2>
        {onCheckChange && <Switch checked={!!isChecked} onChange={onCheckChange} />}
      </div>
      {links.map((link, idx) => (
        <SocialLinkItem
          key={`${link.title}-${idx.toString()}`}
          link={link}
          onClick={onClick}
          isCheckMode={!!onCheckChange}
          onRadioChange={handleRadioChange}
          notiEnabled={isChecked}
          isSelected={selectedRadio === link.key}
          isUpdate={isUpdate}
        />
      ))}
    </div>
  );
};

export default InformationContainer;
