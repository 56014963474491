import classNames from 'classnames';
import React, { useState } from 'react';

export type PaymentOptionsType = {
  id: string;
  label: string;
  description: string;
  logo: string;
};

interface PaymentMethodSelectionProps {
  onChange?: (value: string) => void;
  isStatic?: boolean;
  options?: PaymentOptionsType[];
  selectedValue?: string;
  isOver20?: boolean;
}

const PaymentMethodSelection: React.FC<PaymentMethodSelectionProps> = ({
  onChange, options, selectedValue, isStatic, isOver20
}) => {
  const [selectedMethod, setSelectedMethod] = useState<string>(selectedValue || '');

  const handleSelect = (id: string) => {
    setSelectedMethod(id);
    onChange?.(id);
  };

  const generalClass = classNames(
    'flex items-center ',
    {
      'border rounded-lg cursor-pointer py-[10px] px-4': !isStatic,
    }
  );
  const paymentOptions = (() => (isStatic
    ? options?.filter((option) => option.id === selectedValue)
    : options))();

  return (
    <div className='o-paymentMethodSelection space-y-4'>
      {paymentOptions?.map((option) => (
        <div
          key={option.id}
          onClick={() => handleSelect(option.id)}
          // eslint-disable-next-line max-len
          // className={`${generalClass} ${!isOver20 ? (option.id === 'vietqr' || option.id === 'banking' || option.id === 'zalopay' || option.id === 'vnpay') : () ? '' : 'pointer-events-none opacity-60'} ${selectedMethod === option.id && !isStatic ? 'border-blueRyb border-[2px] shadow-input-focus' : 'border-gainsboro'}`}
          // eslint-disable-next-line no-nested-ternary
          className={`${generalClass} ${!isOver20
            ? (['vietqr', 'banking', 'vnpay'].includes(option.id) ? '' : 'pointer-events-none bg-anti-flash-white2')
            : ((option.id !== 'vietqr' && option.id !== 'banking') ? 'pointer-events-none bg-anti-flash-white2' : '')
            } ${selectedMethod === option.id && !isStatic
              ? 'border-blueRyb border-[2px] shadow-input-focus'
              : 'border-gainsboro'
            }`}
        >
          <img src={option.logo} alt={option.label} className='w-8 h-8 mr-3' />
          <div className='flex-grow'>
            <div className='font-semibold text-gunmetal-700'>{option.label}</div>
            <div className='text-roman-silver text-md'>{option.description}</div>
          </div>
          {
            !isStatic
            && (
              <div className='ml-4'>
                <div
                  className={`relative w-5 h-5 rounded-full border-2 
              ${selectedMethod === option.id ? 'border-blueRyb bg-blueRyb' : 'border-roman-silver'}
            `}
                >
                  {selectedMethod === option.id && (
                    <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center'>
                      <div className='w-2.5 h-2.5 bg-white rounded-full' />
                    </div>
                  )}
                </div>
              </div>
            )
          }
        </div>
      ))}
    </div>
  );
};

export default PaymentMethodSelection;
