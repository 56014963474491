/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ProductType } from 'components/organisms/ProductCard';
import CategoryCardSlide from 'components/templates/CategoryCardSlide';
import ProductGrid from 'components/templates/ProductGrid';
import {
  getCategoriesWithProductsCountApi,
  getFeaturedProductsApi,
} from 'services/home';

export interface IFeaturedContainer {
  products: ProductType[],
  loading: boolean,
  refetchData: () => void,
}

const FeaturedProductContainer = ({ products, loading, refetchData }: IFeaturedContainer) => {
  const [categories, setCategories] = useState<any[]>([...new Array(5)]);
  const [isLoadingCate, setIsLoadingCate] = useState<boolean>(false);

  const fetchCategoriesWithProductsCount = async () => {
    try {
      setIsLoadingCate(true);
      const response: any = await getCategoriesWithProductsCountApi();
      setCategories(
        response?.data?.categories?.map((category: any) => ({
          title: category?.name,
          sub: `${category?.numberOfProducts} products`,
          image: 'https://picsum.photos/50',
          id: category.id,
        }))
      );
      setIsLoadingCate(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
    }
  };
  useEffect(() => {
    fetchCategoriesWithProductsCount();
  }, []);

  return (
    <div className='container mx-auto'>
      {/* <CategoryCardSlide data={categories} isLoadingFetch={isLoadingCate} /> */}
      <ProductGrid products={products} isLoadingFetch={loading} refetchData={refetchData} />
    </div>
  );
};

export default FeaturedProductContainer;
