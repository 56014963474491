import React, { useEffect } from 'react';

import CartContainer from 'containers/CartContainer';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { setCartStepReducer } from 'store/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const Cart: React.FC = () => {
  const [summaryOrderHeight, setSummaryOrderHeight] = React.useState(0);
  const { cartStep } = useAppSelector((state) => state.general);
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();

  useEffect(() => {
    dispatch(setCartStepReducer(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const summaryEle = document.querySelector('.o-orderSummary__mobile');
    if (summaryEle) {
      if (width < 1024) {
        setSummaryOrderHeight(summaryEle.clientHeight);
      } else {
        setSummaryOrderHeight(0);
      }
    }
  }, [width, cartStep]);
  return (
    <div className='p-cart bg-page' style={summaryOrderHeight ? { paddingBottom: summaryOrderHeight } : {}}>
      <CartContainer />
    </div>
  );
};

export default Cart;
