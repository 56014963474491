import React from 'react';

interface Props {
  children: React.ReactNode;
  heading: string;
}

const PolicyLayout: React.FC<Props> = ({ children, heading }) => (
  <div className='t-policyLayout bg-page pt-6'>
    <div className='container mx-auto'>
      <div className='t-policyLayout__heading text-3xl font-semibold'>
        {heading}
      </div>
      <div className='t-policyLayout__content p-6 bg-white rounded-xl mt-4'>
        {children}
      </div>
    </div>
  </div>
);

export default PolicyLayout;
