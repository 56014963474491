/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import completeImg from 'assets/images/order-complete.png';
import { OrderDetailSkeleton } from 'components/atoms/Skeleton';
import { AddressItemType } from 'components/organisms/AddressSelector';
import ContentPanel from 'components/organisms/ContentPanel';
import OrderSummary from 'components/organisms/OrderSummary';
import PaymentInfo from 'components/templates/PaymentInfo';
import ProductTable from 'components/templates/ProductTable';
import useCart from 'hooks/useCart';
import { getOrderDetailData } from 'services/order';
import { setIsRepayment, setRepaymentData } from 'store/cart';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IDataOrderDetail } from 'types/order';
import {
  calculatePoint, decodeHtmlEntities, formatDate, formatVndCurrency
} from 'utils/functions';
import transOrderStatus, { PENDING } from 'utils/order';

interface Props {
  isUser?: boolean;
  isShowProfile?: boolean;
  orderId?: string;
}

export function compareAddressData(customerData: any, administrativeData: any) {
  try {
    let address2: any = {};
    if (!customerData?.address2) {
      return customerData;
    }

    if (typeof customerData?.address2 === 'string') {
      address2 = JSON.parse(customerData?.address2 || '');
    } else {
      address2 = customerData?.address2;
    }

    if (!address2?.city) {
      return customerData;
    }
    if (!address2.city || !address2.district) {
      return customerData;
    }

    // const cityInfo: any = administrativeData.find((city: any) => city?.codeName === address2.city);

    // if (!cityInfo) {
    //   return customerData;
    // }

    // const districtInfo = cityInfo.district?.find((district: any) => district?.codeName === address2.district);

    // if (!districtInfo) {
    //   return customerData;
    // }

    // const wardInfo = districtInfo?.ward?.find((ward: any) => ward.codeName === address2.ward);

    // if (address2.ward && !wardInfo) {
    //   return customerData;
    // }

    const formattedAddress = [
      customerData?.address,
      address2.ward,
      address2.district,
      address2.city
    ]
      ?.filter(Boolean)
      ?.join(', ')
      ?.trim();

    return {
      name: customerData?.name || '',
      phone: customerData?.phone || '',
      email: customerData?.email || '',
      address: formattedAddress || '',
      id: customerData?.id || null,
      address2: address2.city || ''
    };
  } catch (error) {
    console.warn('Failed to parse address2:', error);
    return customerData;
  }
}

const OrderCompletedContainer: React.FC<Props> = ({
  isUser,
  orderId,
  isShowProfile
}) => {
  const [data, setData] = useState<IDataOrderDetail>();
  const { user } = useAppSelector((state) => state.auth);
  const { id } = useParams();
  const { handleReOrder, handleRepayment } = useCart();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { provinces } = useAppSelector((state) => state.general);

  function getCheckoutNoteValue(attributeInfo: string, key: string): string | null {
    const decodedInfo = decodeHtmlEntities(attributeInfo);
    const attributeValuePairs = decodedInfo.split('<br />').map((pair) => pair.split(': ').map((str) => str.trim()));

    for (const [attributeName, valueName] of attributeValuePairs) {
      if (attributeName === key) {
        return valueName;
      }
    }

    return null;
  }

  const fetchDataOrderDetail = async () => {
    try {
      setLoading(true);
      const response = await getOrderDetailData(id as string || orderId as string);
      setData(response?.data);
      setLoading(false);

      dispatch(setRepaymentData(response?.data));
      dispatch(setIsRepayment(true));
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
      setLoading(false);
    }
  };

  useEffect(() => {
    if ((id || orderId) && user) {
      fetchDataOrderDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, orderId]);

  const customerParse = {
    name: `${data?.billingAddress?.firstName} ${data?.billingAddress?.lastName}` || '',
    phone: data?.billingAddress?.phoneNumber || '',
    address: data?.billingAddress?.address1 || data?.billingAddress?.address2 || '',
    email: data?.billingAddress?.email || '',
    id: data?.id,
    address2: data?.billingAddress?.address2 || '',
  };
  const productParse = data?.items?.map((item) => ({
    id: item?.productId || 0,
    name: item?.productName || '',
    image: item?.defaultPictureModel?.imageUrl || 'https://picsum.photos/200',
    price: item?.unitPriceValue || 0,
    discount: 0,
    count: item?.quantity || 0,
    attributeInfo: item?.attributeInfo,
    productId: item.productId
  }));

  const orderSummary = {
    orderTotal: data?.orderTotal || '',
    orderTotalDiscount: formatVndCurrency(
      Number(data?.orderSubtotalValue || 0)
      - Number(data?.orderTotalValue || 0)
    ),
    subTotal: data?.orderSubtotal || '',
    willEarnRewardPoints: calculatePoint(data?.orderSubtotalValue || 0),
    subTotalDiscount: -(data?.orderTotalDiscountValue || 0),
    redeemedRewardPoints: data?.redeemedRewardPoints || 0,
    redeemedRewardPointsAmount: data?.redeemedRewardPointsAmount || '',
    isRepayment: data?.paymentMethodStatus === PENDING,
  };

  // const handleRepayment = async () => {
  //   try {
  //     dispatch(setLoadingReducer(true));
  //     const vnpayResponse: any = await createVnpayPaymentUrl({
  //       OrderId: Number(orderId),
  //     });
  //     setOpenFailedModal(false);
  //     dispatch(setLoadingReducer(false));
  //     window.open(vnpayResponse.url, '_self');
  //   } catch (error: any) {
  //     toast.error(error?.response?.data?.ErrorList?.[0] || 'Đã xảy ra lỗi');
  //   }
  // };

  const resultAddress = compareAddressData(customerParse as any, provinces as any);

  return (
    <div>
      {
        loading ? <OrderDetailSkeleton />
          : (
            <div className={`${!isShowProfile ? 'container' : ''} mx-auto`}>
              <div className='lg:flex gap-6'>
                <div className='w-full lg:w-8/12'>
                  <div className='flex flex-col gap-4 lg:gap-6'>
                    <ContentPanel contentClassName='p-6'>
                      {
                        !isUser
                        && (
                          <div className='top'>
                            <div className='img max-w-[120px] max-h-[120px] md:max-w-[148px] md:max-h-[148px] aspect-1 mx-auto'>
                              <img src={completeImg} alt='order complete' className='w-full h-full object-cover' />
                            </div>
                            <div>
                              <h2 className='text-4xl lg:text-5xl leading-[48px] lg:leading-[60px] font-semibold mt-6 max-w-[300px] text-center mx-auto'>
                                Đặt hàng thành công
                              </h2>
                              <div className='max-w-[750px] text-center mx-auto'>
                                <p className='text-feldgrau text-base md:text-lg mt-4'>
                                  Cảm ơn bạn đã đặt hàng tại BidaHub
                                </p>
                                <p className='text-feldgrau text-base md:text-lg'>
                                  Chúng tôi đã gửi thông tin đơn hàng đến Zalo và địa chỉ Email của bạn.
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      <div className='info mt-2'>
                        {
                          isUser
                            ? (
                              <h3 className='text-lg py-3 px-6 font-semibold border-gainsboro border-b-1'>
                                Thông tin chi tiết đơn hàng
                              </h3>
                            )
                            : (
                              <h3 className='text-xl lg:text-2xl font-semibold py-3 border-gainsboro border-b-1'>
                                Thông tin chi tiết đơn hàng
                              </h3>
                            )
                        }

                        <div className={`detail flex flex-col gap-2 ${isUser ? 'p-4 lg:p-6' : 'mt-4 lg:mt-6'}`}>
                          <p className='font-semibold text-lg'>
                            ID đơn hàng: <a className='text-brilliant-azure hover:underline'>#{data?.id}</a>
                          </p>
                          <div className='flex justify-between text-gunmetal'>
                            <p>Ngày đặt:</p>
                            <p>{formatDate(data?.createdOn)}</p>
                          </div>
                          <div className='flex justify-between text-gunmetal'>
                            <p>Trạng thái đơn hàng: </p>
                            {/* TODO: For only waiting payment status  */}
                            <p className={`${data?.paymentMethodStatus === PENDING ? 'text-neon-fuchsia' : ''}`}>{transOrderStatus(data)}</p>
                          </div>
                          {/* TODO: Note for orders when paying manually via bank transfer */}
                          <p className='text-gunmetal italic text-md'>Đơn hàng của bạn đã đuộc ghi nhận. Bộ phận CSKH của BidaHub sẽ sớm liên hệ với bạn trong thời gian sớm nhất để xác nhận và giao hàng.</p>
                        </div>
                        {
                          !isUser
                          && (
                            <div className='text-gunmetal bg-alice-blue p-3 lg:p-4 mt-4 lg:mt-6 rounded-lg'>
                              Khi cần hỗ trợ vui lòng gọi {' '}
                              <a href='tel:0392555999' className='text-brilliant-azure font-semibold'>0392.555.999</a>
                              {' '}
                              (8:00 - 20:00)
                            </div>
                          )
                        }
                      </div>
                    </ContentPanel>
                    <ContentPanel contentClassName='p-0'>
                      <ProductTable isStatic products={productParse as []} />
                    </ContentPanel>
                    <PaymentInfo
                      isStatic
                      noTitle={isUser}
                      paymentMethod={getCheckoutNoteValue(data?.checkoutAttributeInfo as string, 'PaymentMethod') as string}
                      notes={getCheckoutNoteValue(data?.checkoutAttributeInfo as string, 'Checkout Note') as string}
                      selectedAddress={resultAddress as AddressItemType}
                    />
                  </div>
                </div>
                <div className='w-full lg:w-4/12 mt-4 lg:mt-0'>
                  <OrderSummary
                    onReOrder={() => {
                      if (data?.paymentMethodStatus === PENDING) {
                        handleRepayment(Number(data?.id));
                      } else {
                        handleReOrder(String(data?.id));
                      }
                    }}
                    isCompleted
                    isDetail={isUser}
                    orderSummary={orderSummary as any}
                  />
                </div>
              </div>
            </div>
          )
      }
    </div>
  );
};

export default OrderCompletedContainer;
