import React from 'react';

import CategoryContainer from 'containers/CategoryContainer';

const SubCategory: React.FC = () => (
  <div className='p-subCategory bg-page'>
    <CategoryContainer isSub />
  </div>
);

export default SubCategory;
