import React, { useEffect, useState } from 'react';

interface QuantitySelectorProps {
  initialQuantity?: number;
  minQuantity?: number;
  maxQuantity?: number;
  onChange?: (quantity: number) => void;
  modifier?: 'lg';
  isFull?: boolean;
  isRepayment?: boolean;
}

const QuantitySelector: React.FC<QuantitySelectorProps> = ({
  initialQuantity = 1,
  minQuantity = 1,
  maxQuantity = 99,
  onChange,
  modifier,
  isFull,
  isRepayment
}) => {
  const [quantity, setQuantity] = useState(initialQuantity);

  const increment = () => {
    if (quantity < maxQuantity) {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      if (onChange) onChange(newQuantity);
    }
  };

  const decrement = () => {
    if (quantity > minQuantity) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      if (onChange) onChange(newQuantity);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= minQuantity && value <= maxQuantity) {
      setQuantity(value);
      if (onChange) onChange(value);
    }
  };

  const handleInputBlur = () => {
    // Reset to min or max if input goes out of bounds
    if (quantity < minQuantity) {
      setQuantity(minQuantity);
      if (onChange) onChange(minQuantity);
    }
    if (quantity > maxQuantity) {
      setQuantity(maxQuantity);
      if (onChange) onChange(maxQuantity);
    }
  };

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  return (
    <div className={`justify-between items-center space-x-2 border-gainsboro border rounded-lg${modifier === 'lg' ? ' p-3 h-12' : ' p-[5px] h-9'}${isFull ? ' flex' : ' inline-flex'}`}>
      <button
        onClick={decrement}
        className='w-7 h-7 rounded-full flex items-center justify-center'
        disabled={quantity === minQuantity || isRepayment}
      >
        <svg className='w-full h-full' width='24' height='24' viewBox='0 0 29 28' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
          <path d='M19.221 2.33337H9.44435C5.19768 2.33337 2.66602 4.86504 2.66602 9.11171V18.8767C2.66602 23.135 5.19768 25.6667 9.44435 25.6667H19.2093C23.456 25.6667 25.9877 23.135 25.9877 18.8884V9.11171C25.9993 4.86504 23.4677 2.33337 19.221 2.33337ZM18.9993 14.875H9.66602C9.18768 14.875 8.79102 14.4784 8.79102 14C8.79102 13.5217 9.18768 13.125 9.66602 13.125H18.9993C19.4777 13.125 19.8743 13.5217 19.8743 14C19.8743 14.4784 19.4777 14.875 18.9993 14.875Z' fill={quantity === minQuantity || isRepayment ? '#C7C9D1' : '#1F2128'} />
        </svg>
      </button>

      <input
        type='number'
        value={quantity}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        min={minQuantity}
        max={maxQuantity}
        disabled={isRepayment}
        className='w-12 text-md text-center p-0 border-0 outline-none shadow-none ring-0 focus:ring-0 focus:outline-none'
      />

      <button
        onClick={increment}
        className='w-7 h-7 rounded-full flex items-center justify-center'
        disabled={quantity === maxQuantity || isRepayment}
      >
        <svg className='w-full h-full' width='24' height='24' viewBox='0 0 29 28' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
          <path d='M19.221 2.33337H9.44435C5.19768 2.33337 2.66602 4.86504 2.66602 9.11171V18.8767C2.66602 23.135 5.19768 25.6667 9.44435 25.6667H19.2093C23.456 25.6667 25.9877 23.135 25.9877 18.8884V9.11171C25.9993 4.86504 23.4677 2.33337 19.221 2.33337ZM18.9993 14.875H15.2077V18.6667C15.2077 19.145 14.811 19.5417 14.3327 19.5417C13.8543 19.5417 13.4577 19.145 13.4577 18.6667V14.875H9.66602C9.18768 14.875 8.79102 14.4784 8.79102 14C8.79102 13.5217 9.18768 13.125 9.66602 13.125H13.4577V9.33337C13.4577 8.85504 13.8543 8.45837 14.3327 8.45837C14.811 8.45837 15.2077 8.85504 15.2077 9.33337V13.125H18.9993C19.4777 13.125 19.8743 13.5217 19.8743 14C19.8743 14.4784 19.4777 14.875 18.9993 14.875Z' fill={quantity === maxQuantity || isRepayment ? '#C7C9D1' : '#1F2128'} />
        </svg>
      </button>
    </div>
  );
};

export default QuantitySelector;
