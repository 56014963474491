/* eslint-disable max-len */
import React from 'react';

import CustomModal from '../Modal';

import bankLineTexture from 'assets/images/bank-line.png';
import qrMBBank from 'assets/images/banks/qr_banking.jpg';
// import qrImage from 'assets/images/qr-image.png';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import { formatVndCurrency, handleCopyToClipboard } from 'utils/functions';

export interface OrderSummaryDataTypes {
  orderNumber: string;
  totalAmount: number;
  qrCode: string;
}

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  order: OrderSummaryDataTypes;
  onSuccess?: () => void;
}

const BankingMethodModal: React.FC<Props> = ({
  isOpen, order, handleClose, onSuccess
}) => {
  const accountNumber: string = '0973458511';
  return (
    <div>
      <CustomModal
        isOpen={isOpen}
        onRequestClose={handleClose}
        title='Thanh toán đơn hàng'
        className='max-w-3xl'
        modalFooter={(
          <div className='flex flex-row gap-4 justify-end'>
            <Button
              size='large'
              variants='secondary'
              className='max-w-[216px]'
              onClick={handleClose}
            >
              Đổi phương thức khác
            </Button>
            <Button onClick={onSuccess} size='large' variants='neon-fuchsia' className='max-w-[160px]'>
              Đã thanh toán
            </Button>
          </div>
        )}
      >
        <div className='info bg-anti-flash-white2 p-4 relative'>
          {order?.orderNumber && (
            <div className='item flex items-center justify-between py-2 border-b border-dashed border-gainsboro'>
              <p className='text-feldgrau text-md'> Mã đơn hàng</p>
              <p className='text-gunmetal text-md font-semibold'>#{order.orderNumber}</p>
            </div>
          )}
          <div className='item flex items-center justify-between py-2 border-b border-dashed border-gainsboro'>
            <p className='text-feldgrau text-md'> Phương thức thanh toán</p>
            <div className='text-gunmetal text-md flex items-center gap-x-1'>
              <Icon iconName='bank' size='32x32' />
              Chuyển khoản
            </div>
          </div>
          <div className='item flex items-center justify-between py-2 border-b border-dashed border-gainsboro'>
            <p className='text-feldgrau text-md'>Số tiền cần thanh toán</p>
            <p className='text-neon-fuchsia text-lg font-semibold'>{formatVndCurrency(order.totalAmount)}</p>
          </div>
          <div className='line absolute top-[100%] left-0'>
            <img src={bankLineTexture} alt='texture' className='w-full' />
          </div>
        </div>
        <div className='instruction flex gap-x-6 mt-8'>
          <div className='qrCode aspect-3/4 w-[200px] bg-white rounded-lg shadow-sm'>
            <img src={order.qrCode || qrMBBank} alt='qr code' className='w-full object-contain rounded-lg' />
          </div>
          <div className='content'>
            <h4 className='font-semibold text-gunmetal'>Hướng dẫn thanh toán</h4>
            <p className='mt-2 text-feldgrau text-sm italic'>Quét mã QR hoặc chuyển khoản theo thông tin sau</p>
            <ul className='text-gunmetal text-md mt-2 flex flex-col gap-y-1 font-medium'>
              <li>Ngân hàng: MBBank</li>
              <li>Số tài khoản: {accountNumber} <button onClick={() => handleCopyToClipboard(String(accountNumber))} className='text-brilliant-azure'>[Sao chép]</button></li>
              <li>Chủ tài khoản: TRUONG THI MY HANH</li>
              {order?.orderNumber ? (
                <li>
                  <p className='italic'>Nội dung thanh toán: Mã đơn hàng + Số điện thoại mua hàng
                    <span className='block'>(Ví dụ: 596302 0949696696)</span>
                  </p>
                </li>
              )
                : (
                  <li>
                    <p className='italic'>Nội dung thanh toán: Số điện thoại mua hàng
                      <span className='block'>(Ví dụ: 0949696696)</span>
                    </p>
                  </li>
                )}
            </ul>
          </div>
        </div>
        <p className='text-sm italic mt-3 text-gunmetal'>Sau khi chuyển khoản, vui lòng nhấn vào nút “Đã Thanh Toán” để hoàn tất giao dịch. Bộ CSKH của BidaHub sẽ sớm liên hệ với bạn sau khi đơn hàng đã thanh toán để xác nhận và giao hàng.</p>
      </CustomModal>
    </div>
  );
};

export default BankingMethodModal;
