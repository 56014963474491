/* eslint-disable import/prefer-default-export */
import React from 'react';

import Button from 'components/atoms/Button';
import CustomModal from 'components/organisms/Modal';
import { closeConfirmModalReducer } from 'store/general';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface ConfirmModalProps {
  onConfirm: () => void;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({ onConfirm }) => {
  const { confirm: confirmModalState } = useAppSelector((state) => state.general.modalVisible);
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeConfirmModalReducer('confirm'));
  };
  return (
    <div>
      <CustomModal
        isOpen={confirmModalState.visible}
        onRequestClose={onClose}
        title={confirmModalState.title}
        modalFooter={(
          <div className='flex flex-row gap-4 justify-end'>
            <Button
              onClick={onClose}
              size='large'
              variants='secondary'
              className='max-w-[120px]'
            >
              Không
            </Button>
            <Button
              onClick={onConfirm}
              size='large'
              className='max-w-[120px]'
            >
              Đồng ý
            </Button>
          </div>
        )}
      >
        <p className='text-gunmetal p-6'>
          {confirmModalState.message}
        </p>
      </CustomModal>
    </div>
  );
};
