import classNames from 'classnames';
import React, { ButtonHTMLAttributes, useState } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  icon?: string;
  rtl?: boolean;
  size?: 'medium' | 'large' | 'oversize';
  className?: string;
  variants?: 'primary' | 'secondary' | 'neon-fuchsia';
}

interface InnerIconProps {
  icon: string;
  isHovered?: boolean;
  variants?: 'primary' | 'secondary' | 'neon-fuchsia';
  size?: 'medium' | 'large' | 'oversize';
  disabled?: boolean;
}

const getButtonClasses = (
  size: ButtonProps['size'],
  variants: ButtonProps['variants'],
  disabled: boolean,
  loading: boolean
) => {
  const baseClasses = 'a-button button-base-style w-full rounded-lg font-semibold capitalize';
  return classNames(
    baseClasses,
    {
      'px-4 py-2 text-md h-[36px]': size === 'medium',
      'px-4 py-3 text-base h-[48px]': size === 'large',
      'px-4 py-3 text-base h-[56px]': size === 'oversize',
      'bg-anti-flash-white2 text-slate-gray pointer-events-none border-0': disabled,
      'bg-blueRyb text-white lg:hover:bg-blueRyb2 active:bg-blueRyb2': !disabled && variants === 'primary',
      'bg-white text-gunmetal border border-brilliant-azure lg:hover:bg-alice-blue lg:hover:text-brilliant-azure active:bg-alice-blue active:text-brilliant-azure':
        !disabled && variants === 'secondary',
      'bg-neon-fuchsia text-white border border-neon-fuchsia lg:hover:bg-white lg:hover:text-neon-fuchsia active:bg-white active:text-neon-fuchsia':
        !disabled && variants === 'neon-fuchsia',
      'pointer-events-none opacity-75': loading,
    }
  );
};

const LoadingSpinner: React.FC = () => (
  <svg className='animate-spin h-5 w-5 mr-3 opc' viewBox='0 0 24 24'>
    <circle
      className='opacity-25'
      cx='12'
      cy='12'
      r='10'
      stroke='currentColor'
      strokeWidth='4'
    />
    <path
      className='opacity-75'
      fill='currentColor'
      d='M4 12a8 8 0 018-8v8H4z'
    />
  </svg>
);

const InnerIcon: React.FC<InnerIconProps> = ({
  icon,
  isHovered,
  variants,
  size = 'medium',
  disabled,
}) => {
  const iconColorMap = {
    primary: 'white',
    secondary: isHovered ? '#3D8EFF' : '#2E3538',
    'neon-fuchsia': isHovered ? '#F5416C' : '#ffffff',
  };

  const iconSizeMap = {
    medium: '20',
    large: '24',
    oversize: '26',
  };

  const fillColor = disabled ? '#75848A' : iconColorMap[variants || 'primary'];
  const iconSize = iconSizeMap[size];

  switch (icon) {
    case 'eye':
      return (
        <svg
          width={iconSize}
          height={iconSize}
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z'
            stroke={fillColor}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z'
            stroke={fillColor}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      );
    case 'plus':
      return (
        <svg
          width={iconSize}
          height={iconSize}
          viewBox='0 0 21 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11 2.75C11 2.33579 10.6642 2 10.25 2C9.83579 2 9.5 2.33579 9.5 2.75V9H3.25C2.83579 9 2.5 9.33579 2.5 9.75C2.5 10.1642 2.83579 10.5 3.25 10.5H9.5V16.75C9.5 17.1642 9.83579 17.5 10.25 17.5C10.6642 17.5 11 17.1642 11 16.75V10.5H17.25C17.6642 10.5 18 10.1642 18 9.75C18 9.33579 17.6642 9 17.25 9H11V2.75Z'
            fill={fillColor}
          />
        </svg>
      );
    case 'cart':
      return (
        <svg
          width={iconSize}
          height={iconSize}
          viewBox='0 0 25 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M18 20.75C18 21.4404 17.4404 22 16.75 22C16.0596 22 15.5 21.4404 15.5 20.75C15.5 20.0596 16.0596 19.5 16.75 19.5C17.4404 19.5 18 20.0596 18 20.75Z' fill={fillColor} stroke={fillColor} />
          <path d='M10 20.75C10 21.4404 9.44036 22 8.75 22C8.05964 22 7.5 21.4404 7.5 20.75C7.5 20.0596 8.05964 19.5 8.75 19.5C9.44036 19.5 10 20.0596 10 20.75Z' fill={fillColor} stroke={fillColor} />
          <path d='M5.83834 3.98068L5.83841 3.98069L5.83896 3.97219C5.86733 3.53252 5.72482 3.09755 5.41924 2.76287L5.41933 2.76278L5.41168 2.75476C5.11093 2.43969 4.69177 2.25 4.24 2.25H2.5C2.36614 2.25 2.25 2.13386 2.25 2C2.25 1.86614 2.36614 1.75 2.5 1.75H4.24C4.81521 1.75 5.38255 1.99766 5.79089 2.42739C6.03653 2.70687 6.1996 3.04635 6.28243 3.41081L6.37089 3.8H6.77H19.06C20.5416 3.8 21.6599 4.9953 21.5513 6.47338L21.5513 6.47438C21.5403 6.62815 21.4123 6.75 21.25 6.75H5.94C5.75356 6.75 5.62382 6.60139 5.6382 6.4324L5.63834 6.43068L5.83834 3.98068Z' fill={fillColor} stroke={fillColor} />
          <path d='M21.0101 8.75H5.67005C5.25005 8.75 4.91005 9.07 4.87005 9.48L4.51005 13.83C4.37005 15.54 5.71005 17 7.42005 17H18.5401C20.0401 17 21.3601 15.77 21.4701 14.27L21.8001 9.6C21.8401 9.14 21.4801 8.75 21.0101 8.75Z' fill={fillColor} />
        </svg>

      );
    case 'liked':
      return (
        <svg
          width={iconSize}
          height={iconSize}
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M6.99121 15.4083V6.94166C6.99121 6.60832 7.09121 6.28332 7.27454 6.00832L9.54954 2.62499C9.90788 2.08332 10.7995 1.69999 11.5579 1.98332C12.3745 2.25832 12.9162 3.17499 12.7412 3.99166L12.3079 6.71666C12.2745 6.96666 12.3412 7.19166 12.4829 7.36666C12.6245 7.52499 12.8329 7.62499 13.0579 7.62499H16.4829C17.1412 7.62499 17.7079 7.89166 18.0412 8.35832C18.3579 8.80832 18.4162 9.39166 18.2079 9.98332L16.1579 16.225C15.8995 17.2583 14.7745 18.1 13.6579 18.1H10.4079C9.84954 18.1 9.06621 17.9083 8.70788 17.55L7.64121 16.725C7.23288 16.4167 6.99121 15.925 6.99121 15.4083Z' fill={fillColor} />
          <path d='M4.34199 5.31668H3.48366C2.19199 5.31668 1.66699 5.81668 1.66699 7.05001V15.4333C1.66699 16.6667 2.19199 17.1667 3.48366 17.1667H4.34199C5.63366 17.1667 6.15866 16.6667 6.15866 15.4333V7.05001C6.15866 5.81668 5.63366 5.31668 4.34199 5.31668Z' fill={fillColor} />
        </svg>
      );
    case 'arrowRight':
      return (
        <svg
          width={iconSize}
          height={iconSize}
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M13.7045 4.28377C13.3111 3.89615 12.678 3.90084 12.2904 4.29424C11.9027 4.68765 11.9074 5.3208 12.3008 5.70842L17.6712 10.9998H4C3.44771 10.9998 3 11.4475 3 11.9998C3 12.5521 3.44772 12.9998 4 12.9998H17.6646L12.3008 18.2847C11.9074 18.6723 11.9027 19.3055 12.2904 19.6989C12.678 20.0923 13.3111 20.097 13.7045 19.7094L20.6287 12.887C21.1256 12.3974 21.1256 11.5958 20.6287 11.1062L13.7045 4.28377Z'
            fill={fillColor}
          />
        </svg>
      );

    default:
      return null;
  }
};

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  onClick,
  children,
  disabled = false,
  loading = false,
  rtl = false,
  icon,
  size = 'medium',
  variants = 'primary',
  className,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonClass = getButtonClasses(size, variants, disabled, loading);

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${buttonClass} ${className}`}
      disabled={disabled || loading}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        icon
        && !rtl && (
          <div className={`mr-2 inline-block ${icon}`}>
            <InnerIcon
              disabled={disabled}
              size={size}
              variants={variants}
              icon={icon}
              isHovered={isHovered}
            />
          </div>
        )
      )}
      {children}
      {!loading && icon && rtl && (
        <div className={`ml-2 inline-block ${icon}`}>
          <InnerIcon
            disabled={disabled}
            size={size}
            variants={variants}
            icon={icon}
            isHovered={isHovered}
          />
        </div>
      )}
    </button>
  );
};

export default Button;
