import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductSummaryType } from 'components/templates/ProductTable';

interface IInitialState {
  data: ProductSummaryType[];
  loading: boolean;
}

const initialState: IInitialState = {
  data: [],
  loading: false,
};

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    setWishListData: ($state, action: PayloadAction<any>) => {
      $state.data = action.payload;
    },
    setLoading: ($state, action: PayloadAction<boolean>) => {
      $state.loading = action.payload;
    },
  },
});

export const { setWishListData, setLoading } = wishlistSlice.actions;
export default wishlistSlice.reducer;
