// src/components/Breadcrumbs.tsx

import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbItem {
  label: string;
  path?: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => (
  <nav className='flex rounded'>
    {items.map((item, index) => (
      <div key={`breadcrumb-${index.toString()}`} className='flex items-center'>
        {index > 0 && (
          <svg
            className='w-4 h-4 mx-2 text-gray-400'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M9 5l7 7-7 7'
            />
          </svg>
        )}
        {
          item.path ? (
            <Link
              to={item.path}
              className={`text-sm font-medium ${index === items.length - 1 ? 'text-gunmetal' : 'text-manatee hover:underline'
                }`}
            >
              {item.label}
            </Link>
          ) : (
            <div
              className={`text-sm font-medium ${index === items.length - 1 ? 'text-gunmetal' : 'text-manatee'
                }`}
            >
              {item.label}
            </div>
          )
        }

      </div>
    ))}
  </nav>
);

export default Breadcrumbs;
