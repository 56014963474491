import React, {
  useEffect, useState
} from 'react';
import { useSearchParams } from 'react-router-dom';

import Receipt from 'assets/icons//receipt-item.svg';
import Dollar from 'assets/icons/dollar-circle.svg';
import icBlueHeart from 'assets/icons/ic_blue_heart.svg';
import location from 'assets/icons/location.svg';
import mailAlert from 'assets/icons/mailAlert.svg';
import ProfileActive from 'assets/icons/profile-active.svg';
import { Drawer, MenuItem } from 'components/organisms/Drawer';
import UserAddressContainer from 'containers/CustomerContainer/UserAddress';
import UserInfo from 'containers/CustomerContainer/UserInfo';
import UserOrderContainer from 'containers/CustomerContainer/UserOrder';
import NotifyProduct from 'containers/NotifyProduct';
import ProductLiked from 'containers/ProductLiked';
import { UserBcoinContainer, UserReviewsContainer } from 'containers/UserDashboardBackup';
import useOrderHistory from 'hooks/useOrderHistory';

const DefaultPage = () => <div>Default Page</div>;

const CustomerInfo: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const routeKey = searchParams.get('key') || '';
  const { data } = useOrderHistory();

  const dummyData = [
    {
      iconUrl: ProfileActive,
      text: 'Thông tin khách hàng',
      routeKey: 'customer-info',
    },
    {
      iconUrl: location,
      text: 'Địa chỉ giao hàng',
      routeKey: 'delivery-info',
    },
    {
      iconUrl: Receipt,
      text: 'Quản lý đơn hàng',
      count: data?.length,
      routeKey: 'order-history',
    },
    {
      iconUrl: Dollar,
      text: 'Điểm thưởng B-coin',
      routeKey: 'b-coin',
    },
    {
      iconUrl: icBlueHeart,
      text: 'Sản phẩm yêu thích',
      routeKey: 'liked',
    },
    {
      iconUrl: mailAlert,
      text: 'Thông báo khi có hàng',
      routeKey: 'notification',
    },
    // {
    //   iconUrl: comment,
    //   text: 'Sản phẩm đã đánh giá',
    //   routeKey: 'product-rating',
    // }
  ];

  const handleToggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const renderContent = () => {
    switch (routeKey) {
      case 'customer-info':
        return <UserInfo onBack={handleToggleDrawer} />;
      case 'delivery-info':
        return <UserAddressContainer onBack={handleToggleDrawer} />;
      case 'order-history':
        return <UserOrderContainer onBack={handleToggleDrawer} />;
      case 'b-coin':
        return <UserBcoinContainer onBack={handleToggleDrawer} />;
      case 'notification':
        return <NotifyProduct />;
      case 'product-rating':
        return <UserReviewsContainer onBack={handleToggleDrawer} />;
      case 'liked':
        return <ProductLiked onBack={handleToggleDrawer} />;
      default:
        return <DefaultPage />;
    }
  };

  const handleMenuItemClick = (key: string) => {
    setSearchParams({ key }); // Update the query parameter with the key
    setIsOpen(false); // Optionally close the drawer after updating the params
  };

  useEffect(() => {
    if (!searchParams.get('key')) {
      setSearchParams({ key: dummyData[0].routeKey });
    }
  }, [searchParams, setSearchParams]);

  return (
    <div className='p-customerInfo bg-alice-blue2 bg-page'>
      <div className='container mx-auto'>
        <h2 className='hidden xl:block text-lg sm:text-xl xl:text-3xl font-semibold pt-6'>Tài khoản</h2>
        <div className='flex flex-col items-start xl:flex-row xl:items-start lg:gap-4 xl:gap-6 pt-6 md:pt-4'>
          <div className=''>
            <Drawer
              isOpen={isOpen}
              handleClose={handleToggleDrawer}
            >
              <ul>
                {dummyData.map((item, idx) => (
                  <MenuItem
                    key={`menu-item-${String(idx)}`}
                    {...item}
                    handleClick={() => handleMenuItemClick(item.routeKey)}
                    activeKey={routeKey}
                  />
                ))}
              </ul>
            </Drawer>
          </div>
          <div className='content w-full'>
            {renderContent()} {/* Render the content based on the key */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
