import React from 'react';

import { colorSelectionsDumy, handySelectionDummy, sizeSelectionDummy } from 'assets/dummy';
import closeImg from 'assets/icons/ic_circle_close.svg';
import BackgroundBadge from 'components/atoms/BackgroundBadge';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import BoxSelections from 'components/molecules/BoxSelections';
import QuantitySelector from 'components/molecules/QuantitySelector';
import { ProductType } from 'components/organisms/ProductCard';
import SLUG_PARAMS from 'utils/constants';
import { formatVndCurrency } from 'utils/functions';

interface ProductVariantSelectionMobileProps {
  data: ProductType;
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: (data: any) => void;
}

const ProductVariantSelectionMobile: React.FC<ProductVariantSelectionMobileProps> = ({
  data, isOpen = false, onClose, onSubmit
}) => {
  const handleSubmit = () => {
    onSubmit?.(1);
  };
  return (
    <div className={`t-productVariantSelectionMobile ${isOpen ? 'is-open' : ''}`}>
      <div className='layer z-[1]' onClick={onClose} />
      <div className='bg-white p-4 relative z-10 w-full'>
        <div className='top flex gap-3 p-1 items-center relative'>
          <button
            onClick={onClose}
            className='absolute top-0 right-0 md:top-2 md:right-2 rounded-lg hover:bg-anti-flash-white2 transition ease-in-out duration-300 p-1'
          >
            <img src={closeImg} alt='close' className='w-6' />
          </button>
          <div className='flex-none w-[72px]'>
            <a href={`${SLUG_PARAMS.PRODUCTS_DETAIL}/:id`} className='aspect-1 w-full rounded-lg block overflow-hidden'>
              <img src={data.imageSrc} alt={data.title} loading='lazy' className='w-full h-full object-cover' />
            </a>
          </div>
          <div className='flex-none w-full'>
            <h3 className='title'>{data.title}</h3>
            <p className='font-semibold mt-1'>{formatVndCurrency(data.price)}</p>
            <div className='mt-2'>
              <BackgroundBadge theme='turquoise' icon={<Icon iconName='verifyTurquoise' size='16x16' />}>
                BidaHub
              </BackgroundBadge>
            </div>
          </div>
        </div>
        <div className='h-[1px] bg-gainsboro w-full my-4' />
        <div className='flex flex-col gap-6'>
          <BoxSelections
            title='Màu sắc'
            isNaked
            options={colorSelectionsDumy}
            onSelect={(value) => console.log(value)}
          />
          <BoxSelections
            title='Kích thước'
            isNaked
            options={sizeSelectionDummy}
            onSelect={(value) => console.log(value)}
          />
          <BoxSelections
            title='Phân loại'
            isNaked
            options={handySelectionDummy}
            onSelect={(value) => console.log(value)}
          />
        </div>
        <div className='h-[1px] bg-gainsboro w-full my-4' />
        <div className='mb-4'>
          <p className='text-gunmetal font-semibold mb-3'> Số lượng</p>
          <QuantitySelector
            initialQuantity={data.count || 0}
            isFull
            modifier='lg'
            onChange={(value) => console.log(value)}
          />
        </div>
        <Button onClick={handleSubmit} className='text-white bg-blueRyb hover:bg-blue-400  button-base-style flex-none'>
          Cập nhật
        </Button>
      </div>
    </div>
  );
};

export default ProductVariantSelectionMobile;
