import React from 'react';

import { CategoryCardSkeleton } from 'components/atoms/Skeleton';

export type CategoryCardType = {
  title: string;
  sub: string;
  image: string;
  id: number;
};

interface CategoryCardProps {
  data: CategoryCardType;
  onClick?: (id: number) => void;
  isLoadingFetch?: boolean;
}

const CategoryCard: React.FC<CategoryCardProps> = ({
  data, onClick, isLoadingFetch
}) => {
  if (isLoadingFetch) {
    return <CategoryCardSkeleton />;
  }
  return (
    <button className='m-categoryCard flex cursor-pointer px-3 py-[10px] md:px-4 md:py-2 bg-white rounded-lg hover:shadow-md base-transition justify-between' onClick={() => onClick?.(data.id)}>
      <div className='min-w-[160px] text-left' title={data?.title}>
        <h3 className='text-md md:text-base font-semibold line-clamp-1'>{data?.title}</h3>
        <p className='text-feldgrau text-sm'>{data?.sub}</p>
      </div>
      <div className='flex-none w-[40px] h-[40px]'>
        <img src={data?.image} loading='lazy' alt={data?.title} className='w-full h-full object-cover' />
      </div>
    </button>
  );
};

export default CategoryCard;
