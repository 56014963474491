import React, { useRef, useState } from 'react';

import Complete from './complete';
import ForgotPassword from './forgotPassword';
import OTPForgotPassword, { OTPVerifyActionRef } from './otpForgotPassword';
import OTPScreen, { OTPScreenActionRef } from './otpScreen';
import PasswordScreen, { PasswordScreenActionRef } from './passwordScreen';
import SetPassword from './setPassword';

import { verifyOTPForgotPasswordApi } from 'services/auth';
import SLUG_PARAMS, { PASSWORD_STEP } from 'utils/constants';

type PasswordType = {
  type: string,
  value: string
};

interface PasswordProps {
  phone: string;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  loginLoading: boolean;
  handleResendOtp: any;
  handleBack: () => void;
  handleCloseModal: () => void;
  handleSwitchOtp: () => void;
  handleSetPassword: (data: PasswordType) => void;
  isCustomerExist: boolean
  errorText?: string
  setErrorText?: (val: string) => void
  loadingOtp?: boolean
}

const Password: React.FC<PasswordProps> = ({
  phone,
  step,
  setStep,
  loginLoading,
  handleSwitchOtp,
  handleBack,
  handleCloseModal,
  handleSetPassword,
  handleResendOtp,
  isCustomerExist,
  errorText,
  setErrorText = () => { },
  loadingOtp = false
}) => {
  // const [step, setStep] = useState<number>(PASSWORD_STEP.PASSWORD);
  const [isResend, setIsResend] = useState<boolean>(false);
  const [contact, setContact] = useState<string>('');
  const passwordRef = useRef<PasswordScreenActionRef>(null);
  const otpRef = useRef<OTPScreenActionRef>(null);
  const otpForgotPasswordRef = useRef<OTPVerifyActionRef>(null);
  const [params, setParams] = useState<any>(null);

  const handleSubmit = async () => {
    const otpInformation = await otpRef.current?.handleForm();
    const passwordInformation = await passwordRef.current?.handleForm();
    if (otpInformation) {
      handleSetPassword({
        type: 'otp',
        value: otpInformation.otp
      });
    } else if (passwordInformation) {
      handleSetPassword({
        type: 'password',
        value: passwordInformation.password
      });
    }
  };

  const handleVerifyOtpForgotPassword = async () => {
    try {
      const data = otpForgotPasswordRef.current?.handleGetValue();
      const paramsVerify = {
        CustomerId: params.customerId,
        Identifier: contact,
        VerificationCode: data?.otp
      };
      const response: any = await verifyOTPForgotPasswordApi(paramsVerify);
      const resetPasswordPage = window.open(`${SLUG_PARAMS.CHANGE_PASSWORD}?contact=${contact}&otp=${data?.otp}&token=${response?.data?.token}`, '_blank');
      const interval = setInterval(() => {
        if (resetPasswordPage && resetPasswordPage.closed) {
          clearInterval(interval);
          window.focus();
        }
      }, 500);
    } catch (error: any) {
      setErrorText('Mã xác thực không hợp lệ');
    }
  };

  const renderStep = () => {
    switch (step) {
      case PASSWORD_STEP.PASSWORD:
        return (
          <PasswordScreen
            phone={phone}
            loading={loginLoading}
            handleBack={handleBack}
            handleSwitchOtp={handleSwitchOtp}
            handleForgotPassword={() => setStep(PASSWORD_STEP.FORGOT_PASSWORD)}
            handleSubmit={handleSubmit}
            ref={passwordRef}
            errorText={errorText}
            loadingOtp={loadingOtp}
          />
        );
      case PASSWORD_STEP.OTP:
        return (
          <OTPScreen
            phone={phone}
            isCustomerExist={isCustomerExist}
            handleBack={() => { setStep(PASSWORD_STEP.PASSWORD); setErrorText(''); }}
            isResend={isResend}
            loading={loginLoading}
            handleResend={(value) => {
              handleResendOtp();
              setIsResend(value);
            }}
            handleSubmit={handleSubmit}
            ref={otpRef}
            errorText={errorText}
          />
        );
      case PASSWORD_STEP.FORGOT_PASSWORD:
        return (
          <ForgotPassword
            handleBack={() => { setStep(PASSWORD_STEP.PASSWORD); setErrorText(''); }}
            handleComplete={(val) => {
              setContact(val);
              setStep(PASSWORD_STEP.VERIFY_OTP);
            }}
            setParams={setParams}
          />
        );
      case PASSWORD_STEP.VERIFY_OTP:
        return (
          <OTPForgotPassword
            phone={contact}
            isCustomerExist={isCustomerExist}
            handleBack={() => { setStep(PASSWORD_STEP.FORGOT_PASSWORD); setErrorText(''); }}
            isResend={isResend}
            loading={loginLoading}
            handleResend={(value) => {
              handleResendOtp();
              setIsResend(value);
            }}
            handleSubmit={handleVerifyOtpForgotPassword}
            errorText={errorText}
            ref={otpForgotPasswordRef}
          />
        );
      case PASSWORD_STEP.COMPLETE:
        return (
          <Complete
            handleBack={() => { setStep(PASSWORD_STEP.PASSWORD); setErrorText(''); }}
            contact={contact}
            handleCloseModal={handleCloseModal}
          />
        );
      case PASSWORD_STEP.SET_PASSWORD:
        return <SetPassword closeModal={handleCloseModal} />;
      default:
        return null;
    }
  };

  return renderStep();
};

export default Password;
