import React from 'react';

interface BackgroundBadgeProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  theme?: 'turquoise' | 'blueberry' | 'blueRyb' | 'forestGreen' | 'chocolate' | 'cadmiumRed';
  size?: 'md' | 'lg' | 'xl';
  className?: string
}

const BackgroundBadge: React.FC<BackgroundBadgeProps> = ({
  children, icon, theme, size, className = ''
}) => {
  const background = () => {
    switch (theme) {
      case 'turquoise':
        return 'border-medium-turquoise bg-medium-turquoise2 text-medium-turquoise bg-opacity-[0.16]';
      case 'blueberry':
        return 'border-blueberry bg-glitter text-cobalt-blue';
      case 'blueRyb':
        return 'border-blueRyb bg-medium-turquoise2 bg-opacity-[0.16] text-blueRyb';
      case 'forestGreen':
        return 'border-teal-deer bg-honeydew text-forest-green';
      case 'chocolate':
        return 'border-jasmine bg-cosmic-latte text-chocolate';
      case 'cadmiumRed':
        return 'border-baker-miller-pink bg-lavender-blush text-cadmium-red';
      default:
        return 'border-blueberry bg-glitter text-cobalt-blue';
    }
  };

  const boxModifier = () => {
    switch (size) {
      case 'md':
        return 'rounded py-1 px-2 h-[24px] text-xs';

      case 'lg':
        return 'rounded-lg py-1 px-2 text-xs';

      case 'xl':
        return 'rounded py-1 px-3 text-md';

      default:
        return 'rounded-3xl py-1 px-2 md:py-[6px] md:px-3';
    }
  };
  return (
    <div className={`a-backgroundBadge inline-flex items-center border ${boxModifier()} ${background()} ${className}`}>
      {
        icon
        && (
          <>
            {/* eslint-disable-next-line react/no-danger */}
            <div className='flex items-center mr-1'>
              {icon}
            </div>
          </>
        )
      }
      <p>
        {children}
      </p>
    </div>
  );
};

BackgroundBadge.defaultProps = {
  children: undefined,
};

export default BackgroundBadge;
