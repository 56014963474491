/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect, useImperativeHandle, useMemo, useRef, useState
} from 'react';
import Slider, { Settings } from 'react-slick';
import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import './index.scss';

export type SlideshowViewerType = {
  title: string;
  src: string;
};

interface SlideshowViewerProps {
  data: SlideshowViewerType[];
}

export interface SlideshowViewerRef {
  goToSlide: (i: number) => void;
}

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];
const THUMBNAIL_WIDTH = 1080;
const THUMBNAIL_HEIGHT = 780;

const SlideshowViewer = forwardRef<SlideshowViewerRef, SlideshowViewerProps>((props, ref) => {
  const { data } = props;
  const sliderRef1 = useRef<Slider | null>(null);
  const sliderInnerRef2 = useRef<Slider | null>(null);

  // State to keep track of slider navigation
  const [nav1, setNav1] = useState<Slider | null>(null);
  const [nav2, setNav2] = useState<Slider | null>(null);
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [index, setIndex] = useState(-1);

  useImperativeHandle(ref, () => ({
    goToSlide: (i: number) => {
      sliderInnerRef2.current?.slickGoTo(i);
    },
  }));

  useEffect(() => {
    setNav1(sliderRef1.current);
    setNav2(sliderInnerRef2?.current);

    return () => {
      setNav1(null);
      setNav2(null);
    };
  }, []);

  // Handler for afterChange event
  const handleAfterChange = (current: number) => {
    setActiveSlide(current);
  };

  const SLIDER_COMMON_SETTINGS: Settings = {
    autoplay: false,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    focusOnSelect: false,
  };

  const mainSettings: Settings = {
    ...SLIDER_COMMON_SETTINGS,
    slidesToShow: 1,
    arrows: false,
    afterChange: handleAfterChange,
  };

  const navSettings: Settings = {
    ...SLIDER_COMMON_SETTINGS,
    slidesToShow: 4,
    focusOnSelect: false,
    responsive: [
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };
  const lightboxThumbnail = useMemo(
    () => data.map((item) => ({
      alt: item.title || 'Image',
      src: item.src || 'fallback-image-url.jpg',
      width: THUMBNAIL_WIDTH,
      height: THUMBNAIL_HEIGHT,
      srcSet: breakpoints.map((breakpoint) => ({
        src: item.src,
        width: breakpoint,
        height: Math.round((THUMBNAIL_WIDTH / THUMBNAIL_HEIGHT) * breakpoint),
      })),
    })),
    [data]
  );
  const arrowClass = useCallback((direction: string) => classNames(
    'flex justify-center items-center w-8 h-8 rounded border border-gainsboro base-transition',
    {
      'bg-white text-raisin-black hover:bg-blueRyb hover:text-white': (activeSlide !== 0 && direction === 'left') || (activeSlide !== data.length - 1 && direction === 'right'),
      'bg-anti-flash-white2 text-slate-gray': (activeSlide === 0 && direction === 'left') || (activeSlide === data.length - 1 && direction === 'right'),
    }
  ), [activeSlide, data.length]);

  const newArray = Array(4).fill(null);

  data.forEach((item, i) => {
    newArray[i] = item;
  });

  if (!data || data.length === 0) {
    return <p className='text-center text-gray-500'>No images available</p>;
  }

  return (
    <div className='o-slideshowViewer bg-white p-2 relative'>
      <div className='o-slideshowViewer__thumbnail relative'>
        <Lightbox
          slides={lightboxThumbnail}
          open={index >= 0}
          index={index}
          zoom={{ scrollToZoom: true }}
          close={() => setIndex(-1)}
          plugins={[Fullscreen, Thumbnails, Zoom]}
          className='o-slideshowViewer__lightbox'
        />
        <Slider
          {...mainSettings}
          ref={sliderRef1}
          asNavFor={nav2 as unknown as Slider | undefined}
        >
          {data?.map((item, idx) => (
            <div
              key={`item-${idx.toString()}`}
              className='cursor-pointer'
              onClick={() => setIndex(idx)}
            >
              <div className='aspect-1 p-2'>
                <img
                  src={item.src}
                  alt={item.title}
                  className='w-full h-full object-contain'
                />
              </div>
            </div>
          ))}
        </Slider>
        {/* Arrows  */}
        <div className='justify-end items-center gap-2 p-2 hidden lg:flex'>
          <button
            aria-label='arrow'
            type='button'
            className={arrowClass('left')}
            onClick={() => sliderRef1.current?.slickPrev()}
          >
            <svg
              className='w-7 h-7'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <path
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='m14 8-4 4 4 4'
              />
            </svg>
          </button>
          <button
            aria-label='arrow'
            type='button'
            className={arrowClass('right')}
            onClick={() => sliderRef1.current?.slickNext()}
          >
            <svg
              className='w-7 h-7'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <path
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='m10 16 4-4-4-4'
              />
            </svg>
          </button>
        </div>
        {/* Index  */}
        <div className='justify-end items-center px-2 flex lg:hidden absolute right-5 bottom-9'>
          <p className='bg-raisin-black bg-opacity-80 text-white text-md px-2 py-1 rounded-[20px] min-w-[40px]'>
            {activeSlide + 1}/{data.length}
          </p>
        </div>
      </div>
      <div className='o-slideshowViewer__navigator hidden lg:block'>
        <Slider
          {...navSettings}
          ref={sliderInnerRef2}
          asNavFor={nav1 as unknown as Slider | undefined}

        >
          {newArray.map((item, idx) => (item ? (
            <div key={`item-${idx.toString()}`} className='p-2'>
              <div
                className={`aspect-1 p-2 border-2 base-transition cursor-pointer ${activeSlide === idx ? 'border-blueRyb' : 'border-gainsboro'
                  }`}
                onClick={() => sliderRef1?.current?.slickGoTo(idx)}
              >
                <img
                  src={item.src}
                  alt={item.title}
                  className='w-full h-full object-contain'
                />
              </div>
            </div>
          ) : <div />))}
        </Slider>
      </div>
    </div>
  );
});

export default SlideshowViewer;
