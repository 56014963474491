import React, {
  useEffect,
  useMemo
} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { CustomerAddressFormType } from '../CustomerAddressForm';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Textarea from 'components/atoms/Textarea';
import Pulldown from 'components/molecules/Pulldown';
import { useAppSelector } from 'store/hooks';

interface PropsDataAddress {
  lastName: string;
  firstName: string;
  name: string
  phone: any,
  email: string,
  city: string,
  address: string
  address2: {
    district?: string,
    ward?: string
    city?: string
  }
  specificAddress: string;
  isDefault?: boolean
}

interface CustomerAddressForm2Props {
  onSubmit: SubmitHandler<CustomerAddressFormType>;
  onRequestClose?: () => void;
  data?: PropsDataAddress
  isNewAddress?: boolean
}

const CustomerAddressForm2 = React.forwardRef((props: CustomerAddressForm2Props) => {
  const {
    control, handleSubmit, formState: { errors }, watch, setValue, reset,
  } = useForm<CustomerAddressFormType>();

  const { provinces } = useAppSelector((state) => state.general);
  const selectedCity = watch('city');
  const selectedDistrict = watch('district');
  const filteredDistricts = useMemo(() => {
    if (selectedCity) {
      const province = provinces?.find((p) => p.fullName === selectedCity);
      return province?.district?.map((district) => ({
        label: district.fullName,
        value: district.fullName,
      }));
    }
    return [];
  }, [selectedCity]);

  const filteredWards = useMemo(() => {
    if (selectedDistrict) {
      const province = provinces?.find((p) => p.fullName === selectedCity);
      const district = province?.district?.find((d) => d.fullName === selectedDistrict);
      return district?.ward?.map((ward) => ({
        label: ward.fullName,
        value: ward.fullName,
      }));
    }
    return [];
  }, [selectedDistrict]);

  useEffect(() => {
    if (!props.isNewAddress) {
      const fetchAddress = async () => {
        const [firstName, ...lastNameArr] = props.data?.name.split(' ') || [];
        const lastName = lastNameArr.join(' ');
        const firstIndex = props?.data?.address.indexOf(',');
        const specificAddress = props?.data?.address.substring(0, firstIndex).trim();
        const city = props?.data?.address2.city;
        const district = props?.data?.address2?.district;
        const ward = props?.data?.address2?.ward;
        const email = props?.data?.email?.includes(props?.data?.phone) ? '' : props?.data?.email;
        reset({
          lastName,
          firstName,
          phoneNumber: props?.data?.phone,
          email,
          city,
          district,
          ward,
          specificAddress,
          saveAddress2: props.data?.isDefault
        });
      };

      fetchAddress();
    }
  }, [props.data]);

  return (
    <form className='t-CustomerAddressForm2' onSubmit={handleSubmit(props.onSubmit)}>
      <div className='flex flex-col gap-y-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
          <div>
            <Controller
              name='lastName'
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <Input
                  {...field}
                  id='lastName'
                  error={errors.lastName?.message}
                  placeholder='Họ *'
                />
              )}
            />
          </div>

          <div>
            <Controller
              name='firstName'
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <Input
                  {...field}
                  id='firstName'
                  error={errors.firstName?.message}
                  placeholder='Tên *'
                />
              )}
            />
          </div>
        </div>

        <div>
          <Controller
            name='phoneNumber'
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Input
                {...field}
                id='phoneNumber'
                error={errors.phoneNumber?.message}
                type='tel'
                placeholder='Số điện thoại *'
              />
            )}
          />
        </div>

        <div>
          <Controller
            name='email'
            control={control}
            // rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Input
                {...field}
                id='email'
                type='email'
                error={errors.email?.message}
                placeholder='Email'
              />

            )}
          />
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-3 gap-4'>
          <div>
            <Controller
              name='city'
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <Pulldown
                  {...field}
                  placeholder='Chọn thành phố'
                  id='city'
                  onChange={(e) => {
                    setValue('city', e?.target?.value);
                    setValue('district', '');
                    setValue('ward', '');
                  }}
                  options={
                    provinces?.map((province) => ({
                      label: province.fullName,
                      value: province.fullName,
                    })) || []
                  }
                  error={errors?.city?.message || ''}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name='district'
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <Pulldown
                  {...field}
                  onChange={(e) => {
                    setValue('district', e?.target?.value);
                    setValue('ward', '');
                  }}
                  placeholder='Chọn Quận'
                  id='district'
                  options={filteredDistricts || []}
                  error={errors?.district?.message || ''}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name='ward'
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <Pulldown
                  {...field}
                  placeholder='Chọn phường'
                  id='ward'
                  options={filteredWards || []}
                  error={errors?.ward?.message || ''}
                />
              )}
            />
          </div>
        </div>

        <div>
          <Controller
            name='specificAddress'
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <Textarea
                {...field}
                id='specificAddress'
                error={errors.specificAddress?.message}
                placeholder='Địa chỉ cụ thể *'
              />
            )}
          />
        </div>

        <div className='flex items-center'>
          <Controller
            control={control}
            name='saveAddress2'
            render={({
              field: { onChange, onBlur, value },
            }) => (

              <label htmlFor='saveAddress2' className='inline-flex items-center cursor-pointer'>
                <input
                  onChange={onChange}
                  onBlur={onBlur}
                  checked={value}
                  id='saveAddress2'
                  type='checkbox'
                  className='sr-only peer'
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600" />
                <span className='ml-2 font-medium text-gunmetal'>
                  Đặt làm địa chỉ mua hàng mặc định
                </span>
              </label>

            )}
          />
        </div>
      </div>
      <div className='h-[1px] bg-gainsboro w-full mt-6' />

      <div className='submit mt-4 lg:mt-6'>
        <div className='flex flex-row gap-4 justify-end'>
          <Button
            type='button'
            size='large'
            onClick={props.onRequestClose}
            variants='secondary'
            className='max-w-[120px]'
          >
            Quay lại
          </Button>
          <Button
            type='submit'
            size='large'
            className='max-w-[120px]'
          >
            Lưu
          </Button>
        </div>
      </div>
    </form>
  );
});

export default CustomerAddressForm2;
