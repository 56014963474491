import React, { useCallback, useState } from 'react';

import { citiesOptions } from 'assets/dummy';
import BackgroundBadge from 'components/atoms/BackgroundBadge';
import Button from 'components/atoms/Button';
import Radio from 'components/atoms/Radio';
import { setUserId } from 'store/auth';
import { useAppDispatch } from 'store/hooks';

export interface AddressItemType {
  id: number;
  name: string;
  phone: string;
  address: string;
  email?: string;
  city?: string;
  address2?: string;
  isDefault?: boolean; // Indicates if this address is the default
}

interface AddressSelectorProps {
  addresses: AddressItemType[];
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
  isNaked?: boolean;
  isShowDivider?: boolean;
  selectedAddress?: AddressItemType | null;
  handleChangeAddress?: (value: AddressItemType) => void;
}

const AddressSelector: React.FC<AddressSelectorProps> = ({
  addresses,
  isNaked,
  onEdit,
  onDelete,
  isShowDivider,
  handleChangeAddress = () => { },
  selectedAddress = null,
}) => {
  const sortedAddresses = addresses.sort((a) => (a.isDefault ? -1 : 1));
  // Sort addresses so default is first
  const [selectedAddressId, setSelectedAddressId] = useState<number>(
    selectedAddress?.id || sortedAddresses[0].id
  );

  const dispatch = useAppDispatch();

  const handleSelect = (address: AddressItemType) => {
    setSelectedAddressId(address.id);
    handleChangeAddress(address);
  };

  const getActiveClass = (isActive: boolean) => (isActive
    ? 'border-blueRyb border-[2px] shadow-input-focus'
    : 'border-gainsboro');

  const getClassName = useCallback(
    (isLastIndex: boolean) => (isLastIndex ? '' : 'pb-6 border-b-1 border-b-gainsboro'),
    []
  );

  const activeClass = useCallback(
    (isActive: boolean) => (isNaked ? '' : ` p-4 border rounded-lg ${getActiveClass(isActive)}`),
    [isNaked]
  );

  function processAddresses(addressess: AddressItemType[], Options: any[]) {
    return addressess?.map((address) => {
      const processedAddress = { ...address };
      const lastCommaIndex = address?.address?.lastIndexOf(',');
      const cityCode = address?.address?.substring(lastCommaIndex + 1).trim();
      const matchedCity = Options?.find((city) => city?.id === cityCode?.toLowerCase());
      if (matchedCity) {
        processedAddress.address = `${address?.address?.substring(0, lastCommaIndex + 1)} ${matchedCity.label}`;
      }

      return processedAddress;
    });
  }

  const processedAddresses: AddressItemType[] = processAddresses(sortedAddresses, citiesOptions);

  return (
    <div className={`${isNaked ? 'space-y-4 lg:space-y-6' : 'space-y-4'}`}>
      {processedAddresses.map((address, index) => (
        <div
          key={`address-${address.id}-${String(index)}`}
          onClick={() => handleSelect(address)}
          className={`relative cursor-pointer ${activeClass(
            selectedAddressId === address.id
          )} ${isShowDivider
            ? getClassName(index === sortedAddresses.length - 1)
            : ''
            }`}
        >
          {!isNaked && (
            <div className='absolute top-2 right-2 cursor-pointer'>
              <Radio
                name='address'
                value={address.id}
                checked={selectedAddressId === address.id}
                onChange={(e) => {
                  e.stopPropagation();
                  handleSelect(address);
                }}
              />
            </div>
          )}
          <div>
            <div className='flex items-center'>
              <h2 className='font-semibold text-lg text-raisin-black mr-3'>
                {address.name}
              </h2>
              {address.isDefault && (
                <BackgroundBadge
                  size='md'
                  theme='blueRyb'
                  className='border-transparent'
                >
                  Mặc định
                </BackgroundBadge>
              )}
            </div>
            <div className='text-gunmetal'>
              <p>{address.phone}</p>
              <p className=''>{address.address}</p>
            </div>
            <div className='mt-4 flex flex-wrap gap-2'>
              <Button
                variants='secondary'
                className='w-auto'
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit?.(address.id);
                  dispatch(setUserId(address.id));
                }}
              >
                Chỉnh Sửa
              </Button>
              {/* <Button
                onClick={() => handleSelect(address.id)}
                className={`${address.isDefault
                  ? 'bg-anti-flash-white2 text-slate-gray cursor-not-allowed'
                  : 'text-gunmetal bg-white hover:bg-blueRyb hover:text-white border-blueRyb border'
                  } h-9 text-sm w-auto min-w-[105px]`}
                disabled={address.isDefault}
              >
                Mặc Định
              </Button> */}
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete?.(address.id);
                  dispatch(setUserId(address.id));
                }}
                variants='secondary'
                className='w-auto'
                disabled={address.isDefault}
              >
                Xoá
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddressSelector;
