/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import { o } from 'ramda';
import React, { forwardRef, useState } from 'react';
import { toast } from 'react-toastify';

import CustomerAddressForm, { CustomerAddressFormType } from '../CustomerAddressForm';
import EditAddressModal from '../EditAddressModal';
import UpdateAddressModal from '../UpdateAddressModal';

import { paymentOptions } from 'assets/dummy';
import Button from 'components/atoms/Button';
import Textarea from 'components/atoms/Textarea';
import { AddressItemType } from 'components/organisms/AddressSelector';
import ContentPanel from 'components/organisms/ContentPanel';
import DeliveryPartner from 'components/organisms/DeliveryPartner';
import CustomModal from 'components/organisms/Modal';
import PaymentMethodSelection, { PaymentOptionsType } from 'components/organisms/PaymentMethodSelection';
import { addDeliveryAddressApi, deleteDeliveryAddressApi, editDeliveryAddressApi } from 'services/profile';
import { useAppSelector } from 'store/hooks';
import { IParamsAddress } from 'types/users';
import { parseCurrency } from 'utils/functions';

export interface PaymentInfoProps {
  isStatic?: boolean;
  noTitle?: boolean;
  notes?: string;
  paymentMethod?: string;
  addresses?: AddressItemType[];
  selectedAddress?: AddressItemType | null
  handleChangeShippingAddress?: (address: AddressItemType | null) => void;
  fetchDataDeliveryAddress?: () => void;
  handleNotesChange?: (e: any) => void
  onPaymentMethodSelected?: (value: PaymentOptionsType) => void
  isRepayment?: boolean;
}

const PaymentInfo = forwardRef(({
  isStatic,
  noTitle,
  notes,
  paymentMethod = '',
  addresses = [],
  selectedAddress = null,
  fetchDataDeliveryAddress,
  handleNotesChange,
  handleChangeShippingAddress = (address: AddressItemType | null) => { console.log(address); },
  onPaymentMethodSelected,
  isRepayment = false
}: PaymentInfoProps, ref) => {
  const [openAddress, setOpenAddress] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [openDeleteAddress, setOpenDeleteAddress] = useState(false);
  const userId = useAppSelector((state: any) => state.auth.userId);
  const addressState = useAppSelector((state) => state.cart.address);
  const [editAddressId, setEditAddressId] = useState<number | null>(null);
  const [pickedAddress, setPickedAddress] = useState<AddressItemType | null>(null);
  const { orderTotals, repaymentData } = useAppSelector((state) => state.cart);

  const over20Value = () => {
    if (isRepayment) {
      const value = parseCurrency(repaymentData?.orderTotal || '');
      return value;
    }
    const value = parseCurrency(orderTotals?.orderTotal || '');
    return value;
  };

  const onChange = async (
    address: AddressItemType | null
  ) => {
    handleChangeShippingAddress(address || addressState as AddressItemType);
    setOpenAddress(false);
  };

  const handleDeleteAddress = async (id: number) => {
    try {
      await deleteDeliveryAddressApi(id);
      fetchDataDeliveryAddress?.();
      setOpenDeleteAddress(false);
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };

  const handleFormSubmit = async (data: CustomerAddressFormType) => {
    try {
      setOpenEditAddress(false);
      if (newAddress) {
        const paramsAdd = {
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
          email: data.email || `${data.phoneNumber}@gmail.com`,
          address1: data.specificAddress,
          address2: JSON.stringify({
            district: data.district,
            ward: data.ward,
            city: data.city,
          })
        };
        const formValue = [
          {
            Key: 'address_attribute_1',
            Value: data?.saveAddress2 ? 1 : 0
          }
        ];
        await addDeliveryAddressApi(paramsAdd as IParamsAddress, formValue);
        toast.success('Thêm địa chỉ thành công!');
      } else {
        const paramsEdit = {
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
          email: data.email || `${data.phoneNumber}@gmail.com`,
          address1: data.specificAddress,
          address2: JSON.stringify({
            district: data.district,
            ward: data.ward,
            city: data.city,
          }),
          id: editAddressId
        };
        const formValue = [
          {
            Key: 'address_attribute_1',
            Value: data?.saveAddress2 ? 1 : 0
          }
        ];
        setOpenAddress(true);
        await editDeliveryAddressApi(paramsEdit as IParamsAddress, editAddressId as number, formValue);
        toast.success('Cập nhật địa chỉ thành công!');
      }

      fetchDataDeliveryAddress?.();
    } catch (error: any) {
      toast.error(error.response.data.ErrorList[0] || 'Đã xảy ra lỗi!');
    }
  };

  const dataUserEdit = addresses.filter((address: AddressItemType) => address?.id === editAddressId);

  const handleChangePaymentMethod = (value: string) => {
    onPaymentMethodSelected?.(paymentOptions.find((item) => item.id === value) || paymentOptions[0]);
  };

  return (
    <>
      <div className='t-paymentInfo'>
        {!noTitle && (
          <h2 className='text-xl md:text-2xl lg:text-3xl hidden lg:block font-semibold lg:mb-4 lg:px-0 px-4 lg:py-0 py-3 lg:border-none border-b-1 border-gainsboro'>
            Thông tin thanh toán
          </h2>
        )}
        <div className='flex flex-col gap-6'>
          <ContentPanel
            title={
              selectedAddress ? 'Địa chỉ giao hàng' : 'Thông tin vận chuyển'
            }
            topAction={
              selectedAddress && !isStatic && !isRepayment ? (
                <button
                  className='text-brilliant-azure font-semibold p-0'
                  onClick={() => setOpenAddress(true)}
                >
                  Thay đổi
                </button>
              ) : null
            }
          >
            {selectedAddress ? (
              <div className='text-gunmetal flex flex-col gap-1'>
                <div className='user flex'>
                  <p className=' font-semibold'>
                    {selectedAddress?.name} | {selectedAddress?.phone}
                  </p>
                </div>
                {!selectedAddress?.email?.includes(selectedAddress?.phone)
                  && <p>Email: {selectedAddress?.email || ''}</p>}
                <p>Địa chỉ: {selectedAddress?.address || ''}</p>
              </div>
            ) : (
              <CustomerAddressForm onSubmit={(value) => { console.log(value); }} ref={ref} />
            )}
          </ContentPanel>
          <ContentPanel title='Ghi chú cho đơn hàng'>
            {isStatic || isRepayment ? (
              <div>
                <p className='text-gunmetal'>{notes}</p>
              </div>
            ) : (
              <Textarea disabled={isRepayment} onChange={handleNotesChange} rows={3} placeholder='Nhập ghi chú' />
            )}
          </ContentPanel>
          <ContentPanel title='Đơn vị vận chuyển'>
            <DeliveryPartner isStatic={isStatic} />
          </ContentPanel>
          <ContentPanel title='Phương thức thanh toán'>
            <PaymentMethodSelection
              isStatic={isStatic}
              options={paymentOptions}
              // TODO: For orders over 20 million VND, add the VietQR payment method ,Banking, and disable Zalo and VNPay.
              isOver20={over20Value() >= 20000000}
              selectedValue={paymentMethod || paymentOptions[0].id}
              onChange={handleChangePaymentMethod}
            />
          </ContentPanel>
        </div>
      </div>
      {/* ========================= MODAL ================================  */}
      <EditAddressModal
        addressData={addresses}
        selectedAddress={selectedAddress}
        handleSubmit={(value) => onChange(value)}
        isOpen={openAddress}
        onEdit={(id) => {
          setEditAddressId(id);
          setOpenEditAddress(true);
          setOpenAddress(false);
          setNewAddress(false);
        }}
        onAdd={() => {
          setOpenEditAddress(true);
          setNewAddress(true);
        }}
        onDelete={() => {
          setOpenDeleteAddress(true);
          setOpenAddress(false);
        }}
        onRequestClose={() => {
          setOpenAddress(false);
          setEditAddressId(null);
        }}
        initPickedAddress={pickedAddress}
        onPickedAddress={(value) => setPickedAddress(value)}
      />
      <UpdateAddressModal
        title={newAddress ? 'Thêm địa chỉ' : 'Cập nhật địa chỉ'}
        isOpen={openEditAddress}
        onRequestClose={() => {
          setOpenEditAddress(false);
          setOpenAddress(true);
        }}
        handleSubmit={handleFormSubmit}
        userAddress={dataUserEdit?.[0]}
        isNewAddress={newAddress}
      />

      {/* Delete address popup */}
      <CustomModal
        isOpen={openDeleteAddress}
        onRequestClose={() => setOpenDeleteAddress(false)}
        title='Xóa địa chỉ'
        modalFooter={(
          <div className='flex flex-row gap-4 justify-end'>
            <Button
              onClick={() => setOpenDeleteAddress(false)}
              size='large'
              variants='secondary'
              className='max-w-[120px]'
            >
              Không
            </Button>
            <Button
              size='large'
              variants='neon-fuchsia'
              className='max-w-[120px]'
              onClick={() => handleDeleteAddress(userId)}
            >
              Đồng ý
            </Button>
          </div>
        )}
      >
        <p className='text-gunmetal p-6'>
          Bạn có chắc muốn xóa địa chỉ đã chọn không ?
        </p>
      </CustomModal>
    </>
  );
});
export default PaymentInfo;
